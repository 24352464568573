import React from 'react';
import PropTypes from 'prop-types';
import Loading from '../pr-loading';
import './pr-button.scss';
import PrInfoButton from '../pr-info-button';
import PrTooltipComponent from '../pr-tooltip';

const PrButton = ({ value, onClick, type, isDisabled, isLoading, icon, tooltip, svg, suffix }) => {
  const noLoadWithNoSuffix = !isLoading && !suffix;
  const noLoadWithSuffix = !isLoading && suffix;
  return (
    <div
      className={`pr-button-wrapper ${type || ''} ${isDisabled ? 'disable' : ''} ${
        isLoading ? 'loading' : ''
      }`}
      onClick={(e) => onClick && onClick(e)}
    >
      {isLoading && <Loading type="button" color="#fff" />}
      {noLoadWithNoSuffix && icon ? <img src={icon} alt=""></img> : null}
      {noLoadWithNoSuffix && svg  ? svg : null}
      {!isLoading && <p>{value}</p>}
      {noLoadWithSuffix && icon ? <img src={icon} alt=""></img> : null}
      {noLoadWithSuffix && svg ? svg : null}
      {!isLoading && type.includes('withIcon') ? (
        <div className="tooltip-wrap">
          <PrInfoButton></PrInfoButton>
          <PrTooltipComponent
            type="with-triangle large right black"
            CTAsList={[{ type: 'description', name: tooltip }]}
          ></PrTooltipComponent>
        </div>
      ) : null}
      {!isLoading && type.includes('btn-tooltip') && (
        <PrTooltipComponent
          type="with-triangle large bottom-right black"
          CTAsList={[{ type: 'description', name: tooltip }]}
        ></PrTooltipComponent>
      )}
    </div>
  );
};

PrButton.propTypes = {
  value: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  type: PropTypes.string,
  icon: PropTypes.string,
  isLoading: PropTypes.bool,
  dot: PropTypes.bool,
  isDisabled: PropTypes.bool,
  suffix: PropTypes.bool,
};

PrButton.defaultProps = {
  onClick: undefined,
  dot: false,
  type: '',
  dotColor: undefined,
  isLoading: false,
  isDisabled: false,
  icon: null,
  suffix: false,
};

export default PrButton;
