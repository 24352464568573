const CANDIDATES_AT_RISK_REQUEST = 'CANDIDATES_AT_RISK_REQUEST';
const CANDIDATES_AT_RISK_SUCCESS = 'CANDIDATES_AT_RISK_SUCCESS';
const CANDIDATES_AT_RISK_FAILURE = 'CANDIDATES_AT_RISK_FAILURE';

const candidatesAtRiskRequest = (dispatch) => {
  dispatch({ type: CANDIDATES_AT_RISK_REQUEST, payload: { loading: true } });
};

const candidatesAtRiskSuccess = (dispatch, response) => {
  dispatch({
    type: CANDIDATES_AT_RISK_SUCCESS,
    payload: { data: response, loading: false },
  });
};

const candidatesAtRiskFailure = (dispatch) => {
  dispatch({ type: CANDIDATES_AT_RISK_FAILURE, payload: { loading: false } });
};

export {
  CANDIDATES_AT_RISK_REQUEST,
  CANDIDATES_AT_RISK_SUCCESS,
  CANDIDATES_AT_RISK_FAILURE,
  candidatesAtRiskRequest,
  candidatesAtRiskSuccess,
  candidatesAtRiskFailure,
};
