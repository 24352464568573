const CLIENTS_AT_RISK_REQUEST = 'CLIENTS_AT_RISK_REQUEST';
const CLIENTS_AT_RISK_SUCCESS = 'CLIENTS_AT_RISK_SUCCESS';
const CLIENTS_AT_RISK_FAILURE = 'CLIENTS_AT_RISK_FAILURE';

const clientsAtRiskRequest = (dispatch) => {
  dispatch({ type: CLIENTS_AT_RISK_REQUEST, payload: { loading: true } });
};

const clientsAtRiskSuccess = (dispatch, response) => {
  dispatch({
    type: CLIENTS_AT_RISK_SUCCESS,
    payload: { data: response, loading: false },
  });
};

const clientsAtRiskFailure = (dispatch) => {
  dispatch({ type: CLIENTS_AT_RISK_FAILURE, payload: { loading: false } });
};

export {
  CLIENTS_AT_RISK_REQUEST,
  CLIENTS_AT_RISK_SUCCESS,
  CLIENTS_AT_RISK_FAILURE,
  clientsAtRiskRequest,
  clientsAtRiskSuccess,
  clientsAtRiskFailure,
};
