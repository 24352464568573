import axios from 'axios';
import store from 'store2';
import {
  addCompanyRequest,
  addCompanySuccess,
  addCompanyFailure,
  holidayCalendarProcessing,
  holidayCalendarSuccess,
  holidayCalendarFailure,
} from './actions';
import { consoleOnlyInDebugMode } from '../../../../utils/debugging';
import { validObjectWithParameterKeys, extractErrorMessage } from '../../../../utils/commonUtils';
import {
  companyDetailsRequest,
  companyDetailsSuccess,
  companyDetailsFailure,
} from '../Edit/Common/actions';
import { errorConstants } from '../../../../redux/constants';

const getCompanyData = async (id, dispatch) => {
  companyDetailsRequest(dispatch);
  try {
    const url = `${process.env.REACT_APP_APIHOST}/companies/${id}`;
    const res = await axios.get(url, {
      headers: {
        'x-access-token': store.get('user').token,
      },
    });
    companyDetailsSuccess(dispatch, res.data.data, res.data.field_permissions);
    return res.data.data;
  } catch (error) {
    dispatch({
      type: errorConstants.SET_ERROR,
      error: extractErrorMessage(error),
    });
    companyDetailsFailure(dispatch);
    consoleOnlyInDebugMode('err--add/edit client', error);
    return false;
  }
};

const addCompany = (companyDetails, callback) => (dispatch) => {
  addCompanyRequest(dispatch);
  const url = `${process.env.REACT_APP_APIHOST}/companies`;
  axios
    .post(url, companyDetails, {
      headers: {
        'x-access-token': store.get('user').token,
      },
    })
    .then(async (res) => {
      // NOTE: Backend assign account manager to the newly added client,
      // but that assignment does not happen immediately, so that value
      // is not possible to get from add response, to fix that, we are
      // calling company details api again.
      const data = await getCompanyData(res.data.data._id, dispatch);
      if (data) {
        addCompanySuccess(dispatch, data);
        callback(data);
      }
    })
    .catch((err) => {
      dispatch({
        type: errorConstants.SET_ERROR,
        error: extractErrorMessage(err),
      });
      addCompanyFailure(dispatch, extractErrorMessage(err));
      if (err.response && err.response.status && err.response.status === 403) {
        callback(
          err.response.data && {
            ...err.response.data.company,
            alreadyExistedDomain: true,
          },
        );
      }
      consoleOnlyInDebugMode('err-addCompany', err.response);
    });
};

const updateCompany = (id, company, successCallback) => async (dispatch) => {
  try {
    addCompanyRequest(dispatch);
    const companyDetails = { ...company };
    // deleting extra keys
    if (validObjectWithParameterKeys(companyDetails, ['isEditing'])) {
      delete companyDetails.isEditing;
    }
    if (
      validObjectWithParameterKeys(companyDetails, ['locations']) &&
      validObjectWithParameterKeys(companyDetails.locations, ['location_coordinates'])
    ) {
      delete companyDetails.locations.location_coordinates;
    }
    const url = `${process.env.REACT_APP_APIHOST}/companies/update`;
    const res = await axios.post(
      url,
      { ...companyDetails, company: id },
      {
        headers: {
          'x-access-token': store.get('user').token,
        },
      },
    );
    addCompanySuccess(dispatch, {});
    successCallback(company);
    return res.data;
  } catch (err) {
    dispatch({
      type: errorConstants.SET_ERROR,
      error: extractErrorMessage(err),
    });
    addCompanyFailure(dispatch, extractErrorMessage(err));
    consoleOnlyInDebugMode('err-updateCompany', err.response);
    return false;
  }
};

const getClientUsers = (clientId, keyword) => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_APIHOST}/companies/users/${clientId}?page=1&limit=0&sort=name&order=asc&keyword=${keyword}`;
    const res = await axios.post(
      url,
      { name: keyword },
      {
        headers: {
          'x-access-token': store.get('user').token,
        },
      },
    );
    return res.data.data.rows;
  } catch (error) {
    dispatch({
      type: errorConstants.SET_ERROR,
      error: extractErrorMessage(error),
    });
    addCompanyFailure(dispatch, extractErrorMessage(error));
    consoleOnlyInDebugMode('err-getResources', extractErrorMessage(error));
    return undefined;
  }
};

const getHolidayCalendar = async (dispatch) => {
  holidayCalendarProcessing(dispatch);
  try {
    const url = `${process.env.REACT_APP_APIHOST}/settings?select=holidaycalendars`;
    const res = await axios.get(url, {
      headers: { 'x-access-token': store.get('user').token },
    });

    const data = res.data.data.holiday_calendars.map((holiday) => ({
      label: holiday.name,
      value: holiday._id,
      ...holiday,
    }));
    holidayCalendarSuccess(dispatch, data);

    return data;
  } catch (error) {
    dispatch({
      type: errorConstants.SET_ERROR,
      error: extractErrorMessage(error),
    });
    holidayCalendarFailure(dispatch, extractErrorMessage(error));
    consoleOnlyInDebugMode('err-getHolidayCalendar', extractErrorMessage(error));
    return false;
  }
};

const checkIfDomainExists = (parameters) => async (dispatch, getState, api) => {
  try {
    const url = `${process.env.REACT_APP_APIHOST}/companies/check-domain-availability`;
    const res = await api.post(url, { data: parameters });
    const { message } = res;
    const resData = {
      message,
    };

    return resData;
  } catch (error) {
    const resData = {
      error,
    };
    dispatch({
      type: errorConstants.SET_ERROR,
      error: error || messages.DEFAULT_ERROR_MESSAGE,
    });
    consoleOnlyInDebugMode('err-checkIfDomainExists', extractErrorMessage(error));
    return resData;
  }
};

export {
  addCompany,
  updateCompany,
  getClientUsers,
  getCompanyData,
  getHolidayCalendar,
  checkIfDomainExists,
};
