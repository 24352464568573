import axios from 'axios';
import store from 'store2';
import { removeInValidKeyValuePairs } from '../../../../../utils/commonUtils';
import { consoleOnlyInDebugMode } from '../../../../../utils/debugging';
import { notificationRequest, notificationSuccess, notificationFailure } from './actions';

const APIUrl = `${process.env.REACT_APP_APIHOST}/alerts/list`;

const getNotificationContents =
  (pn = 1, ps = 10, sc = 'alert_id', st = 'desc', tableFilters = {}) =>
  (dispatch) => {
    const searchFilters = {
      ...tableFilters,
    };
    notificationRequest(dispatch);
    const url = `${APIUrl}?page=${pn}&limit=${ps}&sort=${sc}&order=${st}`;
    axios
      .post(url, removeInValidKeyValuePairs(searchFilters), {
        headers: {
          'x-access-token': store.get('user').token,
        },
      })
      .then((res) => {
        notificationSuccess(dispatch, res.data.data);
      })
      .catch((err) => {
        notificationFailure(dispatch, err.Error);
        consoleOnlyInDebugMode('err-getAttachments', err.Error);
      });
  };

export {
  getNotificationContents,
};
