const REQUEST = 'REQUEST';
const SUCCESS = 'SUCCESS';
const FAILURE = 'FAILURE';

const request = (dispatch, key = 'data') => {
  dispatch({ type: REQUEST, payload: { loading: true, [key]: {} } });
};

const success = (dispatch, response, key = 'data') => {
  dispatch({ type: SUCCESS, payload: { [key]: response, loading: false } });
};

const failure = (dispatch, err) => {
  dispatch({ type: FAILURE, payload: { err, loading: false } });
};

export { request, success, failure, REQUEST, SUCCESS, FAILURE };
