export const CLIENTS_PROCESSING = 'placementsWorkbench/CLIENTS_PROCESSING';
export const CLIENTS_SUCCESS = 'placementsWorkbench/CLIENTS_SUCCESS';
export const CLIENTS_FAILURE = 'placementsWorkbench/CLIENTS_FAILURE';
export const JOBS_PROCESSING = 'placementsWorkbench/JOBS_PROCESSING';
export const JOBS_SUCCESS = 'placementsWorkbench/JOBS_SUCCESS';
export const JOBS_FAILURE = 'placementsWorkbench/JOBS_FAILURE';
export const EMPLOYEES_PROCESSING = 'placementsWorkbench/EMPLOYEES_PROCESSING';
export const EMPLOYEES_SUCCESS = 'placementsWorkbench/EMPLOYEES_SUCCESS';
export const EMPLOYEES_FAILURE = 'placementsWorkbench/EMPLOYEES_FAILURE';
export const DEPARTMENTS_PROCESSING = 'placementsWorkbench/DEPARTMENTS_PROCESSING';
export const DEPARTMENTS_SUCCESS = 'placementsWorkbench/DEPARTMENTS_SUCCESS';
export const DEPARTMENTS_FAILURE = 'placementsWorkbench/DEPARTMENTS_FAILURE';
export const RULES_AND_HOLIDAY_CALENDAR_PROCESSING =
  'placementsWorkbench/RULES_AND_HOLIDAY_CALENDAR_PROCESSING';
export const RULES_AND_HOLIDAY_CALENDAR_SUCCESS =
  'placementsWorkbench/RULES_AND_HOLIDAY_CALENDAR_SUCCESS';
export const RULES_AND_HOLIDAY_CALENDAR_FAILURE =
  'placementsWorkbench/RULES_AND_HOLIDAY_CALENDAR_FAILURE';
export const ADD_PLACEMENT_PROCESSING = 'placementsWorkbench/ADD_PLACEMENT_PROCESSING';
export const ADD_PLACEMENT_SUCCESS = 'placementsWorkbench/ADD_PLACEMENT_SUCCESS';
export const ADD_PLACEMENT_FAILURE = 'placementsWorkbench/ADD_PLACEMENT_FAILURE';
export const PLACEMENT_DETAIL_PROCESSING = 'placementsWorkbench/PLACEMENT_DETAIL_PROCESSING';
export const PLACEMENT_DETAIL_SUCCESS = 'placementsWorkbench/PLACEMENT_DETAIL_SUCCESS';
export const PLACEMENT_DETAIL_FAILURE = 'placementsWorkbench/PLACEMENT_DETAIL_FAILURE';
export const JOB_MANAGERS_PROCESSING = 'placementsWorkbench/JOB_MANAGERS_PROCESSING';
export const JOB_MANAGERS_SUCCESS = 'placementsWorkbench/JOB_MANAGERS_SUCCESS';
export const JOB_MANAGERS_FAILURE = 'placementsWorkbench/JOB_MANAGERS_FAILURE';
export const RECRUITERS_PROCESSING = 'placementsWorkbench/RECRUITERS_PROCESSING';
export const RECRUITERS_SUCCESS = 'placementsWorkbench/RECRUITERS_SUCCESS';
export const RECRUITERS_FAILURE = 'placementsWorkbench/RECRUITERS_FAILURE';
export const VERIFIERS_PROCESSING = 'placementsWorkbench/VERIFIERS_PROCESSING';
export const VERIFIERS_SUCCESS = 'placementsWorkbench/VERIFIERS_SUCCESS';
export const VERIFIERS_FAILURE = 'placementsWorkbench/VERIFIERS_FAILURE';
export const VERSIONS_PROCESSING = 'placementsWorkbench/VERSIONS_PROCESSING';
export const VERSIONS_SUCCESS = 'placementsWorkbench/VERSIONS_SUCCESS';
export const VERSIONS_FAILURE = 'placementsWorkbench/VERSIONS_FAILURE';

export const addPlacementConstant = {
  LOAD: 'placement/add/LOAD',
  LOAD_INLINE: 'placement/add/LOAD_INLINE',
  LOAD_SUCCESS: 'placement/add/LOAD_SUCCESS',
  LOAD_FAIL: 'placement/add/LOAD_FAIL',

  GET_CLIENTS_ARRAY: 'placements/add/GET_CLIENTS_ARRAY',
  GET_CANDIDATES_ARRAY: 'placements/add/GET_CANDIDATES_ARRAY',
  GET_JOB_ARRAY: 'placements/add/GET_JOB_ARRAY',
  GET_RULES_AND_HOLIDAY_CALENDER: 'placements/add/GET_RULES_AND_HOLIDAY_CALENDER',
  GET_CANDIDATE_DETAIL: 'placements/add/GET_CANDIDATE_DETAIL',
  GET_JOB_DETAIL: 'placements/add/GET_JOB_DETAIL',
  GET_CLIENT_DETAIL: 'placements/add/GET_CLIENT_DETAIL',
  ADD_NEW_PLACEMENT: 'placements/add/ADD_NEW_PLACEMENT',
};
