import { NOTIFICATIONS_REQUEST, NOTIFICATIONS__SUCCESS, NOTIFICATIONS__FAILURE } from './actions';

const initialState = {};

const adminNotifications = (state = initialState, action) => {
  switch (action.type) {
    case NOTIFICATIONS_REQUEST:
    case NOTIFICATIONS__SUCCESS:
    case NOTIFICATIONS__FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export { adminNotifications };
