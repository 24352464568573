import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Route, Switch } from 'react-router-dom';
import CRMModule from '../containers/WorkBench/Client/Edit/SubModules/CRMModule';
import {
  AddAjnaUser,
  AddCandidate,
  AddClient,
  AddLocation,
  AddRegion,
  DebuggingTool,
  ViewRule,
  AdminDashBoard,
  AjnaTeam,
  CandidatesList,
  CandidateJobInvite,
  ClientDashboard,
  ClientsList,
  CreateJob,
  CompanyProfile,
  Domains,
  EditCandidate,
  EditAjnaUser,
  EditJob,
  EditLocation,
  EditRegion,
  EditUser,
  InterviewDashboard,
  InterviewsList,
  JobList,
  ListAjnaUsers,
  ListRegions,
  Locations,
  ManageUsers,
  PlacementsWorkbenchList,
  PlacementsWorkbenchAdd,
  RulesCalculation,
  TimesheetList,
  ViewTimesheet,
  PlacementsWorkbenchEdit,
  InvoiceListView,
  InterviewScheduleTemplate,
} from './LoadableComponents';
import NotFound from '../containers/404-not-found';
import { getPermission } from '../redux/selectors/permissions';
import Loading from '../components/--primitives/pr-loading';
import Risk from '../containers/Dashboard/Risk';
import Reports from '../containers/WorkBench/Reports';
import Leads from '../containers/WorkBench/Leads';
import NotificationContents from '../containers/Dashboard/Modules/NotificationContents/List';
import emailEdit from '../containers/Dashboard/Modules/NotificationContents/Edit';
function AdminRoutes({
  doHaveAjnaUsersPermission,
  doHaveRegionsPermission,
  doHaveFinancialRulesPermission,
  tenantConfig,
}) {
  // NOTE: Do not change the condition, we need to check for undefined only
  // We are adding these checks directly in admin routes, as there is no extra
  // check for which we need to call any other API then permission API, like
  // client location and user permissions.
  if (
    doHaveAjnaUsersPermission === undefined ||
    doHaveRegionsPermission === undefined ||
    doHaveFinancialRulesPermission === undefined
  ) {
    return <Loading type="overlay" />;
  }
  const enableFinancial = tenantConfig.finance;

  const notFoundPage = () => <NotFound />;
  return (
    <Switch>
      <Route exact path="/job/create/:client/:clientUser" component={CreateJob} />
      <Route exact path="/job/:id" component={EditJob} />
      <Route exact path="/job/:id/:option" component={EditJob} />
      <Route exact path="/job/:id/:option/:candidateId" component={EditJob} />
      <Route exact path="/job/:id/:option/:candidateId/:action" component={EditJob} />
      <Route exact path="/interviews-list-view" component={InterviewsList} />
      <Route exact path="/interview-dashboard/:id/:option" component={InterviewDashboard} />
      <Route
        exact
        path="/jobs/:id/schedule-template/:templateId"
        component={InterviewScheduleTemplate}
      />
      <Route
        exact
        path="/jobs/:id/schedule-template/:templateId/:mode"
        component={InterviewScheduleTemplate}
      />
      <Route exact path="/job-list-view" component={JobList} />

      {/* Candidate */}
      <Route exact path="/candidates-list-view" component={CandidatesList} />
      <Route exact path="/add-candidate" component={AddCandidate} />
      <Route exact path="/candidate-dashboard/:id" component={EditCandidate} />
      <Route exact path="/candidate-dashboard/:id/:page" component={EditCandidate} />
      <Route exact path="/candidate-dashboard/:id/:page/:interviewId" component={EditCandidate} />
      <Route exact path="/candidate/job-invite" component={CandidateJobInvite} />
      <Route exact path="/client-functions/domains/:id" component={Domains} />

      {/* Locations */}
      <Route exact path="/client-functions/locations/:id" component={Locations} />
      <Route exact path="/client-functions/locations/:id/add" component={AddLocation} />
      <Route
        exact
        path="/client-functions/location/:id/:locationId/edit"
        component={EditLocation}
      />

      <Route exact path="/client-functions/location/:id/edit" component={EditLocation} />

      <Route exact path="/client-functions/manage-users/:id" component={ManageUsers} />
      <Route exact path="/client-functions/manage-users/:id/edit/:userId" component={EditUser} />
      <Route exact path="/client-functions/profile/:id/:currentLevel" component={CompanyProfile} />
      <Route exact path="/clients-list-view" component={ClientsList} />
      <Route exact path="/clients-list-view/ajnateam/:id" component={AjnaTeam} />

      {/* Client */}
      <Route exact path="/client/:action" component={AddClient} />
      <Route exact path="/client/:action/:id/:tab" component={AddClient} />
      <Route exact path="/client-dashboard/:id" component={ClientDashboard} />
      <Route exact path="/client-functions/:id/:moduleType" component={CRMModule} />
      <Route path="/timesheet-list-view" component={TimesheetList} />
      <Route exact path="/timesheet/:id" component={ViewTimesheet} />
      <Route exact path="/timesheet/:id/:page" component={ViewTimesheet} />
      <Route exact path="/" component={AdminDashBoard} />

      {/* Placements */}
      <Route exact path="/placement-list-view" component={PlacementsWorkbenchList} />
      <Route exact path="/placement/add" component={PlacementsWorkbenchAdd} />
      <Route
        exact
        path={['/placement/:id', '/placement/:id/:option']}
        component={PlacementsWorkbenchEdit}
      />
      {/* Job Placements */}
      <Route exact path="/job/placement-list-view/:id" component={PlacementsWorkbenchList} />

      <Route exact path="/notification-contents" component={NotificationContents} />
      <Route exact path="/notification-contents/:alertId" component={emailEdit} />

      <Route exact path="/debugging-tool" component={DebuggingTool} />

      {/* Super Admin Functions */}
      {doHaveRegionsPermission && <Route exact path="/regions" component={ListRegions} />}
      {doHaveRegionsPermission && <Route exact path="/regions/add" component={AddRegion} />}
      {doHaveRegionsPermission && <Route exact path="/region/:id" component={EditRegion} />}
      {doHaveAjnaUsersPermission && <Route exact path="/ajna-users" component={ListAjnaUsers} />}
      {doHaveAjnaUsersPermission && <Route exact path="/ajna-users/add" component={AddAjnaUser} />}
      {doHaveAjnaUsersPermission && (
        <Route exact path="/ajna-user/:userId" component={EditAjnaUser} />
      )}
      {enableFinancial && doHaveFinancialRulesPermission && (
        <Route exact path="/rules-calculation" component={RulesCalculation} />
      )}
      {enableFinancial && doHaveFinancialRulesPermission && (
        <Route exact path="/rules-calculation/view-rule/:id" component={ViewRule} />
      )}
      {/* Invoices */}
      <Route exact path="/invoices-list-view" component={InvoiceListView} />

      <Route path="/not-found" exact component={notFoundPage} />
      <Route path="/risk" component={Risk} />
      <Route path="/reports" component={Reports} />
      <Route path="/leads" component={Leads} />
      <Route path="*" exact component={notFoundPage} />
    </Switch>
  );
}

AdminRoutes.propTypes = {
  doHaveAjnaUsersPermission: PropTypes.bool,
  doHaveRegionsPermission: PropTypes.bool,
  doHaveFinancialRulesPermission: PropTypes.bool,
  tenantConfig: PropTypes.objectOf(PropTypes.shape),
};

AdminRoutes.defaultProps = {
  doHaveAjnaUsersPermission: undefined,
  doHaveRegionsPermission: undefined,
  doHaveFinancialRulesPermission: undefined,
  tenantConfig: {},
};

const mapStateProps = (state) => ({
  tenantConfig: state.get('config').get('tenantConfig'),
  doHaveAjnaUsersPermission: getPermission(state, 'AjnaUsers'),
  doHaveRegionsPermission: getPermission(state, 'Regions'),
  doHaveFinancialRulesPermission: getPermission(state, 'FinancialRules'),
});

export default withRouter(connect(mapStateProps)(memo(AdminRoutes)));
