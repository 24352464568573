const INTERVIEWS_AT_RISK_REQUEST = 'INTERVIEWS_AT_RISK_REQUEST';
const INTERVIEWS_AT_RISK_SUCCESS = 'INTERVIEWS_AT_RISK_SUCCESS';
const INTERVIEWS_AT_RISK_FAILURE = 'INTERVIEWS_AT_RISK_FAILURE';

const interviewsAtRiskRequest = (dispatch) => {
  dispatch({ type: INTERVIEWS_AT_RISK_REQUEST, payload: { loading: true } });
};

const interviewsAtRiskSuccess = (dispatch, response) => {
  dispatch({
    type: INTERVIEWS_AT_RISK_SUCCESS,
    payload: { data: response, loading: false },
  });
};

const interviewsAtRiskFailure = (dispatch) => {
  dispatch({ type: INTERVIEWS_AT_RISK_FAILURE, payload: { loading: false } });
};

export {
  INTERVIEWS_AT_RISK_REQUEST,
  INTERVIEWS_AT_RISK_SUCCESS,
  INTERVIEWS_AT_RISK_FAILURE,
  interviewsAtRiskRequest,
  interviewsAtRiskSuccess,
  interviewsAtRiskFailure,
};
