const EDIT_NOTIFICATION_CONTENTS_REQUEST = 'EDIT_NOTIFICATION_CONTENTS_REQUEST';
const EDIT_NOTIFICATION_CONTENTS_SUCCESS = 'EDIT_NOTIFICATION_CONTENTS_SUCCESS';
const EDIT_NOTIFICATION_CONTENTS_FAILURE = 'EDIT_NOTIFICATION_CONTENTS_FAILURE';

const editNotificationRequest = (dispatch) => {
  dispatch({
    type: EDIT_NOTIFICATION_CONTENTS_REQUEST,
    payload: { loading: true },
  });
};

const editNotificationSuccess = (dispatch, data) => {
  dispatch({
    type: EDIT_NOTIFICATION_CONTENTS_SUCCESS,
    payload: { data, loading: false },
  });
};

const editNotificationFailure = (dispatch, error) => {
  dispatch({
    type: EDIT_NOTIFICATION_CONTENTS_FAILURE,
    payload: { error, loading: false },
  });
};

export {
  EDIT_NOTIFICATION_CONTENTS_REQUEST,
  EDIT_NOTIFICATION_CONTENTS_SUCCESS,
  EDIT_NOTIFICATION_CONTENTS_FAILURE,
  editNotificationRequest,
  editNotificationSuccess,
  editNotificationFailure,
};
