export const TIMEZONES_PROCESSING = 'TIMEZONES_PROCESSING';
export const TIMEZONES_SUCCESS = 'TIMEZONES_SUCCESS';
export const TIMEZONES_FAILURE = 'TIMEZONES_FAILURE';
export const SAVE_TEMPLATE_PROCESSING = 'SAVE_TEMPLATE_PROCESSING';
export const SAVE_TEMPLATE_SUCCESS = 'SAVE_TEMPLATE_SUCCESS';
export const SAVE_TEMPLATE_FAILURE = 'SAVE_TEMPLATE_FAILURE';
export const SEND_INVITE_PROCESSING = 'SEND_INVITE_PROCESSING';
export const SEND_INVITE_SUCCESS = 'SEND_INVITE_SUCCESS';
export const SEND_INVITE_FAILURE = 'SEND_INVITE_FAILURE';
export const GET_TEMPLATE_PROCESSING = 'GET_TEMPLATE_PROCESSING';
export const SET_TEMPLATE_SUCCESS = 'SET_TEMPLATE_SUCCESS';
export const GET_TEMPLATE_FAILURE = 'GET_TEMPLATE_FAILURE';
export const SAVE_FEEDBACK = 'SAVE_FEEDBACK';
