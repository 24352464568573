import moment from 'moment';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CustomReactTable from '../../../components/table/CustomReactTable';
import { Dropdown } from '../../../components/final-form/ff-dropdown-input/ff-dropdown-input';
import { editLogsSuccess } from './actions';
import { getEditLogs } from './api';
import { getDataChangedString } from './helper';
import {
  showDateTimeInListingWithoutTimezone,
  isValidDateString,
  formatDateToUTC,
} from '../../../utils/stringsHelpers';
import {
  DEFAULT_TIMEZONE,
  DATE_FORMAT_MDY,
  DATE_FORMAT_HOUR,
  DATE_FORMAT_HOUR_WITHOUT_AM_PM,
  TIMESHEET_STATUS_TITLE,
  CANDIDATE_EXP_AND_RESP,
  LEADS_STATUS_TITLE,
  getJobRemoteLOVs,
  OPEN_TO_REMOTE_OPTIONS,
  PreferredLocationKeys,
  CANDIDATE_VERTICALIZATION,
  RoleFilterOptions,
  JOB_STATUS_TITLE,
  CLIENT_STATUS_TITLE,
  CANDIDATE_USER_STATUS_TITLE,
} from '../../../utils/siteSpecificConstants';
import {
  strictValidArrayWithLength,
  strictValidObjectWithKeys,
  strictValidString,
  validObjectWithParameterKeys,
  strictValidNumber,
  strictValidStringWithMinLength,
} from '../../../utils/commonUtils';
import { getTimezone } from '../../../utils/siteSpecificCommonUtils';
import './style.scss';

/**
 * Please not id is jobId here
 */
class EditLogs extends PureComponent {
  componentDidMount() {
    const { dispatch } = this.props;
    editLogsSuccess(dispatch, {});
  }

  parseValue = (value, label, fullRowValue = {}, action_on = '') => {
    // if value is array of timesheet time entries
    if (label && label === 'Remote Friendly') {
      return getJobRemoteLOVs(parseInt(value, 10));
    }

    if (label && label === 'Open To Remote') {
      const remoteNumber = parseInt(value, 10);
      const remote = strictValidNumber(remoteNumber) ? OPEN_TO_REMOTE_OPTIONS[remoteNumber] : 'N/A';
      return remote || '';
    }

    // if value is array of timesheet time entries
    if (label && label === 'Time Entries') {
      return this.showTimesheetData(value);
    }

    // if value is array of candidate exp & resp
    if (label && label === 'Experience & Responsibilities') {
      return this.showExpAndRespData(value);
    }

    if (label && label === 'Job Function Questions') {
      return this.showVerticalizationData(value);
    }

    if (label && label === 'Preferred Job Locations') {
      return this.showPreferredLocation(value);
    }

    // if value is timesheet status or leads status
    if (label && label === 'Status') {
      let statusTitle = '';
      if (this.props.type === 'leadsStatus' && strictValidNumber(Number(value))) {
        statusTitle = Object.keys(LEADS_STATUS_TITLE).filter(
          (status) => status === value.toString(),
        );
        return LEADS_STATUS_TITLE[statusTitle];
      }
      if (strictValidNumber(Number(value))) {
        const val = Number(value);
        let statusVal = '';
        switch (action_on) {
          case 'Job':
            return JOB_STATUS_TITLE[val];
          case 'Company':
            return CLIENT_STATUS_TITLE[val];
          case 'User':
            return CANDIDATE_USER_STATUS_TITLE[val];
          case 'Timesheet':
            statusVal = Object.keys(TIMESHEET_STATUS_TITLE).filter(
              (status) => status === value.toString(),
            );
            return TIMESHEET_STATUS_TITLE[statusVal];
          default:
            break;
        }
      }
      return value;
    }
    if (label && (label === 'Gap between Interviews' || label === 'Duration')) {
      return `${value * 60} mins`;
    }

    if (label && ['Description', 'Notes', 'Note'].indexOf(label) > -1) {
      return <div dangerouslySetInnerHTML={{ __html: value }} />;
    }
    if (label && label === 'Availability') {
      return (
        value &&
        value.length > 0 &&
        value.map((key) => {
          const dateTimeToShow = this.showDateTimeInListing(
            key.Date,
            key.Start,
            key.End,
            fullRowValue.timezone,
          );
          return validObjectWithParameterKeys(key, ['Date', 'Start', 'End']) ? (
            <p>{dateTimeToShow}</p>
          ) : (
            ''
          );
        })
      );
    }

    if (label && ['Interviewers Without Cal Invite'].indexOf(label) > -1) {
      return (
        value &&
        value.length > 0 &&
        value.map((key) => {
          return validObjectWithParameterKeys(key, ['Email', 'Id']) ? <p>{key.Email}</p> : '';
        })
      );
    }

    // if value is a date string
    if (strictValidStringWithMinLength(value, 5) && isValidDateString(value)) {
      // if date has no time
      const momentDate = moment.utc(value);
      if (momentDate.hours() === 0 && momentDate.minutes() === 0 && momentDate.seconds() === 0)
        return formatDateToUTC(value, 'MM/DD/YYYY');

      // if date has time also, convert to PST timezone
      return momentDate.tz(DEFAULT_TIMEZONE).format('MM/DD/YYYY h:mm A (z)');
    }

    // if value is an array and array of strings
    if (strictValidArrayWithLength(value) && value.some((i) => typeof i === 'string')) {
      return value.map((item, key) => {
        let keyName = 'key-' + key;
        if (strictValidString(item)) {
          return (
            <p key={keyName}>
              {value.length === 1 ? '' : `${key + 1}. `}
              {item}
            </p>
          );
        }
      });
    }

    // if value is a string
    if (strictValidString(value)) return value;

    // if value is a complex object or array
    return JSON.stringify(value);
  };

  showDateTimeInListing = (date = '', start = '', end = '', originalTimezone = '') => {
    const formatedTimezone = getTimezone(originalTimezone);
    return date && start && end
      ? `${moment.unix(start).tz(formatedTimezone).format(DATE_FORMAT_MDY)}-
        ${moment.unix(start).tz(formatedTimezone).format(DATE_FORMAT_HOUR_WITHOUT_AM_PM)}-
        ${moment.unix(end).tz(formatedTimezone).format(DATE_FORMAT_HOUR)}`
      : '-';
  };

  showTimesheetData = (timeEntries) => {
    if (timeEntries.length > 0) {
      const sortByDate = timeEntries.sort((a, b) => new Date(a.Date) - new Date(b.Date));
      const dailyKeysArray = [
        'In Time',
        'Out Time',
        'Meal Break Minutes',
        'Missed Meal Break Reason',
        'Sick Leave Minutes',
        'Comments',
        'Billable Hours',
      ];

      return (
        <table responsive size="sm" striped className="time-entries-table">
          <thead>
            <tr>
              <th />
              {sortByDate.map(({ Date }) => (
                <th key={Date}>{moment.utc(Date).format('MM/DD/YYYY')}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {dailyKeysArray.map((key) => (
              <tr key={key}>
                <th style={{ textAlign: 'left' }}>{key}</th>
                {sortByDate.map((item, j) => {
                  const sortKey = 'sortKey-'+ j;
                  if (key === 'Comments' && item[key] && item[key].length > 0) {
                    return <td key={sortKey}>{item[key][0].Comment}</td>;
                  }
                  return <td key={sortKey}>{item[key]}</td>;
                })}
              </tr>
            ))}
          </tbody>
        </table>
      );
    }

    return timeEntries;
  };

  showExpAndRespData = (data) => {
    if (data.length > 0) {
      const dataKeys = CANDIDATE_EXP_AND_RESP;
      return (
        <table responsive size="sm" striped>
          <thead>
            <tr>
              {dataKeys.map((key) => (
                <th key={key}>{key}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => {
              const keyName = 'keys-' + index;
              return (<tr key={keyName}>
                {dataKeys.map((key, i) => {
                  const subKeyName = 'subkeys' + i;
                  if (key === 'Experience' && item[key]) {
                    return <td key={subKeyName} className="td">{`${item[key]} yr${item[key] > 1 ? 's' : ''}`}</td>;
                  }

                  if (key === 'Duties' && item[key] && item[key].length > 0) {
                    return (
                      <td className="td" key={subKeyName}>
                        {item[key].map((value, i) => (
                          <p key={value}>
                            {item[key].length === 1 ? '' : `${i + 1}. `}
                            {value}
                          </p>
                        ))}
                      </td>
                    );
                  }

                  if (key === 'Requirements' && item[key] && item[key].length > 0) {
                    return (
                      <td className="td" key={subKeyName}>
                        {item[key].map((value, i) => (
                          <p key={value}>
                            {item[key].length === 1 ? '' : `${i + 1}. `}
                            {value}
                          </p>
                        ))}
                      </td>
                    );
                  }

                  return <td className="td" key={subKeyName}>{item[key]}</td>;
                })}
              </tr>)
            })}
          </tbody>
        </table>
      );
    }

    return data;
  };

  showVerticalizationData = (data) => {
    let isFormatValid = true;
    if (data.length > 0) {
      data.forEach((item) => {
        if (
          !(
            item &&
            item[CANDIDATE_VERTICALIZATION[0]] &&
            (item[CANDIDATE_VERTICALIZATION[1]] || item[CANDIDATE_VERTICALIZATION[2]])
          )
        ) {
          isFormatValid = false;
        }
      });
      if (isFormatValid) {
        const dataKeys = CANDIDATE_VERTICALIZATION;
        return (
          <table responsive size="sm" striped>
            <thead>
              <tr>
                {dataKeys.map((key) => (
                  <th key={key}>{key}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((item, i) => {
                const keyName = 'key_'+i;
                return (
                <tr key={keyName}>
                  {dataKeys.map((key, j) => {
                    const subKeyName = 'subKey_'+ j;
                    if (key === 'Title' && item[key]) {
                      return <td key={subKeyName}>{item[key]}</td>;
                    }

                    if (key === 'Selected' && item[key] && item[key].length > 0) {
                      return (
                        <td key={subKeyName}>
                          {item[key].map((value, i) => (
                            <p key={value}>
                              {item[key].length === 1 ? '' : `${i + 1}. `}
                              {value}
                            </p>
                          ))}
                        </td>
                      );
                    }

                    if (key === 'Range' && item[key] && item[key].length > 0) {
                      return <td key={subKeyName}>{item[key]}</td>;
                    }

                    return <td key={subKeyName}>{item[key]}</td>;
                  })}
                </tr>
              )})}
            </tbody>
          </table>
        );
      }
      return JSON.stringify(data);
    }

    return data;
  };

  showPreferredLocation = (data) => {
    if (data.length > 0) {
      const dataKeys = PreferredLocationKeys;
      return (
        <table responsive size="sm" striped>
          <thead>
            <tr>
              {dataKeys.map((key) => (
                <th key={key}>{key}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((itemInner, i) => {
              const item = strictValidObjectWithKeys(itemInner) ? itemInner : {};
              const keyName = 'key_'+i;
              return (
                <tr key={keyName}>
                  {dataKeys.map((key, j) => {
                    const subKeyName = 'subkey_'+j;
                    if (key === 'Proximity' && item[key]) {
                      return <td key={subKeyName}>{`${item[key]} mile${item[key] > 1 ? 's' : ''}`}</td>;
                    }
                    if (key === 'Relocation Required') {
                      return <td key={subKeyName}>{item[key] ? 'Yes' : 'No'}</td>;
                    }

                    return <td key={subKeyName}>{item[key]}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    }

    return data;
  };

  showDataChanged = (props) => {
    const data = props.original;
    return (
      <div className="table-responsive">
        <table bordered responsive style={{ backgroundColor: 'white' }} className="edit-logs-table">
          <thead>
            <tr>
              <th className="data-label">Data Changed</th>
              <th className="data-value">Old Data</th>
              <th className="data-value">New Data</th>
            </tr>
          </thead>
          <tbody>
            {data.data_changed.map((key) => {
              let updated_new_data = data.new_data || {};
              if (key === 'Template Id') {
                updated_new_data = { ...data.new_data, 'Template Id': data.old_data.Id };
              }
              const showData =
                (validObjectWithParameterKeys(data, ['old_data']) &&
                  validObjectWithParameterKeys(data.old_data, [key])) ||
                (validObjectWithParameterKeys(data, ['new_data']) &&
                  validObjectWithParameterKeys(data.new_data, [key]));

              if (showData) {
                return (
                  <tr key={key}>
                    <td>{getDataChangedString(key, data.action_on)}</td>
                    <td>
                      {validObjectWithParameterKeys(data, ['old_data']) &&
                        validObjectWithParameterKeys(data.old_data, [key]) &&
                        this.parseValue(
                          data.old_data[key],
                          getDataChangedString(key, data.action_on),
                          data.old_data,
                          data.action_on,
                        )}
                    </td>
                    <td>
                      {validObjectWithParameterKeys(data, ['new_data']) &&
                        validObjectWithParameterKeys(data.new_data, [key]) &&
                        this.parseValue(
                          updated_new_data[key],
                          getDataChangedString(key, data.action_on),
                          updated_new_data,
                          data.action_on,
                        )}
                    </td>
                  </tr>
                );
              }

              return null;
            })}
          </tbody>
        </table>
      </div>
    );
  };

  RoleFilter =({ filter, onChange })=> <Dropdown options={RoleFilterOptions} onChange={(val) => onChange(val)} />

  getColumns = () => [
    {
      Header: '#',
      accessor: 'id',
      minWidth: 7,
      filterable: false,
    },
    {
      Header: 'Event Creator',
      accessor: 'creator',
      minWidth: 15,
      filterable: false,
    },
    {
      Header: 'Role',
      accessor: 'role',
      minWidth: 25,
      Filter: (props) => this.RoleFilter(props),
      Cell: (props) => {
        let res = null;
        switch (props.value) {
          case 'super-admin':
            res = 'Super Admin';
            break;
          case 'admin':
            res = 'Admin';
            break;
          case 'account-manager':
            res = 'Account Manager';
            break;
          case 'recruiter':
            res = 'Recruiter';
            break;
          case 'verifier':
            res = 'Candidate Success Manager';
            break;
          case 'client-admin':
            res = 'Client Admin';
            break;
          case 'client-user':
            res = 'Client User';
            break;
          case 'candidate':
            res = 'Candidate';
            break;
          default:
            break;
        }
        return res;
      },
    },
    {
      Header: 'Edit log',
      accessor: 'data_changed',
      minWidth: 68,
      filterable: false,
      Cell: (props) => this.showDataChanged(props),
    },
    {
      Header: 'Source',
      accessor: 'action_on',
      minWidth: 10,
      filterable: false,
    },
    {
      Header: 'Timestamp',
      accessor: 'date',
      minWidth: 10,
      Cell: ({ value }) => showDateTimeInListingWithoutTimezone(value),
      filterable: false,
    },
  ];

  render() {
    const {
      logs,
      callEditLogListAPI,
      id: jobId,
      clientId,
      interviewId,
      candidateId,
      timesheetReqData,
      placementId,
      leadId,
    } = this.props;
    const columns = this.getColumns();
    const { count = 0 } = logs;
    return (
      <div className="card edit-logs">
        <div className="row space-between vertical-center">
          <div className="col">
            <h2 className="card-title">Edit Logs</h2>
            <div className="card-subtitle">
              {`${count}`} {count > 1 ? 'results found' : 'result found'}
            </div>
          </div>
        </div>
        <p>&nbsp;</p>
        <CustomReactTable
          defaultData={logs}
          className="overflow-filter"
          columns={columns}
          callDataAPI={(...params) =>
            callEditLogListAPI(
              jobId,
              clientId,
              interviewId,
              candidateId,
              timesheetReqData,
              placementId,
              leadId,
              ...params,
            )
          }
          defaultPageSize={25}
        />
      </div>
    );
  }
}

EditLogs.propTypes = {
  callEditLogListAPI: PropTypes.func.isRequired,
  logs: PropTypes.objectOf(PropTypes.shape).isRequired,
  dispatch: PropTypes.func.isRequired,
  id: PropTypes.string,
  clientId: PropTypes.string,
  interviewId: PropTypes.string,
  candidateId: PropTypes.string,
  timesheetReqData: PropTypes.objectOf(PropTypes.shape).isRequired,
  placementId: PropTypes.string,
  leadId: PropTypes.string,
  type: PropTypes.string,
};

EditLogs.defaultProps = {
  id: undefined,
  clientId: undefined,
  interviewId: undefined,
  candidateId: undefined,
  timesheetReqData: undefined,
  placementId: undefined,
  leadId: undefined,
  type: undefined,
};

const mapStateProps = (state) => {
  const { data: { rows = [], count = 0 } = {}, loading } = state.get('editLogs');
  return {
    logs: {
      data: rows,
      count,
      loading,
    },
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  callEditLogListAPI: (...params) => dispatch(getEditLogs(...params)),
});

export default withRouter(connect(mapStateProps, mapDispatchToProps)(EditLogs));
