import Immutable from 'immutable';
import { typeCastResponse } from '../../utils/siteSpecificCommonUtils';
import { settingsConstants } from '../constants';

const initialState = Immutable.fromJS({
  industries: [],
  educations: [],
  benefits: [],
  cultures: [],
  jobFunctions: [],
  regions: [],
  titles: [],
  invoicePaycodesListing: [],
  location_label: [],
  client_hear_about_us: [],
  candidate_hear_about_us: [],
  markup: [],
  resignation_notice: [],
  reason_for_leaving: [],
  candidate_decline_reason: [],
  close_reason: [],
  company_size: [],
  placement_reason_ended: [],
  timesheet_missed_break_reason: [],
  departments: [],
  msa_lovs: {},
  states:[],
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    // Loading
    case settingsConstants.LOAD:
      return state.set('isLoad', true).set('loadErr', null);
    case settingsConstants.LOAD_SUCCESS:
      return state
        .set('isLoad', false)
        .set('loadErr', null)
        .set('message', typeCastResponse(action, 'message'));
    case settingsConstants.LOAD_FAIL:
      return state.set('isLoad', false).set('loadErr', typeCastResponse(action, 'error'));
    // Settings
    case settingsConstants.LOAD_SETTINGS:
      return state
        .set('jobFunctions', typeCastResponse(action, 'jobFunctions', 'array', []))
        .set('industries', typeCastResponse(action, 'industries', 'array', []))
        .set('educations', typeCastResponse(action, 'educations', 'array', []))
        .set('benefits', typeCastResponse(action, 'benefits', 'array', []))
        .set('cultures', typeCastResponse(action, 'cultures', 'array', []))
        .set('titles', typeCastResponse(action, 'titles', 'array', []))
        .set(
          'invoicePaycodesListing',
          typeCastResponse(action, 'invoicePaycodesListing', 'array', []),
        )
        .set('regions', typeCastResponse(action, 'regions', 'array', []))
        .set('msa_lovs', typeCastResponse(action, 'msa_lovs', 'object', {}))
        .set('location_label', typeCastResponse(action, 'location_label', 'array', []))
        .set('client_hear_about_us', typeCastResponse(action, 'client_hear_about_us', 'array', []))
        .set(
          'candidate_hear_about_us',
          typeCastResponse(action, 'candidate_hear_about_us', 'array', []),
        )
        .set('markup', typeCastResponse(action, 'markup', 'array', []))
        .set('resignation_notice', typeCastResponse(action, 'resignation_notice', 'array', []))
        .set('reason_for_leaving', typeCastResponse(action, 'reason_for_leaving', 'array', []))
        .set(
          'candidate_decline_reason',
          typeCastResponse(action, 'candidate_decline_reason', 'array', []),
        )
        .set('close_reason', typeCastResponse(action, 'close_reason', 'array', []))
        .set('company_size', typeCastResponse(action, 'company_size', 'array', []))
        .set(
          'placement_reason_ended',
          typeCastResponse(action, 'placement_reason_ended', 'array', []),
        )
        .set('departments', typeCastResponse(action, 'departments', 'array', []))
        .set(
          'timesheet_missed_break_reason',
          typeCastResponse(action, 'timesheet_missed_break_reason', 'array', []),
        )
        .set('states', typeCastResponse(action, 'states', 'array', []), );
    default:
      return state;
  }
}
