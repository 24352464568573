import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './ff-multi-email.scss';
import { VALID_EMAIL } from '../../../utils/regexs';
import {
  isFunction,
  strictValidArray,
  strictValidArrayWithLength,
} from '../../../utils/commonUtils';

export default function FfMultiEmail({
  input,
  placeholder,
  meta: { touched, error },
  disabled,
}) {
  const reg = /[, \s]/g;
  const [inputValue, setInputValue] = useState('');
  const inputEl = useRef(null);
  const parentInputEl = useRef(null);

  function onFocus() {
    if (disabled) return;
    parentInputEl.current.classList.add('focused');
  }

  function onBlur() {
    parentInputEl.current.classList.remove('focused');
    setInputValue('');
  }

  useEffect(() => {
    inputEl.current.addEventListener('blur', onBlur, false);
    inputEl.current.addEventListener('focus', onFocus, false);
    return () => {
      if (inputEl && inputEl.current) {
        inputEl.current.removeEventListener('focus', onFocus);
        inputEl.current.removeEventListener('blur', onBlur);
      }
    };
  }, []);

  const checkForCommaOrSpace = (text) => {
    const commaSpace = text.match(reg);
    return strictValidArray(commaSpace) && commaSpace.length > 0;
  };

  // method to handle custom actions on "onChange" event
  function handleChange(e) {
    if (checkForCommaOrSpace(inputValue)) return;
    setInputValue(e.target.value);
  }

  async function handleKeyDown(e) {
    const { value, onChange } = input;
    let email = inputValue ? inputValue.trim().toLowerCase() : inputValue;

    if (['Enter', 'Tab', ',', ' ', undefined].includes(e.key) || checkForCommaOrSpace(inputValue)) {
      email = email && email.replace(reg, '');
      e.preventDefault();
      if (!VALID_EMAIL.test(email)) {
        setInputValue('');
        return;
      }

      if (value.includes(email)) {
        setInputValue('');
        return;
      }
      const emails = [...value, email];

      onChange(emails);
      setInputValue('');
    } else if (['Backspace', 'Delete'].includes(e.key)) {
      if (email || !strictValidArrayWithLength(value)) {
        return;
      }

      value.pop();
      onChange([...value]);
    }
  }

  function handleDelete(index, e) {
    e.stopPropagation();
    const emails = [...input.value];
    const { onChange } = input;
    emails.splice(index, 1);
    onChange(emails);
  }

  return (
    <div
      className={`ff-multi-email-wrapper ${disabled ? 'disabled' : ''}`}
      onClick={() => !disabled && inputEl.current.focus()}
    >
      {strictValidArrayWithLength(input.value) &&
        input.value.map((item, index) => {
          const key = 'key-'+index;
          return (
          <p key={key} className={disabled ? 'disabled' : ''}>
            {item}{' '}
            {!disabled && <span onClick={(e) => !disabled && handleDelete(index, e)}>&times;</span>}
          </p>
        )})}
      <div
        className={`input-wrapper ${
          inputValue || strictValidArrayWithLength(input.value) ? 'filled' : ''
        }`}
        ref={parentInputEl}
        tip="error top-left"
        data-description={error}
        is-error={touched && error && !disabled ? 'true' : 'false'}
      >
        <input
          {...input}
          ref={inputEl}
          readOnly={disabled}
          onChange={handleChange}
          onKeyUp={handleKeyDown}
          onBlur={handleKeyDown}
          value={inputValue}
        />
        {isFunction(placeholder) ? (
          placeholder()
        ) : (
          <span className="placeholder">{placeholder}</span>
        )}
      </div>
    </div>
  );
}

FfMultiEmail.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  disabled: PropTypes.bool,
  dispatch: PropTypes.func,
  companyId: PropTypes.string,
  asyncValidation: PropTypes.bool,
};

FfMultiEmail.defaultProps = {
  meta: {
    touched: false,
    error: '',
  },
  disabled: false,
  dispatch: () => {/* empty fun */},
  companyId: '',
  asyncValidation: false,
};
