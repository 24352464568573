import React, { useEffect } from 'react';
import browser from 'browser-detect';
import './404-not-found.scss';
import PrButton from '../../components/--primitives/pr-button';
import history from '../../utils/history';

function Feedback() {
  const browserInfo = browser();
  const onScroll = () => {/* empty fun */};

  useEffect(() => {
    document.getElementsByTagName('html')[0].classList.add('no-scroll');
    return () => {
      document.getElementsByTagName('html')[0].classList.remove('no-scroll');
    };
  }, []);

  return (
    <div className="card error-outer">
      <section className="page-wrapper error-page">
        <section className="error-info align-center">
          <div className={`content-wrapper ${browserInfo.name}`} onScroll={(e) => onScroll(e)}>
            <h2>404</h2>
            <p className="title">Oops! The page you’re looking for doesn’t exist.</p>
            <p className="desc-text">
              Don&apos;t panic! Click the button to return to previous page!
            </p>
            <PrButton onClick={history.goBack} type="blue" value="Back to previous page" />
          </div>
        </section>
      </section>
    </div>
  );
}

export default Feedback;
