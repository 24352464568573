import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import AddNote from './add';
import Notes from './list';

class Note extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      shouldShowAddNote: false,
    };
  }

  switchAddAndList = () => {
    const { updateOptionToShow } = this.props;
    const { shouldShowAddNote } = this.state;
    this.setState(
      (prevState) => ({ shouldShowAddNote: !prevState.shouldShowAddNote }),
      () => updateOptionToShow(shouldShowAddNote ? 9 : 11),
    );
  };

  render() {
    const { shouldShowAddNote } = this.state;
    const {
      jobId,
      clientId,
      job,
      client,
      interviewId,
      interview,
      candidateId,
      leadId,
      leadName,
      candidateName: candidateSideCandidateName,
      timesheet,
      timesheetId,
      shouldShowAddOption,
      placementId,
      placement,
    } = this.props;
    const { company: { name: companyName, _id: company_id } = {}, job_title, candidateName } = job;
    const { name: clientSideCompanyName } = client;
    const {
      id: interviewIdToShow,
      job_title: interviewJobTitle,
      job: job_id,
      candidate: { name: interviewCandidateName, _id: candidate_id } = {},
      company: { name: interviewSideCompanyName, _id: client_Id } = {},
    } = interview;
    const {
      company: timesheetSideCompanyName,
      employee: timesheetCandidateName,
      job_title: timesheetJobTitle,
      company_id: timesheetCompanyId,
      employee_id,
      job: timesheetJobId,
    } = timesheet;
    const {
      company: placementSideCompanyName,
      candidate: placementCandidateName,
      job: placementJobTitle,
      candidateId: plcCandId,
      clientId: plcClientId,
      jobId: plcJobId,
    } = placement;

    const activeLinks = {
      CompanyID: company_id || client_Id || plcClientId || timesheetCompanyId,
      CandidateId: candidate_id || plcCandId || employee_id,
      JobId: job_id || plcJobId || timesheetJobId,
    };
    return (
      <div>
        {shouldShowAddNote ? (
          <AddNote
            jobId={jobId}
            companyId={clientId}
            interviewId={interviewId}
            candidateId={candidateId}
            switchAddAndList={this.switchAddAndList}
            timesheetId={timesheetId}
            placementId={placementId}
            leadId={leadId}
            leadName={leadName}
            companyName={
              companyName ||
              clientSideCompanyName ||
              interviewSideCompanyName ||
              timesheetSideCompanyName ||
              placementSideCompanyName
            }
            jobTitle={job_title || interviewJobTitle || timesheetJobTitle || placementJobTitle}
            candidateName={
              candidateName ||
              interviewCandidateName ||
              timesheetCandidateName ||
              candidateSideCandidateName ||
              placementCandidateName
            }
          />
        ) : (
          <Notes
            jobId={jobId}
            clientId={clientId}
            candidateId={candidateId}
            interviewId={interviewId}
            companyName={
              companyName ||
              clientSideCompanyName ||
              interviewSideCompanyName ||
              timesheetSideCompanyName ||
              placementSideCompanyName
            }
            interviewIdToShow={interviewIdToShow}
            jobTitle={job_title || interviewJobTitle || timesheetJobTitle || placementJobTitle}
            candidateName={
              candidateName ||
              interviewCandidateName ||
              timesheetCandidateName ||
              candidateSideCandidateName ||
              placementCandidateName
            }
            switchAddAndList={this.switchAddAndList}
            timesheetId={timesheetId}
            shouldShowAddOption={shouldShowAddOption}
            placementId={placementId}
            leadId={leadId}
            leadName={leadName}
            activeLinks={activeLinks}
          />
        )}
      </div>
    );
  }
}

Note.propTypes = {
  jobId: PropTypes.string,
  clientId: PropTypes.string,
  interviewId: PropTypes.string,
  updateOptionToShow: PropTypes.func,
  job: PropTypes.objectOf(PropTypes.shape),
  client: PropTypes.objectOf(PropTypes.shape),
  interview: PropTypes.objectOf(PropTypes.shape),
  timesheetId: PropTypes.string,
  timesheet: PropTypes.objectOf(PropTypes.shape),
  candidateId: PropTypes.string,
  candidateName: PropTypes.string,
  shouldShowAddOption: PropTypes.bool,
  placementId: PropTypes.string,
  placement: PropTypes.objectOf(PropTypes.shape),
  leadId: PropTypes.string,
  leadName: PropTypes.string,
};

Note.defaultProps = {
  jobId: undefined,
  clientId: undefined,
  interviewId: undefined,
  updateOptionToShow: () => {
    /* empty fun */
  },
  job: {},
  client: {},
  interview: {},
  timesheetId: undefined,
  timesheet: {},
  candidateId: undefined,
  candidateName: undefined,
  shouldShowAddOption: true,
  placementId: undefined,
  placement: {},
  leadId: undefined,
  leadName: undefined,
};

export default withRouter(Note);
