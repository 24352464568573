import React from 'react';
import Helmet from 'react-helmet';

function DocumentTitleComponent({ title }) {
  const defaultTitle = 'Ajna';
  return (
    <Helmet>
      <title>{title ? `${title} - Ajna` : defaultTitle}</title>
    </Helmet>
  );
}

export default DocumentTitleComponent;
