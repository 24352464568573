import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Common from '../../Common';
import ActivityLogs from '../../../../../admin/activity-log';
import EditLogs from '../../../../../admin/edit-log';
import Notes from '../../../../../admin/notes';
import Notifications from '../../../../../admin/notifications';
import Attachments from '../../../../../admin/attachments';
import {
  validObjectWithParameterKeys,
  strictValidString,
  strictValidNumber,
} from '../../../../../../utils/commonUtils';
import { SPECIAL_CHARACTERS } from '../../../../../../utils/regexs';

class CRMModuleComponent extends Component {
  getCompanyId = () => {
    const { match = {} } = this.props;
    const { params = {} } = match;
    const { id } = params;
    return id;
  };

  getModule = () => {
    const { match = {} } = this.props;
    const { params = {} } = match;
    const { moduleType } = params;
    return moduleType;
  };

  getPathname = () => {
    const { client } = this.props;
    let pathname = '';
    if (
      validObjectWithParameterKeys(client, ['name', 'id']) &&
      strictValidString(client.name) &&
      strictValidNumber(client.id)
    ) {
      const client_name = client.name.replace(SPECIAL_CHARACTERS, '_'); // for replacing special character with "_"
      const { id } = client;
      pathname = `companies/${id}_${client_name}/Client_Documents`;
    }
    return pathname;
  };

  renderCRMModule = (component) => <div style={{ marginTop: '20px' }}>{component}</div>;

  render() {
    const { client } = this.props;
    return (
      <div className="container-fluid">
        <div id="commonLayout">
          <Common action={this.getModule()} />
          {this.getModule() === 'activities' &&
            this.renderCRMModule(<ActivityLogs clientId={this.getCompanyId()} />)}
          {this.getModule() === 'logs' &&
            this.renderCRMModule(<EditLogs clientId={this.getCompanyId()} />)}
          {this.getModule() === 'notes' &&
            this.renderCRMModule(<Notes client={client} clientId={this.getCompanyId()} />)}
          {this.getModule() === 'notifications' &&
            this.renderCRMModule(
              <Notifications workBench="company" client={client} clientId={this.getCompanyId()} />,
            )}
          {this.getModule() === 'attachments' &&
            this.renderCRMModule(
              <Attachments Id={this.getCompanyId()} type="company" pathname={this.getPathname()} />,
            )}
        </div>
      </div>
    );
  }
}

CRMModuleComponent.propTypes = {
  client: PropTypes.objectOf(PropTypes.shape).isRequired,
  match: PropTypes.objectOf(PropTypes.shape).isRequired,
};

const mapStateProps = (state) => {
  const { data: client } = state.get('clientWorkBench').get('companyDetails');
  return {
    client,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

const CRMModule = withRouter(connect(mapStateProps, mapDispatchToProps)(CRMModuleComponent));

export default CRMModule;
