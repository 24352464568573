const COMPANIES_LIST_REQUEST = 'COMPANIES_LIST_REQUEST';
const COMPANIES_LIST_SUCCESS = 'COMPANIES_LIST_SUCCESS';
const COMPANIES_LIST_FAILURE = 'COMPANIES_LIST_FAILURE';

const companiesListRequest = (dispatch) => {
  dispatch({ type: COMPANIES_LIST_REQUEST, payload: { loading: true } });
};

const companiesListSuccess = (dispatch, response) => {
  dispatch({
    type: COMPANIES_LIST_SUCCESS,
    payload: { data: response, loading: false },
  });
};

const companiesListFailure = (dispatch) => {
  dispatch({ type: COMPANIES_LIST_FAILURE, payload: { loading: false } });
};

const STATES_REQUEST = 'STATES_REQUEST';
const STATES_SUCCESS = 'STATES_SUCCESS';
const STATES_FAILURE = 'STATES_FAILURE';

const statesListRequest = (dispatch) => {
  dispatch({ type: STATES_REQUEST, payload: { loading: true } });
};

const statesListSuccess = (dispatch, response) => {
  dispatch({
    type: STATES_SUCCESS,
    payload: { data: response, loading: false },
  });
};

const statesListFailure = (dispatch) => {
  dispatch({ type: STATES_FAILURE, payload: { loading: false } });
};

export {
  COMPANIES_LIST_REQUEST,
  COMPANIES_LIST_SUCCESS,
  COMPANIES_LIST_FAILURE,
  companiesListRequest,
  companiesListSuccess,
  companiesListFailure,
  STATES_REQUEST,
  STATES_SUCCESS,
  STATES_FAILURE,
  statesListRequest,
  statesListSuccess,
  statesListFailure,
};
