import { LOCATION_CHANGE } from 'react-router-redux';
import {
  ASSIGNMENTS_AT_RISK_REQUEST,
  ASSIGNMENTS_AT_RISK_SUCCESS,
  ASSIGNMENTS_AT_RISK_FAILURE,
} from './actions';

const initialState = {};

const risk = (state = initialState, action = {}) => {
  switch (action.type) {
    case ASSIGNMENTS_AT_RISK_REQUEST:
    case ASSIGNMENTS_AT_RISK_SUCCESS:
    case ASSIGNMENTS_AT_RISK_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case LOCATION_CHANGE:
      return {
        ...state,
        err: null,
      };
    default:
      return state;
  }
};
export { risk };
