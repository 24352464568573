const STATES_PROCESSING = 'STATES_PROCESSING';
const STATES_SUCCESS = 'STATES_SUCCESS';
const STATES_FAILURE = 'STATES_FAILURE';
const PAYCODES_PROCESSING = 'PAYCODES_PROCESSING';
const PAYCODES_SUCCESS = 'PAYCODES_SUCCESS';
const PAYCODES_FAILURE = 'PAYCODES_FAILURE';
const ADD_RULES_CALCULATION_PROCESSING = 'ADD_RULES_CALCULATION_PROCESSING';
const ADD_RULES_CALCULATION_SUCCESS = 'ADD_RULES_CALCULATION_SUCCESS';
const ADD_RULES_CALCULATION_FAILURE = 'ADD_RULES_CALCULATION_FAILURE';
const RULES_DETAILS_PROCESSING = 'RULES_DETAILS_PROCESSING';
const RULES_DETAILS_SUCCESS = 'RULES_DETAILS_SUCCESS';
const RULES_DETAILS_FAILURE = 'RULES_DETAILS_FAILURE';
const UPDATE_RULES_CALCULATION_PROCESSING = 'UPDATE_RULES_CALCULATION_PROCESSING';
const UPDATE_RULES_CALCULATION_SUCCESS = 'UPDATE_RULES_CALCULATION_SUCCESS';
const UPDATE_RULES_CALCULATION_FAILURE = 'UPDATE_RULES_CALCULATION_FAILURE';

const statesProcessing = (dispatch) => {
  dispatch({ type: STATES_PROCESSING, payload: { loading: true } });
};

const statesSuccess = (dispatch, response) => {
  dispatch({
    type: STATES_SUCCESS,
    payload: { data: response, loading: false },
  });
};

const statesFailure = (dispatch) => {
  dispatch({ type: STATES_FAILURE, payload: { loading: false } });
};

const paycodesProcessing = (dispatch) => {
  dispatch({ type: PAYCODES_PROCESSING, payload: { loading: true } });
};

const paycodesSuccess = (dispatch, response) => {
  dispatch({
    type: PAYCODES_SUCCESS,
    payload: { data: response, loading: false },
  });
};

const paycodesFailure = (dispatch) => {
  dispatch({ type: PAYCODES_FAILURE, payload: { loading: false } });
};

const addRulesCalculationProcessing = (dispatch) => {
  dispatch({
    type: ADD_RULES_CALCULATION_PROCESSING,
    payload: { loading: true },
  });
};

const addRulesCalculationSuccess = (dispatch, response) => {
  dispatch({
    type: ADD_RULES_CALCULATION_SUCCESS,
    payload: { data: response, loading: false },
  });
};

const addRulesCalculationFailure = (dispatch) => {
  dispatch({
    type: ADD_RULES_CALCULATION_FAILURE,
    payload: { loading: false },
  });
};

const ruleDetailsProcessing = (dispatch) => {
  dispatch({
    type: RULES_DETAILS_PROCESSING,
    payload: { loading: true },
  });
};

const ruleDetailsSuccess = (dispatch, response) => {
  dispatch({
    type: RULES_DETAILS_SUCCESS,
    payload: { data: response, loading: false },
  });
};

const ruleDetailsFailure = (dispatch, err) => {
  dispatch({
    type: RULES_DETAILS_FAILURE,
    payload: { loading: false, err },
  });
};

const updateRulesCalProcessing = (dispatch) => {
  dispatch({
    type: UPDATE_RULES_CALCULATION_PROCESSING,
    payload: { loading: true },
  });
};

const updateRulesCalSuccess = (dispatch, response) => {
  dispatch({
    type: UPDATE_RULES_CALCULATION_SUCCESS,
    payload: { success: response, loading: false },
  });
};

const updateRulesCalFailure = (dispatch, err) => {
  dispatch({
    type: UPDATE_RULES_CALCULATION_FAILURE,
    payload: { loading: false, err },
  });
};

export {
  STATES_PROCESSING,
  STATES_SUCCESS,
  STATES_FAILURE,
  PAYCODES_PROCESSING,
  PAYCODES_SUCCESS,
  PAYCODES_FAILURE,
  ADD_RULES_CALCULATION_PROCESSING,
  ADD_RULES_CALCULATION_SUCCESS,
  ADD_RULES_CALCULATION_FAILURE,
  RULES_DETAILS_PROCESSING,
  RULES_DETAILS_SUCCESS,
  RULES_DETAILS_FAILURE,
  UPDATE_RULES_CALCULATION_PROCESSING,
  UPDATE_RULES_CALCULATION_SUCCESS,
  UPDATE_RULES_CALCULATION_FAILURE,
  statesProcessing,
  statesSuccess,
  statesFailure,
  paycodesProcessing,
  paycodesSuccess,
  paycodesFailure,
  addRulesCalculationProcessing,
  addRulesCalculationSuccess,
  addRulesCalculationFailure,
  ruleDetailsProcessing,
  ruleDetailsSuccess,
  ruleDetailsFailure,
  updateRulesCalProcessing,
  updateRulesCalSuccess,
  updateRulesCalFailure,
};
