import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createLinkToAlertNotification } from '../../../../../utils/navigation';
import { strictValidArrayWithLength, strictValidArray } from '../../../../../utils/commonUtils';
import CustomReactTable from '../../../../../components/table/CustomReactTable';
import { getNotificationContents } from './api';
import DocumentTitleComponent from '../../../../../components/DocumentTitleComponent';
import PrAnimatedInput from '../../../../../components/--primitives/pr-data-input-2';
import FfDropdownInputGroup from '../../../../../components/final-form/ff-dropdown-input-group';

const roles = [
  { value: 'client-admin', label: 'Client Admin' },
  { value: 'job-owner', label: 'Job Owner' },
  { value: 'invoice-contact', label: 'Invoice Contact' },
  { value: 'timesheet-approver', label: 'Timesheet Approver' },
  { value: 'candidate', label: 'Candidate' },
  { value: 'job-manager', label: 'Job Manager' },
  { value: 'account-manager', label: 'Account Manager' },
  { value: 'recruiter', label: 'Recruiter' },
  { value: 'verifier', label: 'Candidate Success Manager' },
  { value: 'payroll', label: 'Payroll' },
];
function NotificationContents({ callGetNotificationAPI, notifications, dispatch }) {
  const toGetShow = (roleArr, emailArr) => {
    let rolesWithLabel = [];
    if (strictValidArrayWithLength(roleArr)) {
      rolesWithLabel = roleArr.map((v) => {
        const arr = roles.filter((role) => role.value === v);
        if (strictValidArrayWithLength(arr)) return arr[0].label;
        return v;
      });
    }
    const toShow = [...rolesWithLabel, ...emailArr];
    return (
      strictValidArrayWithLength(toShow) && (
        <ol>
          {toShow.map((v) => (
            <li key={v}>{v}</li>
          ))}
        </ol>
      )
    );
  };

  const renderInput = (filter, onChange) => (
    <PrAnimatedInput
      type="text"
      onChange={(value) => onChange(value)}
      value={filter ? filter.value : ''}
    />
  );

  const alertType = ({ filter, onChange }) => (
    <FfDropdownInputGroup
      placeholder=""
      input={{
        value: filter ? filter.value : { label: 'ALL', value: '' },
        onChange: (v) => onChange(v.value),
      }}
      options={[
        { label: 'ALL', value: '' },
        { label: 'Email', value: 'Email' },
        { label: 'RTN', value: 'RTN' },
      ]}
    />
  )

  const getColumns = () => [
    {
      Header: 'Alert Id',
      accessor: 'alert_id',
      minWidth: 20,
      Filter: ({ filter, onChange }) => renderInput(filter, onChange),
      Cell: (props) => {
        // eslint-disable-next-line react/prop-types
        const { alert_id, _id } = props.original;
        return createLinkToAlertNotification(dispatch, _id, alert_id);
      },
    },
    {
      Header: 'Type',
      accessor: 'alert_type',
      minWidth: 20,
      // eslint-disable-next-line react/prop-types
      Filter: (props) => alertType(props),
    },
    {
      Header: 'To',
      accessor: 'to',
      sortable: false,
      Filter: ({ filter, onChange }) => renderInput(filter, onChange),
      Cell: (props) => {
        // eslint-disable-next-line react/prop-types
        const { to = [], to_emails = [] } = props.original;
        if (strictValidArray(to) && strictValidArray(to_emails)) {
          return toGetShow(to, to_emails);
        }
        return 'N/A';
      },
      minWidth: 20,
    },
    {
      Header: 'CC',
      accessor: 'cc',
      sortable: false,
      minWidth: 20,
      Filter: ({ filter, onChange }) => renderInput(filter, onChange),
      Cell: (props) => {
        // eslint-disable-next-line react/prop-types
        const { cc = [], cc_emails = [] } = props.original;
        if (strictValidArray(cc) && strictValidArray(cc_emails)) {
          return toGetShow(cc, cc_emails);
        }
        return 'N/A';
      },
    },
    {
      Header: 'From Name',
      accessor: 'sender_name',
      Filter: ({ filter, onChange }) => renderInput(filter, onChange),
      minWidth: 20,
    },
    {
      Header: 'From Email',
      Filter: ({ filter, onChange }) => renderInput(filter, onChange),
      accessor: 'sender_email',
      minWidth: 20,
    },
  ];

  return (
    <div className="page-wrapper notification-list">
      <DocumentTitleComponent title="Notifications" />
      <div className="card">
        <div className="row">
          <h2 className="card-title">Notifications</h2>
        </div>
        <CustomReactTable
          defaultData={notifications}
          columns={getColumns()}
          callDataAPI={callGetNotificationAPI}
          defaultPageSize={10}
          defaultSortKey="alert_type"
        />
      </div>
    </div>
  );
}

NotificationContents.propTypes = {
  callGetNotificationAPI: PropTypes.func.isRequired,
  notifications: PropTypes.objectOf(PropTypes.shape).isRequired,
  dispatch: PropTypes.func.isRequired,
  alert_id: PropTypes.string.isRequired,
  cc: PropTypes.arrayOf(PropTypes.shape),
  cc_emails: PropTypes.arrayOf(PropTypes.shape),
  to: PropTypes.arrayOf(PropTypes.shape),
  to_emails: PropTypes.arrayOf(PropTypes.shape),
};

NotificationContents.defaultProps = {
  cc: [],
  cc_emails: [],
  to: [],
  to_emails: [],
};

const mapStateToProps = (state) => {
  const { data: { rows = [], count = 0 } = {}, loading = false } = state
    .get('notificationContent')
    .get('notificationContents');
  return {
    notifications: {
      data: rows,
      count,
      loading,
    },
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  callGetNotificationAPI: (...params) => dispatch(getNotificationContents(...params)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NotificationContents));
