import { combineReducers } from 'redux-immutable';
import { candidatesList } from '../../containers/WorkBench/Candidate/List/reducer';
import { AddCandidate } from '../../containers/WorkBench/Candidate/Add/reducer';
import { candidateDashboard } from '../../containers/WorkBench/Candidate/Edit/reducer';

export default combineReducers({
  candidatesList,
  AddCandidate,
  candidateDashboard,
});
