import {
  validObjectWithParameterKeys,
  strictValidObject,
  jsonToQueryString,
} from '../../utils/commonUtils';
import { errorConstants, clientConstants } from '../constants';

export const getClientUsers = (id, filters) => async (dispatch, getState, api) => {
  dispatch({ type: clientConstants.LOAD });
  try {
    let queryString = '';
    let postdata = {};
    if (strictValidObject(filters)) {
      queryString = jsonToQueryString(filters);
      postdata = validObjectWithParameterKeys(filters, ['job']) ? { job: filters.job } : {};
    } else {
      queryString = 'page=1&status=1';
    }

    const res = await api.post(`/companies/users/${id}?${queryString}`, { data: postdata });
    dispatch({
      type: clientConstants.GET_ALL_CLIENTS,
      data: validObjectWithParameterKeys(res.data, ['rows']) ? res.data.rows : [],
      count: res.data.count || 0,
    });
    dispatch({ type: clientConstants.LOAD_SUCCESS });
  } catch (error) {
    dispatch({
      type: errorConstants.SET_ERROR,
      error,
    });
    dispatch({
      type: clientConstants.LOAD_FAIL,
      error,
    });
  }
};

export const setClient = (companyUser) => async (dispatch, getState, api) => {
  dispatch({
    type: clientConstants.SET_CLIENT,
    companyUser,
  });
};

export const getClient = (_id) => async (dispatch, getState, api) => {
  dispatch({ type: clientConstants.LOAD });
  try {
    const res = await api.get(`/companies/user/${_id}`);
    await dispatch(setClient(res.data));
    await dispatch({ type: clientConstants.LOAD_SUCCESS });
    return res;
  } catch (error) {
    dispatch({
      type: errorConstants.SET_ERROR,
      error,
    });
    dispatch({
      type: clientConstants.LOAD_FAIL,
      error,
    });
  }
};

export const updateClient = (data) => async (dispatch, getState, api) => {
  dispatch({ type: clientConstants.LOAD });
  try {
    delete data.createdAt;
    const id = data._id;
    delete data._id;
    const res = await api.put(`/companies/user/${id}`, { data });
    await dispatch({ type: clientConstants.LOAD_SUCCESS });
    return res;
  } catch (error) {
    dispatch({
      type: errorConstants.SET_ERROR,
      error,
    });
    dispatch({
      type: clientConstants.LOAD_FAIL,
      error,
    });
  }
};

export const addClient = (data) => async (dispatch, getState, api) => {
  dispatch({ type: clientConstants.LOAD });
  try {
    const res = await api.post('/companies/user', { data });
    await dispatch({ type: clientConstants.LOAD_SUCCESS });
    return res;
  } catch (error) {
    dispatch({
      type: errorConstants.SET_ERROR,
      error,
    });
    dispatch({
      type: clientConstants.LOAD_FAIL,
      error,
    });
  }
};

export const getClientLocationsList = (id) => async (dispatch, getState, api) => {
  dispatch({ type: clientConstants.LOAD });
  try {
    const res = await api.post(`/companies/locations/${id}`);
    dispatch({
      type: clientConstants.GET_ALL_LOCATIONS,
      data: validObjectWithParameterKeys(res.data, ['rows']) ? res.data.rows : [],
      count: res.data.count || 0,
    });
    dispatch({ type: clientConstants.LOAD_SUCCESS });
  } catch (error) {
    dispatch({
      type: errorConstants.SET_ERROR,
      error,
    });
    dispatch({
      type: clientConstants.LOAD_FAIL,
      error,
    });
  }
};

export const getClientLocation = (companyId, locationId) => async (dispatch, getState, api) => {
  dispatch({ type: clientConstants.LOAD });
  try {
    const res = await api.get(`/companies/location/${locationId}/${companyId}`);
    dispatch(setClientLocation(res.data));
    dispatch({ type: clientConstants.LOAD_SUCCESS });
  } catch (error) {
    dispatch({
      type: errorConstants.SET_ERROR,
      error,
    });
    dispatch({
      type: clientConstants.LOAD_FAIL,
      error,
    });
  }
};

export const addClientLocation = (data) => async (dispatch, getState, api) => {
  dispatch({ type: clientConstants.LOAD });
  try {
    await api.post(`/companies/location/`, { data });
    dispatch({ type: clientConstants.LOAD_SUCCESS });
    return true;
  } catch (error) {
    dispatch({
      type: clientConstants.LOAD_FAIL,
      error,
    });
    dispatch({
      type: errorConstants.SET_ERROR,
      error: error || messages.DEFAULT_ERROR_MESSAGE,
    });
    return false;
  }
};

export const updateClientLocation = (data, _id) => async (dispatch, getState, api) => {
  dispatch({ type: clientConstants.LOAD });
  try {
    await api.put(`/companies/location/${_id}`, { data });
    dispatch({ type: clientConstants.LOAD_SUCCESS });
    return true;
  } catch (error) {
    dispatch({
      type: clientConstants.LOAD_FAIL,
      error,
    });
    dispatch({
      type: errorConstants.SET_ERROR,
      error: error || messages.DEFAULT_ERROR_MESSAGE,
    });
    return false;
  }
};

export const setClientLocation = (location) => async (dispatch, getState, api) => {
  dispatch({
    type: clientConstants.SET_CLIENT_LOCATION,
    location,
  });
};

export const sendInvities = (data) => async (dispatch, getState, api) => {
  dispatch({ type: clientConstants.LOAD });
  try {
    const res = await api.post('/jobs/share', { data });
    dispatch({ type: clientConstants.LOAD_SUCCESS });
    return res;
  } catch (error) {
    dispatch({
      type: errorConstants.SET_ERROR,
      error,
    });
    dispatch({
      type: clientConstants.LOAD_FAIL,
      error,
    });
  }
};

export const clientResetMessage = () => async (dispatch, getState, api) => {
  dispatch({ type: clientConstants.RESET_MESSAGE });
};

/**
 * To send job details in email to active candidates in flight
 * Access role allowed - any
 * @param data: object containing jobId: string & candidatesIds: array
 */
export const sendJobEmail = (data, type) => async (dispatch, getState, api) => {
  dispatch({ type: clientConstants.LOAD });
  try {
    if (type === 1) {
      await api.post('/jobs/job-email', { data });
    } else {
      await api.post('/jobs/share-with', { data });
    }
    dispatch({ type: clientConstants.LOAD_SUCCESS });
    return true;
  } catch (error) {
    dispatch({
      type: errorConstants.SET_ERROR,
      error,
    });
    dispatch({
      type: clientConstants.LOAD_FAIL,
      error,
    });
    return false;
  }
};
