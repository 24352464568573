export const USER_ROLES = {
  SUPER_ADMIN: 'super-admin',
  ADMIN: 'admin',
  ACCOUNT_MANAGER: 'account-manager',
  JOB_MANAGER: 'job-manager',
  RECRUITER: 'recruiter',
  VERIFIER: 'verifier',
  CORPORATE: 'corporate',
  FINANCE: 'finance',
  PAYROLL: 'payroll',
  HR: 'hr',
  CLIENT_ADMIN: 'client-admin',
  CLIENT_USER: 'client-user',
  INTERVIEWER: 'interviewer',
  CANDIDATE: 'candidate',
};

export const TIMESHEET_STATUS = {
  DRAFT: 1,
  NEW: 0,
  PENDING_APPROVAL: 2,
  REJECTED: 3,
  APPROVED: 4,
};

export const TIMESHEET_STATUS_TITLE = {
  0: 'New',
  1: 'Draft',
  2: 'Pending Approval',
  3: 'Rejected',
  4: 'Approved',
};

export const LEADS_STATUS_TITLE = {
  0: 'Closed',
  1: 'Prospecting',
  2: 'Discovery meeting',
  3: 'Pending approval',
  4: 'Won / converted',
  5: 'Lost',
};

export const CANDIDATE_USER_STATUS = {
  ACTIVE: 1,
  INACTIVE: 0,
  PARTIAL_ACTIVE: 2,
  NEW: 3,
  INVITED: 4,
  PASSIVE: 5,
  ON_HOLD: 6,
  UNAVAILABLE_ON_ASSIGNMENT: 7,
  UNAVAILABLE_PLACED: 8,
};

export const CANDIDATE_USER_STATUS_TITLE = {
  1: 'Active',
  0: 'Inactive',
  2: 'Partial Active',
  3: 'New',
  4: 'Invited',
  5: 'Passive',
  6: 'On Hold',
  7: 'Unavailable - On Assignment',
  8: 'Unavailable - Placed',
};

export const USER_ROLES_TITLE = {
  'client-admin': 'Admin',
  'client-user': 'Standard',
  candidate: 'Candidate',
};

export const CLIENT_STATUS = {
  ACTIVE: 1,
  INACTIVE: 0,
  PARTIAL_ACTIVE: 2,
  NEW: 3,
  INVITED: 4,
  ON_HOLD: 6,
};

export const CLIENT_STATUS_TITLE = {
  1: 'Active',
  0: 'Inactive',
  2: 'Partially Active',
  3: 'New',
  4: 'Invited',
  5: 'DNC',
};

export const JOB_STATUS = {
  IN_ACTIVE: 0,
  ACTIVE: 1,
  IN_PROGRESS: 2,
  ON_HOLD: 3,
  CLOSE: 4,
};

export const JOB_STATUS_TITLE = {
  0: 'InActive',
  1: 'Open',
  2: 'Draft',
  3: 'On Hold',
  4: 'Closed',
};

export const RECOMENDED_JOB_STATUS = {
  ALL: 'ALL',
  recommended: 'Recommended',
  suggested: 'Suggested',
};

export const INTERVIEW_STATUS_TITLE = {
  0: 'All',
  1: 'Invited',
  2: 'Draft',
  '2:1': 'Passed On',
  '2:2': 'Declined',
  '2:5': 'Offer Declined',
  '3:0': 'Scheduling',
  '3:1': 'Scheduled',
  '3:2': 'Conflict',
  4: 'Offered',
  6: 'Interviewed',
  7: 'Offer Accepted',
};

export const JOB_CANDIDATE_STATUS = {
  PENDING: 1,
  DROPPED: 2,
  SCHEDULED_FOR_INTERVIEW: 3,
  OFFER_MADE: 4,
  HOLD: 5,
  INTERVIEWED: 6,
  FOLLOW_UP_INTERVIEW: 3,
  OFFER_ACCEPTED: 7,
};

export const mapCandidateStatus = (status) => {
  switch (status) {
    case 1:
      return 'invited';
    case 2:
      return 'dropped';
    case 3:
      return 'scheduled';
    case 4:
      return 'offered';
    case 6:
      return 'interviewed';
    case 7:
      return 'offer_accepted';
    default:
      return '';
  }
};

export const JOB_CANDIDATE_STATUS_TITLE = {
  PENDING: 'Pending Candidate <br/> Response',
  DROPPED: 'Dropped',
  SCHEDULED_FOR_INTERVIEW: 'Scheduling',
  OFFER_MADE: 'Offer Made',
  OFFERED: 'Offered',
  OFFER_ACCEPTED: 'Accepted',
  INTERVIEWED: 'Interviewed',
};

export const JOB_CANDIDATE_SCHEDULING_SUB_STATUS = {
  DEFAULT: 0,
  SCHEDULED: 1,
  CONFLICT: 2,
  INTERVIEW_TIME_UP: 3,
  // Virtually by default all dropped bucket's
  // rejected and declined job candidate handled with status 2 and sub_status : 4
  // PASSED_ON: 4,
  DECLINED_BY_CLIENT: 5,
};

export const JOB_CANDIDATE_SCHEDULING_SUB_STATUS_TITLE = {
  DEFAULT: 'Scheduling',
  SCHEDULED: 'Scheduled',
  CONFLICT:
    // eslint-disable-next-line max-len
    'There is a scheduling conflict. Please click "Add Availability" to provide more times for the candidate to select from.',
  INTERVIEW_TIME_UP: 'Interview Time Up',
};

export const ENGLISH_WORD = {
  1: 'First',
  2: 'Second',
  3: 'Third',
  4: 'Fourth',
  5: 'Fifth',
  6: 'Sixth',
  7: 'Seventh',
};

export const AjnaUsersRoles = [
  { value: USER_ROLES.SUPER_ADMIN, label: 'Super Admin' },
  { value: 'account-manager', label: 'Account Manager' },
  { value: 'recruiter', label: 'Recruiter' },
  { value: 'verifier', label: 'Candidate Success Manager' },
  { value: 'corporate', label: 'Corporate' },
  { value: 'finance', label: 'Finance' },
  { value: 'payroll', label: 'Payroll' },
  { value: 'hr', label: 'HR' },
];

export const RoleFilterOptions = [
  { label: 'All', value: '' },
  { label: 'Super Admin', value: 'super-admin' },
  { label: 'Admin', value: 'admin' },
  { label: 'Account Manager', value: 'account-manager' },
  { label: 'Recruiter', value: 'recruiter' },
  { label: 'Candidate Success Manager', value: 'verifier' },
  { label: 'Client Admin', value: 'client-admin' },
  { label: 'Client User', value: 'client-user' },
  { label: 'Candidate', value: 'candidate' },
];

export const DEFAULT_LIMIT = 5;

export const DEFAULT_PASSWORD_MIN_LENGTH = 8;

export const DEFAULT_PASSWORD_MAX_LENGTH = 20;

export const COMPANY_MAIN_CARD_HEADERS_LIST = [
  'What kind of hire are you looking to make?',
  'How many years of relevant experience are you looking for?',
  'Select from the titles and job duties below.',
  'Select the Job Requirements below (if any)',
  'Enter job location to see relevant matches',
  "Great! Here's a preview of your top matches!",
  'Almost there! A little more information and we can start matching you with great talent!',
  'An email has been sent to activate your account. Complete the registration to see all your matches. ',
];

export const CANDIDATE_MAIN_CARD_HEADERS_LIST = [
  'Which of the following job functions interests you?',
  'What is your current experience?',
  'Select the following job duties that best suits your experience',
  "Great! Here's a preview of your top matches!",
  'First things first, tell us a bit about yourself!',
  // eslint-disable-next-line max-len
  'It looks like you have not yet activated your account. Please check your email for an activation link. Upon activation, you will be able to see your matches.',
  'An email has been sent to activate your account. Complete the registration to see all your matches. ',
];

// Job type constant values
export const JOB_TYPE_OPTIONS = {
  CONTRACT: 'Contract/Temporary',
  TEMPTOHIRE: 'Temp to Hire',
  PERMANENT: 'Permanent',
};

export const JOB_TYPE_CONTRACT = 'contract';

// helper Text string for compensation
export const COMPENSATION_HELPER_TEXT = `For ${JOB_TYPE_OPTIONS.CONTRACT} and ${JOB_TYPE_OPTIONS.TEMPTOHIRE} searches, the hourly bill rate includes the candidate pay rate + employment and service costs.`;

export const BOX_STYLE_ON_HOVER_BG_COLOR = 'rgba(82, 30, 114, 0.67)';

export const BG_COLOR_ON_WHITE_BACKGROUND = '#808080a6';

export const BG_COLOR_ON_TRANSPARENT_BACKGROUND = '#f2f2f24f';

export const CIRCLE_STYLE_ON_HOVER_BG_COLOR = 'rgba(82, 30, 114, 0.67)';

export const BOX_STYLE_BG_COLOR = 'rgba(29, 29, 28, 0)';

export const CIRCLE_STYLE_BG_COLOR = 'rgb(123, 124, 122)';

export const INLINE_BOXSTYLE_OBJECT = {
  padding: '20px',
  width: '100%',
  height: '115px',
  backgroundSize: '60px',
  backgroundPosition: 'center',
  boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 9px 0px',
  borderRadius: '7px',
  backgroundColor: '#F9FCFE',
  display: 'flex',
};

export const INLINE_EXP_BOXSTYLE_OBJECT = {
  padding: '20px',
  width: '60%',
  height: '130px',
  boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 9px 0px',
  borderRadius: '7px',
  backgroundColor: '#F9FCFE',
  display: 'inline-block',
};

export const INLINE_CIRCLESTYLE_OBJECT = {
  padding: '30px',
  display: 'inline-block',
  borderRadius: '50%',
  width: '140px',
  height: '140px',
  backgroundSize: '65px',
  backgroundPosition: 'center',
  boxShadow: '1px 5px 9px 0px rgba(0, 0, 0, 0.18)',
  marginBottom: '10px',
};

export const INLINE_BUTTON_STYLE_OBJECT = {
  padding: '4px 20px',
  textAlign: 'center',
  fontSize: '20px',
  color: 'white',
  backgroundColor: '#2c93fa',
  border: '1px solid #2c93fa',
  borderRadius: '13px',
  fontWeight: '600',
  margin: '10px',
};

export const INLINE_BACKBUTTON_STYLE_OBJECT = {
  padding: '4px 20px',
  textAlign: 'center',
  fontSize: '20px',
  color: '#2c93fa',
  backgroundColor: 'white',
  border: '1px solid #2c93fa',
  borderRadius: '13px',
  margin: '10px',
  fontWeight: '600',
};

export const INLINE_DROPDOWN_STYLE_OBJECT = {
  margin: '15px',
};

export const INLINE_ICON_STYLE = {
  height: '36px',
  width: '36px',
  margin: 'auto',
};

export const COMPANY_STEPPER_CONFIG_OBJECT = {
  completeColor: '#000000',
  activeColor: '#6A6A6A',
  defaultColor: '#C4C4C4',
  steps: [
    { title: 'JOB FUNCTION' },
    { title: 'EXPERIENCE' },
    { title: 'TITLE/DUTIES' },
    { title: 'TOP 5 MATCHES' },
    { title: 'ABOUT YOU' },
  ],
};

export const CANDIDATE_STEPPER_CONFIG_OBJECT = {
  completeColor: '#000000',
  activeColor: '#6A6A6A',
  defaultColor: '#C4C4C4',
  steps: [
    { title: 'JOB FUNCTION' },
    { title: 'EXPERIENCE' },
    { title: 'DUTIES' },
    { title: 'TOP 5 MATCHES' },
    { title: 'ABOUT YOU' },
  ],
};

export const ANIMATION_STYLE_OBJECT = {
  fadeInDown: {
    animation: 'x 1s',
    animationName: 'fadeInDown',
  },
};

export const CALENDAR = {
  start_time: {
    h: 8,
    m: 0,
    s: 0,
  },
  end_time: {
    h: 18,
    m: 0,
    s: 0,
  },
};

export const DEFAULT_PROXIMITY_RADIUS = 10;

export const DEFAULT_MAX_ROWS = 4;

export const JOB_COMPENSATION = {
  BOUNDS: {
    min: 20,
    max: 120,
  },
  DEFAULT_VALUES: {
    min: 40,
    max: 100,
  },
};

export const MAX_JOBS_FETCHED_LIMIT = 0;

export const GOOGLE_MAPS = {
  DEFAULT_LOCATION: {
    lat: 39.8097343,
    lng: -98.5556199,
  },
  DEFAULT_ZOOM: 10,
  DEFAULT_RADIUS: 10,
};

export const DEFAULT_LIST_FILTERS = {
  page: 1,
  limit: DEFAULT_LIMIT,
};

export const DATE_FORMAT_MDY = 'MM/DD/YYYY';

export const DATE_FORMAT_PRETTY_MDY = 'MMMM DD, YYYY';

export const DATE_FORMAT_DDDD = 'dddd';

export const DATE_FORMAT_DDDD_WITH_TIME = 'dddd hh:mm A';

export const DATE_FORMAT_HOUR_WITHOUT_AM_PM = 'hh:mm';

export const DATE_FORMAT_HOUR = `${DATE_FORMAT_HOUR_WITHOUT_AM_PM} A`;

export const DATE_FORMAT_MDY_WITH_TIME = `${DATE_FORMAT_MDY} hh:mm A`;

export const DATE_FORMAT_YMD = 'YYYY-MM-DD';

export const DEFAULT_ZOOM_TO_CENTIMETERS = [
  591657550.5, 295828775.3, 147914387.6, 73957193.82, 36978596.91, 18489298.45, 9244649.227,
  4622324.614, 2311162.307, 1155581.153, 577790.5767, 288895.2884, 144447.6442, 72223.82209,
  36111.91104, 18055.95552, 9027.977761, 4513.98888, 2256.99444, 1128.49722,
];

export const PREPEND_LINKEDIN_VALUE = 'https://www.linkedin.com/in/';

export const CHECK_LINKED_IN_CASES = [
  'https://www.linkedin.com/in/',
  'https://www.linkedin.com/in/www.',
  'https',
  'http://www.linkedin.com/in/',
  'http',
  'www.linkedin.com/in/',
  'www.linkedin.com',
  'www.linkedin/',
  'www.linkedin',
  'www',
  'linkedin.com/in/',
  'linkedin.com',
  '.com',
  './',
  '/',
];

export const LEADS_STATUS_OPTIONS = [
  { label: 'Closed', value: 0 },
  { label: 'Discovery meeting', value: 2 },
  { label: 'Lost', value: 5 },
  { label: 'Pending approval', value: 3 },
  { label: 'Prospecting', value: 1 },
  { label: 'Won / converted', value: 4 },
];

export const TYPE_OF_REGION_BOUNDARIES = ['city', 'state'];

export const DEFAULT_TIMEZONE = 'America/Los_Angeles';

export const blackListRoutes = [
  '/culture-and-values',
  '/login',
  '/reset-password',
  '/activate-account',
];

export const CANDIDATE_EXP_AND_RESP = [
  'Experience',
  'Industry',
  'Title',
  'Job Function',
  'Duties',
  'Requirements',
  'Reason For Leaving',
  'Exp Start Date',
  'Exp End Date',
];

export const CANDIDATE_VERTICALIZATION = ['Title', 'Selected', 'Range'];

export const PreferredLocationKeys = [
  'City',
  'State',
  'Zipcode',
  'Country',
  'Proximity',
  'Relocation Required',
  'Timezone',
];
export const TIMESHEET_HOLIDAY_VERBIAGE = {
  CLIENT: {
    IS_PTO: 'Per your agreement with Premier, this candidate will receive holiday pay',
    HEADER:
      'Candidate should only enter holiday hours that were worked. For any inaccuracies, reject the timesheet to allow the candidate to resubmit.',
  },
  CANDIDATE: {
    HEADER: 'Only enter hours that you actually worked.',
    IS_PTO: 'You are eligible for holiday PTO, which will be added to your paycheck accordingly.',
  },
};

export const GENDER_PRONOUN_OPTIONS = [
  { value: 'She, Her, Hers', label: 'She, Her, Hers' },
  { value: 'He, Him, His', label: 'He, Him, His' },
  { value: 'Ze, Zir, Zirs', label: 'Ze, Zir, Zirs' },
  { value: 'They, Them, Theirs', label: 'They, Them, Theirs' },
  { value: 'Prefer not to say', label: 'Prefer not to say' },
];

export const OPEN_TO_REMOTE_OPTIONS = {
  0: "No, I'm not open to remote work",
  1: 'Yes, I only want to work remotely',
  2: "Yes, I'm open to remote work",
};

export const getJobRemoteLOVs = (id) => {
  switch (id) {
    case 0:
      return 'No';
    case 1:
      return '100% remote';
    case 2:
      return 'Remote optional';
    case 3:
      return 'Temporarily remote due to special circumstance';
    default:
      return '';
  }
};

export const getRemoteValue = (id) => {
  switch (id) {
    case 0:
      return 'Not open to remote';
    case 1:
      return '100% remote';
    case 2:
      return 'Open to Remote';
    default:
      return '';
  }
};

export const JOB_FUNCTION_TYPE = [
  { label: 'Interested in', value: 0 },
  { label: 'Experience in', value: 1 },
  { label: 'Both', value: 2 },
];

export const DEPENDENT_OPTION = [
  { label: 'Overall', value: 0 },
  { label: 'Dependent on Job Function', value: 1 },
  { label: 'Dependent on Job Title', value: 2 },
];

export const timezoneOptions = [
  {
    label: 'EST',
    value: 'America/New_York',
  },
  {
    label: 'CST',
    value: 'America/Chicago',
  },
  {
    label: 'MST',
    value: 'America/Denver',
  },
  {
    label: 'PST',
    value: 'America/Los_Angeles',
  },
  {
    label: 'AKST',
    value: 'America/Anchorage',
  },
  {
    label: 'HST',
    value: 'Pacific/Honolulu',
  },
];

export const defaultCompanyLogo = `${process.env.REACT_APP_S3_URL}/images/companies/company.png`;
export const defaultUserLogo = `${process.env.REACT_APP_S3_URL}/images/users/user.png`;

export const AttachmentFilterOptions = [
  { label: 'All', value: '' },
  { label: 'Unarchived', value: false },
  { label: 'Archived', value: true },
];
