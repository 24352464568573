const ACCOUNT_MANAGERS_REQUEST = 'ACCOUNT_MANAGERS_REQUEST';
const ACCOUNT_MANAGERS_SUCCESS = 'ACCOUNT_MANAGERS_SUCCESS';
const ACCOUNT_MANAGERS_FAILURE = 'ACCOUNT_MANAGERS_FAILURE';

const accountManagersRequest = (dispatch) => {
  dispatch({ type: ACCOUNT_MANAGERS_REQUEST, payload: { loading: true } });
};

const accountManagersSuccess = (dispatch, response) => {
  dispatch({
    type: ACCOUNT_MANAGERS_SUCCESS,
    payload: { data: response, loading: false },
  });
};

const accountManagersFailure = (dispatch) => {
  dispatch({ type: ACCOUNT_MANAGERS_FAILURE, payload: { loading: false } });
};

const SAVE_ACCOUNT_MANAGERS_REQUEST = 'SAVE_ACCOUNT_MANAGERS_REQUEST';
const SAVE_ACCOUNT_MANAGERS_SUCCESS = 'SAVE_ACCOUNT_MANAGERS_SUCCESS';
const SAVE_ACCOUNT_MANAGERS_FAILURE = 'SAVE_ACCOUNT_MANAGERS_FAILURE';

const saveAccountManagerRequest = (dispatch) => {
  dispatch({ type: SAVE_ACCOUNT_MANAGERS_REQUEST, payload: { loading: true } });
};

const saveAccountManagerSuccess = (dispatch, response) => {
  dispatch({
    type: SAVE_ACCOUNT_MANAGERS_SUCCESS,
    payload: { data: response, loading: false },
  });
};

const saveAccountManagerFailure = (dispatch, error) => {
  dispatch({
    type: SAVE_ACCOUNT_MANAGERS_FAILURE,
    payload: { loading: false, error },
  });
};

export {
  ACCOUNT_MANAGERS_REQUEST,
  ACCOUNT_MANAGERS_SUCCESS,
  ACCOUNT_MANAGERS_FAILURE,
  accountManagersRequest,
  accountManagersSuccess,
  accountManagersFailure,
  SAVE_ACCOUNT_MANAGERS_REQUEST,
  SAVE_ACCOUNT_MANAGERS_SUCCESS,
  SAVE_ACCOUNT_MANAGERS_FAILURE,
  saveAccountManagerRequest,
  saveAccountManagerSuccess,
  saveAccountManagerFailure,
};
