export const candidateListConstants = {
  LOAD: 'candidates/list/LOAD',
  LOAD_SUCCESS: 'candidates/list/LOAD_SUCCESS',
  LOAD_FAIL: 'candidates/list/LOAD_FAIL',

  // Listing Constants
  GET_CANDIDATES_LIST: 'candidates/list/GET_CANDIDATES_LIST',
  SET_SETTINGS: 'candidates/list/SET_SETTINGS',
  SET_SEARCH_STRING: 'candidates/list/SET_SEARCH_STRING',
};
