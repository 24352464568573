import React from 'react';
import PropTypes from 'prop-types';
import { Popup, PopupHeader, PopupBody, PopupFooter } from '../../--primitives/pr-popup';
import { connect } from 'react-redux';
import PrButton from '../../--primitives/pr-button';
import { flushSuccessMessage } from '../../../redux/actions/successMessage.actions';
import { strictValidStringWithMinLength } from '../../../utils/commonUtils';

const SuccessPopup = ({ message, status, dispatch, callFLushAPI }) => {
  return (
    <Popup isOpen={!!status || strictValidStringWithMinLength(message)} action="success">
      <PopupHeader action="success" close toggle={callFLushAPI} />
      <PopupBody>
        <div className="title content" dangerouslySetInnerHTML={{ __html: message }} />
      </PopupBody>
      <PopupFooter type="flex-centered">
        <PrButton value="Ok" type="light-blue tall" onClick={callFLushAPI} />
      </PopupFooter>
    </Popup>
  );
};

SuccessPopup.propTypes = {
  callFLushAPI: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  message: PropTypes.string,
  status: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]).isRequired,
};

SuccessPopup.defaultProps = {
  message: null,
};

const mapStateProps = (state) => {
  return {
    message: state.get('successMessage').get('message'),
    status: state.get('successMessage').get('status'),
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  callFLushAPI: (...params) => dispatch(flushSuccessMessage(...params)),
});

export default connect(mapStateProps, mapDispatchToProps)(SuccessPopup);
