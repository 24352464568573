import {
  CLIENT_FEEDBACK_REQUEST,
  CLIENT_FEEDBACK_SUCCESS,
  CLIENT_FEEDBACK_FAILURE,
} from './actions';
import { feedbackListConstants } from '../constants';

const initialState = {};

const client = (state = initialState, action) => {
  switch (action.type) {
    case CLIENT_FEEDBACK_REQUEST:
    case CLIENT_FEEDBACK_SUCCESS:
    case CLIENT_FEEDBACK_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case feedbackListConstants.TOGGLE_FEEDBACK_LOAD:
      return { ...state, isLoading: true, loadingId: action.id };
    case feedbackListConstants.TOGGLE_FEEDBACK_SUCCESS:
      let updatedState = { ...state, isLoading: false, loadingId: '' };
      const { data: { rows = [] } = {} } = updatedState;
      const matchedItem = rows.find((item) => item._id === action.id);
      if (matchedItem) {
        updatedState = {
          ...updatedState,
          data: {
            ...updatedState.data,
            rows: updatedState.data.rows.map((item) => {
              if (item._id === action.id) return { ...item, is_hidden: !item.is_hidden };

              return item;
            }),
          },
        };
      }
      return updatedState;
    case feedbackListConstants.TOGGLE_FEEDBACK_FAIL:
      return { ...state, isLoading: false, loadingId: '' };
    default:
      return state;
  }
};

export { client };
