import React, { useEffect, useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import './ff-dropdown-input.scss';
import debounce from 'lodash.debounce';
import {
  strictValidArrayWithLength,
  validObjectWithParameterKeys,
  strictValidObjectWithKeys,
  strictValidStringWithMinLength,
  isFunction,
  strictValidArray,
  strictValidString,
  strictValidObject,
} from '../../../utils/commonUtils';
import Loading from '../../--primitives/pr-loading';

/* props required to inherit this component are given below
 * placeholder: type string
 * Options: type Array of objects with {value and label}
 */
const initialState = {
  items: [],
  opened: false,
  filled: false,
  inputValue: '',
  selectedOption: { label: '', value: '' },
};

function FfDropdownInput({
  input,
  input: { value, onChange } = {},
  placeholder,
  isValueOnlyRequired,
  listClassName,
  options: staticOptions = [],
  meta: { touched, error } = {},
  disabled,
  apiCall,
  asyncDropdown,
  addNew,
  canType,
  className,
  onSelect,
  dropDownRefComp,
  stopMoveNextField,
  isMatchValue,
  isMulti,
  grouping,
  clearField,
  eventClass,
  isLoadingManual = false,
  clearOnBlur,
  ...othersProps
}) {
  const [options, setOptions] = useState([]);
  const listEl = useRef(null);
  const inputEl = useRef(null);
  const parentInputEl = useRef(null);
  const [isLoading, setLoading] = useState(false);
  const [{ items, opened, filled, inputValue }, setState] = useState({
    ...initialState,
    options,
  });
  const [openPosition, setOpenPosition] = useState('bottom');
  useEffect(() => {
    if (strictValidArrayWithLength(staticOptions)) {
      grouping ? setOptions(AddedeTitleOption(staticOptions)) : setOptions(staticOptions);
    }
  }, [staticOptions]);

  useEffect(() => {
    if (strictValidArray(options)) {
      let updatedFilters = [...options];
      if (asyncDropdown && inputValue) {
        if (addNew) {
          updatedFilters.push({
            label: `Add new ${' '}"${inputValue}"`,
            value: inputValue,
            newOption: true,
          });
        }
        updatedFilters = updatedFilters.map((option) => {
          const index = option.label
            ? option.label.toLowerCase().indexOf(inputValue.toLowerCase())
            : -1;
          option.formattedLabel = option.isTitle ? (
            <p>{option.label}</p>
          ) : (
            <p>
              {option.label.substring(0, index)}
              <span className="highlight">
                {option.label.substring(index, index + inputValue.length)}
              </span>
              {option.label.substring(index + inputValue.length)}
            </p>
          );

          return option;
        });
        setState((prevState) => ({
          ...prevState,
          opened: asyncDropdown ? true : prevState.opened,
          options,
          items: updatedFilters,
        }));
        return;
      }
      setState((prevState) => ({
        ...prevState,
        options,
      }));
    }
  }, [options]);

  useEffect(() => {
    if (isValueOnlyRequired && options && options.length > 0) {
      let selected;
      selected = isMulti
        ? options.filter((option) => value.some((item) => item === option.value))
        : options.find((option) => option.value === value);
      if (strictValidObjectWithKeys(selected)) {
        const { subtitle, label } = selected;
        const inputText = subtitle ? `${label} - ${subtitle}` : label || '';
        setState((prevState) => ({
          ...prevState,
          selectedOption: selected || value,
          inputValue: inputText,
          filled: label || '',
        }));
      } else if (strictValidArrayWithLength(selected)) {
        setState((prevState) => ({
          ...prevState,
          inputValue: '',
          opened: false,
          filled: true,
          items: asyncDropdown ? [] : prevState.items,
        }));
      } else if (!strictValidObjectWithKeys(selected)) {
        setState((prevState) => ({
          ...prevState,
          selectedOption: selected || value,
          inputValue: '',
          filled: '',
        }));
      }
    }
  }, [value, options]);

  useEffect(() => {
    if (strictValidObjectWithKeys(value)) {
      const { label = '', subtitle = '' } = value;
      const inputTextVal = subtitle ? `${label} - ${subtitle}` : label;
      setState((prevState) => ({
        ...prevState,
        inputValue: inputTextVal,
        filled: strictValidStringWithMinLength(label),
        selectedOption: value,
      }));
    } else if (strictValidArray(value)) {
      setState((prevState) => ({
        ...prevState,
        filled: strictValidArrayWithLength(value),
        selectedOption: value[0],
      }));
    }
  }, [value]);

  useEffect(() => {
    setDropDownPosition();
    setTimeout(() => {
      if (
        !inputValue &&
        validObjectWithParameterKeys(listEl, ['current']) &&
        strictValidObjectWithKeys(listEl.current)
      )
        listEl.current.scrollTop = 0;
    }, 200);
  }, [inputValue, opened]);

  const handleClickOutside = useCallback((e) => {
    const { target } = e;
    if (parentInputEl && parentInputEl.current && !parentInputEl.current.contains(target)) {
      setState((prevState) => ({
        ...prevState,
        inputValue: isMulti ? '' : prevState.selectedOption.label || '',
        opened: false,
        items: [],
        filled:
          prevState.selectedOption &&
          strictValidStringWithMinLength(prevState.selectedOption.label),
      }));
    }
    isMulti && asyncDropdown && setOptions([]);
  }, []);

  const setDropDownPosition = () => {
    let btspace = document.getElementsByClassName('pr-floating-message');
    if (!strictValidArrayWithLength(btspace))
      btspace = document.getElementsByClassName('page-footer');
    const extraSpaceExists = btspace ? (btspace[0] ? btspace[0].offsetHeight / 1.03 : 0) : 0;
    let bottomSpace;
    const position = inputEl.current.getBoundingClientRect();
    const listPosition = listEl.current && listEl.current.getBoundingClientRect();
    const listHeight = listPosition ? listPosition.height : 0;
    const topSpace = position.y;

    if (validObjectWithParameterKeys(dropDownRefComp, ['current']) && dropDownRefComp.current) {
      const elToCompareHeight = dropDownRefComp.current.getBoundingClientRect().y;
      bottomSpace = elToCompareHeight - position.bottom - extraSpaceExists;
    } else {
      bottomSpace = window.innerHeight - position.bottom - extraSpaceExists;
    }

    if (bottomSpace < listHeight && topSpace > listHeight) {
      setOpenPosition('top');
    } else {
      setOpenPosition('bottom');
    }
  };

  const onFocus = () => {
    setState((prevState) => {
      return {
        ...prevState,
        opened: true,
        items: asyncDropdown
          ? prevState.items
          : prevState.options.map((v) => ({
              ...v,
              formattedLabel: undefined,
            })),
      };
    });
  };

  useEffect(() => {
    inputEl.current.addEventListener('focus', onFocus, false);
    if (strictValidString(eventClass)) {
      const eventField = document.querySelector(`.${eventClass}`);
      eventField.addEventListener('click', handleClickOutside, false);
    } else document.addEventListener('click', handleClickOutside, false);
    return () => {
      if (strictValidString(eventClass)) {
        const eventField = document.querySelector(`.${eventClass}`);
        eventField && eventField.removeEventListener('click', handleClickOutside);
      } else document.removeEventListener('click', handleClickOutside);
      if (inputEl && inputEl.current) inputEl.current.removeEventListener('focus', onFocus);
    };
  }, []);

  const AddedeTitleOption = (arr) => {
    if (!strictValidArrayWithLength(arr)) return [];
    const allGroupedOption = [];
    let result = [];
    arr.forEach((option) => {
      const { groupTitle, groupId } = option;
      const index = allGroupedOption.findIndex((v) => v.groupId === groupId);
      if (index > -1) {
        allGroupedOption[index].groupedOptions.push(option);
      } else {
        allGroupedOption.push({
          groupId,
          groupTitle,
          groupedOptions: [option],
        });
      }
    });
    allGroupedOption.forEach((vv) => {
      const { groupTitle, groupedOptions } = vv;
      result = [...result, { label: groupTitle, isTitle: true }, ...groupedOptions];
    });
    return result;
  };

  const debouncedSave = useCallback(
    debounce(async (nextValue) => {
      await apiCall(nextValue, (v) =>
        grouping ? setOptions(AddedeTitleOption(v)) : setOptions(v),
      );
      setLoading(false);
    }, 1000),
    [], // will be created only once initially
  );

  const onChangeInput = (e) => {
    const inputVal = e.target.value || '';
    if (asyncDropdown) {
      if (inputVal) {
        setLoading(true);
        debouncedSave(inputVal);
      }
      setState((prevState) => ({
        ...prevState,
        inputValue: inputVal,
        filled: strictValidStringWithMinLength(inputVal),
        options: [],
        items: [],
      }));
      return;
    }

    const filteredOptions = options
      .filter((option) => {
        return option.label
          ? option.label.toLowerCase().indexOf(inputVal.toLowerCase()) > -1
          : false;
      })
      .map((option) => {
        const index = option.label
          ? option.label.toLowerCase().indexOf(inputVal.toLowerCase())
          : -1;
        option.formattedLabel = (
          <p>
            {option.label.substring(0, index)}
            <span className="highlight">
              {option.label.substring(index, index + inputVal.length)}
            </span>
            {option.label.substring(index + inputVal.length)}
          </p>
        );

        return option;
      });
    setState((prevState) => ({
      ...prevState,
      filled: strictValidStringWithMinLength(inputVal),
      opened: strictValidStringWithMinLength(inputVal),
      items: strictValidArrayWithLength(filteredOptions)
        ? filteredOptions
        : [{ label: 'No Results', value: 'No Results' }],
      inputValue: inputVal,
    }));
  };

  const onBlurInput = (e) => {
    const inputVal = e.target.value || '';
    const enteredText = options.find((val) => val.label === inputVal) || {};
    if (
      (clearOnBlur && !strictValidString(inputVal)) ||
      (isMatchValue && inputVal && !validObjectWithParameterKeys(enteredText, ['value']))
    ) {
      setState((prevState) => ({
        ...prevState,
        opened: false,
        filled: false,
        inputValue: '',
        selectedOption: {},
        items: asyncDropdown ? [] : prevState.items,
      }));
      isValueOnlyRequired ? onChange('') : onChange({});
      if (onSelect) onSelect({});
    }
  };

  const handleSelect = (selected = {}) => {
    const selectedOption = { ...selected };
    const { label, value: optionValue, subtitle, newOption = false } = selectedOption;
    if (newOption) {
      selectedOption.label = optionValue;
    }
    const inputText = subtitle ? `${label} - ${subtitle}` : label;
    setState((prevState) => ({
      ...prevState,
      inputValue: isMulti ? '' : inputText,
      opened: false,
      filled: optionValue !== '',
      items: asyncDropdown ? [] : prevState.items,
    }));

    if (isValueOnlyRequired) {
      if (isMulti) {
        const updatedValuesArr = updatedValue.map((v) => v.value);
        onChange(updatedValuesArr);
      } else onChange(optionValue);
    } else if (isMulti) {
      const updatedValue = [...value, selectedOption];
      onChange(updatedValue);
    } else onChange(selectedOption);

    if (onSelect && isMulti) {
      const updatedValue = [...value, selectedOption];
      onSelect(updatedValue);
    }
    if (onSelect && !isMulti) onSelect(selectedOption);
    isMulti && asyncDropdown && setOptions([]);
  };

  const toggleDropdown = () => {
    setState((prevState) => ({
      ...prevState,
      opened: !prevState.opened,
      items: asyncDropdown
        ? prevState.items
        : prevState.options.map((v) => ({
            ...v,
            formattedLabel: undefined,
          })),
    }));
  };

  const handleDelete = (index) => {
    const updatedValue = [...value];
    updatedValue.splice(index, 1);
    if (updatedValue.length === 0) {
      setState((prev) => ({ ...prev, selectedOption: {} }));
    }
    onChange(updatedValue);
  };

  const onDeleteByBackspace = async () => {
    if (inputValue) return;
    if (!strictValidArrayWithLength(value)) return;
    handleDelete(value.length - 1);
  };

  return (
    <div
      ref={parentInputEl}
      className={`ff-dropdown-input-wrapper${disabled || !opened ? '' : ' opened'}${
        filled ? ' filled' : ''
      }${disabled ? ' disabled' : ''} ${className || ''}`}
      is-error={touched && error ? 'true' : 'false'}
      onClick={() => !opened && inputEl.current.focus()}
    >
      {isMulti &&
        strictValidArrayWithLength(value) &&
        value.map((item, index) => {
          const { label, subtitle } = item;
          const key = `key-${index}`;
          return (
            // eslint-disable-next-line react/no-array-index-key
            <p key={key} className={disabled ? 'disabled' : ''}>
              {subtitle ? `${label} - ${subtitle}` : label}{' '}
              <span onClick={() => !disabled && handleDelete(index)}>&times;</span>
            </p>
          );
        })}
      <div
        className="input-wrapper"
        tip="error tool-bottom-left"
        data-description={error}
        is-error={touched && error ? 'true' : 'false'}
      >
        <input
          ref={inputEl}
          value={clearField ? '' : inputValue}
          onInput={onChangeInput}
          spellCheck="false"
          autoComplete="off"
          disabled={disabled}
          readOnly={!canType}
          {...othersProps}
          className={stopMoveNextField ? 'stayOnDropdownField' : ''}
          onBlur={onBlurInput}
          onKeyDown={(e) => {
            if (['Backspace', 'Delete'].includes(e.key)) {
              onDeleteByBackspace();
            }
            if (e.key === 'tab' || e.keyCode === 9) {
              toggleDropdown();
            }
          }}
        />
      </div>
      {isFunction(placeholder) ? placeholder() : <span className="placeholder">{placeholder}</span>}
      {(isLoading || isLoadingManual) && <Loading type="button" color="#4a85fb" />}
      <svg onClick={toggleDropdown} className="arrow" viewBox="0 0 14 8" fill="none">
        <path d="M1 1L7 7L13 1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
      {strictValidArrayWithLength(items) && (
        <ul className={`list ${openPosition} ${listClassName}`} ref={listEl}>
          {items
            .filter((item) =>
              strictValidArrayWithLength(value) ? !value.some((v) => v.value === item.value) : true,
            )
            .map((option) => {
              const {
                value: optionValue,
                label,
                formattedLabel,
                subtitle,
                isTitle = false,
                groupTitle = '',
              } = option;
              return (
                <li
                  key={optionValue ? optionValue + groupTitle : label}
                  className={
                    (strictValidString(optionValue) &&
                      optionValue === value.optionValue &&
                      'active') ||
                    (optionValue === input.value && 'active') ||
                    (optionValue === 'No Results' && 'empty') ||
                    (isTitle && 'option-group-title') ||
                    ''
                  }
                  onClick={() => optionValue !== 'No Results' && !isTitle && handleSelect(option)}
                >
                  {formattedLabel || <p>{subtitle ? `${label} - ${subtitle}` : label}</p>}
                </li>
              );
            })}
        </ul>
      )}
    </div>
  );
}

FfDropdownInput.propTypes = {
  placeholder: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  disabled: PropTypes.bool,
  canType: PropTypes.bool,
  onSelect: PropTypes.func,
  asyncDropdown: PropTypes.bool,
  addNew: PropTypes.bool,
  apiCall: PropTypes.func,
  dropDownRefComp: PropTypes.bool,
  stopMoveNextField: PropTypes.bool,
  isMatchValue: PropTypes.bool,
  isMulti: PropTypes.bool,
  grouping: PropTypes.bool,
  isLoadingManual: PropTypes.bool,
  eventClass: PropTypes.string,
};

FfDropdownInput.defaultProps = {
  placeholder: 'Select',
  disabled: false,
  canType: true,
  className: '',
  asyncDropdown: false,
  addNew: false,
  apiCall: () => {
    /* empty fun */
  },
  dropDownRefComp: null,
  stopMoveNextField: false,
  isMatchValue: false,
  isMulti: false,
  grouping: false,
  isLoadingManual: false,
  eventClass: '',
};

export function Dropdown({
  options = [],
  onChange = () => {
    /* empty fun */
  },
  placeholder = '',
}) {
  const [state, setState] = useState({});

  useEffect(() => {
    if (strictValidArrayWithLength(options)) {
      const defaultOption = options.find(({ selected }) => selected) || options[0];
      setState(defaultOption);
    }
  }, [options]);

  const onSelectValue = (value = {}) => {
    setState(value);
    let filterValue = value;
    if (strictValidObjectWithKeys(value)) {
      if (validObjectWithParameterKeys(value, ['value'])) {
        filterValue = value.value;
      } else {
        filterValue = '';
      }
    }
    onChange(filterValue);
  };

  const input = {
    value: state,
    onChange: onSelectValue,
  };
  return (
    <FfDropdownInput
      options={options}
      input={input}
      placeholder={placeholder}
      className="plain-dropdown"
    />
  );
}
export default FfDropdownInput;
