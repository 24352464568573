import { LOCATION_CHANGE } from 'react-router-redux';
import {
  TIMEZONES_PROCESSING,
  TIMEZONES_SUCCESS,
  TIMEZONES_FAILURE,
  SAVE_TEMPLATE_FAILURE,
  SAVE_TEMPLATE_PROCESSING,
  SEND_INVITE_FAILURE,
  SEND_INVITE_SUCCESS,
  SET_TEMPLATE_SUCCESS,
  GET_TEMPLATE_FAILURE,
  GET_TEMPLATE_PROCESSING,
  SAVE_FEEDBACK,
  SEND_INVITE_PROCESSING,
} from './constants';

const initialState = {
  isLoading: true,
  error: null,
  timezones: [],
  templateDetail: {},
  feedback: '',
};

export default function scheduleTemplate(state = initialState, action) {
  switch (action.type) {
    case TIMEZONES_PROCESSING:
    case SAVE_TEMPLATE_PROCESSING:
    case GET_TEMPLATE_PROCESSING:
    case SEND_INVITE_PROCESSING: {
      return { ...state, isLoading: true };
    }
    case TIMEZONES_SUCCESS: {
      return { ...state, isLoading: false, timezones: action.payload.data };
    }
    case SEND_INVITE_SUCCESS: {
      return { ...state, isLoading: false };
    }
    case SET_TEMPLATE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        templateDetail: action.payload.data,
      };
    }
    case SAVE_FEEDBACK: {
      return { ...state, feedback: action.feedback };
    }
    case TIMEZONES_FAILURE:
    case SAVE_TEMPLATE_FAILURE:
    case SEND_INVITE_FAILURE:
    case GET_TEMPLATE_FAILURE: {
      return { ...state, isLoading: false, error: action.payload.error };
    }
    // Reset state on location change
    case LOCATION_CHANGE:
      return {
        ...state,
        isLoading: false,
        error: null,
        message: null,
      };
    default:
      return state;
  }
}
