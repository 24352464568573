// Please read https://github.com/reduxjs/reselect, before touching this code.
// For quick info, https://www.saltycrane.com/blog/2017/05/what-are-redux-selectors-why-use-them/
// Plan for using re-reselect.

import { createSelector } from 'reselect';
import { USER_ROLES } from '../../utils/siteSpecificConstants';
import { PERMISSION_TYPE } from '../../components/render-if-permission-available/helper';

const getPermissionFromStore = (state, permissionKey, permissionType = PERMISSION_TYPE.WRITE) => {
  let permissions = state.get('auth').get('permissions');
  permissions = permissions.size === 0 ? undefined : permissions;

  const user = state.get('auth').get('user');
  let roles = user ? user.roles : undefined;
  roles = roles && roles.length ? roles : undefined;

  if (!roles || !permissions) {
    // NOTE: We need to return undefined only, don't change it
    return undefined;
  }
  if (!permissions[permissionKey]) {
    return false;
  }
  return !(!permissions[permissionKey].find((permission) => permission === permissionType));
};

export const getPermission = createSelector([getPermissionFromStore], (permission) => permission);

const getIfUserOwnTheClient = (state) => {
  const user = state.get('auth').get('user');
  let roles = user ? user.roles : undefined;
  roles = roles && roles.length ? roles : [];

  if (
    roles.find((role) => role === USER_ROLES.SUPER_ADMIN) ||
    roles.find((role) => role === USER_ROLES.ACCOUNT_MANAGER)
  ) {
    // NOTE: We need to return undefined only, don't add default only to is_own
    const { data: { is_own } = {} } = state.get('clientWorkBench').get('companyDetails');
    return is_own;
  }
  return true;
};

export const getClientPermission = createSelector(
  [getPermission, getIfUserOwnTheClient],
  (permission, isOwn) => permission && isOwn,
);

const getIfUserOwnTheJob = (state) => {
  const user = state.get('auth').get('user');
  let roles = user ? user.roles : undefined;
  roles = roles && roles.length ? roles : [];

  if (
    roles.find((role) => role === USER_ROLES.SUPER_ADMIN) ||
    roles.find((role) => role === USER_ROLES.ACCOUNT_MANAGER) ||
    roles.find((role) => role === USER_ROLES.RECRUITER)
  ) {
    // NOTE: We need to return undefined only, don't add default only to is_own
    const { is_own } = state.get('jobs').get('job');
    return is_own;
  }
  return true;
};

export const getJobPermission = createSelector(
  [getPermission, getIfUserOwnTheJob],
  (permission, isOwn) => permission && isOwn,
);

const getIfUserOwnTheInterview = (state) => {
  const user = state.get('auth').get('user');
  let roles = user ? user.roles : undefined;
  roles = roles && roles.length ? roles : [];

  if (
    roles.find((role) => role === USER_ROLES.SUPER_ADMIN) ||
    roles.find((role) => role === USER_ROLES.ACCOUNT_MANAGER) ||
    roles.find((role) => role === USER_ROLES.RECRUITER)
  ) {
    // NOTE: We need to return undefined only, don't add default only to is_own
    const { data: { is_own } = {} } = state.get('interviews').get('details');
    return is_own;
  }
  return true;
};

export const getInterviewPermission = createSelector(
  [getPermission, getIfUserOwnTheInterview],
  (permission, isOwn) => permission && isOwn,
);

const getIfUserOwnThePlacement = (state) => {
  const user = state.get('auth').get('user');
  let roles = user ? user.roles : undefined;
  roles = roles && roles.length ? roles : [];

  if (
    roles.find((role) => role === USER_ROLES.SUPER_ADMIN) ||
    roles.find((role) => role === USER_ROLES.ACCOUNT_MANAGER) ||
    roles.find((role) => role === USER_ROLES.RECRUITER) ||
    roles.find((role) => role === USER_ROLES.VERIFIER)
  ) {
    // NOTE: We need to return undefined only, don't add default only to is_own
    const { data: { is_own } = {} } = state.get('placementsWorkbench').get('placementDetail');
    return is_own;
  }
  return true;
};

export const getPlacementPermission = createSelector(
  [getPermission, getIfUserOwnThePlacement],
  (permission, isOwn) => permission && isOwn,
);

const getIfUserOwnTheTimesheet = (state) => {
  const user = state.get('auth').get('user');
  let roles = user ? user.roles : undefined;
  roles = roles && roles.length ? roles : [];

  if (
    roles.find((role) => role === USER_ROLES.SUPER_ADMIN) ||
    roles.find((role) => role === USER_ROLES.ACCOUNT_MANAGER) ||
    roles.find((role) => role === USER_ROLES.RECRUITER) ||
    roles.find((role) => role === USER_ROLES.VERIFIER)
  ) {
    // NOTE: We need to return undefined only, don't add default only to is_own
    const { is_own } = state.get('timesheetsWorkbench').get('timesheet').get('detail');
    return is_own;
  }
  return true;
};

export const getTimesheetPermission = createSelector(
  [getPermission, getIfUserOwnTheTimesheet],
  (permission, isOwn) => permission && isOwn,
);

const getRoles = (state) => {
  const user = state.get('auth').get('user');
  let roles = user ? user.roles : undefined;
  roles = roles && roles.length ? roles : [];
  return roles;
};

export const checkIfUserIsAccountManager = createSelector(
  [getRoles],
  (roles) =>
    !roles.find((role) => role === USER_ROLES.SUPER_ADMIN) &&
    !!roles.find((role) => role === USER_ROLES.ACCOUNT_MANAGER),
);

export const checkIfUserIsRecruiter = createSelector(
  [getRoles],
  (roles) =>
    !(
      roles.find((role) => role === USER_ROLES.SUPER_ADMIN) ||
      roles.find((role) => role === USER_ROLES.ACCOUNT_MANAGER)
    ) && !!roles.find((role) => role === USER_ROLES.RECRUITER),
);

export const checkIfUserIsSuperAdminOrAccountManager = createSelector(
  [getRoles],
  (roles) =>
    !!roles.find((role) => role === USER_ROLES.SUPER_ADMIN) ||
    !!roles.find((role) => role === USER_ROLES.ACCOUNT_MANAGER),
);
export const checkIfUserIsSuperAdmin = createSelector(
  [getRoles],
  (roles) => !!roles.find((role) => role === USER_ROLES.SUPER_ADMIN),
);

export const checkIfUserIsSuperAdminOrHrOrPayroll = createSelector(
  [getRoles],
  (roles) =>
    !!roles.find((role) => role === USER_ROLES.SUPER_ADMIN) ||
    !!roles.find((role) => role === USER_ROLES.HR) ||
    !!roles.find((role) => role === USER_ROLES.PAYROLL),
);

export const checkIfUserIsSuperAdminOrAccountManagerOrPayrollOrFinance = createSelector(
  [getRoles, getIfUserOwnTheClient],
  (roles, isOwn) =>
    !!roles.find((role) => role === USER_ROLES.SUPER_ADMIN) ||
    (!!roles.find((role) => role === USER_ROLES.ACCOUNT_MANAGER) && isOwn) ||
    !!roles.find((role) => role === USER_ROLES.PAYROLL) ||
    !!roles.find((role) => role === USER_ROLES.FINANCE),
);

// Impact Dashboard Start

export const checkIfUserIsSuperAdminOrVerifierOrAccountManagerOrRecruiter = createSelector(
  [getRoles],
  (roles) =>
    !!roles.find((role) => role === USER_ROLES.SUPER_ADMIN) ||
    !!roles.find((role) => role === USER_ROLES.VERIFIER) ||
    !!roles.find((role) => role === USER_ROLES.ACCOUNT_MANAGER) ||
    !!roles.find((role) => role === USER_ROLES.RECRUITER),
);

export const checkIfUserIsSuperAdminOrAccountManagerOrRecruiter = createSelector(
  [getRoles],
  (roles) =>
    !!roles.find((role) => role === USER_ROLES.SUPER_ADMIN) ||
    !!roles.find((role) => role === USER_ROLES.ACCOUNT_MANAGER) ||
    !!roles.find((role) => role === USER_ROLES.RECRUITER),
);

// Impact Dashboard End
