export const feedbackListConstants = {
  LOAD: 'feedback/list/LOAD',
  LOAD_SUCCESS: 'feedback/list/LOAD_SUCCESS',
  LOAD_FAIL: 'feedback/list/LOAD_FAIL',
  TOGGLE_FEEDBACK_LOAD: 'feedback/list/TOGGLE_FEEDBACK_LOAD',
  TOGGLE_FEEDBACK_SUCCESS: 'feedback/list/TOGGLE_FEEDBACK_SUCCESS',
  TOGGLE_FEEDBACK_FAIL: 'feedback/list/TOGGLE_FEEDBACK_FAIL',
  // Listing Constants
  GET_CLIENTS_LIST: 'feedback/list/GET_CLIENTS_LIST',
};
