import axios from 'axios';
import store from 'store2';
import {
  strictValidString,
  strictValidStringWithMinLength,
  extractErrorMessage,
} from '../../utils/commonUtils';
import { consoleOnlyInDebugMode } from '../../utils/debugging';
import { errorConstants } from '../constants/error.constants';

/**
 * Get clients from db based on keyword searched
 * @param {*} keyword
 * @param {*} callback
 * @param {*} msa_sign
 */
export const getClientLookup =
  (keyword = '', onlyClients = true, isAccountManager = false, msa_sign = undefined) =>
  async (dispatch, getState, api) => {
    if (keyword.length <= 1) return [];
    try {
      let is_own = 'no';
      if (isAccountManager) {
        is_own = 'yes';
      }

      const url =
        strictValidString(msa_sign) && msa_sign === 'yes'
          ? `${process.env.REACT_APP_APIHOST}/lookup/company?&keyword=${keyword}&is_own=${is_own}&msa_sign=${msa_sign}`
          : `${process.env.REACT_APP_APIHOST}/lookup/company?&keyword=${keyword}&is_own=${is_own}`;
      const res = await api.get(url);
      const clients = res.data.map(({ _id, name }) => ({
        value: _id,
        label: name,
      }));
      return onlyClients
        ? clients
        : res.data.map(({ _id, name, contacts }) => ({
            value: _id,
            label: name,
            contacts,
          }));
    } catch (error) {
      consoleOnlyInDebugMode('err-getCompanies', error.response);
      dispatch({ type: errorConstants.SET_ERROR, error: extractErrorMessage(error.response) });
    }
  };

/**
 * Get clients from db based on keyword searched same as above one but will fetch all clients
 * @param {*} keyword
 */

export const getAllClients =
  (keyword = '', callback = () => null) =>
  async () => {
    if (keyword.length < 2) return [];
    const url = `${process.env.REACT_APP_APIHOST}/lookup/company?keyword=${keyword}&select=all`;
    try {
      const res = await axios.get(url, {
        headers: {
          'x-access-token': store.get('user').token,
        },
      });
      // Map list to show in multi select
      const clients = res.data.data.map(({ _id, name }) => ({
        value: _id,
        label: name,
      }));
      callback(clients);
      return clients;
    } catch (err) {
      callback([]);
      consoleOnlyInDebugMode('err-getClients', err.response);
      return false;
    }
  };

export const getJobsLookUp = (keyword) => async (dispatch, getState, api) => {
  const url = `${process.env.REACT_APP_APIHOST}/lookup/jobs?status=1,3,4&keyword=${keyword}`;
  try {
    const res = await api.get(url);
    // Map list to show in multi select
    return res.data.map(({ _id, id, job_title, city, state }) => ({
      value: _id,
      label: `${id} - ${job_title} - ${city}, ${state}`,
    }));
  } catch (err) {
    consoleOnlyInDebugMode('err-getJobs', err.response);
    return false;
  }
};

/**
 * Get candidates from db based on keyword searched
 * @param {*} keyword
 * @param {*} callback
 */
export const getCandidateLookup =
  (keyword = '', status = '') =>
  async (dispatch, getState, api) => {
    if (keyword.length <= 1) return [];
    try {
      let typeOfCandidateSearch = '';
      if (status === 'all') {
        typeOfCandidateSearch = 'all=1&';
      }
      const url = `${process.env.REACT_APP_APIHOST}/lookup/candidates?${typeOfCandidateSearch}keyword=${keyword}`;
      const res = await api.get(url);
      return res.data.map(({ _id, name, email }) => ({ value: _id, label: `${name} - ${email}` }));
    } catch (error) {
      consoleOnlyInDebugMode('err-candidate', error.response);
      dispatch({ type: errorConstants.SET_ERROR, error: extractErrorMessage(error.response) });
    }
  };

/**
 * Get Candidate Success Manager from db based on keyword searched
 * @param {*} keyword
 * @param {*} callback
 */
export const getVerifierLookup =
  (keyword = '', callback = () => null) =>
  async (dispatch, getState, api) => {
    try {
      const url = `${process.env.REACT_APP_APIHOST}/lookup/users?select=verifier&keyword=${keyword}`;
      const res = await api.get(url);
      const verifiers = res.data.map(({ _id, name }) => ({ value: _id, label: name }));
      return callback(verifiers);
    } catch (error) {
      callback([]);
      consoleOnlyInDebugMode('err-getCompanies', error.response);
      dispatch({ type: errorConstants.SET_ERROR, error: extractErrorMessage(error.response) });
    }
  };

/**
 * Get jobowenes from keyword
 * @param {*} keyword
 */
export const getJobOwners =
  (keyword = '', callback = () => null) =>
  async (dispatch, getState, api) => {
    try {
      const url = `${process.env.REACT_APP_APIHOST}/lookup/users?select=client-admin,client-user&keyword=${keyword}`;
      const res = await api.get(url);
      const jobOwners = res.data.map(({ _id, name }) => ({ value: _id, label: name }));
      callback(jobOwners);
      return jobOwners;
    } catch (error) {
      callback([]);
      consoleOnlyInDebugMode('err-getJobOwners', error.response);
      dispatch({ type: errorConstants.SET_ERROR, error: extractErrorMessage(error.response) });
    }
  };

/**
 * get Candidate Success Manager from keyword
 * @param {*} keyword
 */
export const getRecruiters =
  (keyword = '', callback = () => null) =>
  async (dispatch, getState, api) => {
    try {
      const url = `${process.env.REACT_APP_APIHOST}/lookup/users?select=recruiter&keyword=${keyword}`;
      const res = await api.get(url);
      const recruiters = res.data.map(({ _id, name }) => ({ value: _id, label: name }));
      callback(recruiters);
      return recruiters;
    } catch (error) {
      callback([]);
      consoleOnlyInDebugMode('err-recruters', error.response);
      dispatch({ type: errorConstants.SET_ERROR, error: extractErrorMessage(error.response) });
    }
  };

/**
 * Get cities from keyword for client
 * @param {*} keyword
 */
export const getCitiesClientLookup = (keyword) => async (dispatch, getState, api) => {
  if (!strictValidStringWithMinLength(keyword)) return;
  try {
    const url = `${process.env.REACT_APP_APIHOST}/lookup/cities/client?keyword=${keyword}`;
    const res = await api.get(url);
    return res.data.map(({ city, state }) => ({
      value: `${city}, ${state}`,
      label: `${city}, ${state}`,
      city,
      state,
    }));
  } catch (error) {
    consoleOnlyInDebugMode('err-getcities', error.response);
    dispatch({ type: errorConstants.SET_ERROR, error: extractErrorMessage(error.response) });
  }
};
/**
 * Get cities from keyword for candidate
 * @param {*} keyword
 */

export const getCitiesCandidateLookup = (keyword) => async (dispatch, getState, api) => {
  if (!strictValidStringWithMinLength(keyword, 3)) return;
  try {
    const url = `${process.env.REACT_APP_APIHOST}/lookup/cities/candidate?keyword=${keyword}`;
    const res = await api.get(url);
    return res.data.map(({ city, coordinates, state }) => ({
      value: `${city}, ${state}`,
      label: `${city}, ${state}`,
      coordinates,
      city,
      state,
    }));
  } catch (error) {
    consoleOnlyInDebugMode('err-getcities', error.response);
    dispatch({ type: errorConstants.SET_ERROR, error: extractErrorMessage(error.response) });
  }
};

/**
 * Get cities from keyword for placements
 * @param {*} keyword
 */
export const getCitiesPlacementLookup = (keyword) => async (dispatch, getState, api) => {
  if (!strictValidStringWithMinLength(keyword)) return;
  try {
    const url = `${process.env.REACT_APP_APIHOST}/lookup/placement/cities?keyword=${keyword}`;
    const res = await api.get(url);
    return res.data.map(({ city, state }) => ({
      value: `${city}, ${state}`,
      label: `${city}, ${state}`,
      city,
      state,
    }));
  } catch (error) {
    consoleOnlyInDebugMode('err-getcities', error.response);
    dispatch({ type: errorConstants.SET_ERROR, error: extractErrorMessage(error.response) });
  }
};

/**
 * Get placements from keyword
 * @param {*} keyword
 */

export const getPlacementsLookup = (keyword) => async (dispatch, getState, api) => {
  try {
    const url = `${process.env.REACT_APP_APIHOST}/lookup/placements?keyword=${keyword}`;
    const res = await api.get(url);
    return res.data.map(({ _id, placement_id }) => ({
      value: _id,
      label: placement_id,
    }));
  } catch (error) {
    consoleOnlyInDebugMode('err-getPlacements', error.response);
    dispatch({ type: errorConstants.SET_ERROR, error: extractErrorMessage(error.response) });
    return [];
  }
};

export const getAllAjnaInternalUsersLookup = (keyword = '', callback = () => null) => {
  const url = `${
    process.env.REACT_APP_APIHOST
  }/lookup/users?&select=super-admin,account-manager,recruiter,verifier,corporate,finance,payroll,hr${
    keyword ? `&keyword=${keyword}` : ''
  }`;
  axios
    .get(url, {
      headers: {
        'x-access-token': store.get('user').token,
      },
    })
    .then((res) => {
      // Map list to show in multi select
      const users = res.data.data.map(({ _id, name }) => ({
        value: _id,
        label: name,
      }));
      callback(users || []);
    })
    .catch((err) => {
      callback([]);
      consoleOnlyInDebugMode('err-getJobManagers', err.response);
    });
};
