import { Component } from 'react';
import QueryString from 'query-string';
import {
  loadRoute,
  strictValidString,
  validObjectWithParameterKeys,
} from '../../utils/commonUtils';
import history from '../../utils/history';

export default class Origin extends Component {
  state = {
    isLoading: false,
    error: null,
    alert: {
      message: null,
      type: null,
    },
  };

  // Start loading
  startLoading = (defaultState) => {
    this.setState({
      [defaultState]: true,
    });
  };

  // Stop loading
  stopLoading = (defaultState) => {
    this.setState({
      [defaultState]: false,
    });
  };

  // Scroll to ref
  scrollToRef = (ref) =>
    window.scroll({
      top: ref.offsetTop || 0,
      left: 0,
      behavior: 'smooth',
    });

  // Scroll to ref
  scrollToTop = () =>
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });

  // Perform async action before mutating state
  async dispatchActions(dispatch, action, defaultState = 'isLoading') {
    if (typeof dispatch !== 'function' || typeof action !== 'function') return;
    this.startLoading(defaultState);
    const response = await dispatch(action);
    this.stopLoading(defaultState);
    return response;
  }

  // Set error
  setMessage = (message, type) => {
    // If string is not valid then return;
    if (!strictValidString(message) || !strictValidString(type)) {
      return;
    }
    // Else continue
    this.setState({
      alert: { ...alert, message, type },
    });
    // Scroll to top
    window.scrollTo(0, 0);
  };

  // Remove message
  removeMessage = () => {
    this.setState({
      alert: { ...alert, message: null, type: null },
    });
  };

  // Set error
  setError = (error) => {
    this.setState(
      {
        error: '',
      },
      () => {
        // If string is not valid then return;
        if (strictValidString(error)) {
          this.setState({
            error,
          });

          // Scroll to top
          window.scrollTo(0, 0);
          const { body } = document;
          body.scrollTop = 0;
          body.scrollLeft = 0;
        }
      },
    );
  };

  // Load route
  loadRoute = (link, state) => {
    const { dispatch } = this.props;
    if (typeof dispatch !== 'function' && strictValidString(link)) return;
    loadRoute(dispatch, link || '/', state);
  };

  // Get job title
  getJobTitle = () => {
    const { jobFunctions, job } = this.props;
    const { job_function, titles } = job;
    const selectedJobFunction = jobFunctions.find((v) => v._id === job_function) || {};
    const selectedTitle = validObjectWithParameterKeys(selectedJobFunction, ['titles'])
      ? selectedJobFunction.titles.find((v) => v._id === titles) || {}
      : {};
    return (
      (validObjectWithParameterKeys(selectedTitle, ['title']) && selectedTitle.title) ||
      (validObjectWithParameterKeys(job, ['titles']) && job.titles.title) ||
      ''
    );
  };

  // Set Query String from url
  getQueryString = () => {
    const { location } = this.props;
    return QueryString.parse(location.search, { parseBooleans: true, parseNumbers: true });
  };

  // Set Query String in url
  setQueryString = (params, pushToURL = true, options = { arrayFormat: 'comma' }) => {
    const queryString = QueryString.stringify(params, options);
    if (pushToURL) history.replace({ search: strictValidString(queryString) ? queryString : '' });
    return queryString;
  };
}
