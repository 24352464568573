const ACTIVITIES_LIST_REQUEST = 'ACTIVITIES_LIST_REQUEST';
const ACTIVITIES_LIST_SUCCESS = 'ACTIVITIES_LIST_SUCCESS';
const ACTIVITIES_LIST_FAILURE = 'ACTIVITIES_LIST_FAILURE';

const activityLogsRequest = (dispatch) => {
  dispatch({ type: ACTIVITIES_LIST_REQUEST, payload: { loading: true } });
};

const activityLogsSuccess = (dispatch, response) => {
  dispatch({ type: ACTIVITIES_LIST_SUCCESS, payload: { data: response, loading: false } });
};

const activityLogsFailure = (dispatch) => {
  dispatch({ type: ACTIVITIES_LIST_FAILURE, payload: { loading: false } });
};

export {
  ACTIVITIES_LIST_REQUEST,
  ACTIVITIES_LIST_SUCCESS,
  ACTIVITIES_LIST_FAILURE,
  activityLogsRequest,
  activityLogsSuccess,
  activityLogsFailure,
};
