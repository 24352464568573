import Loadable from '@loadable/component';
import Loading from '../components/--primitives/pr-loading';

export const DebuggingTool = Loadable(() => import('../containers/DebuggingTool'), {
  fallback: <Loading />,
});

/**
 * Pre Login modules
 */
export const Login = Loadable(() => import('../containers/Common/login/index'), {
  fallback: <Loading />,
});

export const ResetPassword = Loadable(() => import('../containers/Common/reset-password'), {
  fallback: <Loading />,
});

/**
 * Post Login Modules
 */

// Main Dashboard
export const AdminDashBoard = Loadable(() => import('../containers/Dashboard/Main'), {
  fallback: <Loading />,
});

// Job Workbench
export const JobList = Loadable(() => import('../containers/WorkBench/Job/List'), {
  fallback: <Loading />,
});

export const EditJob = Loadable(() => import('../containers/WorkBench/Job/Edit'), {
  fallback: <Loading />,
});
export const CreateJob = Loadable(() => import('../containers/WorkBench/Job/Post'), {
  fallback: <Loading />,
});

export const InterviewFeedback = Loadable(
  () => import('../containers/WorkBench/Job/Edit/Modules/InterviewFeedback'),
  {
    fallback: <Loading />,
  },
);

export const CandidateInFlight = Loadable(
  () => import('../containers/WorkBench/Job/Edit/Modules/CandidateInFlight'),
  {
    fallback: <Loading />,
  },
);

export const FindMatchProfile = Loadable(
  () => import('../containers/WorkBench/Job/Edit/Modules/FindMatchProfile'),
  {
    fallback: <Loading />,
  },
);

export const JobsAtRisk = Loadable(() => import('../containers/Dashboard/Risk/Jobs'), {
  fallback: <Loading />,
});

export const InterviewScheduleTemplate = Loadable(
  () => import('../containers/WorkBench/Job/Edit/Modules/ScheduleTemplate/Add'),
  {
    fallback: <Loading />,
  },
);

// Company Profile
export const CompanyProfile = Loadable(
  () => import('../containers/WorkBench/Client/Edit/Modules/Profile'),
  {
    fallback: <Loading />,
  },
);

export const Domains = Loadable(
  () => import('../containers/WorkBench/Client/Edit/Modules/Domains'),
  {
    fallback: <Loading />,
  },
);
export const Locations = Loadable(
  () => import('../containers/WorkBench/Client/Edit/Modules/Locations'),
  {
    fallback: <Loading />,
  },
);
export const AddLocation = Loadable(
  () => import('../containers/WorkBench/Client/Edit/Modules/Locations/AddLocation'),
  {
    fallback: <Loading />,
  },
);
export const EditLocation = Loadable(
  () => import('../containers/WorkBench/Client/Edit/Modules/Locations/EditLocation'),
  {
    fallback: <Loading />,
  },
);
export const ManageUsers = Loadable(
  () => import('../containers/WorkBench/Client/Edit/Modules/ManageUsers'),
  {
    fallback: <Loading />,
  },
);
export const EditUser = Loadable(
  () => import('../containers/WorkBench/Client/Edit/Modules/ManageUsers/EditUser'),
  {
    fallback: <Loading />,
  },
);

// Interview workbench
export const InterviewsList = Loadable(() => import('../containers/WorkBench/Interview/list'), {
  fallback: <Loading />,
});
export const InterviewsAtRisk = Loadable(() => import('../containers/Dashboard/Risk/Interviews'), {
  fallback: <Loading />,
});
export const InterviewDashboard = Loadable(
  () => import('../containers/WorkBench/Interview/dashboard'),
  {
    fallback: <Loading />,
  },
);

// Admin specific routes
export const ListRegions = Loadable(
  () => import('../containers/Dashboard/Modules/Regions/listRegions'),
  {
    fallback: <Loading />,
  },
);
export const AddRegion = Loadable(
  () => import('../containers/Dashboard/Modules/Regions/addRegion'),
  {
    fallback: <Loading />,
  },
);
export const EditRegion = Loadable(
  () => import('../containers/Dashboard/Modules/Regions/editRegion'),
  {
    fallback: <Loading />,
  },
);

// Rules calculations and placements
export const RulesCalculation = Loadable(
  () => import('../containers/Dashboard/Modules/RulesCalculation/RulesList'),
  {
    fallback: <Loading />,
  },
);
export const ViewRule = Loadable(
  () => import('../containers/Dashboard/Modules/RulesCalculation/ViewRule'),
  {
    fallback: <Loading />,
  },
);

// Client Workbench
export const ClientsList = Loadable(() => import('../containers/WorkBench/Client/List'), {
  fallback: <Loading />,
});
export const ClientsAtRisk = Loadable(() => import('../containers/Dashboard/Risk/Clients'), {
  fallback: <Loading />,
});
export const AjnaTeam = Loadable(
  () => import('../containers/WorkBench/Client/Edit/Modules/AjnaTeam'),
  {
    fallback: <Loading />,
  },
);
export const AddClient = Loadable(() => import('../containers/WorkBench/Client/Add'), {
  fallback: <Loading />,
});
export const ClientDashboard = Loadable(() => import('../containers/WorkBench/Client/Edit'), {
  fallback: <Loading />,
});

// Misc Modules
export const ListAjnaUsers = Loadable(
  () => import('../containers/Dashboard/Modules/AjnaUsers/listUsers'),
  {
    fallback: <Loading />,
  },
);
export const AddAjnaUser = Loadable(
  () => import('../containers/Dashboard/Modules/AjnaUsers/addUser'),
  {
    fallback: <Loading />,
  },
);
export const EditAjnaUser = Loadable(
  () => import('../containers/Dashboard/Modules/AjnaUsers/editUser'),
  {
    fallback: <Loading />,
  },
);

// Candidate WorkBench
export const CandidatesList = Loadable(() => import('../containers/WorkBench/Candidate/List'), {
  fallback: <Loading />,
});
export const CandidatesAtRisk = Loadable(() => import('../containers/Dashboard/Risk/Candidates'), {
  fallback: <Loading />,
});
export const AssignmentsAtRisk = Loadable(
  () => import('../containers/Dashboard/Risk/Assignments'),
  {
    fallback: <Loading />,
  },
);
export const AddCandidate = Loadable(() => import('../containers/WorkBench/Candidate/Add'), {
  fallback: <Loading />,
});
export const EditCandidate = Loadable(() => import('../containers/WorkBench/Candidate/Edit'), {
  fallback: <Loading type="overlay" />,
});
export const CandidateJobInvite = Loadable(
  () => import('../containers/WorkBench/Candidate/Edit/Modules/JobInvite'),
  {
    fallback: <Loading />,
  },
);

// Timesheets Workbench
export const TimesheetList = Loadable(() => import('../containers/WorkBench/Timesheets/List'), {
  fallback: <Loading />,
});
export const ViewTimesheet = Loadable(() => import('../containers/WorkBench/Timesheets/View'), {
  fallback: <Loading />,
});

// Placements Workbench
export const PlacementsWorkbenchList = Loadable(
  () => import('../containers/WorkBench/Placements/List'),
  {
    fallback: <Loading />,
  },
);
export const PlacementsWorkbenchAdd = Loadable(
  () => import('../containers/WorkBench/Placements/Add/index'),
  {
    fallback: <Loading />,
  },
);
export const PlacementsWorkbenchEdit = Loadable(
  () => import('../containers/WorkBench/Placements/Edit/index'),
  {
    fallback: <Loading />,
  },
);

// Invoice Workbench
export const InvoiceListView = Loadable(() => import('../containers/WorkBench/Invoices/List'), {
  fallback: <Loading />,
});

// Report Workbench
export const ReportsListView = Loadable(() => import('../containers/WorkBench/Reports/View'), {
  fallback: <Loading />,
});

export const ReportsDataView = Loadable(() => import('../containers/WorkBench/Reports/View/data'), {
  fallback: <Loading />,
});

// Leads Workbench

export const LeadsListView = Loadable(() => import('../containers/WorkBench/Leads/List'), {
  fallback: <Loading />,
});

export const LeadsAddView = Loadable(() => import('../containers/WorkBench/Leads/Add'), {
  fallback: <Loading />,
});

export const LeadsEditView = Loadable(() => import('../containers/WorkBench/Leads/Edit'), {
  fallback: <Loading />,
});
