import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Popup, PopupHeader, PopupFooter, PopupBody } from '../../--primitives/pr-popup';
import { strictValidStringWithMinLength, loadRoute } from '../../../utils/commonUtils';
import PrButton from '../../--primitives/pr-button';
import { flushError } from '../../../redux/actions/error.actions';
import './error-popup.scss';

const blacklistMessages = [
  'The user field is required.',
  'Please sign MSA before processed this request.',
  'Failed to authenticate token.',
  'Your session has expired. Please login again to continue.',
  'Please login with your credentials.',
];

const ErrorPopup = ({ message, callFLushAPI, dispatch, status, tenantConfig }) => {
  return blacklistMessages.indexOf(message) === -1 ? (
    <Popup
      className="error-popup"
      action="error"
      isOpen={!!status || strictValidStringWithMinLength(message)}
    >
      <PopupHeader action="error" close toggle={callFLushAPI} />
      <PopupBody>
        {status ? (
          <Fragment>
            <div className="content title">
              Your credentials do not match any client records. Please try again.
            </div>
            <div className="content text">
              If you’re an employee, please login through the employee portal.
            </div>
          </Fragment>
        ) : (
          <div className="content title" dangerouslySetInnerHTML={{ __html: message }} />
        )}
      </PopupBody>
      <PopupFooter align={status ? 'center' : ''}>
        {status ? (
          <Fragment>
            <PrButton
              value="Login as Employee"
              type="light-blue large tall"
              onClick={() => {
                window.location = tenantConfig.candidate_domain;
              }}
            />
            <PrButton value="Try again" type="light-blue large tall" onClick={callFLushAPI} />
          </Fragment>
        ) : (
          <PrButton
            value={
              message === 'This activation link is expired or has already been used.'
                ? 'Go back to login'
                : 'Ok'
            }
            type="light-blue large tall"
            onClick={
              message === 'This activation link is expired or has already been used.'
                ? () => {
                    loadRoute(dispatch, '/login');
                    callFLushAPI();
                  }
                : callFLushAPI
            }
          />
        )}
      </PopupFooter>
    </Popup>
  ) : null;
};

ErrorPopup.propTypes = {
  callFLushAPI: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  message: PropTypes.string,
  status: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]).isRequired,
};

ErrorPopup.defaultProps = {
  message: null,
  tenantConfig: {},
};

const mapStateProps = (state) => {
  return {
    message: state.get('error').get('message'),
    status: state.get('error').get('status'),
    tenantConfig: state.get('config').get('tenantConfig'),
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  callFLushAPI: (...params) => dispatch(flushError(...params)),
});

export default connect(mapStateProps, mapDispatchToProps)(ErrorPopup);
