import {
  INTERVIEW_DETAILS_REQUEST,
  INTERVIEW_DETAILS_SUCCESS,
  INTERVIEW_DETAILS_FAILURE,
  INTERVIEW_UPDATE_FAILURE,
  INTERVIEW_UPDATE_REQUEST,
  INTERVIEW_UPDATE_SUCCESS,
  INTERVIEW_UPDATE_RESET_MESSAGE,
  PREPPED_INTERVIEW,
  SET_EMAIL_BODY,
} from './actions';

const initialState = {};

const details = (state = initialState, action) => {
  switch (action.type) {
    case INTERVIEW_UPDATE_REQUEST:
    case INTERVIEW_UPDATE_SUCCESS:
    case INTERVIEW_UPDATE_FAILURE:
    case INTERVIEW_DETAILS_REQUEST:
    case INTERVIEW_DETAILS_SUCCESS:
    case INTERVIEW_DETAILS_FAILURE:
    case INTERVIEW_UPDATE_RESET_MESSAGE:
      return {
        ...state,
        ...action.payload,
      };
    case PREPPED_INTERVIEW:
    case SET_EMAIL_BODY:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};

export { details };
