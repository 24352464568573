/* eslint-disable no-console */
import Immutable from 'immutable';
import { LOCATION_CHANGE } from 'react-router-redux';
import { listLeadConstants } from './constants';
import { typeCastResponse } from '../../../../utils/siteSpecificCommonUtils';

const initialState = Immutable.fromJS({
  isLoad: false,
  isLoadInline: false,
  loadErr: null,
  message: null,
  users: [],
  leads: {},
});

export const List = (state = initialState, action) => {
  switch (action.type) {
    case listLeadConstants.LOAD:
      return state.set('isLoad', true).set('loadErr', null);
    case listLeadConstants.LOAD_INLINE:
      return state.set('isLoadInline', true).set('loadErr', null);
    case listLeadConstants.LOAD_SUCCESS:
      return state
        .set('isLoad', false)
        .set('isLoadInline', false)
        .set('loadErr', null)
        .set('message', typeCastResponse(action, 'message'));
    case listLeadConstants.LOAD_FAIL:
      return state
        .set('isLoad', false)
        .set('isLoadInline', false)
        .set('message', null)
        .set('loadErr', typeCastResponse(action, 'error'));
    case listLeadConstants.GET_LEADS:
      return state.set('leads', typeCastResponse(action, 'leads', 'object', {}));
    case listLeadConstants.GET_USERS:
      return state.set('users', typeCastResponse(action, 'users', 'array', []));
    // Reset state on location change
    case LOCATION_CHANGE:
      return state.set('message', null).set('loadErr', null).set('users', []);
    default:
      return state;
  }
};
