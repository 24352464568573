import React from 'react';
import { Route, Redirect } from 'react-router';
import store from 'store2';

export function PublicRoute({ component: Component, ...rest }) {
  const isAuthTokenFlag = !!store('SAAS_ADMIN_TOKEN');
  const { computedMatch = {} } = rest;
  const { params = {} } = computedMatch;
  const { key } = params;
  return (
    <Route
      {...rest}
      render={(props) =>
        !isAuthTokenFlag ? <Component key={key} {...props} /> : <Redirect to="/" />
      }
    />
  );
}
