import { notificationsConstants } from '../constants';
import {
  strictValidNumber,
  strictValidArray,
  typeCastToKeyValueObject,
} from '../../utils/commonUtils';
import { validLoggedInUser } from '../../utils/siteSpecificCommonUtils';

/**
 * Load notifications for  user
 * @param fetchOnlyCount: boolean
 * Access control: client/candidate
 */
export const loadNotifications =
  (fetchOnlyCount = false) =>
  async (dispatch, getState, api) => {
    try {
      const user = typeCastToKeyValueObject(getState().get('auth').get('user'));
      const { _id: userId = '' } = user;
      // Call api to load notifications
      let res = null;
      if (!fetchOnlyCount) {
        res = await api.get(`/notifications/${userId}?page=1&limit=100&offset=0`);
        const { rows, count, unread } = res.data;
        // If valid response continue
        await dispatch({
          type: notificationsConstants.LOAD_NOTIFICATIONS,
          notifications: strictValidArray(rows) ? rows : [],
          count,
          unread,
        });
      } else if (validLoggedInUser(user)) {
        res = await api.get(`/notifications/count?user=${userId}`);
        const { unread } = res.data;
        // If valid response continue
        await dispatch({
          type: notificationsConstants.LOAD_NOTIFICATIONS,
          notifications: getState().get('notifications').get('notifications'),
          count: getState().get('notifications').get('count'),
          unread,
        });
      }
      return true;
    } catch (err) {
      // If an error occurs, set error field
      dispatch({
        type: notificationsConstants.LOAD_FAIL,
        error: 'no notification found',
      });
    }
  };

/**
 * Read notification
 * @param id: string
 * Access control: client/candidate
 */
export const readNotification = (id) => async (dispatch, getState, api) => {
  try {
    const { _id: user = '' } = typeCastToKeyValueObject(getState().get('auth').get('user'));
    const data = { user };
    // Call api to update the unread count for notifications
    const res = await api.post(`notifications/read/${id}`, { data });
    const { unread } = res.data;
    // if valid response continue
    await dispatch({
      type: notificationsConstants.UPDATE_NOTIFICATIONS_COUNT,
      unread: strictValidNumber(unread) ? unread : 0,
    });
    return true;
  } catch (err) {
    // If an error occurs, set error field
    dispatch({
      type: notificationsConstants.LOAD_FAIL,
      error: 'no notification Update',
    });
    return false;
  }
};

export const notificationDetail = (id) => async (dispatch, getState, api) => {
  try {
    // Call api to update the unread count for notifications
    const res = await api.get(`notifications/details/${id}`);
    // if valid response continue
    await dispatch({
      type: notificationsConstants.NOTIFICATION_DETAIL,
      detail: res.data,
    });
    return true;
  } catch (err) {
    // If an error occurs, set error field
    dispatch({
      type: notificationsConstants.LOAD_FAIL,
      error: 'no notification Update',
    });
    return false;
  }
};

/**
 * notification detail
 * @param id: string
 * Access control: client/candidate
 */
export const clearDetails = () => async (dispatch) => {
  await dispatch({
    type: notificationsConstants.NOTIFICATION_DETAIL,
    detail: {},
  });
};

/**
 * notification detail
 * @param id: string
 * Access control: client/candidate
 */
export const updateRTN = (list) => async (dispatch) => {
  await dispatch({
    type: notificationsConstants.UPDATE_RTN,
    list,
  });
};

/**
 * Reset un_read count
 * @param fetchOnlyCount: boolean
 * Access control: client
 */
export const ResetNotificationsCount = () => async (dispatch, getState, api) => {
  try {
    const user = typeCastToKeyValueObject(getState().get('auth').get('user'));
    const { _id: userId = '' } = user;
    // Call api to load notifications
    const res = await api.post(`/notifications/reset-notification-count/${userId}`);
    if (res.message) {
      dispatch({
        type: notificationsConstants.RESET_NEW_UNREAD_COUNT,
        new_unread: 0,
      });
    }
    return true;
  } catch (err) {
    // If an error occurs, set error field
    dispatch({
      type: notificationsConstants.LOAD_FAIL,
      error: 'notification count not reseted',
    });
    return false;
  }
};
