import axios from 'axios';
import store from 'store2';
import moment from 'moment';
import { commonConstants, errorConstants, jobConstants } from '../constants';
import { consoleOnlyInDebugMode } from '../../utils/debugging';
import {
  validObjectWithParameterKeys,
  removeInValidKeyValuePairs,
  strictValidObjectWithKeys,
  extractErrorMessage,
} from '../../utils/commonUtils';
import { USER_ROLES } from '../../utils/siteSpecificConstants';
import { DEFAULT_MILLISECONDS_TO_SHOW_MESSAGES } from '../../utils/constants';

/**
 * Get entity name from db
 * @param {*} keyword
 * @param {*} callback
 */
export const getEntityName =
  (data, withData = true) =>
  async () => {
    const normalizedData = removeInValidKeyValuePairs(data);
    if (!strictValidObjectWithKeys(normalizedData)) return data;
    try {
      const url = `${process.env.REACT_APP_APIHOST}/settings/get-entity`;
      const res = await axios.post(url, removeInValidKeyValuePairs(data), {
        headers: {
          'x-access-token': store.get('user').token,
        },
      });
      const {
        verifiers = [],
        candidates = [],
        companies = [],
        account_managers = [],
        recruiters = [],
        job_managers = [],
        titles = [],
        jobs = [],
        job_owners = [],
        business_development = [],
      } = res.data.data;
      return withData
        ? removeInValidKeyValuePairs({
            verifiers: verifiers.map(({ _id, name }) => ({
              value: _id,
              label: name,
            })),
            candidates: candidates.map(({ _id, name }) => ({
              value: _id,
              label: name,
            })),
            companies: companies.map(({ _id, name }) => ({
              value: _id,
              label: name,
            })),
            jobOwners: job_owners.map(({ _id, name }) => ({
              value: _id,
              label: name,
            })),
            selectedAccountManagers: account_managers.map(({ _id, name }) => ({
              value: _id,
              label: name,
            })),
            recruiters: recruiters.map(({ _id, name }) => ({
              value: _id,
              label: name,
            })),
            jobManagers: job_managers.map(({ _id, name }) => ({
              value: _id,
              label: name,
            })),
            jobTitles: titles.map(({ _id, title }) => ({
              value: _id,
              label: title,
            })),
            jobs: jobs.map(({ _id, name }) => ({ value: _id, label: name })),
            business_development: business_development.map(({ _id, name }) => ({
              value: _id,
              label: name,
            })),
          })
        : removeInValidKeyValuePairs({
            verifiers,
            candidates,
            companies,
            account_managers,
            recruiters,
            job_managers,
            titles,
            jobs,
            business_development,
          });
    } catch (error) {
      consoleOnlyInDebugMode('err-getCompanies', error);
    }
  };

/**
 * get Common data to populate the data
 */
export const getCommonData = (selectedData) => (dispatch) => {
  const url = `${process.env.REACT_APP_APIHOST}/lookup/users?select=${selectedData}`;
  dispatch({ type: commonConstants.LOAD });
  axios
    .get(url, {
      headers: {
        'x-access-token': store.get('user').token,
      },
    })
    .then((res) => {
      const resData =
        res.data.data.filter((e) => e.roles.indexOf(USER_ROLES.ACCOUNT_MANAGER) > -1) || [];
      dispatch({
        type: commonConstants.SET_COMMON_DATA,
        recruiters: res.data.data.filter((e) => e.roles.indexOf(USER_ROLES.RECRUITER) > -1) || [],
        accountManagers: resData.map((item) => ({ ...item, label: item.name, value: item._id })),
      });
    })
    .catch((err) => {
      dispatch({ type: commonConstants.LOAD_FAIL });
    });
};

/**
 * To get place time zone on the basis of lat lng
 * Access role allowed - company aka client
 * @param latLng: object
 */
export const getPlaceTimeZone = (latLng) => async (dispatch, getState, api) => {
  try {
    // Put saved job with api
    const url =
      `${process.env.REACT_APP_APIHOST}/settings/timezone?` +
      `latitude=${latLng.lat}&longitude=${latLng.lng}&timestamp=${moment().unix()}`;
    const res = await api.get(url, {}, true);
    if (!validObjectWithParameterKeys(res, ['timeZoneId'])) {
      return false;
    }
    return res.timeZoneId;
  } catch (error) {
    // If an error occurs, set error field
    await dispatch({
      type: jobConstants.LOAD_FAIL,
      error,
    });
    dispatch({ type: errorConstants.SET_ERROR, error: extractErrorMessage(error) });
    return [];
  }
};

/**
 * for re-send activation api by neeraj kumar
 * @param id,
 * @param successCallback: callBack method
 */
export const resendActivationLink = (id, successCallback) => async (dispatch) => {
  const url = `${process.env.REACT_APP_APIHOST}/users/new-user-invitation/${id}`;
  try {
    const res = await axios.get(url, {
      headers: {
        'x-access-token': store.get('user').token,
      },
    });
    await successCallback(res);
    return res;
  } catch (error) {
    dispatch({ type: commonConstants.LOAD_FAIL });
  }
};

export const setSidebarStatus = (status) => async (dispatch) => {
  dispatch({
    type: commonConstants.SET_SIDEBAR_STATUS,
    status,
  });
};

/**
 * Get Export List
 */
export const getExportList = (data, url) => async (dispatch, getState, api) => {
  dispatch({ type: commonConstants.EXPORT_LIST_LOAD });
  const { sort = 'id', order = 'desc' } = data;
  const urlWithSort = `${url}?sort=${sort}&order=${order}`;
  try {
    const res = await api.post(urlWithSort, {
      data: removeInValidKeyValuePairs(data),
    });
    dispatch({
      type: commonConstants.EXPORT_LIST_LOAD_SUCCESS,
      message: res.message,
    });
    getExportListResetMessage(dispatch, 5000);
    return res.filepath;
  } catch (error) {
    dispatch({
      type: commonConstants.EXPORT_LIST_FAIL,
      error,
    });
    dispatch({
      type: errorConstants.SET_ERROR,
      error,
    });
    getExportListResetMessage(dispatch);
    consoleOnlyInDebugMode('err-getExportList', error);
    return false;
  }
};

/**
 * To reset message fields in reducer
 */
const getExportListResetMessage = (
  dispatch,
  defaultTimeout = DEFAULT_MILLISECONDS_TO_SHOW_MESSAGES,
) => {
  setTimeout(() => {
    dispatch({
      type: commonConstants.EXPORT_LIST_RESET_MESSAGE,
      message: '',
      error: '',
    });
  }, defaultTimeout);
};
