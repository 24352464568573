import { activityLogs } from '../../containers/admin/activity-log/reducer';
import { ajnaUsers } from '../../containers/Dashboard/Modules/AjnaUsers/reducer';
import { editLogs } from '../../containers/admin/edit-log/reducer';
import { adminNotifications } from '../../containers/admin/notifications/reducer';
import { regions } from '../../containers/Dashboard/Modules/Regions/reducer';
import { adminLocations } from '../../containers/WorkBench/Client/Edit/Modules/Locations/reducer';
import {
  racList,
  scList,
} from '../../containers/WorkBench/Job/Edit/Modules/RecommendCandidates/reducer';
import {
  ruleDetails,
  addRulesCalculation,
  updateRulesCalculation,
  paycodes,
  states,
} from '../../containers/Dashboard/Modules/RulesCalculation/AddRule/reducer';
import rules from '../../containers/Dashboard/Modules/RulesCalculation/RulesList/reducer';
import app from './app';
import common from './common';
import auth from './auth';
import config from './config';
import candidateWorkBench from './candidateWorkBench';
import invoiceWorkBench from './invoiceWorkBench';
import client from './client';
import clientWorkBench from './clientWorkBench';
import feedback from './feedback';
import interviews from './interviews';
import jobManager from './jobManager';
import jobs from './jobs';
import note from './note';
import notifications from './notifications';
import reduxForms from './reduxForms';
import settings from './settings';
import timesheetsWorkbench from './timesheetsWorkbench';
import locations from './location';
import placementsWorkbench from './placementsWorkbench';
import attachment from './attachment';
import impactDashboard from './impactDashboard';
import { Reports } from '../../containers/WorkBench/Reports/View/reducer';
import notificationContent from './notificationContent';
import listViewParams from './list-view-params';
import leads from './leads';
import scheduleTemplate from '../../containers/WorkBench/Job/Edit/Modules/ScheduleTemplate/Add/reducers';
import templateListing from '../../containers/WorkBench/Job/Edit/Modules/ScheduleTemplate/List/reducers';
import error from './error';
import successMessage from './successMessage';

export default {
  error,
  app,
  auth,
  config,
  common,
  jobs,
  settings,
  notifications,
  reduxForms,
  jobManager,
  interviews,
  racList,
  scList,
  activityLogs,
  editLogs,
  adminNotifications,
  adminLocations,
  candidateWorkBench,
  invoiceWorkBench,
  note,
  feedback,
  client,
  regions,
  rules,
  states,
  paycodes,
  ruleDetails,
  addRulesCalculation,
  updateRulesCalculation,
  clientWorkBench,
  ajnaUsers,
  timesheetsWorkbench,
  locations,
  placementsWorkbench,
  attachment,
  impactDashboard,
  Reports,
  notificationContent,
  listViewParams,
  leads,
  scheduleTemplate,
  templateListing,
  successMessage,
};
