export const TIMESHEETS_LIST_PROCESSING =
  'timesheetsWorkbench/TIMESHEETS_LIST_PROCESSING';
export const TIMESHEETS_LIST_SUCCESS =
  'timesheetsWorkbench/TIMESHEETS_LIST_SUCCESS';
export const TIMESHEETS_LIST_FAILURE =
  'timesheetsWorkbench/TIMESHEETS_LIST_FAILURE';
export const TIMESHEET_APPROVE_PROCESSING =
  'timesheetsWorkbench/TIMESHEET_APPROVE_PROCESSING';
export const TIMESHEET_APPROVE_SUCCESS =
  'timesheetsWorkbench/TIMESHEET_APPROVE_SUCCESS';
export const TIMESHEET_APPROVE_FAILURE =
  'timesheetsWorkbench/TIMESHEET_APPROVE_FAILURE';
export const TIMESHEET_REJECT_PROCESSING =
  'timesheetsWorkbench/TIMESHEET_REJECT_PROCESSING';
export const TIMESHEET_REJECT_SUCCESS =
  'timesheetsWorkbench/TIMESHEET_REJECT_SUCCESS';
export const TIMESHEET_REJECT_FAILURE =
  'timesheetsWorkbench/TIMESHEET_REJECT_FAILURE';
export const TIMESHEET_ELIGIBILITY_FOR_PTO =
  'timesheetsWorkbench/TIMESHEET_ELIGIBILITY_FOR_PTO';
export const CHECK_ALLOWED_PAYCODES_PROCESSING =
  'timesheetsWorkbench/CHECK_ALLOWED_PAYCODES_PROCESSING';
export const CHECK_ALLOWED_PAYCODES_SUCCESS =
'timesheetsWorkbench/CHECK_ALLOWED_PAYCODES_SUCCESS';
export const CHECK_ALLOWED_PAYCODES_FAILURE =
'timesheetsWorkbench/CHECK_ALLOWED_PAYCODES_FAILURE';
