import createDecorator from 'final-form-focus';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Field, Form } from 'react-final-form';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import draftToHtml from 'draftjs-to-html';
import { convertToRaw } from 'draft-js';
import { HtmlEditor } from '../../../../components/final-form/ff-HTML-editor';
import { addNotification, getReceivers } from '../api';
import validate from './validate';
import PrButton from '../../../../components/--primitives/pr-button';
import FfDropdownInput from '../../../../components/final-form/ff-dropdown-input/ff-dropdown-input';
import FfAnimatedInput from '../../../../components/final-form/ff-animated-input';
import { loadNotifications } from '../../../../redux/actions';

const focusOnErrors = createDecorator();

const mediaTypes = [
  { value: 'Email', label: 'Email' },
  { value: 'RTN', label: 'RTN' },
];

class AddNotification extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      receivers: [],
    };
  }

  onSubmit = (values) => {
    const {
      jobId,
      clientId,
      interviewId,
      callAddNotificationAPI,
      renderNotificationListing,
      candidateId,
      timesheetId,
      placementId,
    } = this.props;
    const job = jobId;
    const company = clientId;
    const candidate = candidateId;
    const interview = interviewId;
    const timesheet = timesheetId;
    const placement = placementId;
    const valuesToSend = {
      job,
      company,
      interview,
      candidate,
      timesheet,
      placement,
      ...values,
      body: draftToHtml(convertToRaw(values.body.getCurrentContent())),
    };
    const { receivers = {} } = values;
    valuesToSend.type = values.type.value;
    valuesToSend.receivers = receivers.map(({ value }) => value);
    callAddNotificationAPI(valuesToSend, renderNotificationListing);
    this.props.loadNotifications();
  };

  render() {
    const {
      workBench,
      jobId,
      clientId,
      renderNotificationListing,
      candidateId,
      placementId,
    } = this.props;
    return (
      <Form
        decorators={[focusOnErrors]}
        validate={validate}
        onSubmit={this.onSubmit}
        render={({ handleSubmit, errors }) => (
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="card-title">New Notification</div>
            <p>&nbsp;</p>
            <div className="row grid grid-12">
              <div className="col">
                <div className="row grid grid-4 vertical-start">
                  <div className="col">
                    <Field
                      name="type"
                      placeholder="Select Notification Type"
                      component={FfDropdownInput}
                      options={mediaTypes}
                    />
                  </div>
                </div>
              </div>
              <div className="col">
                <Field
                  name="receivers"
                  placeholder="Start typing to select Receivers"
                  component={FfDropdownInput}
                  apiCall={(...parameter) =>
                    getReceivers(workBench, clientId, jobId, candidateId, placementId, ...parameter)
                  }
                  type="select"
                  isMulti
                  asyncDropdown
                  grouping
                />
              </div>
              <div className="col">
                <Field name="subject" component={FfAnimatedInput} placeholder="Subject" />
              </div>
              <div className="col">
                <Field name="body" component={HtmlEditor} />
              </div>
            </div>
            <p>&nbsp;</p>
            <div className="submit-button-group">
              <PrButton value="Cancel" type="light-blue" onClick={renderNotificationListing} />
              <PrButton value="Send" type="light-blue" onClick={handleSubmit} />
            </div>
          </form>
        )}
      />
    );
  }
}

AddNotification.propTypes = {
  renderNotificationListing: PropTypes.func.isRequired,
  callAddNotificationAPI: PropTypes.func.isRequired,
  jobId: PropTypes.string,
  clientId: PropTypes.string,
  interviewId: PropTypes.string,
  candidateId: PropTypes.string,
  timesheetId: PropTypes.string,
  placementId: PropTypes.string,
  workBench: PropTypes.string,
};

AddNotification.defaultProps = {
  jobId: undefined,
  clientId: undefined,
  interviewId: undefined,
  timesheetId: undefined,
  candidateId: undefined,
  placementId: undefined,
  workBench: undefined,
};

const mapStateProps = (state) => {
  const { data: { rows = [], count = 0 } = {} } = state.get('adminNotifications');
  return {
    notifications: {
      data: rows,
      count,
    },
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  callAddNotificationAPI: (...params) => dispatch(addNotification(...params)),
  loadNotifications: () => dispatch(loadNotifications()),
});

export default withRouter(connect(mapStateProps, mapDispatchToProps)(AddNotification));
