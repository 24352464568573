import { combineReducers } from 'redux-immutable';
import { interviews, managers, settings } from '../../containers/WorkBench/Interview/list/reducer';
import { details } from '../../containers/WorkBench/Interview/details/reducer';

export default combineReducers({
  interviews,
  managers,
  settings,
  details,
});
