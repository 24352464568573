import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { LeadsListView, LeadsAddView, LeadsEditView } from '../../../routes/LoadableComponents';

function Reports() {
  return (
    <Switch>
      <Route exact path="/leads/list" component={LeadsListView} />
      <Route exact path="/leads/add" component={LeadsAddView} />
      <Route exact path="/leads/edit/:id/:action" component={LeadsEditView} />
      <Route exact path="/leads/edit/:id/:action/:opportunity" component={LeadsEditView} />
    </Switch>
  );
}

export default Reports;
