export const editLeadConstants = {
  LOAD: 'lead/edit/LOAD',
  LOAD_INLINE: 'lead/edit/LOAD_INLINE',
  LOAD_CANDIDATES: 'leads/edit/LOAD_CANDIDATES',
  LOAD_SUCCESS: 'lead/edit/LOAD_SUCCESS',
  LOAD_FAIL: 'lead/edit/LOAD_FAIL',

  GET_LEAD: 'leads/edit/GET_LEAD',
  GET_USERS: 'leads/edit/GET_USERS',
  GET_OPPORTUNITY: 'leads/edit/GET_OPPORTUNITY',
  GET_CANDIDATES: 'leads/edit/GET_CANDIDATES',
};
