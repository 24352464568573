const JOBS_AT_RISK_REQUEST = 'JOBS_AT_RISK_REQUEST';
const JOBS_AT_RISK_SUCCESS = 'JOBS_AT_RISK_SUCCESS';
const JOBS_AT_RISK_FAILURE = 'JOBS_AT_RISK_FAILURE';

const jobsAtRiskRequest = (dispatch) => {
  dispatch({ type: JOBS_AT_RISK_REQUEST, payload: { loading: true } });
};

const jobsAtRiskSuccess = (dispatch, response) => {
  dispatch({
    type: JOBS_AT_RISK_SUCCESS,
    payload: { data: response, loading: false },
  });
};

const jobsAtRiskFailure = (dispatch) => {
  dispatch({ type: JOBS_AT_RISK_FAILURE, payload: { loading: false } });
};

export {
  JOBS_AT_RISK_REQUEST,
  JOBS_AT_RISK_SUCCESS,
  JOBS_AT_RISK_FAILURE,
  jobsAtRiskRequest,
  jobsAtRiskSuccess,
  jobsAtRiskFailure,
};
