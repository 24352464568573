import {
  NOTES_REQUEST,
  NOTES_SUCCESS,
  NOTES_FAILURE,
  COMMENT_REQUEST,
  COMMENT_SUCCESS,
  COMMENT_FAILURE,
} from './actions';

const initialState = {};

const notes = (state = initialState, action) => {
  switch (action.type) {
    case NOTES_REQUEST:
    case NOTES_SUCCESS:
    case NOTES_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

const commentInitialState = {};
const noteComments = (state = commentInitialState, action) => {
  switch (action.type) {
    case COMMENT_REQUEST:
    case COMMENT_SUCCESS:
    case COMMENT_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export { notes, noteComments };
