import React from 'react';
import './pr-img-icon.scss';

export default class PrImgIconComponent extends React.Component {
  render() {
    const { onClick = () => {/* empty fun */} } = this.props;
    return (
      <div className={`pr-img-icon-wrapper ${this.props.type}`} onClick={onClick}>
        <img src={this.props.url} alt=""></img>
      </div>
    );
  }
}
