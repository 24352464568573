import { combineReducers } from 'redux-immutable';
import { companies, states } from '../../containers/WorkBench/Client/List/reducer';
import { companyDetails } from '../../containers/WorkBench/Client/Edit/Common/reducer';
import { addCompany, holidayCalendar } from '../../containers/WorkBench/Client/Add/reducer';
import { ajnaTeam } from '../../containers/WorkBench/Client/Edit/Modules/AjnaTeam/reducer';
import { users } from '../../containers/WorkBench/Client/Edit/Modules/ManageUsers/reducer';
import {
  jobManagers,
  saveJobManager,
} from '../../containers/WorkBench/Client/Edit/SubModules/AccountManager/reducer';

export default combineReducers({
  companies,
  states,
  companyDetails,
  addCompany,
  ajnaTeam,
  jobManagers,
  saveJobManager,
  users,
  holidayCalendar,
});
