import { combineReducers } from 'redux-immutable';
import { placementsList } from '../../containers/WorkBench/Placements/List/reducer';
import {
  clients,
  jobs,
  departments,
  rulesAndHolidayCalendar,
  employees,
  addPlacement,
  placementDetail,
  jobManagers,
  recruiters,
  AddPlacementPage,
  versions,
} from '../../containers/WorkBench/Placements/Add/reducer';
import { EditPlacementPage } from '../../containers/WorkBench/Placements/Edit/reducer';

export default combineReducers({
  placementsList,
  clients,
  jobs,
  departments,
  rulesAndHolidayCalendar,
  employees,
  addPlacement,
  placementDetail,
  jobManagers,
  AddPlacementPage,
  versions,
  EditPlacementPage,
});
