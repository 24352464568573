// initial state of each workbench list view URL params
const initialState = {
  timesheet_params: '',
};

/* the params have to be URL string after "?"
  the dispatch method's blueprint should be
  dispatch({ type: 'WB_PARAMS', value: 'URL params string after ?' })
  e.g., for Timesheet WB list view params the dispatch should be
  dispatch({ type: 'TIMESHEET_PARAMS', value: 'URL params string after ?' })
*/

export default function reducer(state = initialState, action) {
  if (action.type) {
    const lowerCasedType = action.type.toLowerCase();
    return {
      ...state,
      [lowerCasedType]: action.value,
    };
  }

  return state;
}
