
export const transactionConstants = {
  LOAD: 'transaction/LOAD',
  LOAD_SUCCESS: 'transaction/LOAD_SUCCESS',
  LOAD_FAIL: 'transaction/LOAD_FAIL', 
  DETAIL_PROCESSING: 'transaction/DETAIL_PROCESSING',
  DETAIL_SUCCESS: 'transaction/DETAIL_SUCCESS',
  DETAIL_FAILURE: 'transaction/DETAIL_FAILURE',  
  PAY_CODES_SUCCESS:'transaction/PAY_CODES_SUCCESS',
};

export const payCodesConstants = {
  OT: 'OT',
  DT: 'DT',
};
