import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import AddNotification from './AddNotification';
import NotificationList from './NotificationsList';

/**
 * Please note id is jobId here
 */
class Notifications extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      newNotification: false,
    };
  }

  onAddNotifications = () => {
    const { updateOptionToShow } = this.props;
    const { newNotification } = this.state;
    this.setState(
      (prevState) => ({ newNotification: !prevState.newNotification }),
      () => updateOptionToShow(newNotification ? 8 : 12),
    );
  };

  render() {
    const { newNotification } = this.state;
    const {
      id,
      clientId,
      interviewId,
      candidateId,
      timesheetId,
      job = {},
      client = {},
      interview = {},
      timesheet,
      candidateName: candidateSideCandidateName,
      placementId,
      placement,
      workBench,
    } = this.props;
    const { company: { name: jobSideCompanyName } = {}, job_title = '' } = job;
    const { name: clientSideCompanyName } = client;
    const companyName = jobSideCompanyName || clientSideCompanyName;
    const {
      id: interviewIdToShow,
      job_title: interviewJobTitle,
      candidate: { name: interviewCandidateName } = {},
      company: { name: interviewSideCompanyName } = {},
    } = interview;
    const {
      company: timesheetSideCompanyName,
      employee: timesheetCandidateName,
      job_title: timesheetJobTitle,
    } = timesheet;
    const {
      company: placementSideCompanyName,
      candidate: placementCandidateName,
      job: placementJobTitle,
    } = placement;
    return (
      <div className="card">
        {newNotification ? (
          <AddNotification
            workBench={workBench}
            jobId={id}
            clientId={clientId}
            interviewId={interviewId}
            candidateId={candidateId}
            timesheetId={timesheetId}
            renderNotificationListing={this.onAddNotifications}
            placementId={placementId}
          />
        ) : (
          <NotificationList
            jobId={id}
            clientId={clientId}
            interviewId={interviewId}
            candidateId={candidateId}
            interviewIdToShow={interviewIdToShow}
            timesheetId={timesheetId}
            renderNotificationListing={this.onAddNotifications}
            companyName={
              companyName ||
              interviewSideCompanyName ||
              timesheetSideCompanyName ||
              placementSideCompanyName
            }
            jobTitle={job_title || interviewJobTitle || timesheetJobTitle || placementJobTitle}
            candidateName={
              interviewCandidateName ||
              timesheetCandidateName ||
              candidateSideCandidateName ||
              placementCandidateName
            }
            placementId={placementId}
          />
        )}
      </div>
    );
  }
}

Notifications.propTypes = {
  updateOptionToShow: PropTypes.func,
  job: PropTypes.objectOf(PropTypes.shape),
  id: PropTypes.string,
  clientId: PropTypes.string,
  candidateId: PropTypes.string,
  timesheetId: PropTypes.string,
  client: PropTypes.objectOf(PropTypes.shape),
  interviewId: PropTypes.string,
  interview: PropTypes.objectOf(PropTypes.shape),
  timesheet: PropTypes.objectOf(PropTypes.shape),
  candidateName: PropTypes.string,
  placementId: PropTypes.string,
  placement: PropTypes.objectOf(PropTypes.shape),
  workBench: PropTypes.string,
};

Notifications.defaultProps = {
  id: undefined,
  clientId: undefined,
  interviewId: undefined,
  candidateId: undefined,
  timesheetId: undefined,
  job: {},
  client: {},
  interview: {},
  timesheet: {},
  updateOptionToShow: () => {/* empty fun */},
  candidateName: undefined,
  placementId: undefined,
  placement: {},
  workBench: undefined,
};

export default Notifications;
