/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter, useHistory } from 'react-router-dom';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import {
  HtmlEditor,
  draftFromHtml,
  getTextFromHTML,
} from '../../../../components/final-form/ff-HTML-editor';
import {
  createLinkToClientDashboard,
  createLinkToCandidateDashboard,
  createLinkToJobDashboard,
  // createLinkToInterviewDashboard,
} from '../../../../utils/navigation';
import { DATE_FORMAT_MDY_WITH_TIME } from '../../../../utils/siteSpecificConstants';
import { Popup, PopupHeader, PopupBody } from '../../../../components/--primitives/pr-popup';
import {
  importImagesFromImageDirectory,
  strictValidObjectWithKeys,
} from '../../../../utils/commonUtils';
import '../style.scss';
import PrButton from '../../../../components/--primitives/pr-button';
import { updateNotes, getComments, addComment, getNoteDetail } from './api';
import { convertEditNoteContent, convertHTMLData, updateHTMLBodyContent } from '../helpers';

const images = importImagesFromImageDirectory(
  require.context('../../../../assets/icons', false, /\.(png|jpe?g|svg)$/),
);

const defaultEditorProps = {
  open: false,
  type: '',
};
function Details({
  dispatch,
  isOpen,
  toggleModal,
  note,
  companyName,
  jobTitle,
  candidateName,
  // interviewId,
  timesheetId,
  companyId,
  jobId,
  candidateId,
  interview_Id,
  leadId,
  leadName,
  placementId,
  commentListData,
  callUpdateNotesAPI,
  callCommentListAPI,
  callAddCommentAPI,
  isLoading,
  callNoteDetailAPI,
  activeNoteId,
  activeLoginUser,
  userList,
  activeLinks,
}) {
  const history = useHistory();
  const [body, setBody] = useState(EditorState.createEmpty());
  const [comment, setComment] = useState(EditorState.createEmpty());
  const [error, setError] = useState('');
  const [NoteEdit, setNoteEdit] = useState(false);
  const [showComments, setShowComments] = useState();
  const [{ open, type }, setShowEditor] = useState(defaultEditorProps);
  const [noteDetail, setNoteDetail] = useState({});

  const {
    html_body = '',
    date,
    updatedAt,
    _id,
    creator_id,
    creator = '',
  } = useMemo(() => {
    return updateHTMLBodyContent(strictValidObjectWithKeys(noteDetail) ? noteDetail : note);
  }, [note, noteDetail]);

  useEffect(async () => {
    if (_id && isOpen) {
      callCommentListAPI(_id, leadId);
    }
  }, [_id, isOpen]);

  const callDetailAPI = async (id) => {
    const res = await callNoteDetailAPI(id, leadId);
    if (res) {
      setNoteDetail(res);
    }
  };

  useEffect(() => {
    if (activeNoteId && isOpen) {
      callDetailAPI(activeNoteId);
    }
    return () => setNoteDetail({});
  }, [activeNoteId]);

  const commentList = useMemo(() => {
    return convertHTMLData(commentListData.data || []);
  }, [commentListData]);

  useEffect(() => {
    setBody(draftFromHtml(html_body.replaceAll('data-id', 'href')));
  }, [html_body]);

  const EditNote = async () => {
    const valuesToSend = {
      body: getTextFromHTML(body),
      ...convertEditNoteContent(draftToHtml(convertToRaw(body.getCurrentContent()))),
      company: companyId,
      companyName,
      job: jobId,
      jobTitle,
      candidate: candidateId,
      candidateName,
      timesheet: timesheetId,
      interview: interview_Id,
      leadName,
      placement: placementId,
    };
    const res = await callUpdateNotesAPI(_id, valuesToSend, leadId);
    if (res) {
      setShowEditor(defaultEditorProps);
      setNoteEdit(true);
      callDetailAPI(_id);
      if (activeNoteId) {
        history.replace({ search: '' });
      }
    }
  };

  const AddComment = async () => {
    const valuesToSend = {
      body: getTextFromHTML(comment),
      ...convertEditNoteContent(draftToHtml(convertToRaw(comment.getCurrentContent()))),
      note: _id,
      company: companyId,
      companyName,
      job: jobId,
      jobTitle,
      candidate: candidateId,
      candidateName,
      timesheet: timesheetId,
      interview: interview_Id,
      leadName,
      placement: placementId,
    };
    valuesToSend.leadId = leadId;
    const res = await callAddCommentAPI(valuesToSend, leadId);
    if (res) {
      callCommentListAPI(_id, leadId);
      callDetailAPI(_id);
      setComment(draftFromHtml(''));
      setShowEditor(defaultEditorProps);
      setNoteEdit(true);
    }
  };

  const sameEditDate =
    moment(new Date(updatedAt)).format(DATE_FORMAT_MDY_WITH_TIME) ===
    moment(new Date(date)).format(DATE_FORMAT_MDY_WITH_TIME);

  return (
    <Popup scrollContent className="Note-details" isOpen={isOpen}>
      <PopupHeader
        close
        toggle={() => {
          if (activeNoteId) {
            history.replace({ search: '' });
          }
          toggleModal(NoteEdit);
        }}
      >
        <div className="title">Note Details</div>
      </PopupHeader>
      <PopupBody>
        {companyName && (
          <div className="notes-content notes-link row">
            <div>
              <strong>Client :</strong>
            </div>
            <div className="grid note-data">
              <span>
                {createLinkToClientDashboard(
                  dispatch,
                  companyId || activeLinks.CompanyID,
                  companyName,
                )}
              </span>
            </div>
          </div>
        )}
        {candidateName && (
          <div className="notes-content notes-link row">
            <div>
              <strong>Candidate :</strong>
            </div>
            <div className="grid note-data">
              <span>
                {createLinkToCandidateDashboard(
                  dispatch,
                  candidateId || activeLinks.CandidateId,
                  candidateName,
                )}
              </span>
            </div>
          </div>
        )}
        {jobTitle && (
          <div className="notes-content notes-link row">
            <div>
              <strong>Job Title:</strong>
            </div>
            <div className="grid note-data">
              <span>
                {createLinkToJobDashboard(dispatch, jobId || activeLinks.JobId, jobTitle)}
              </span>
            </div>
          </div>
        )}
        {/* {interviewId && (
          <div className="notes-content notes-link row">
            <div>
              <strong>Interview ID :</strong>
            </div>
            <div className="grid note-data">
              <span>{createLinkToInterviewDashboard(dispatch, interview_Id, interviewId)}</span>
            </div>
          </div>
        )} */}
        <div className="notes-content notes-link row">
          <div>
            <strong>Created Date :</strong>
          </div>
          <div className="grid note-data">
            {moment(new Date(date)).format(DATE_FORMAT_MDY_WITH_TIME)}
          </div>
        </div>
        <div className="notes-content notes-link row">
          <div>
            <strong>Last Activity Date :</strong>
          </div>
          <div>
            {!sameEditDate ? moment(new Date(updatedAt)).format(DATE_FORMAT_MDY_WITH_TIME) : 'NA'}
          </div>
        </div>
        <hr />
        <div className="notes-content notes-link row">
          <div>
            <strong>Note :</strong>
          </div>
          <div>
            {open && type === 'edit' ? (
              <div className="MentionUI">
                <HtmlEditor
                  input={{
                    onChange: (reason) => {
                      setBody(reason);
                      setError(null);
                    },
                    value: body,
                  }}
                  meta={{
                    touched: !!error,
                    error,
                  }}
                  mention={{
                    separator: ' ',
                    trigger: '@',
                    suggestions: userList,
                  }}
                />
                <div className="row actionBtn btn-group space-end">
                  <PrButton
                    value="Cancel"
                    type="light-blue medium tall"
                    isDisabled={isLoading}
                    onClick={() => {
                      setShowEditor(defaultEditorProps);
                      setBody(draftFromHtml(html_body.replaceAll('data-id', 'href')));
                    }}
                  />
                  &nbsp;
                  <PrButton
                    isDisabled={isLoading}
                    value="Save"
                    type="light-blue medium tall"
                    onClick={EditNote}
                  />
                </div>
              </div>
            ) : (
              <div dangerouslySetInnerHTML={{ __html: html_body }} />
            )}
          </div>
          {activeLoginUser === creator_id && type !== 'edit' ? (
            <img
              style={{ cursor: 'pointer', width: '25px' }}
              src={images['pen.svg']}
              alt="pin"
              onClick={() => {
                setShowEditor({ open: true, type: 'edit' });
                setShowComments(false);
              }}
            />
          ) : (
            ''
          )}
        </div>
        {/* Comments */}
        {commentListData.count > 0 && (
          <div className="notes-content notes-link row">
            <div className="row">
              <img
                style={{ marginRight: '4px', width: '20px' }}
                src={images['comment.svg']}
                alt="pin"
                onClick={() => !commentListData.loading && setShowComments((prev) => !prev)}
              />
              <span
                className="make-link"
                onClick={() => !commentListData.loading && setShowComments((prev) => !prev)}
              >
                {showComments ? 'Hide ' : ''}
                {commentListData.count} Comment(s)
              </span>
            </div>
          </div>
        )}
        <br />
        {showComments && (
          <ul className="list">
            {commentList.map((item) => (
              <li key={item._id}>
                <div className="comments">
                  <p>
                    <strong>
                      {strictValidObjectWithKeys(item.creator) ? item.creator.name : ''}
                    </strong>
                  </p>
                  <div>{moment(item.createdAt).format(DATE_FORMAT_MDY_WITH_TIME)}</div>
                </div>
                <p dangerouslySetInnerHTML={{ __html: item.html_body }} />
              </li>
            ))}
          </ul>
        )}
        {(commentListData.count === 0 || showComments) && !open && (
          <div className="row actionBtn btn-group space-end">
            <PrButton
              value="Comment"
              type="light-blue medium tall"
              isDisabled={commentListData.loading}
              onClick={() => {
                setShowComments((prev) => !prev);
                setShowEditor({ open: true, type: 'comment' });
              }}
            />
          </div>
        )}
        {open && type === 'comment' && (
          <div className="MentionUI">
            <h4>{creator}</h4>
            <HtmlEditor
              input={{
                onChange: (res) => setComment(res),
                value: comment,
              }}
              mention={{
                separator: ' ',
                trigger: '@',
                suggestions: userList,
              }}
            />
            <div className="row actionBtn btn-group space-end">
              <PrButton
                value="Cancel"
                type="light-blue medium tall"
                isDisabled={commentListData.loading}
                onClick={() => {
                  setComment(draftFromHtml(''));
                  setShowEditor(defaultEditorProps);
                }}
              />
              &nbsp;
              <PrButton
                value="Save"
                type="light-blue medium tall"
                isDisabled={commentListData.loading}
                onClick={AddComment}
              />
            </div>
          </div>
        )}
      </PopupBody>
      {/* <PopupFooter /> */}
    </Popup>
  );
}

Details.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  note: PropTypes.objectOf(PropTypes.shape).isRequired,
  userList: PropTypes.arrayOf(PropTypes.shape),
  companyId: PropTypes.string,
  companyName: PropTypes.string,
  jobId: PropTypes.string,
  jobTitle: PropTypes.string,
  candidateId: PropTypes.string,
  candidateName: PropTypes.string,
  // interviewId: PropTypes.string,
  interview_Id: PropTypes.string,
  timesheetId: PropTypes.string,
  leadId: PropTypes.string,
  leadName: PropTypes.string,
  placementId: PropTypes.string,
  activeNoteId: PropTypes.string,
  callUpdateNotesAPI: PropTypes.func,
  callCommentListAPI: PropTypes.func,
  callAddCommentAPI: PropTypes.func,
  commentListData: PropTypes.objectOf(PropTypes.shape).isRequired,
  isLoading: PropTypes.bool,
  callNoteDetailAPI: PropTypes.func,
  activeLoginUser: PropTypes.string,
  activeLinks: PropTypes.objectOf(PropTypes.shape),
};

Details.defaultProps = {
  companyId: undefined,
  companyName: undefined,
  jobId: undefined,
  jobTitle: undefined,
  candidateId: undefined,
  candidateName: undefined,
  // interviewId: undefined,
  timesheetId: undefined,
  interview_Id: undefined,
  leadId: undefined,
  leadName: undefined,
  placementId: undefined,
  activeNoteId: undefined,
  activeLoginUser: undefined,
  isLoading: false,
  activeLinks: {},
  userList: [],
  callUpdateNotesAPI: () => {
    /* empty fun */
  },
  callCommentListAPI: () => {
    /* empty fun */
  },
  callAddCommentAPI: () => {
    /* empty fun */
  },
  callNoteDetailAPI: () => {
    /* empty fun */
  },
};

const mapStateProps = (state) => {
  const { loading: notesLoading } = state.get('note').get('notes');
  const { data: { rows = [], count = 0 } = {}, loading: commentLoading } =
    state.get('note').get('noteComments') || {};
  const { _id: activeLoginUser } = state.get('auth').get('user') || {};
  return {
    commentListData: {
      data: rows,
      count,
      loading: commentLoading,
    },
    isLoading: notesLoading || commentLoading,
    activeLoginUser,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  callNoteDetailAPI: (...params) => dispatch(getNoteDetail(...params)),
  callCommentListAPI: (...params) => dispatch(getComments(...params)),
  callUpdateNotesAPI: (...params) => dispatch(updateNotes(...params)),
  callAddCommentAPI: (...params) => dispatch(addComment(...params)),
});

export default withRouter(connect(mapStateProps, mapDispatchToProps)(Details));
// export default Details;
