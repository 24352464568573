import React from 'react';
import './pr-info-button.scss';

function PrInfoButton({ type, onClick }) {
  return (
    <div className={`pr-info-button-wrapper ${type || ''} `} onClick={() => onClick && onClick()}>
      <svg viewBox="0 0 9 14" fill="none">
        <path
          d="M5.49553 12.2944L3.63292 12.2944L5.49566 5.99767L3.63305 5.99765"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.55859 1.49997H5.57209"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}

export default PrInfoButton;
