import React, { useEffect, useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import './ff-dropdown-input-group.scss';

import {
  strictValidArrayWithLength,
  strictValidString,
  validObjectWithParameterKeys,
  strictValidObjectWithKeys,
  strictValidStringWithMinLength,
  strictValidArray,
} from '../../../utils/commonUtils';
import Loading from '../../--primitives/pr-loading';

const initialState = {
  items: [],
  opened: false,
  filled: false,
  inputValue: '',
  selectedOption: { label: '', value: '' },
  multiValues: [],
};
const NoResultText = 'No Results';

function FfDropdownInputGroup({
  input,
  input: { value, onChange } = {},
  meta: { touched, error },
  placeholder,
  options = [],
  disabled,
  tip,
  listClassName = '',
  className,
  hideLabelOnSelect,
  canType,
  onSelect,
  grouping,
  showError,
  errorText,
  isMulti,
  apiCall,
  dropDownRefComp,
  removeIcon,
  clearFieldOnSelect,
  ...otherProps
}) {
  const listEl = useRef(null);
  const inputEl = useRef(null);
  const parentInputEl = useRef(null);
  const [isLoading, setLoading] = useState(false);
  const [dropdownPosition, setOpenPosition] = useState('bottom');
  const [{ items, opened, filled, inputValue, multiValues, selectedOption }, setState] = useState({
    ...initialState,
    options,
  });

  const AddedeTitleOption = (arr) => {
    if (!strictValidArrayWithLength(arr)) return [];
    const allGroupedOption = [];
    let result = [];
    arr.forEach((option) => {
      const { groupTitle, groupId } = option;
      const index = allGroupedOption.findIndex((v) => v.groupId === groupId);
      if (index > -1) {
        allGroupedOption[index].groupedOptions.push(option);
      } else {
        allGroupedOption.push({
          groupId,
          groupTitle,
          groupedOptions: [option],
        });
      }
    });
    allGroupedOption.forEach((vv) => {
      const { groupTitle, groupedOptions } = vv;
      result = [...result, { label: groupTitle, isTitle: true }, ...groupedOptions];
    });
    return result;
  };

  useEffect(() => {
    if (strictValidArrayWithLength(options)) {
      let optionWithTitle = options;
      if (grouping) optionWithTitle = AddedeTitleOption(options);
      setState((prevState) => ({
        ...prevState,
        options: optionWithTitle,
      }));
    }
  }, [options]);

  // eslint-disable-next-line sonarjs/cognitive-complexity
  useEffect(() => {
    if (strictValidString(value)) {
      const selectedValue = strictValidArrayWithLength(options)
        ? options.find((v) => v.value === value)
        : {};
      if (strictValidObjectWithKeys(selectedValue)) {
        setState((prevState) => ({
          ...prevState,
          inputValue: isMulti || clearFieldOnSelect ? '' : selectedValue.label,
          filled: !isMulti && strictValidStringWithMinLength(value),
          selectedOption: selectedValue,
          multiValues: selectedValue && isMulti ? [selectedValue] : selectedValue,
        }));
      }
    } else if (
      strictValidObjectWithKeys(value) &&
      validObjectWithParameterKeys(value, ['value', 'label'])
    ) {
      const { label: optionLabel = '' } = value;
      setState((prevState) => ({
        ...prevState,
        inputValue: optionLabel,
        filled: strictValidStringWithMinLength(optionLabel),
        selectedOption: value,
      }));
    } else if (strictValidArray(value) && isMulti) {
      setState((prevState) => ({
        ...prevState,
        inputValue: '',
        filled: strictValidArrayWithLength(value),
        selectedOption: value,
        multiValues: value,
      }));
    }
    if (removeIcon && !value) {
      let optionWithTitle = options;
      if (grouping)
        optionWithTitle = strictValidArrayWithLength(options) && AddedeTitleOption(options);
      setState((prevState) => ({
        ...prevState,
        ...initialState,
        options: optionWithTitle,
      }));
    }
  }, [value]);

  const handleClickOutside = useCallback((e) => {
    const { target, forceClose = false } = e;
    if (!parentInputEl.current.contains(target) || forceClose) {
      setState((prevState) => ({
        ...prevState,
        inputValue: isMulti || clearFieldOnSelect ? '' : prevState.selectedOption.label,
        opened: false,
        filled: isMulti
          ? strictValidArrayWithLength(prevState.multiValues)
          : !clearFieldOnSelect && strictValidStringWithMinLength(prevState.selectedOption.label),
      }));
    }
  }, []);

  const handleBlur = () => {
    if (!removeIcon && inputValue !== selectedOption.label) {
      onChange(selectedOption);
    }
  };

  const onFocus = () => {
    setState((prevState) => {
      return {
        ...prevState,
        inputValue: hideLabelOnSelect ? '' : prevState.inputValue,
        opened: true,
        items:
          (strictValidArrayWithLength(prevState.options) &&
            prevState.options.map((v) => ({
              ...v,
              formattedLabel: undefined,
            }))) ||
          [],
      };
    });
  };

  useEffect(() => {
    inputEl.current.addEventListener('focus', onFocus, false);
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside);
      if (inputEl && inputEl.current) inputEl.current.removeEventListener('focus', onFocus);
    };
  }, []);

  const setDropDownPosition = () => {
    let btspace = document.getElementsByClassName('pr-floating-message');
    if (!strictValidArrayWithLength(btspace))
      btspace = document.getElementsByClassName('page-footer');
    const extraSpaceExists = strictValidArrayWithLength(btspace)
      ? btspace[0].offsetHeight / 1.03
      : 0;
    let bottomSpace;
    const position = inputEl.current.getBoundingClientRect();
    const listPosition = listEl.current && listEl.current.getBoundingClientRect();
    const listHeight = listPosition ? listPosition.height : 0;
    const topSpace = position.y;

    if (validObjectWithParameterKeys(dropDownRefComp, ['current']) && dropDownRefComp.current) {
      const elToCompareHeight = dropDownRefComp.current.getBoundingClientRect().y;
      bottomSpace = elToCompareHeight - position.bottom - extraSpaceExists;
    } else {
      bottomSpace = window.innerHeight - position.bottom - extraSpaceExists;
    }

    if (bottomSpace < listHeight && topSpace > listHeight) {
      setOpenPosition('top');
    } else {
      setOpenPosition('bottom');
    }
  };

  useEffect(() => {
    setDropDownPosition();
    setTimeout(() => {
      if (
        !inputValue &&
        validObjectWithParameterKeys(listEl, ['current']) &&
        strictValidObjectWithKeys(listEl.current)
      )
        listEl.current.scrollTop = 0;
    }, 200);
  }, [inputValue, opened]);

  // eslint-disable-next-line no-shadow
  const highlightOptions = (values = [], optionInputValue = '') => {
    let filteredOptions = [];
    let remainingOptions = values || [];
    const allInputs = optionInputValue.split(' ').filter((v) => v);
    allInputs.unshift(optionInputValue);
    allInputs.forEach((singleInput) => {
      const filterdRemainingOption = [];
      if (strictValidArrayWithLength(remainingOptions)) {
        remainingOptions.forEach((option) => {
          const index = option.label.toLowerCase().indexOf(singleInput.toLowerCase());
          if (index > -1) {
            option.formattedLabel = (
              <p>
                {option.label.substring(0, index)}
                <span className="highlight">
                  {option.label.substring(index, index + singleInput.length)}
                </span>
                {option.label.substring(index + singleInput.length)}
              </p>
            );
            filteredOptions.push(option);
          } else {
            filterdRemainingOption.push(option);
          }
        });
      }
      remainingOptions = filterdRemainingOption;
    });

    if (grouping) {
      filteredOptions = AddedeTitleOption(filteredOptions);
    }

    setState((prevState) => ({
      ...prevState,
      opened: strictValidStringWithMinLength(optionInputValue),
      filled: strictValidStringWithMinLength(optionInputValue),
      items: strictValidArrayWithLength(filteredOptions)
        ? filteredOptions
        : [{ label: NoResultText, value: NoResultText }],
      inputValue: optionInputValue,
    }));
    setLoading(false);
  };

  const debouncedSave = useCallback(
    debounce(async (nextValue) => {
      await apiCall(nextValue, (v) => highlightOptions(v, nextValue));
      setLoading(false);
    }, 1000),
    [],
  );

  // eslint-disable-next-line consistent-return
  const onChangeInput = async (e) => {
    const inputVal = e.target.value || '';
    highlightOptions(options, inputVal);
    if (apiCall) {
      setLoading(true);
      await debouncedSave(inputVal);
      setState((prevState) => ({
        ...prevState,
        inputVal,
        filled: strictValidStringWithMinLength(inputVal),
        options: [],
        items: [],
      }));
      return false;
    }
  };

  const handleSelect = (selectedOption) => {
    const { label } = selectedOption;
    setState((prevState) => ({
      ...prevState,
      inputValue: isMulti || clearFieldOnSelect ? '' : label,
      opened: false,
      filled: isMulti ? true : !clearFieldOnSelect && selectedOption.value !== '',
      selectedOption,
      multiValues: isMulti ? [...prevState.multiValues, selectedOption] : [],
    }));

    const valuesToSend = isMulti ? [...multiValues, selectedOption] : selectedOption;

    onChange(valuesToSend);
    if (onSelect) onSelect(valuesToSend);
  };

  const handleDelete = (index) => {
    setState((prevState) => ({
      ...prevState,
      multiValues: prevState.multiValues.filter((_v, i) => i !== index),
    }));
    onChange(multiValues.filter((_v, i) => i !== index));
  };

  const onDeleteByBackspace = async () => {
    if (inputValue) return;
    if (!strictValidArrayWithLength(multiValues)) return;
    handleDelete(multiValues.length - 1);
  };

  const removeValue = () => {
    setState({ ...initialState, options });
    onChange(null);
    if (onSelect) onSelect(null);
  };

  return (
    <div
      ref={parentInputEl}
      className={`ff-dropdown-input-wrapper-group ${disabled || !opened ? '' : 'opened'} ${
        filled || !!inputValue || (isMulti && strictValidArrayWithLength(multiValues))
          ? 'filled'
          : ''
      } ${disabled ? 'disabled' : ''} ${className || ''} ${!placeholder ? 'no-placeholder' : ''}`}
      tip={`${tip} ${error && error.length > 45 ? 'double-line-tooltip' : ''}`}
      data-description={errorText || error}
      is-error={touched && error && showError && !disabled ? 'true' : 'false'}
      onClick={() => !opened && !disabled && inputEl.current.focus()}
    >
      {isMulti && strictValidArrayWithLength(multiValues) && (
        <div className="multi-values">
          {multiValues.map((item, index) => {
            const { label, subtitle } = item;
            const key = `key-${index}`;
            return (
              <p key={key} className={disabled ? 'disabled' : ''}>
                {subtitle ? `${label} - ${subtitle}` : label}{' '}
                <span onClick={() => !disabled && handleDelete(index)}>&times;</span>
              </p>
            );
          })}
        </div>
      )}
      <div className="input-wrapper">
        <input
          {...otherProps}
          {...input}
          ref={inputEl}
          value={inputValue || ''}
          onInput={onChangeInput}
          autoComplete="off"
          spellCheck="false"
          disabled={disabled}
          readOnly={!canType}
          onKeyDown={(e) => {
            if (['Backspace', 'Delete'].includes(e.key)) {
              onDeleteByBackspace();
            }
            if (e.key === 'tab' || e.keyCode === 9) {
              handleClickOutside({ ...e, forceClose: true });
            }
          }}
          onBlur={handleBlur}
        />
        {!disabled && removeIcon && inputValue && <span className="x-mark" onClick={removeValue} />}
      </div>
      <span className="placeholder">{placeholder}</span>
      {isLoading && <Loading type="button" color="#4a85fb" />}
      <svg className="arrow" viewBox="0 0 14 8" fill="none">
        <path d="M1 1L7 7L13 1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
      {strictValidArrayWithLength(items) && (
        <ul className={`list ${dropdownPosition} ${listClassName}`} ref={listEl}>
          {items
            .filter((item) =>
              strictValidArrayWithLength(multiValues)
                ? !multiValues.some((v) => v.value === item.value)
                : true,
            )
            .map((option) => {
              const {
                value: optionValue,
                label,
                formattedLabel,
                isTitle = false,
                groupTitle = '',
              } = option;
              return (
                <li
                  key={optionValue ? optionValue + groupTitle : label}
                  className={
                    (strictValidObjectWithKeys(value) && optionValue === value.value && 'active') ||
                    (optionValue === NoResultText && 'empty') ||
                    (isTitle && 'option-group-title') ||
                    ''
                  }
                  onClick={() => optionValue !== NoResultText && !isTitle && handleSelect(option)}
                >
                  {formattedLabel || <p>{label}</p>}
                </li>
              );
            })}
        </ul>
      )}
    </div>
  );
}

FfDropdownInputGroup.propTypes = {
  errorText: PropTypes.string,
  placeholder: PropTypes.string,
  tip: PropTypes.string,
  listClassName: PropTypes.string,
  className: PropTypes.string,
  meta: PropTypes.objectOf(PropTypes.shape()),
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  showError: PropTypes.bool,
  isMulti: PropTypes.bool,
  disabled: PropTypes.bool,
  hideLabelOnSelect: PropTypes.bool,
  canType: PropTypes.bool,
  dropDownRefComp: PropTypes.bool,
  grouping: PropTypes.bool,
  onSelect: PropTypes.func,
  apiCall: PropTypes.func,
  removeIcon: PropTypes.bool,
  clearFieldOnSelect: PropTypes.bool,
  input: PropTypes.objectOf(PropTypes.shape()),
};

FfDropdownInputGroup.defaultProps = {
  placeholder: 'Select',
  meta: {},
  disabled: false,
  isMulti: false,
  hideLabelOnSelect: false,
  dropDownRefComp: null,
  canType: true,
  grouping: false,
  tip: 'error tool-bottom-left',
  errorText: null,
  showError: true,
  apiCall: null,
  removeIcon: false,
  clearFieldOnSelect: false,
  listClassName: '',
  className: '',
  input: {},
  onSelect: () => {
    /* empty fun */
  },
};

export default FfDropdownInputGroup;
