import Immutable from 'immutable';
import { LOCATION_CHANGE } from 'react-router-redux';
import { typeCastResponse } from '../../utils/siteSpecificCommonUtils';
import { clientConstants } from '../constants/client.constants';

const initialState = Immutable.fromJS({
  isLoad: false,
  loadErr: null,
  message: null,
  companyUsers: [],
  companyUser: {},
  companyLocations: [],
  companyLocation: {},
  totalRecords: 0,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case clientConstants.LOAD:
      return state.set('isLoad', true).set('loadErr', null);
    case clientConstants.LOAD_SUCCESS:
      return state
        .set('isLoad', false)
        .set('loadErr', null)
        .set('message', typeCastResponse(action, 'message'));
    case clientConstants.LOAD_FAIL:
      return state.set('isLoad', false).set('loadErr', typeCastResponse(action, 'error'));
    case clientConstants.GET_ALL_CLIENTS:
      return state
        .set('companyUsers', typeCastResponse(action, 'data', 'array', []))
        .set('totalRecords', typeCastResponse(action, 'count', 'number', 0));
    case clientConstants.SET_CLIENT:
      return state.set('companyUser', typeCastResponse(action, 'companyUser', 'object', {}));
    case clientConstants.GET_ALL_LOCATIONS:
      return state
        .set('companyLocations', typeCastResponse(action, 'data', 'array', []))
        .set('totalRecords', typeCastResponse(action, 'count', 'number', 0));
    case clientConstants.SET_CLIENT_LOCATION:
      return state.set('companyLocation', typeCastResponse(action, 'location', 'object', {}));
    case clientConstants.RESET_MESSAGE:
      return state.set('message', null).set('loadErr', null).set('isLoad', null);
    case clientConstants.FLUSH:
      return initialState.set('message', null).set('loadErr', null).set('isLoad', null);
    case LOCATION_CHANGE:
      return state.set('message', null).set('loadErr', null).set('isLoad', null);
    // Default
    default:
      return state;
  }
}
