import { LOCATION_CHANGE } from 'react-router-redux';
import {
  CANDIDATES_AT_RISK_REQUEST,
  CANDIDATES_AT_RISK_SUCCESS,
  CANDIDATES_AT_RISK_FAILURE,
} from './actions';

const initialState = {};

const risk = (state = initialState, action) => {
  switch (action.type) {
    case CANDIDATES_AT_RISK_REQUEST:
    case CANDIDATES_AT_RISK_SUCCESS:
    case CANDIDATES_AT_RISK_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case LOCATION_CHANGE:
      return {
        ...state,
        err: null,
      };
    default:
      return state;
  }
};
export { risk };
