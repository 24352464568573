import axios from 'axios';
import store from 'store2';
import { addNoteRequest, addNoteSuccess, addNoteFailure } from './actions';
import { consoleOnlyInDebugMode } from '../../../../utils/debugging';
import {
  removeInValidKeyValuePairs,
  validObjectWithParameterKeys,
  strictValidStringWithMinLength,
  extractErrorMessage,
} from '../../../../utils/commonUtils';
import { errorConstants } from '../../../../redux/constants/error.constants';

// eslint-disable-next-line no-unused-vars
const addNote = (body, successCallback) => (dispatch) => {
  addNoteRequest(dispatch);

  const APIUrl =
    validObjectWithParameterKeys(body, ['lead']) && strictValidStringWithMinLength(body.lead)
      ? `${process.env.REACT_APP_APIHOST}/leads/note/`
      : `${process.env.REACT_APP_APIHOST}/notes/add-note`;

  axios
    .post(APIUrl, removeInValidKeyValuePairs(body), {
      headers: {
        'x-access-token': store.get('user').token,
      },
    })
    .then((res) => {
      addNoteSuccess(dispatch, res.data.data);
      successCallback();
    })
    .catch((err) => {
      const errMsg = err.response && err.response.data && err.response.data.message;
      addNoteFailure(dispatch, errMsg);
      dispatch({ type: errorConstants.SET_ERROR, error: extractErrorMessage(errMsg) });
      consoleOnlyInDebugMode('err-addNote', errMsg);
    });
};

export { addNote };
