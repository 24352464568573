const USERS_REQUEST = 'USERS_REQUEST';
const USERS__SUCCESS = 'USERS__SUCCESS';
const USERS__FAILURE = 'USERS__FAILURE';

const usersRequest = (dispatch) => {
  dispatch({ type: USERS_REQUEST, payload: { loading: true, loadErr: null } });
};

const usersSuccess = (dispatch, response) => {
  dispatch({
    type: USERS__SUCCESS,
    payload: { data: response, loading: false, loadErr: null },
  });
};

const usersFailure = (dispatch, loadErr) => {
  dispatch({ type: USERS__FAILURE, payload: { loading: false, loadErr } });
};

export { USERS_REQUEST, USERS__SUCCESS, USERS__FAILURE, usersFailure, usersRequest, usersSuccess };
