const PROCESSING = 'PLACEMENT_LIST_PROCESSING';
const SUCCESS = 'PLACEMENT_LIST_SUCCESS';
const FAILURE = 'PLACEMENT_LIST_FAILURE';

const processing = (dispatch) => {
  dispatch({ type: PROCESSING, payload: { loading: true } });
};

const success = (dispatch, response) => {
  dispatch({ type: SUCCESS, payload: { data: response, loading: false } });
};

const failure = (dispatch, response) => {
  dispatch({ type: FAILURE, payload: { loadError: response, loading: false } });
};

export { PROCESSING, SUCCESS, FAILURE, processing, success, failure };
