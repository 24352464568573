import { AJNA_TEAM_LIST_REQUEST, AJNA_TEAM_LIST_SUCCESS, AJNA_TEAM_LIST_FAILURE } from './actions';

const initialState = {};

const ajnaTeam = (state = initialState, action) => {
  switch (action.type) {
    case AJNA_TEAM_LIST_REQUEST:
    case AJNA_TEAM_LIST_SUCCESS:
    case AJNA_TEAM_LIST_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export { ajnaTeam };
