import React from 'react';
import PropTypes from 'prop-types';
import './pr-ico-eye.scss';
import passEyeClose from '../../../assets/icons/password_eye_closed.svg';
import passEye from '../../../assets/icons/password_eye.svg';

const PrIcoEyeComponent = ({ onClick: handleClick, inputType }) => {
  return (
    <div onClick={() => handleClick && handleClick()} className="pr-ico-eye-wrapper">
      {inputType === 'password' ? (
        <img width="27" src={passEyeClose} alt=""></img>
      ) : (
        <img width="27" src={passEye} alt=""></img>
      )}
    </div>
  );
};

PrIcoEyeComponent.propTypes = {
  onClick: PropTypes.func,
  inputType: PropTypes.string,
};

PrIcoEyeComponent.defaultProps = {
  onClick: undefined,
  inputType: 'text',
};

export default PrIcoEyeComponent;
