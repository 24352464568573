import { LOCATION_CHANGE } from 'react-router-redux';
import {
  TIMESHEETS_LIST_FAILURE,
  TIMESHEETS_LIST_PROCESSING,
  TIMESHEETS_LIST_SUCCESS,
  TIMESHEET_APPROVE_FAILURE,
  TIMESHEET_APPROVE_PROCESSING,
  TIMESHEET_APPROVE_SUCCESS,
  TIMESHEET_REJECT_FAILURE,
  TIMESHEET_REJECT_PROCESSING,
  TIMESHEET_REJECT_SUCCESS,
  TIMESHEET_ELIGIBILITY_FOR_PTO,
  CHECK_ALLOWED_PAYCODES_PROCESSING,
  CHECK_ALLOWED_PAYCODES_SUCCESS,
  CHECK_ALLOWED_PAYCODES_FAILURE,
} from './constants';

const initialState = {
  checkAllowedPaycodesError: '',
};

export const timesheetsList = (state = initialState, action) => {
  switch (action.type) {
    case TIMESHEETS_LIST_PROCESSING:
    case TIMESHEETS_LIST_SUCCESS:
    case TIMESHEETS_LIST_FAILURE:
    case TIMESHEET_APPROVE_FAILURE:
    case TIMESHEET_APPROVE_PROCESSING:
    case TIMESHEET_APPROVE_SUCCESS:
    case TIMESHEET_REJECT_FAILURE:
    case TIMESHEET_REJECT_PROCESSING:
    case TIMESHEET_REJECT_SUCCESS:
    case TIMESHEET_ELIGIBILITY_FOR_PTO:
      return {
        ...state,
        ...action.payload,
      };
    case CHECK_ALLOWED_PAYCODES_PROCESSING:
    case CHECK_ALLOWED_PAYCODES_SUCCESS:
      return {
        ...state,
        checkAllowedPaycodesError: '',
      }
    case CHECK_ALLOWED_PAYCODES_FAILURE:
      return {
        ...state,
        checkAllowedPaycodesError: action.error,
      }
    // Reset state on location change
    case LOCATION_CHANGE:
      return { ...state, loadError: null, checkAllowedPaycodesError: '' };
    default:
      return state;
  }
};
