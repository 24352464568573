/* eslint-disable */
import React, { Component } from 'react';
import { debounce } from 'lodash';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import PropTypes from 'prop-types';
import './style.scss';
import CustomPagination from './custom-pagination';
import { strictValidString } from '../../utils/commonUtils';

/**
 * This component is extended from React Table and customized as per the project need.
 * Data and Columns props has to be in React Table NPM supported format.
 * By default is makes all the columns searchable, sortable and resizable.
 * NOTE: Feel free to add new props whenever required making sure nothing breaks.
 */
class CustomReactTable extends Component {
  state = {
    initialCall: true,
    isInitialized: false,
  };

  constructor(props) {
    super(props);
  }

  componentWillReceiveProps(nextProps) {
    const { isInitialized } = this.state;
    if (nextProps.initialCall === false && !isInitialized) {
      this.setState({
        initialCall: false,
        isInitialized: true,
      });
    }
  }

  onFetchData = (state) => {
    const { initialCall } = this.state;
    const { callDataAPI, defaultSortKey } = this.props;
    let sortColumn = defaultSortKey;
    let sortType = 'desc';
    let search = {};
    const { page, pageSize, sorted, filtered } = state;
    if (sorted.length > 0) {
      sortColumn = sorted[0].id;
      sortType = sorted[0].desc ? 'desc' : 'asc';
    }
    if (filtered.length > 0) {
      search = {};
      filtered.map((item) => {
        search[item.id] = item.value;
        return item;
      });
      initialCall && callDataAPI(page + 1, pageSize, sortColumn, sortType, search);
    } else {
      initialCall && callDataAPI(page + 1, pageSize, sortColumn, sortType, search);
    }
    this.setState({
      initialCall: true,
    });
  };

  render() {
    const {
      defaultData: { data, count, loading: isLoading },
      columns,
      loading,
      defaultPageSize,
      getSortingDetails,
      getSearchingDetails,
      getPagingDetails,
      page,
      defaultSorted,
      className = '',
      defaultFiltered = []
    } = this.props;
    let pages = parseInt((count / defaultPageSize).toString().split('.')[0], 10) + 1;
    if (count % defaultPageSize === 0) {
      pages = count / defaultPageSize;
    }
    let extendedProps = {};
    if (typeof page === 'number') {
      extendedProps.page = page;
    }
    return (
      <>
        <ReactTable
          {...extendedProps}
          data={data}
          columns={columns}
          defaultPageSize={defaultPageSize}
          sortable
          multiSort
          resizable
          filterable
          getTdProps={() => ({
            style: {
              textAlign: 'left',
              whiteSpace: 'unset',
            },
          })}
          className={`-striped customReactTable ${className}`}
          loading={isLoading || loading}
          PaginationComponent={CustomPagination}
          pages={pages}
          getTheadThProps={(_state, _rowInfo, column) => {
            if (strictValidString(column.Header)) {
              return {
                title: column.Header,
              };
            } else {
              return {
                title: '',
              };
            }
          }}
          manual
          onFetchData={debounce(this.onFetchData, 500)}
          minRows={1}
          onSortedChange={getSortingDetails}
          onFilteredChange={getSearchingDetails}
          onPageChange={getPagingDetails}
          defaultSorted={defaultSorted}
          defaultFiltered={defaultFiltered}
        />
      </>
    );
  }
}

CustomReactTable.propTypes = {
  defaultData: PropTypes.objectOf(PropTypes.shape),
  columns: PropTypes.arrayOf(PropTypes.shape).isRequired,
  loading: PropTypes.bool,
  callDataAPI: PropTypes.func,
  defaultSortKey: PropTypes.string,
  defaultPageSize: PropTypes.number,
  getSortingDetails: PropTypes.func,
  getSearchingDetails: PropTypes.func,
  getPagingDetails: PropTypes.func,
  page: PropTypes.number,
};

CustomReactTable.defaultProps = {
  defaultData: {
    data: [],
    count: 0,
  },
  loading: false,
  defaultSortKey: 'id',
  defaultPageSize: 10,
  getSortingDetails: () => {/* empty fun */},
  getSearchingDetails: () => {/* empty fun */},
  getPagingDetails: () => {/* empty fun */},
  callDataAPI: () => {/* empty fun */},
  page: undefined,
};

export default CustomReactTable;
