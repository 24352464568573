import React, { useRef, useEffect, useCallback } from 'react';
import browser from 'browser-detect';
import './pr-data-input-2.scss';
import PrIcoCalendarComponent from '../pr-ico-calendar';
import PrIcoAddressComponent from '../pr-ico-address';
import PrIcoEyeComponent from '../pr-ico-eye';
import PrIcoArrowLinkComponent from '../pr-ico-arrow-link';

const PrDataInput2Component = ({
  type,
  isDisable,
  min,
  max,
  value,
  onChange,
  maxFlag,
  placeholder,
  error,
}) => {
  const inputEl = useRef(null);
  const parentInputEl = useRef(null);

  const onFocus = () => parentInputEl.current.classList.add('focused');

  const onBlur = useCallback((e) => {
    // Update coordinates
    if (e.target.value !== '') parentInputEl.current.classList.add('filled');
    else parentInputEl.current.classList.remove('filled');
    parentInputEl.current.classList.remove('focused');
  }, []);

  useEffect(() => {
    inputEl.current.addEventListener('blur', onBlur, false);
    inputEl.current.addEventListener('focus', onFocus, false);
    return () => {
      if (inputEl && inputEl.current) {
        inputEl.current.removeEventListener('focus', onFocus);
        inputEl.current.removeEventListener('blur', onBlur);
      }
    };
  }, []);

  const isAndroid = browser().os.indexOf('Android') === -1 ? false : true;
  let ico = null;
  if (type.indexOf('date') !== -1 && !isAndroid) {
    ico = (
      <div className="ico-wrapper">
        <PrIcoCalendarComponent></PrIcoCalendarComponent>
      </div>
    );
  } else if (type.indexOf('address') !== -1) {
    ico = (
      <div className="ico-wrapper">
        <PrIcoAddressComponent></PrIcoAddressComponent>
      </div>
    );
  } else if (type.indexOf('YR') !== -1) {
    ico = <p className="ico-wrapper text">/YR</p>;
  } else if (type.indexOf('dollar-ico') !== -1) {
    ico = <p className="ico-wrapper text">$</p>;
  } else if (type.indexOf('arrow-link-ico') !== -1) {
    ico = (
      <div className="ico-wrapper after-filling">
        {<PrIcoArrowLinkComponent></PrIcoArrowLinkComponent>}
      </div>
    );
  } else if (type.indexOf('eye-ico') !== -1) {
    ico = (
      <div className="ico-wrapper after-filling">
        <PrIcoEyeComponent></PrIcoEyeComponent>
      </div>
    );
  }

  let isIco = ico ? true : false;

  return (
    <div
      ref={parentInputEl}
      className={`pr-data-input-2-wrapper ${type} ${value ? 'filled' : ''} is-ico-${isIco} ${
        browser().name
      }`}
      tip="error tool-bottom-left"
      data-description={error}
      is-error={!!error ? 'true' : 'false'}
    >
      <input
        ref={inputEl}
        value={value}
        type={type.replace(/ .*/, '')}
        min={min}
        max={max}
        maxLength={maxFlag ? max : null}
        readOnly={!!isDisable ? true : false}
        spellCheck="false"
        onChange={(e) => onChange(e.target.value)}
      />
      <span>{placeholder}</span>
      {ico}
    </div>
  );
};

export default PrDataInput2Component;
