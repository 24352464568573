import React from 'react';

const Alert = ({ type, message, bsClassName, style }) => {
  let component = null;
  switch (type) {
    case 'error':
      component = (
        <div style={style} className="alert alert-danger">
          <div dangerouslySetInnerHTML={{ __html: message }} />
        </div>
      );
      break;
    case 'success':
      component = (
        <div style={style} className="alert alert-success">
          <div dangerouslySetInnerHTML={{ __html: message }} />
        </div>
      );
      break;
    case 'warning':
      component = (
        <div style={style} className="alert alert-warning">
          <div dangerouslySetInnerHTML={{ __html: message }} />
        </div>
      );
      break;
    case 'info':
      component = (
        <div style={style} className="alert alert-info">
          <div dangerouslySetInnerHTML={{ __html: message }} />
        </div>
      );
      break;
    case 'notification':
    default:
      component = (
        <div style={style} className={`alert ${bsClassName}`}>
          <div dangerouslySetInnerHTML={{ __html: message }} />
        </div>
      );
      break;
  }
  return component;
};

export default Alert;
