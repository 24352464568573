import createDecorator from 'final-form-focus';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import React, { useState, useEffect } from 'react';
import { Field, Form } from 'react-final-form';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { VALID_EMAIL } from '../../../../../utils/regexs';
import Alert from '../../../../../components/Alert';
import Loading from '../../../../../components/--primitives/pr-loading';
import { getTextFromHTML, HtmlEditor } from '../../../../../components/final-form/ff-HTML-editor';

import {
  validObjectWithParameterKeys,
  strictValidString,
  strictValidStringWithMinLength,
  strictValidArray,
  strictValidArrayWithLength,
  loadRoute,
  strictValidObjectWithKeys,
} from '../../../../../utils/commonUtils';
import { editNotificationContent, getNotificationContent } from './api';
import '../style.scss';
import validate from './validate';
import PrExpandCollapse from '../../../../../components/--primitives/pr-expand-collapse';
import PrButton from '../../../../../components/--primitives/pr-button';
import PrInfoButton from '../../../../../components/--primitives/pr-info-button';
import PrCheckbox from '../../../../../components/--primitives/pr-checkbox';
import FfDropdownInputGroup from '../../../../../components/final-form/ff-dropdown-input-group';
import FfAnimatedInput from '../../../../../components/final-form/ff-animated-input';
import FfDataTextArea from '../../../../../components/final-form/ff-data-textarea';
import FfMultiEmail from '../../../../../components/final-form/ff-multi-email';
import PrTooltipComponent from '../../../../../components/--primitives/pr-tooltip';

const focusOnErrors = createDecorator();

const mediaTypes = [
  { value: 'Email', label: 'Email' },
  { value: 'RTN', label: 'RTN' },
];

const candidate = [{ value: 'candidate', label: 'Candidate' }];
const clientUsers = [
  { value: 'client-admin', label: 'Client Admin' },
  { value: 'client-user', label: 'Client User' },
  { value: 'job-owner', label: 'Job Owner' },
  { value: 'invoice-contact', label: 'Invoice Contact' },
  { value: 'timesheet-approver', label: 'Timesheet Approver' },
];
const Roles = [
  { value: 'job-manager', label: 'Job Manager' },
  { value: 'account-manager', label: 'Account Manager' },
  { value: 'recruiter', label: 'Recruiter' },
  { value: 'verifier', label: 'Candidate Success Manager' },
  { value: 'payroll', label: 'Payroll' },
  { value: 'plc-verifier', label: 'CSM on PLC' },
];

const AllRoles = [...candidate, ...clientUsers, ...Roles];

const groupedOptions = [
  ...Roles.map((v) => ({ ...v, groupTitle: 'Roles', groupId: 0 })),
];
const groupedAllRoleOptions = [
  ...Roles.map((v) => ({ ...v, groupTitle: 'Roles', groupId: 0 })),
  ...clientUsers.map((v) => ({ ...v, groupTitle: 'Client Users', groupId: 1 })),
  ...candidate.map((v) => ({ ...v, groupTitle: 'Candidate', groupId: 2 })),
];

const initialState = {
  body: EditorState.createEmpty(),
  rtn_body: EditorState.createEmpty(),
  sender_email: [],
  to: [],
  cc: [],
  tag: { tagOptions: [] },
  to_field_required: true,
};

function EditAlertNotification({
  callGetNotification,
  alertNotification,
  callEditNotification,
  isLoading,
  // eslint-disable-next-line react/prop-types
  dispatch,
  error,
  match,
}) {
  const getAlertId = () => {
    const { params = {} } = match;
    const { alertId } = params;
    return alertId;
  };

  useEffect(() => {
    callGetNotification(getAlertId());
  }, []);

  const draftFromHtml = (html) => {
    let editorState;
    if (html) {
      const contentBlock = htmlToDraft(html);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        editorState = EditorState.createWithContent(contentState);
      } else {
        editorState = EditorState.createEmpty();
      }
    } else {
      editorState = EditorState.createEmpty();
    }
    return editorState;
  };

  const [initialData, setInitialData] = useState(initialState);
  const [tag, setTag] = useState([]);
  const [copy, copied] = useState('');
  const setDetail = () => {
    const {
      alert_type,
      body = '',
      rtn_body = '',
      sender_email,
      sender_name,
      cc_emails,
      bcc_emails,
      to_emails,
      to,
      cc,
      bcc,
      tags = {},
      to_field_required = true,
    } = alertNotification;
    const email = VALID_EMAIL.test(sender_email)
      ? { sender_email, sender_role: null }
      : {
          sender_role: AllRoles.find((v) => v.value === sender_email),
          sender_email: '',
        };
    const name = AllRoles.find((v) => v.value === sender_name)
      ? {
          sender_role: AllRoles.find((v) => v.value === sender_name),
          sender_name: '',
        }
      : { sender_name, sender_role: null };
    let tagsList = strictValidObjectWithKeys(tags) ? Object.keys(tags) : [];
    tagsList = tagsList.map((item) => ({
      value: item,
      label: item,
      tagOptions: tags[item].map((tagItem) => ({
        label: tagItem.name,
        value: tagItem.tag,
      })),
    }));

    setInitialData({
      ...initialState,
      ...alertNotification,
      ...email,
      ...name,
      alert_type: { value: alert_type, label: alert_type },
      body: draftFromHtml(body),
      rtn_body: draftFromHtml(rtn_body),
      tagsList,
      to: strictValidArrayWithLength(to) && to.map((v) => AllRoles.find((k) => k.value === v)),
      bcc: strictValidArrayWithLength(bcc) && bcc.map((v) => Roles.find((k) => k.value === v)),
      cc: strictValidArrayWithLength(cc) && cc.map((v) => AllRoles.find((k) => k.value === v)),
      bcc_emails:
        strictValidArray(bcc_emails) && bcc_emails.filter((v) => strictValidStringWithMinLength(v)),
      cc_emails:
        strictValidArray(cc_emails) && cc_emails.filter((v) => strictValidStringWithMinLength(v)),
      to_emails:
        strictValidArray(to_emails) && to_emails.filter((v) => strictValidStringWithMinLength(v)),
      to_field_required,
    });
  };
  useEffect(() => {
    setDetail();
  }, [alertNotification]);

  const onSubmit = (values) => {
    const {
      _id,
      alert_type,
      sender_email,
      sender_role,
      to = [],
      cc = [],
      bcc = [],
      to_emails = [],
      cc_emails = [],
      bcc_emails = [],
      body,
      rtn_body,
      status,
      sender_name,
      subject,
      rtn_subject,
      text_body,
      business_description,
      business_trigger,
      to_field_required,
    } = values;

    // eslint-disable-next-line no-console
    const windowLocation =
      (window && validObjectWithParameterKeys(window, ['location']) && window.location.origin) ||
      '';
    const Url = `${windowLocation}/notification-contents/`;
    const regForUrl = new RegExp(Url, 'g');

    let valuesToSend = {
      alert_type: alert_type.value,
      sender_email: strictValidString(sender_email) ? sender_email : sender_role.value,
      sender_name: strictValidString(sender_name) ? sender_name : sender_role.value,
      to: to_field_required
        ? (strictValidArrayWithLength(to) && to.map((role) => role.value)) || []
        : undefined,
      cc: (strictValidArrayWithLength(cc) && cc.map((role) => role.value)) || [],
      bcc: (strictValidArrayWithLength(bcc) && bcc.map((role) => role.value)) || [],
      to_emails: (strictValidArrayWithLength(to_emails) && to_emails) || [],
      cc_emails: (strictValidArrayWithLength(cc_emails) && cc_emails) || [],
      bcc_emails: (strictValidArrayWithLength(bcc_emails) && bcc_emails) || [],
      status,
      subject,
      rtn_subject,
      text_body,
      business_description,
      business_trigger,
    };

    if (strictValidStringWithMinLength(getTextFromHTML(body))) {
      const Body = body && draftToHtml(convertToRaw(body.getCurrentContent()));
      const replacedBody = Body.replace(regForUrl, '');
      // eslint-disable-next-line no-const-assign
      valuesToSend = {
        ...valuesToSend,
        body: replacedBody,
      };
    } else {
      valuesToSend = {
        ...valuesToSend,
        body: '',
      };
    }
    if (strictValidStringWithMinLength(getTextFromHTML(rtn_body))) {
      const RTNBody = rtn_body && draftToHtml(convertToRaw(rtn_body.getCurrentContent()));
      const replacedRTNBody = RTNBody.replace(regForUrl, '');
      // eslint-disable-next-line no-const-assign
      valuesToSend = {
        ...valuesToSend,
        rtn_body: replacedRTNBody,
      };
    } else {
      valuesToSend = {
        ...valuesToSend,
        rtn_body: '',
      };
    }

    // eslint-disable-next-line no-console
    callEditNotification(valuesToSend, _id);
  };

  return (
    <div className="card" id="edit-notification">
      {isLoading && <Loading />}
      {error && <Alert type="error" message={error} />}
      <div className="heading">
        <div className="card-title">Notification Detail</div>
      </div>
      <Form
        decorators={[focusOnErrors]}
        initialValues={initialData}
        onSubmit={onSubmit}
        validate={validate}
        render={(props) => {
          const { handleSubmit, values } = props;
          const { sender_email, sender_name, sender_role, } = values;
          return (
            <form>
              <div className="row grid-6 notification-grid">
                <div className="grid">
                  <div className="row grid-6 form-group">
                    <div className="row">
                      <Field
                        name="alert_id"
                        component={FfAnimatedInput}
                        type="text"
                        placeholder="Alert Id"
                        isDisable
                      />
                    </div>
                    <div className="row">
                      <Field
                        name="alert_type"
                        placeholder="Type"
                        component={FfDropdownInputGroup}
                        options={mediaTypes}
                      />
                    </div>
                  </div>
                  <div className="row grid form-group">
                    <Field
                      name="business_trigger"
                      component={FfAnimatedInput}
                      type="text"
                      placeholder="Alert Logic Description"
                      // isDisable
                    />
                  </div>
                  <div className="row">
                    <Field
                      name="business_description"
                      component={FfAnimatedInput}
                      placeholder="Alert Business Description"
                    />
                  </div>

                  <div className="row form-group" />
                  <PrExpandCollapse title="From Name">
                    <div className="row grid-6 form-group">
                      <div className="row">
                        <Field
                          name="sender_name"
                          placeholder="Sender Name"
                          component={FfAnimatedInput}
                          isDisable={values.sender_role}
                        />
                      </div>
                      <div className="row">
                        <Field
                          name="sender_role"
                          component={FfDropdownInputGroup}
                          options={groupedOptions}
                          grouping
                          removeIcon
                          placeholder="select..."
                          disabled={
                            strictValidString(sender_email) || strictValidString(sender_name)
                          }
                        />
                      </div>
                    </div>
                  </PrExpandCollapse>
                  <PrExpandCollapse title="From Email">
                    <div className="row grid-6 form-group">
                      <div className="row">
                        <Field
                          name="sender_email"
                          placeholder="Please enter email address here"
                          component={FfAnimatedInput}
                          isDisable={sender_role}
                        />
                      </div>
                      <div className="row">
                        <Field
                          name="sender_role"
                          component={FfDropdownInputGroup}
                          options={groupedOptions}
                          grouping
                          removeIcon
                          placeholder="select..."
                          disabled={
                            strictValidString(sender_email) || strictValidString(sender_name)
                          }
                        />
                      </div>
                    </div>
                  </PrExpandCollapse>
                  <PrExpandCollapse title="To Email">
                    <div className="row grid-6 form-group">
                      <div className="row">
                        <Field
                          placeholder="Please enter email address(es) here"
                          name="to_emails"
                          component={FfMultiEmail}
                        />
                      </div>
                      <div className="row">
                        <Field
                          name="to"
                          component={FfDropdownInputGroup}
                          placeholder="select..."
                          grouping
                          options={groupedAllRoleOptions}
                          isMulti
                        />
                      </div>
                    </div>
                  </PrExpandCollapse>
                  <PrExpandCollapse title="CC Email">
                    <div className="row grid-6 form-group">
                      <div className="row">
                        <Field
                          placeholder="Please enter email address(es) here"
                          name="cc_emails"
                          component={FfMultiEmail}
                        />
                      </div>
                      <div className="row">
                        <Field
                          name="cc"
                          component={FfDropdownInputGroup}
                          placeholder="select..."
                          options={groupedAllRoleOptions}
                          grouping
                          isMulti
                        />
                      </div>
                    </div>
                  </PrExpandCollapse>
                  <PrExpandCollapse title="BCC Email">
                    <div className="row grid-6 form-group">
                      <div className="row">
                        <Field
                          placeholder="Please enter email address(es) here"
                          name="bcc_emails"
                          component={FfMultiEmail}
                        />
                      </div>
                      <div className="row">
                        <Field
                          name="bcc"
                          component={FfDropdownInputGroup}
                          options={Roles}
                          placeholder="select..."
                          isMulti
                        />
                      </div>
                    </div>
                  </PrExpandCollapse>
                  <PrExpandCollapse title="Email">
                    <div className="row form-group">
                      <Field
                        name="subject"
                        component={FfAnimatedInput}
                        placeholder="Email Subject"
                        maxLength="140"
                        className="width-100"
                      />
                    </div>
                    <div className="grid form-group htmlEditor">
                      <label>Email Body</label>
                      <Field
                        name="body"
                        component={HtmlEditor}
                        defaultFontSize={12}
                        defaultFontFamily="Arial"
                      />
                    </div>
                  </PrExpandCollapse>
                  <PrExpandCollapse title="RTN">
                    <div className="row form-group">
                      <Field
                        name="rtn_subject"
                        placeholder="RTN Subject"
                        component={FfAnimatedInput}
                        maxLength="70"
                        className="width-100"
                      />
                    </div>
                    <div className="grid form-group htmlEditor">
                      <label>RTN Body</label>
                      <Field
                        name="rtn_body"
                        component={HtmlEditor}
                        defaultFontSize={12}
                        defaultFontFamily="Arial"
                      />
                    </div>
                  </PrExpandCollapse>
                  <PrExpandCollapse title="Text">
                    <div className="grid full-width form-group">
                      <label>Text Body</label>
                      <Field name="text_body" component={FfDataTextArea} />
                    </div>
                  </PrExpandCollapse>
                  <div className="row form-group">
                    <Field
                      name="status"
                      render={({ input }) => (
                        <div className="checkbox" onClick={() => input.onChange(!input.value)}>
                          <PrCheckbox active={input.value} />
                          <span>Enable?</span>
                        </div>
                      )}
                    />
                  </div>
                </div>
                <div className="grid" />
              </div>
              <div className="submit-button-group">
                <PrButton
                  value="Cancel"
                  onClick={() => loadRoute(dispatch, '/notification-contents')}
                  type="light-blue"
                />
                <PrButton type="light-blue" onClick={handleSubmit} value="Save" />
              </div>
            </form>
          );
        }}
      />
      <div className="grid affix">
        <div className="tags">
          <div className="">
            <label>
              Parameters
              <span className="tooltip-wrap">
                <PrInfoButton />
                <PrTooltipComponent
                  type="with-triangle large right black"
                  CTAsList={[
                    {
                      type: 'description',
                      name: 'You can copy and paste these parameters in Email subject, Email Body, RTN Subject, RTN Body and Text.',
                    },
                  ]}
                />
              </span>
            </label>
          </div>
          <FfDropdownInputGroup
            input={{
              value: { label: '', value: '' },
              onChange: (v) => setTag(v),
            }}
            options={initialData.tagsList || []}
          />
        </div>
        <ul
          className={
            strictValidObjectWithKeys(tag) &&
            validObjectWithParameterKeys(tag, ['tagOptions']) &&
            tag.tagOptions.length > 5
              ? 'overflow-scroll options view'
              : strictValidObjectWithKeys(tag) &&
                validObjectWithParameterKeys(tag, ['tagOptions']) &&
                'options view'
          }
        >
          {
            // eslint-disable-next-line react/prop-types
            strictValidObjectWithKeys(tag) &&
              validObjectWithParameterKeys(tag, ['tagOptions']) &&
              tag.tagOptions.map((item, i) => {
                const keyName = 'key-'+i;
                return (
                  <li key={keyName}>
                    <CopyToClipboard text={item.value} onCopy={() => copied(item.value)}>
                      <a>{item.label}</a>
                    </CopyToClipboard>
                    {copy === item.value && <span className="pill">Copied</span>}
                  </li>
                );
              })
          }
        </ul>
      </div>
    </div>
  );
}

EditAlertNotification.propTypes = {
  callGetNotification: PropTypes.func.isRequired,
  callEditNotification: PropTypes.func.isRequired,
  match: PropTypes.objectOf(PropTypes.shape).isRequired,
  alertNotification: PropTypes.objectOf(PropTypes.shape),
  isLoading: PropTypes.string,
  error: PropTypes.string,
};

EditAlertNotification.defaultProps = {
  alertNotification: {},
  isLoading: false,
  error: '',
};

const mapStateProps = (state) => {
  const {
    data: alertNotification = {},
    loading,
    error,
  } = state.get('notificationContent').get('notificationContent');
  return {
    alertNotification,
    isLoading: loading,
    error,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  callGetNotification: (...params) => dispatch(getNotificationContent(...params)),
  callEditNotification: (...params) => dispatch(editNotificationContent(...params)),
});

export default withRouter(connect(mapStateProps, mapDispatchToProps)(EditAlertNotification));
