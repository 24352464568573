import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CustomReactTable from '../../../../components/table/CustomReactTable';
import { notificationsSuccess } from '../actions';
import { getNotifications } from '../api';
import NotificationDetails from './detail-modal';
import { showDateTimeInListingWithoutTimezone } from '../../../../utils/stringsHelpers';
import PrButton from '../../../../components/--primitives/pr-button';
import PrAnimatedInput from '../../../../components/--primitives/pr-data-input-2';
import { Dropdown } from '../../../../components/final-form/ff-dropdown-input/ff-dropdown-input';
import { RoleFilterOptions } from '../../../../utils/siteSpecificConstants';

class NotificationsList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showDetailsModal: false,
      selectedNotification: {},
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    notificationsSuccess(dispatch, {});
  }

  renderInput = (filter, onChange) => (
    <PrAnimatedInput
      type="text"
      onChange={(value) => onChange(value)}
      value={filter ? filter.value : ''}
    />
  );

  renderRolFilter = ({ filter, onChange }) => (
    <Dropdown options={RoleFilterOptions} onChange={(val) => onChange(val)} />
  )

  rendersubjectContent = (props) => (
    <a
      className="make-link"
      onClick={() => {
        this.setState({ selectedNotification: props.original }, this.toggleModal);
      }}
    >
      {' '}
      {props.value}
    </a>
  )

  getColumns = () => [
    {
      Header: '#',
      accessor: 'id',
      minWidth: 10,
      Filter: ({ filter, onChange }) => this.renderInput(filter, onChange),
    },
    {
      Header: 'Sender',
      accessor: 'sender',
      minWidth: 20,
      Filter: ({ filter, onChange }) => this.renderInput(filter, onChange),
    },
    {
      Header: 'Role',
      accessor: 'role',
      minWidth: 27,
      Filter: (props) => this.renderRolFilter(props),
      Cell: (props) => {
        let res = null;
        switch (props.value) {
          case 'super-admin':
            res = 'Super Admin';
            break;
          case 'admin':
            res = 'Admin';
            break;
          case 'account-manager':
            res = 'Account Manager';
            break;
          case 'recruiter':
            res = 'Recruiter';
            break;
          case 'verifier':
            res = 'Candidate Success Manager';
            break;
          case 'client-admin':
            res = 'Client Admin';
            break;
          case 'client-user':
            res = 'Client User';
            break;
          case 'candidate':
            res = 'Candidate';
            break;
          default:
            break;
        }
        return res;
      },
    },
    {
      Header: 'Notification Type',
      accessor: 'type',
      minWidth: 15,
      Filter: ({ filter, onChange }) => this.renderInput(filter, onChange),
    },
    {
      Header: 'Receiver',
      accessor: 'receiver',
      minWidth: 15,
      Filter: ({ filter, onChange }) => this.renderInput(filter, onChange),
    },
    {
      Header: 'Subject',
      accessor: 'subject',
      minWidth: 50,
      Filter: ({ filter, onChange }) => this.renderInput(filter, onChange),
      Cell: (props) => this.rendersubjectContent(props),
    },
    {
      Header: 'Timestamp',
      accessor: 'createdAt',
      minWidth: 10,
      filterable: false,
      Cell: ({ value }) => showDateTimeInListingWithoutTimezone(value),
    },
  ];

  toggleModal = () => {
    const { showDetailsModal } = this.state;
    this.setState({ showDetailsModal: !showDetailsModal });
  };

  render() {
    const {
      dispatch,
      notifications,
      callGetNotificationsAPI,
      renderNotificationListing,
      jobId,
      clientId,
      interviewId,
      candidateId,
      companyName,
      jobTitle,
      candidateName,
      interviewIdToShow,
      timesheetId,
      // shouldShowAddOption,
      placementId,
    } = this.props;
    const { showDetailsModal, selectedNotification } = this.state;

    const columns = this.getColumns();
    const { count = 0 } = notifications;
    return (
      <div>
        <div className="row space-between vertical-center">
          <div className="col">
            <h2 className="card-title">Notifications</h2>
            <div className="card-subtitle">
              {`${count}`} {count > 1 ? 'results found' : 'result found'}
            </div>
          </div>
          <div className="col">
            <PrButton
              value="New Notification"
              type="light-blue medium tall"
              onClick={renderNotificationListing}
            />
          </div>
        </div>
        <p>&nbsp;</p>

        <CustomReactTable
          defaultData={notifications}
          className="overflow-filter"
          columns={columns}
          callDataAPI={(...parameters) =>
            callGetNotificationsAPI(
              jobId,
              clientId,
              interviewId,
              candidateId,
              timesheetId,
              placementId,
              ...parameters,
            )
          }
          defaultPageSize={25}
        />
        <NotificationDetails
          dispatch={dispatch}
          isOpen={showDetailsModal}
          toggleModal={this.toggleModal}
          notificationDetails={selectedNotification}
          companyName={companyName}
          jobTitle={jobTitle}
          candidateName={candidateName}
          interviewId={interviewIdToShow}
          jobId={jobId}
          companyId={clientId}
          interview_Id={interviewId}
          candidateId={candidateId}
          placementId={placementId}
        />
      </div>
    );
  }
}

NotificationsList.propTypes = {
  callGetNotificationsAPI: PropTypes.func.isRequired,
  notifications: PropTypes.objectOf(PropTypes.shape).isRequired,
  dispatch: PropTypes.func.isRequired,
  renderNotificationListing: PropTypes.func.isRequired,
  jobId: PropTypes.string,
  clientId: PropTypes.string,
  companyName: PropTypes.string,
  jobTitle: PropTypes.string,
  candidateName: PropTypes.string,
  interviewId: PropTypes.string,
  interviewIdToShow: PropTypes.number,
  timesheetId: PropTypes.string,
  candidateId: PropTypes.string,
  // shouldShowAddOption: PropTypes.bool,
  placementId: PropTypes.string,
};

NotificationsList.defaultProps = {
  companyName: undefined,
  jobTitle: undefined,
  jobId: undefined,
  clientId: undefined,
  candidateName: undefined,
  interviewId: undefined,
  candidateId: undefined,
  interviewIdToShow: undefined,
  timesheetId: undefined,
  // shouldShowAddOption: true,
  placementId: undefined,
};

const mapStateProps = (state) => {
  const { data: { rows = [], count = 0 } = {}, loading } = state.get('adminNotifications');
  const { data: company } = state.get('clientWorkBench').get('companyDetails');
  return {
    notifications: {
      data: rows,
      count,
      loading,
    },
    company,
    isLoading: loading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  callGetNotificationsAPI: (...params) => dispatch(getNotifications(...params)),
});

export default withRouter(connect(mapStateProps, mapDispatchToProps)(NotificationsList));
