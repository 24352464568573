import React from 'react';
import { Route, Redirect } from 'react-router';
import store from 'store2';
import AdminRoutes from './AdminRoutes';
import ScrollToTop from '../containers/App/scroll-to-top';

export function PrivateRoute({ component: Component, ...rest }) {
  const isAuthTokenFlag = !!store('SAAS_ADMIN_TOKEN');
  return (
    <Route
      {...rest}
      render={() =>
        isAuthTokenFlag ? (
          <>
            <ScrollToTop />
            <AdminRoutes />
          </>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
}
