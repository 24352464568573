export const socketConstants = {
  JOIN: 'join',
  NOTIFICATION_RECEIVED: 'noti_received',
  JOB_INVITE: 'job-invite',
  JOB_INVITE_ACCEPTED: 'job-invite-accepted',
  JOB_INVITE_REJECTED: 'job-invite-rejected',
  JOB_APPOINTMENT_REJECTED: 'job-appointment-rejected',
  JOB_APPOINTMENT_RESCHEDULE: 'job-appointment-reschedule',
  MSA_ALERT: 'msa-alert',
  CAN_NEED_AVAILABILITY: 'can-need-availability',
  INTERVIEW_SCHEDULED: 'interview-scheduled',
  INTERVIEW_SCHEDULED_CONFLICT: 'interview-scheduled-conflict',
  DISCONNECT: 'disconnect',
};
