import { validObjectWithParameterKeys, strictValidObjectWithKeys } from '../../utils/commonUtils';
import { configConstants, errorConstants } from '../constants';

/**
 * To tenant config data
 */
export const getTenantConfig = () => async (dispatch, getState, api) => {
  dispatch({ type: configConstants.LOAD });
  try {
    // Call api to fetch user data
    const res = await api.get('/tenant-config');

    // If not valid response object
    if (!validObjectWithParameterKeys(res, ['data']) && !strictValidObjectWithKeys(res.data)) {
      return false;
    }

    // Else if valid response continue
    dispatch({
      type: configConstants.TENANT_CONFIG,
      data: res.data,
    });
    dispatch({ type: configConstants.LOAD_SUCCESS });
  } catch (err) {
    // If an error occurs, set error field
    // dispatch({ type: commonConstants.SET_SIDEBAR_STATUS, status : false });
    dispatch({ type: configConstants.LOAD_FAIL });
  }
};
