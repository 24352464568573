import { successConstants } from '../constants/successMessage.constants';

export const setSuccessMessage = (message) => (dispatch) => {
  dispatch({
    type: successConstants.SET_SUCCESS_MESSAGE,
    message,
  });
};

export const flushSuccessMessage = () => (dispatch) => {
  dispatch({
    type: successConstants.FLUSH_SUCCESS_MESSAGE,
  });
};
