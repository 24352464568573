import { addAttachmentRequest, addAttachmentSuccess, addAttachmentFailure } from './actions';
import { errorConstants, successConstants } from '../../../../redux/constants';

const APIUrl = `${process.env.REACT_APP_APIHOST}/attachments`;

const addAttachment = (data) => async (dispatch, getState, api) => {
  addAttachmentRequest(dispatch);
  try {
    const res = await api.post(APIUrl, { data: data });
    addAttachmentSuccess(dispatch, res.message);
    dispatch({ type: successConstants.SET_SUCCESS_MESSAGE, message: res.message });
    return res;
  } catch (error) {
    addAttachmentFailure(dispatch, error);
    dispatch({ type: errorConstants.SET_ERROR, error: extractErrorMessage(error) });
    return false;
  }
};

export { addAttachment };
