import { LOCATION_CHANGE } from 'react-router-redux';
import {
  NOTIFICATION_CONTENTS_REQUEST,
  NOTIFICATION_CONTENTS_SUCCESS,
  NOTIFICATION_CONTENTS_FAILURE,
} from './actions';

const initialState = {};

const notificationContents = (state = initialState, action) => {
  switch (action.type) {
    case NOTIFICATION_CONTENTS_REQUEST:
    case NOTIFICATION_CONTENTS_SUCCESS:
    case NOTIFICATION_CONTENTS_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case LOCATION_CHANGE:
      return {
        data: {},
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};

export default notificationContents;
