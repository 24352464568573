const AJNA_TEAM_LIST_REQUEST = 'AJNA_TEAM_LIST_REQUEST';
const AJNA_TEAM_LIST_SUCCESS = 'AJNA_TEAM_LIST_SUCCESS';
const AJNA_TEAM_LIST_FAILURE = 'AJNA_TEAM_LIST_FAILURE';

const ajnaTeamListRequest = (dispatch) => {
  dispatch({ type: AJNA_TEAM_LIST_REQUEST, payload: { loading: true } });
};

const ajnaTeamListSuccess = (dispatch, response) => {
  dispatch({
    type: AJNA_TEAM_LIST_SUCCESS,
    payload: { data: response, loading: false },
  });
};

const ajnaTeamListFailure = (dispatch) => {
  dispatch({ type: AJNA_TEAM_LIST_FAILURE, payload: { loading: false } });
};

export {
  AJNA_TEAM_LIST_REQUEST,
  AJNA_TEAM_LIST_SUCCESS,
  AJNA_TEAM_LIST_FAILURE,
  ajnaTeamListRequest,
  ajnaTeamListSuccess,
  ajnaTeamListFailure,
};
