import React from 'react';
import PropType from 'prop-types';
import './pr-x-mark.scss';

const PrXMarkComponent = ({ type, onClick: handleClick }) => {
  return (
    <div
      className={`pr-x-mark-wrapper ${type ? type : ''}`}
      onClick={() => handleClick && handleClick()}
    >
      <span className="x-mark"></span>
      <span className="mask-wrapper">
        <span className="mask"></span>
        <span className="x-mark"></span>
      </span>
    </div>
  );
};

PrXMarkComponent.propTypes = {
  type: PropType.string,
  onClick: PropType.func,
};

PrXMarkComponent.defaultProps = {
  type: '',
  onClick: undefined,
};

export default PrXMarkComponent;
