/* eslint-disable no-console */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getCompanyData } from './api';
import { updateCompany } from '../../Add/api';
import './style.scss';
import {
  loadRoute,
  addhttp,
  normalizePhone,
  createNavigationLink,
  strictValidString,
  importImagesFromImageDirectory,
} from '../../../../../utils/commonUtils';
import { getFormattedDateTime } from '../../../../../utils/stringsHelpers';
import { createIdUsingWordsInPhrase } from '../../../../../utils/tests';
import DocumentTitleComponent from '../../../../../components/DocumentTitleComponent';
import Loading from '../../../../../components/--primitives/pr-loading';
import PrCheckbox from '../../../../../components/--primitives/pr-checkbox';

const images = importImagesFromImageDirectory(
  require.context('../../../../../assets/icons', false, /\.(png|jpe?g|svg)$/),
);
const Image = importImagesFromImageDirectory(
  require.context('../../../../../assets/option_icons', false, /\.(png|jpe?g|svg)$/),
);

class CommonComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_in_business: false,
      is_delinquent_payment: false,
    };
  }

  componentDidMount() {
    const { callDetailsAPI } = this.props;
    callDetailsAPI(this.getCompanyId());
  }

  getCompanyId = () => {
    const { match = {} } = this.props;
    const { params = {} } = match;
    const { id } = params;
    return id;
  };

  renderAnItem = (title, data) => (
    <>
      <p className="headtitle" title={title}>
        {title}
      </p>
      <div
        id={createIdUsingWordsInPhrase(title)}
        className="output_D"
        title={strictValidString(data) ? data : ''}
      >
        {data}
      </div>
    </>
  );

  renderStatusCell = (status) => {
    switch (Number(status)) {
      case 1:
        return 'Active';
      case 2:
        return 'New';
      case 3:
        return 'On Hold';
      case 5:
        return 'DNC';
      default:
        return 'Inactive';
    }
  };

  componentDidUpdate(prevProps) {
    const { company } = this.props;
    const { is_in_business, is_delinquent_payment } = this.state;
    if (
      is_in_business !== company.is_in_business ||
      is_delinquent_payment !== company.is_delinquent_payment
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        is_in_business: company.is_in_business,
        is_delinquent_payment: company.is_delinquent_payment,
      });
    }

    const {
      location: { state: oldState },
    } = prevProps;
    const {
      location: { state },
      callDetailsAPI,
    } = this.props;
    if (state && oldState !== state) {
      callDetailsAPI(this.getCompanyId());
    }
  }

  onChangeCheckBox = async (event) => {
    const { company, updateCompanyDetails } = this.props;
    const params = {
      [event.name]: event.value,
    };
    await updateCompanyDetails(company._id, params, this.onUpdateCompanyDetails);
  };

  onUpdateCompanyDetails = () => {
    const { callDetailsAPI, company } = this.props;
    callDetailsAPI(company._id);
  };

  render() {
    const { company, loading, dispatch, action, tenantConfig } = this.props;
    const { is_in_business, is_delinquent_payment } = this.state;
    const {
      id = '',
      name = '',
      is_verified,
      msa_signed,
      address = '',
      address_2 = '',
      city = '',
      state = '',
      createdAt = '',
      updatedAt = '',
      account_manager = '',
      created_by = '',
      updated_by = '',
      users,
      status,
      office_phone = '',
      website = '',
      background_paid_by_premier,
      x18_character_record_id,
      uses_a_vms = false,
    } = company;
    const enableFinancial = tenantConfig.finance;
    return (
      <>
        <div className="card client-details">
          {!loading && <DocumentTitleComponent title={name} />}
          {loading && <Loading type="overlay" />}
          <div className="row">
            <div className="col">
              <h2 className="card-title">Clients</h2>
            </div>
          </div>
          <h3 className="main-title">Client Details</h3>
          <div className="row grid-6">
            <div className="row grid-6">
              <div className="grid">
                <div className="col">{this.renderAnItem('ID', id)}</div>
                <div className="col">
                  {this.renderAnItem('Phone', normalizePhone(office_phone))}
                </div>
                <div className="col">
                  {this.renderAnItem(
                    'Street Address',
                    address_2 ? `${address}, ${address_2}` : address,
                  )}
                </div>
                <div className="col">{this.renderAnItem('City', city)}</div>
                <div className="col">{this.renderAnItem('State', state)}</div>
                <div className="col">{this.renderAnItem('Users', users)}</div>
              </div>
              <div className="grid">
                <div className="col">
                  <p className="headtitle" title="Client Name">
                    Client
                  </p>
                  <div className="output_D" title={name}>
                    <div>
                      {createNavigationLink(`/client-dashboard/${this.getCompanyId()}`, name)}
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div>
                    <p className="headtitle">Website</p>
                    <div className="output_D">
                      <a id="website" href={addhttp(website)} target="_blank" rel="noreferrer">
                        {addhttp(website)}
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col">
                  {this.renderAnItem('Status', this.renderStatusCell(status))}
                </div>

                <div className="col">
                  {this.renderAnItem(
                    'MSA Signed',
                    <i
                      id="msa_signed"
                      style={
                        msa_signed
                          ? { fontSize: '13px', color: '#189B05' }
                          : { fontSize: '13px', color: '#E32956' }
                      }
                      className={msa_signed ? 'fas fa-check' : 'fas fa-times'}
                    />,
                  )}
                </div>

                <div className="col">
                  {this.renderAnItem(
                    'Verified',
                    <i
                      id="verified"
                      style={
                        is_verified
                          ? { fontSize: '13px', color: '#189B05' }
                          : { fontSize: '13px', color: '#E32956' }
                      }
                      className={is_verified ? 'fas fa-check' : 'fas fa-times'}
                    />,
                  )}
                </div>
                <div className="col">
                  {this.renderAnItem(
                    'Background Check Paid by Tenant',
                    <i
                      id="background_paid_by_premier"
                      style={
                        background_paid_by_premier
                          ? { fontSize: '13px', color: '#189B05' }
                          : { fontSize: '13px', color: '#E32956' }
                      }
                      className={background_paid_by_premier ? 'fas fa-check' : 'fas fa-times'}
                    />,
                  )}
                </div>

                {x18_character_record_id && (
                  <div className="col">
                    {this.renderAnItem('Legacy CRM ID', x18_character_record_id)}
                  </div>
                )}
              </div>
            </div>
            <div className="row grid-6">
              <div className="grid borderLeftRight">
                <h3>Recruiting Team</h3>
                <div className="row grid-6">
                  {this.renderAnItem('Account Manager', account_manager)}
                </div>
                <div className="row">&nbsp;</div>
                <div className="row">&nbsp;</div>
                <div className="row">&nbsp;</div>
                <div className="row">&nbsp;</div>
                <div className="row">&nbsp;</div>
                <div className="row">&nbsp;</div>
                <div className="row">&nbsp;</div>
              </div>
              <div className="grid">
                <h3>Timestamp</h3>
                <div className="row grid-6">
                  {this.renderAnItem('Created On', getFormattedDateTime(createdAt))}
                </div>
                <div className="row grid-6">{this.renderAnItem('Created By', created_by)}</div>
                <div className="row grid-6 borderTop">
                  {this.renderAnItem('Last Updated On', getFormattedDateTime(updatedAt))}
                </div>
                <div className="row grid-6">{this.renderAnItem('Last Updated By', updated_by)}</div>
              </div>
            </div>
          </div>
          <div className="row grid-6 borderTop">
            <div className="row grid-6">
              <div
                className="row checkbox"
                onClick={(e) =>
                  this.onChangeCheckBox({
                    name: 'is_in_business',
                    value: !is_in_business,
                  })
                }
              >
                <PrCheckbox active={is_in_business} />
                <span>No Longer in Business</span>
              </div>
              <div
                className="row checkbox"
                onClick={(e) =>
                  this.onChangeCheckBox({
                    name: 'is_delinquent_payment',
                    value: !is_delinquent_payment,
                  })
                }
              >
                <PrCheckbox active={is_delinquent_payment} />
                <span>Delinquent on Payment</span>
              </div>
            </div>
          </div>
        </div>
        <div className="card client-info">
          <div className="row">
            <div
              className="client-info-one"
              onClick={() =>
                loadRoute(dispatch, `/job-list-view/?companies=${this.getCompanyId()}`)
              }
            >
              <div className="client-info-img">
                <img src={images['icon-experience.svg']} alt="Placements" />
                <img src={Image['jobsinflightWorkBench-white.svg']} alt="jobs" />
              </div>
              <p>Jobs</p>
            </div>
            <div
              className="client-info-one"
              onClick={() =>
                loadRoute(dispatch, `/interviews-list-view?companies[0]=${this.getCompanyId()}`)
              }
            >
              <div className="client-info-img">
                <img src={Image['interviews.svg']} alt="Placements" />
                <img src={Image['interviews-white.svg']} alt="Interviews" />
              </div>
              <p>Interviews</p>
            </div>
            <div
              className="client-info-one"
              onClick={() =>
                loadRoute(dispatch, `/placement-list-view?companies[0]=${this.getCompanyId()}`)
              }
            >
              <div className="client-info-img">
                <img src={images['book-open-icon.svg']} alt="Placements" />
                <img src={images['book-open-icon-white.svg']} alt="Placements" />
              </div>
              <p>Placements</p>
            </div>
            {enableFinancial && (
              <>
                <div
                  className="client-info-one"
                  onClick={() =>
                    loadRoute(dispatch, `/timesheet-list-view/?companies=${this.getCompanyId()}`)
                  }
                >
                  <div className="client-info-img">
                    <img src={images['layers-icon.svg']} alt="Timesheets" />
                    <img src={images['layers-icon-white.svg']} alt="Timesheets" />
                  </div>
                  <p>Timesheets</p>
                </div>
                <div
                  className="client-info-one"
                  onClick={() =>
                    loadRoute(dispatch, `/invoices-list-view?companies[0]=${this.getCompanyId()}`)
                  }
                >
                  <div className="client-info-img">
                    <img src={images['scroll-icon.svg']} alt="Invoices" />
                    <img src={images['scroll-icon-white.svg']} alt="Invoices" />
                  </div>
                  <p>Invoices</p>
                </div>
              </>
            )}
            <div className="divider" />
            <div
              className={`client-info-one ${action === 'details' ? 'active' : ''}`}
              onClick={() => loadRoute(dispatch, `/client-dashboard/${this.getCompanyId()}`)}
            >
              <div className="client-info-img">
                <img src={images['file-icon.svg']} alt="Users" />
                <img src={images['file-icon-white.svg']} alt="Users" />
              </div>
              <p>Client Dashboard</p>
            </div>
            <div
              className={`client-info-one ${action === 'location' ? 'active' : ''}`}
              onClick={() =>
                loadRoute(dispatch, `/client-functions/locations/${this.getCompanyId()}`)
              }
            >
              <div className="client-info-img">
                <img src={images['map-pin-icon.svg']} alt="Locations" />
                <img src={images['map-pin-icon-white.svg']} alt="Locations" />
              </div>
              <p>Locations</p>
            </div>
            <div
              className={`client-info-one ${action === 'domain' ? 'active' : ''}`}
              onClick={() =>
                loadRoute(dispatch, `/client-functions/domains/${this.getCompanyId()}`)
              }
            >
              <div className="client-info-img">
                <img src={images['server-icon.svg']} alt="Domains" />
                <img src={images['server-icon-white.svg']} alt="Domains" />
              </div>
              <p>Domains</p>
            </div>
            <div
              className={`client-info-one ${action === 'user' ? 'active' : ''} ${
                uses_a_vms && 'disabled'
              }`}
              onClick={() =>
                !uses_a_vms &&
                loadRoute(dispatch, `/client-functions/manage-users/${this.getCompanyId()}`)
              }
            >
              <div className="client-info-img">
                <img src={images['users-icon.svg']} alt="Users" />
                <img src={images['users-icon-white.svg']} alt="Users" />
              </div>
              <p>Users</p>
            </div>
            <div
              className={`client-info-one ${action === 'profile' ? 'active' : ''}`}
              onClick={() =>
                loadRoute(dispatch, `/client-functions/profile/${this.getCompanyId()}/1`)
              }
            >
              <div className="client-info-img">
                <img src={images['layout-icon.svg']} alt="Company Profile" />
                <img src={images['layout-icon-white.svg']} alt="Company Profile" />
              </div>
              <p>Company Profile</p>
            </div>
            <div
              className={`client-info-one ${action === 'team' ? 'active' : ''}`}
              onClick={() =>
                loadRoute(dispatch, `/clients-list-view/ajnateam/${this.getCompanyId()}`)
              }
            >
              <div className="client-info-img">
                <img src={images['ajna-icon.svg']} alt="Recruiting Team" />
                <img src={images['ajna-icon-white.svg']} alt="Recruiting Team" />
              </div>
              <p>Recruiting Team</p>
            </div>
            <div className="divider" />
            <div
              className={`client-info-one ${action === 'notes' ? 'active' : ''}`}
              onClick={() => loadRoute(dispatch, `/client-functions/${this.getCompanyId()}/notes`)}
            >
              <div className="client-info-img">
                <img src={images['clipboard-icon.svg']} alt="Notes" />
                <img src={images['clipboard-icon-white.svg']} alt="Notes" />
              </div>
              <p>Notes</p>
            </div>
            <div
              className={`client-info-one ${action === 'activities' ? 'active' : ''}`}
              onClick={() =>
                loadRoute(dispatch, `/client-functions/${this.getCompanyId()}/activities`)
              }
            >
              <div className="client-info-img">
                <img src={images['file-text-icon.svg']} alt="System Activity" />
                <img src={images['file-text-icon-white.svg']} alt="System Activity" />
              </div>
              <p>System Activity</p>
            </div>
            <div
              className={`client-info-one ${action === 'notifications' ? 'active' : ''}`}
              onClick={() =>
                loadRoute(dispatch, `/client-functions/${this.getCompanyId()}/notifications`)
              }
            >
              <div className="client-info-img">
                <img src={images['bell-blue-icon.svg']} alt="Notifications" />
                <img src={images['bell-blue-icon-white.svg']} alt="Notifications" />
              </div>
              <p>Notifications</p>
            </div>
            <div
              className={`client-info-one ${action === 'logs' ? 'active' : ''}`}
              onClick={() => loadRoute(dispatch, `/client-functions/${this.getCompanyId()}/logs`)}
            >
              <div className="client-info-img">
                <img src={images['edit-icon.svg']} alt="Edit Logs" />
                <img src={images['edit-icon-white.svg']} alt="Edit Logs" />
              </div>
              <p>Edit Logs</p>
            </div>
            <div
              className={`client-info-one ${action === 'attachments' ? 'active' : ''}`}
              onClick={() =>
                loadRoute(dispatch, `/client-functions/${this.getCompanyId()}/attachments`)
              }
            >
              <div className="client-info-img">
                <img src={images['paperclip-icon.svg']} alt="Attachment" />
                <img src={images['paperclip-icon-white.svg']} alt="Attachment" />
              </div>
              <p>Attachments</p>
            </div>
          </div>
        </div>
      </>
    );
  }
}

CommonComponent.propTypes = {
  match: PropTypes.objectOf(PropTypes.shape).isRequired,
  callDetailsAPI: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  company: PropTypes.objectOf(PropTypes.shape),
  updateCompanyDetails: PropTypes.func.isRequired,
  location: PropTypes.shape({ state: PropTypes.string }).isRequired,
  loading: PropTypes.bool,
  field_permission: PropTypes.objectOf(PropTypes.shape),
};

CommonComponent.defaultProps = {
  company: {},
  loading: false,
  field_permission: {},
};

const mapStateProps = (state) => {
  const { data, loading, field_permission } = state.get('clientWorkBench').get('companyDetails');
  return {
    company: data,
    loading,
    field_permission,
    tenantConfig: state.get('config').get('tenantConfig'),
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  callDetailsAPI: (...params) => dispatch(getCompanyData(...params)),
  updateCompanyDetails: (...params) => dispatch(updateCompany(...params)),
});

const Common = withRouter(connect(mapStateProps, mapDispatchToProps)(CommonComponent));

export default Common;
