import Immutable from 'immutable';
import { LOCATION_CHANGE } from 'react-router-redux';
import { candidateListConstants } from './constants';
import { typeCastResponse } from '../../../../utils/siteSpecificCommonUtils';

const initialState = Immutable.fromJS({
  candidates: {},
  isLoad: true,
  loadErr: null,
  message: null,
  filters: {},
  searchString: null,
});

export const candidatesList = (state = initialState, action) => {
  switch (action.type) {
    case candidateListConstants.LOAD:
      return state.set('isLoad', true).set('loadErr', null);
    case candidateListConstants.LOAD_SUCCESS:
      return state
        .set('isLoad', false)
        .set('loadErr', null)
        .set('message', typeCastResponse(action, 'message'));
    case candidateListConstants.LOAD_FAIL:
      return state.set('isLoad', false).set('loadErr', typeCastResponse(action, 'error'));
    // Reset state on location change
    case LOCATION_CHANGE:
      return state.set('message', null).set('loadErr', null);
    case candidateListConstants.SET_SEARCH_STRING:
      return state.set('searchString', typeCastResponse(action, 'data', 'string', ''));
    case candidateListConstants.GET_CANDIDATES_LIST:
      return state
        .set('candidates', typeCastResponse(action, 'data', 'object', {}))
        .set('filters', typeCastResponse(action, 'filters', 'object', {}));
    case candidateListConstants.SET_SETTINGS:
      return state
        .set('jobFunctions', typeCastResponse(action, 'jobFunctions', 'array', []))
        .set('regions', typeCastResponse(action, 'regions', 'array', []));
    default:
      return state;
  }
};
