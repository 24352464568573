const RAC_LIST_REQUEST = 'RAC_LIST_REQUEST';
const RAC_LIST_SUCCESS = 'RAC_LIST_SUCCESS';
const RAC_LIST_FAILURE = 'RAC_LIST_FAILURE';
const RAC_LIST_TAB_DATA = 'RAC_LIST_TAB_DATA';

const racListRequest = (dispatch) => {
  dispatch({ type: RAC_LIST_REQUEST, payload: { loading: true } });
};

const racListSuccess = (dispatch, response) => {
  dispatch({
    type: RAC_LIST_SUCCESS,
    payload: { data: response, loading: false },
  });
};

const racListFailure = (dispatch) => {
  dispatch({ type: RAC_LIST_FAILURE, payload: { loading: false } });
};

const racListTabData = (dispatch, data = {}) => {
  dispatch({ type: RAC_LIST_TAB_DATA, payload: data });
};

const RECOMMEND_REQUEST = 'RECOMMEND_REQUEST';
const RECOMMEND_SUCCESS = 'RECOMMEND_SUCCESS';
const RECOMMEND_FAILURE = 'RECOMMEND_FAILURE';

const recommendRequest = (dispatch) => {
  dispatch({ type: RAC_LIST_REQUEST, payload: { loading: true } });
};

const recommendSuccess = (dispatch, response) => {
  dispatch({
    type: RAC_LIST_SUCCESS,
    payload: { data: response, loading: false },
  });
};

const recommendFailure = (dispatch) => {
  dispatch({ type: RAC_LIST_FAILURE, payload: { loading: false } });
};

const SC_LIST_REQUEST = 'SC_LIST_REQUEST';
const SC_LIST_SUCCESS = 'SC_LIST_SUCCESS';
const SC_LIST_FAILURE = 'SC_LIST_FAILURE';

const suggestedCandidateRequest = (dispatch) => {
  dispatch({ type: SC_LIST_REQUEST, payload: { loading: true } });
};

const suggestedCandidateSuccess = (dispatch, response) => {
  dispatch({
    type: SC_LIST_SUCCESS,
    payload: { data: response, loading: false },
  });
};

const suggestedCandidateFailure = (dispatch) => {
  dispatch({ type: SC_LIST_FAILURE, payload: { loading: false } });
};

export {
  RAC_LIST_REQUEST,
  RAC_LIST_SUCCESS,
  RAC_LIST_FAILURE,
  RAC_LIST_TAB_DATA,
  racListRequest,
  racListSuccess,
  racListFailure,
  RECOMMEND_REQUEST,
  RECOMMEND_SUCCESS,
  RECOMMEND_FAILURE,
  recommendRequest,
  recommendSuccess,
  recommendFailure,
  SC_LIST_REQUEST,
  SC_LIST_SUCCESS,
  SC_LIST_FAILURE,
  suggestedCandidateFailure,
  suggestedCandidateRequest,
  suggestedCandidateSuccess,
  racListTabData,
};
