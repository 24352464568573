/* eslint-disable no-console */
import Immutable from 'immutable';
import { LOCATION_CHANGE } from 'react-router-redux';
import {
  addPlacementConstant,
  CLIENTS_PROCESSING,
  CLIENTS_SUCCESS,
  CLIENTS_FAILURE,
  JOBS_PROCESSING,
  JOBS_SUCCESS,
  JOBS_FAILURE,
  EMPLOYEES_PROCESSING,
  EMPLOYEES_SUCCESS,
  EMPLOYEES_FAILURE,
  DEPARTMENTS_PROCESSING,
  DEPARTMENTS_SUCCESS,
  DEPARTMENTS_FAILURE,
  RULES_AND_HOLIDAY_CALENDAR_PROCESSING,
  RULES_AND_HOLIDAY_CALENDAR_SUCCESS,
  RULES_AND_HOLIDAY_CALENDAR_FAILURE,
  ADD_PLACEMENT_PROCESSING,
  ADD_PLACEMENT_SUCCESS,
  ADD_PLACEMENT_FAILURE,
  PLACEMENT_DETAIL_PROCESSING,
  PLACEMENT_DETAIL_SUCCESS,
  PLACEMENT_DETAIL_FAILURE,
  JOB_MANAGERS_PROCESSING,
  JOB_MANAGERS_SUCCESS,
  JOB_MANAGERS_FAILURE,
  RECRUITERS_PROCESSING,
  RECRUITERS_SUCCESS,
  RECRUITERS_FAILURE,
  VERIFIERS_PROCESSING,
  VERIFIERS_SUCCESS,
  VERIFIERS_FAILURE,
  VERSIONS_PROCESSING,
  VERSIONS_SUCCESS,
  VERSIONS_FAILURE,
} from './constants';
import { typeCastResponse } from '../../../../utils/siteSpecificCommonUtils';

const initialState = [];

const jobs = (state = initialState, action) => {
  switch (action.type) {
    case JOBS_PROCESSING:
    case JOBS_SUCCESS:
    case JOBS_FAILURE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const clients = (state = initialState, action) => {
  switch (action.type) {
    case CLIENTS_PROCESSING:
    case CLIENTS_SUCCESS:
    case CLIENTS_FAILURE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const employees = (state = initialState, action) => {
  switch (action.type) {
    case EMPLOYEES_PROCESSING:
    case EMPLOYEES_SUCCESS:
    case EMPLOYEES_FAILURE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const departments = (state = initialState, action) => {
  switch (action.type) {
    case DEPARTMENTS_PROCESSING:
    case DEPARTMENTS_SUCCESS:
    case DEPARTMENTS_FAILURE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const rulesAndHolidayCalendar = (state = initialState, action) => {
  switch (action.type) {
    case RULES_AND_HOLIDAY_CALENDAR_PROCESSING:
    case RULES_AND_HOLIDAY_CALENDAR_SUCCESS:
    case RULES_AND_HOLIDAY_CALENDAR_FAILURE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const addPlacement = (state = initialState, action) => {
  switch (action.type) {
    case ADD_PLACEMENT_PROCESSING:
    case ADD_PLACEMENT_SUCCESS:
    case ADD_PLACEMENT_FAILURE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const placementDetail = (state = {}, action) => {
  switch (action.type) {
    case PLACEMENT_DETAIL_PROCESSING:
    case PLACEMENT_DETAIL_SUCCESS:
    case PLACEMENT_DETAIL_FAILURE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const versions = (state = {}, action) => {
  switch (action.type) {
    case VERSIONS_PROCESSING:
    case VERSIONS_SUCCESS:
    case VERSIONS_FAILURE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const jobManagers = (state = initialState, action) => {
  switch (action.type) {
    case JOB_MANAGERS_PROCESSING:
    case JOB_MANAGERS_SUCCESS:
    case JOB_MANAGERS_FAILURE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const recruiters = (state = initialState, action) => {
  switch (action.type) {
    case RECRUITERS_PROCESSING:
    case RECRUITERS_SUCCESS:
    case RECRUITERS_FAILURE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const verifiers = (state = initialState, action) => {
  switch (action.type) {
    case VERIFIERS_PROCESSING:
    case VERIFIERS_SUCCESS:
    case VERIFIERS_FAILURE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const initialStateT = Immutable.fromJS({
  isLoad: false,
  isLoadInline: false,
  loadErr: null,
  message: null,
  clientArray: [],
  jobArray: [],
  candidateArray: [],
  clientDetail: {},
  jobDetail: {},
  candidateDetail: {},
  newPlacement: {},
});

const AddPlacementPage = (state = initialStateT, action) => {
  switch (action.type) {
    case addPlacementConstant.LOAD:
      return state.set('isLoad', true).set('loadErr', null);
    case addPlacementConstant.LOAD_INLINE:
      return state.set('isLoadInline', true).set('loadErr', null);
    case addPlacementConstant.LOAD_SUCCESS:
      return state
        .set('isLoad', false)
        .set('isLoadInline', false)
        .set('loadErr', null)
        .set('message', typeCastResponse(action, 'message'));
    case addPlacementConstant.LOAD_FAIL:
      return state
        .set('isLoad', false)
        .set('isLoadInline', false)
        .set('message', null)
        .set('loadErr', typeCastResponse(action, 'error'));
    case addPlacementConstant.GET_CLIENTS_ARRAY:
      return state.set('clientArray', typeCastResponse(action, 'payload', 'array', []));
    case addPlacementConstant.GET_CANDIDATES_ARRAY:
      return state.set('candidateArray', typeCastResponse(action, 'payload', 'array', []));
    case addPlacementConstant.GET_JOB_ARRAY:
      return state.set('jobArray', typeCastResponse(action, 'payload', 'array', []));
    case addPlacementConstant.GET_RULES_AND_HOLIDAY_CALENDER:
      return state.set('users', typeCastResponse(action, 'users', 'array', []));
    case addPlacementConstant.GET_CANDIDATE_DETAIL:
      return state.set('candidateDetail', typeCastResponse(action, 'payload', 'object', {}));
    case addPlacementConstant.GET_JOB_DETAIL:
      return state.set('jobDetail', typeCastResponse(action, 'payload', 'object', {}));
    case addPlacementConstant.GET_CLIENT_DETAIL:
      return state.set('clientDetail', typeCastResponse(action, 'payload', 'object', {}));
    case addPlacementConstant.ADD_NEW_PLACEMENT:
      return state.set('newPlacement', typeCastResponse(action, 'payload', 'object', {}));
    // Reset state on location change
    case LOCATION_CHANGE:
      return state
        .set('isLoad', false)
        .set('isLoadInline', false)
        .set('loadErr', null)
        .set('message', null)
        .set('clientArray', [])
        .set('jobArray', [])
        .set('candidateArray', [])
        .set('clientDetail', {})
        .set('jobDetail', {})
        .set('candidateDetail', {})
        .set('newPlacement', {});
    default:
      return state;
  }
};

export {
  jobs,
  clients,
  employees,
  departments,
  rulesAndHolidayCalendar,
  addPlacement,
  placementDetail,
  jobManagers,
  recruiters,
  verifiers,
  AddPlacementPage,
  versions,
};
