import Immutable from 'immutable';
import { LOCATION_CHANGE } from 'react-router-redux';
import { typeCastResponse } from '../../../../utils/siteSpecificCommonUtils';
import { editPlacementConstant } from './constants';

const initialState = Immutable.fromJS({
  isLoad: false,
  isLoadInline: false,
  loadErr: null,
  message: null,
  validateDate: {},
});

export const EditPlacementPage = (state = initialState, action) => {
  switch (action.type) {
    case editPlacementConstant.LOAD:
      return state.set('isLoad', true).set('loadErr', null);
    case editPlacementConstant.LOAD_INLINE:
      return state.set('isLoadInline', true).set('loadErr', null);
    case editPlacementConstant.LOAD_SUCCESS:
      return state
        .set('isLoad', false)
        .set('isLoadInline', false)
        .set('loadErr', null)
        .set('message', typeCastResponse(action, 'message'));
    case editPlacementConstant.LOAD_FAIL:
      return state
        .set('isLoad', false)
        .set('isLoadInline', false)
        .set('message', null)
        .set('loadErr', typeCastResponse(action, 'error'));
    case editPlacementConstant.VALIDATE_DATE:
      return state.set('validateDate', typeCastResponse(action, 'payload', 'object', {}));
    // Reset state on location change
    case LOCATION_CHANGE:
      return state.set('message', null).set('loadErr', null);
    default:
      return state;
  }
};
