import {
  TRANSACTIONS_LOGS_REQUEST,
  TRANSACTIONS_LOGS__SUCCESS,
  TRANSACTIONS_LOGS__FAILURE,
} from './actions';

const initialState = {};

const transactionsLogs = (state = initialState, action) => {
  switch (action.type) {
    case TRANSACTIONS_LOGS_REQUEST:
    case TRANSACTIONS_LOGS__FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case TRANSACTIONS_LOGS__SUCCESS:
      return {
        ...state,
        loading: action.payload.loading,
        [action.payload.transactionId]: action.payload.data,
      };
    default:
      return state;
  }
};

export { transactionsLogs };
