import {
  IMPACT_STATISTICS_REQUEST,
  IMPACT_STATISTICS_SUCCESS,
  IMPACT_STATISTICS_FAILURE,
} from './actions';

const initialState = {};

const statistics = (state = initialState, action) => {
  switch (action.type) {
    case IMPACT_STATISTICS_REQUEST:
    case IMPACT_STATISTICS_SUCCESS:
    case IMPACT_STATISTICS_FAILURE:
      return {
        ...state,
        [action.payload.type]: {
          data: action.payload.response,
          loading: action.payload.loading,
        },
      };
    default:
      return state;
  }
};
export { statistics };
