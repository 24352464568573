const NOTIFICATION_CONTENTS_REQUEST = 'NOTIFICATION_CONTENTS_REQUEST';
const NOTIFICATION_CONTENTS_SUCCESS = 'NOTIFICATION_CONTENTS_SUCCESS';
const NOTIFICATION_CONTENTS_FAILURE = 'NOTIFICATION_CONTENTS_FAILURE';

const notificationRequest = (dispatch) => {
  dispatch({ type: NOTIFICATION_CONTENTS_REQUEST, payload: { loading: true } });
};

const notificationSuccess = (dispatch, data) => {
  dispatch({
    type: NOTIFICATION_CONTENTS_SUCCESS,
    payload: { data, loading: false },
  });
};

const notificationFailure = (dispatch, error) => {
  dispatch({
    type: NOTIFICATION_CONTENTS_FAILURE,
    payload: { error, loading: false },
  });
};

export {
  NOTIFICATION_CONTENTS_REQUEST,
  NOTIFICATION_CONTENTS_SUCCESS,
  NOTIFICATION_CONTENTS_FAILURE,
  notificationRequest,
  notificationSuccess,
  notificationFailure,
};
