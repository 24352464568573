const ADD_COMPANY_REQUEST = 'ADD_COMPANY_REQUEST';
const ADD_COMPANY_SUCCESS = 'ADD_COMPANY_SUCCESS';
const ADD_COMPANY_FAILURE = 'ADD_COMPANY_FAILURE';
const HOLIDAY_CALENDAR_PROCESSING = 'HOLIDAY_CALENDAR_PROCESSING';
const HOLIDAY_CALENDAR_SUCCESS = 'HOLIDAY_CALENDAR_SUCCESS';
const HOLIDAY_CALENDAR_FAILURE = 'HOLIDAY_CALENDAR_FAILURE';

const addCompanyRequest = (dispatch) => {
  dispatch({
    type: ADD_COMPANY_REQUEST,
    payload: { loading: true, error: undefined },
  });
};

const addCompanySuccess = (dispatch, response) => {
  dispatch({
    type: ADD_COMPANY_SUCCESS,
    payload: { data: response, loading: false, error: undefined },
  });
};

const addCompanyFailure = (dispatch, error) => {
  dispatch({ type: ADD_COMPANY_FAILURE, payload: { loading: false, error } });
};

const holidayCalendarProcessing = (dispatch) => {
  dispatch({ type: HOLIDAY_CALENDAR_PROCESSING });
};

const holidayCalendarSuccess = (dispatch, response) => {
  dispatch({ type: HOLIDAY_CALENDAR_SUCCESS, payload: { data: response } });
};

const holidayCalendarFailure = (dispatch, error) => {
  dispatch({ type: HOLIDAY_CALENDAR_FAILURE, payload: { error } });
};

export {
  ADD_COMPANY_REQUEST,
  ADD_COMPANY_SUCCESS,
  ADD_COMPANY_FAILURE,
  addCompanyRequest,
  addCompanySuccess,
  addCompanyFailure,
  HOLIDAY_CALENDAR_PROCESSING,
  HOLIDAY_CALENDAR_SUCCESS,
  HOLIDAY_CALENDAR_FAILURE,
  holidayCalendarProcessing,
  holidayCalendarSuccess,
  holidayCalendarFailure,
};
