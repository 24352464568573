import Immutable from 'immutable';
import { LOCATION_CHANGE } from 'react-router-redux';
import { candidateDashboardConstants } from './constants';
import { typeCastResponse } from '../../../../utils/siteSpecificCommonUtils';

const initialState = Immutable.fromJS({
  isLoad: false,
  isLoadInline: false,
  loadErr: null,
  loadErrInline: null,
  message: null,
  candidate: {},
  clientSlotsForJobPerCandidate: [],
  candidateId: null,
  candidateFieldPermission: null,
  verifiers: [],
  jobs: {
    rows: [],
    count: 0,
    loading: false,
  },
  jobs_liked: {
    rows: [],
    count: 0,
    loading: false,
  },
  suggestedJobs: {
    rows: [],
    count: 0,
    loading: false,
  },
  interviews: {
    rows: [],
    count: 0,
    loading: false,
  },
  keySkills: {
    loading: false,
  },
  recommendedJobs: {
    rows: [],
    count: 0,
    loading: false,
  },
  availability: [],
  invitations: [],
  matchedJobs: {
    count: 0,
    rows: [],
  },
  likedJobs: {
    count: 0,
    rows: [],
  },
  experiences: {},
  job: {},
  cultureAndValuesQuestions: [],
  selectedValues: {},
  jobFunctionQuestions: [],
  verticalizationData: [],
});

export const candidateDashboard = (state = initialState, action) => {
  switch (action.type) {
    case candidateDashboardConstants.LOAD:
      return state.set('isLoad', true).set('loadErr', null).set('loadErrInline', null);
    case candidateDashboardConstants.LOAD_INLINE:
      return state.set('isLoadInline', true).set('loadErr', null).set('loadErrInline', null);
    case candidateDashboardConstants.LOAD_KEY_SKILL:
      return state.set('keySkills', { loading: true }).set('loadErr', null);
    case candidateDashboardConstants.LOAD_SUCCESS:
      return state
        .set('isLoad', false)
        .set('isLoadInline', false)
        .set('loadErr', null)
        .set('loadErrInline', null)
        .set('keySkills', { loading: false })
        .set('message', typeCastResponse(action, 'message'));
    case candidateDashboardConstants.LOAD_FAIL:
      return state
        .set('isLoadInline', false)
        .set('isLoad', false)
        .set('keySkills', { loading: false })
        .set('loadErr', typeCastResponse(action, 'error'));
    case candidateDashboardConstants.LOAD_FAIL_INNER:
      return state
        .set('isLoadInline', false)
        .set('isLoad', false)
        .set('keySkills', { loading: false })
        .set('loadErrInline', typeCastResponse(action, 'error'));
    case candidateDashboardConstants.RESET_MESSAGE:
      return state
        .set('isLoadInline', false)
        .set('isLoad', false)
        .set('loadErrInline', null)
        .set('loadErr', null);
    case candidateDashboardConstants.GET_JOB_FUNCTION_QUESTIONS:
      return state
        .set('jobFunctionQuestions', typeCastResponse(action, 'data', 'array', []))
        .set('jobFunctionTitle', typeCastResponse(action, 'title', 'object', {}));
    case candidateDashboardConstants.GET_JOB_VERTICALIZATION_DATA:
      return state.set('verticalizationData', typeCastResponse(action, 'data', 'array', []));
    // Reset state on location change
    case LOCATION_CHANGE:
      return state
        .set('message', null)
        .set('loadErr', null)
        .set('jobFunctionQuestions', [])
        .set('verticalizationData', []);
    case candidateDashboardConstants.SET_EXPERIENCES_DATA:
      return state.set('experiences', typeCastResponse(action, 'experiences', 'object', {}));
    case candidateDashboardConstants.SET_CANDIDATE:
      return state
        .set('candidate', typeCastResponse(action, 'data', 'object', {}))
        .set('candidateId', typeCastResponse(action.data, '_id', 'string', ''));
    case candidateDashboardConstants.CANDIDATE_FIELD_PERMISSIONS:
      return state.set('candidateFieldPermission', typeCastResponse(action, 'data', 'object', {}));
    case candidateDashboardConstants.SET_VERIFIERS:
      return state.set('verifiers', typeCastResponse(action, 'data', 'array', []));
    case candidateDashboardConstants.SET_CANDIDATE_JOBS:
      return state.set('jobs', typeCastResponse(action, 'data', 'object', {}));
    case candidateDashboardConstants.SET_CANDIDATE_JOBS_LIKED:
      return state.set('jobs_liked', typeCastResponse(action, 'data', 'object', {}));
    case candidateDashboardConstants.SET_CANDIDATE_SUGGESTED_JOBS:
      return state.set('suggestedJobs', typeCastResponse(action, 'data', 'object', {}));
    case candidateDashboardConstants.SET_CANDIDATE_INTERVIEWS:
      return state.set('interviews', typeCastResponse(action, 'data', 'object', {}));
    case candidateDashboardConstants.SET_CANDIDATE_RECOMMENDED_JOBS:
      return state.set('recommendedJobs', typeCastResponse(action, 'data', 'object', {}));
    case candidateDashboardConstants.SET_CANDIDATE_AVAILABILITY:
      return state
        .set('availability', typeCastResponse(action, 'availability', 'array', {}))
        .set('invitations', typeCastResponse(action, 'invitations', 'array', {}))
        .set('appointments_count', typeCastResponse(action, 'appointments_count', 'number', 0));
    case candidateDashboardConstants.SET_MATCHED_JOBS:
      return state.set('matchedJobs', typeCastResponse(action, 'data', 'object', {}));
    case candidateDashboardConstants.SET_LIKED_JOBS:
      return state.set('likedJobs', typeCastResponse(action, 'data', 'object', {}));
    case candidateDashboardConstants.GET_QUESTIONS:
      return state.set('cultureAndValuesQuestions', typeCastResponse(action, 'data', 'array', []));
    case candidateDashboardConstants.SAVE_SELECTED_VALUES:
      return state.set('selectedValues', typeCastResponse(action, 'selectedValues', 'object', {}));
    case candidateDashboardConstants.CANDIDATE_SLOTS:
      return state.set(
        'clientSlotsForJobPerCandidate',
        typeCastResponse(action, 'data', 'array', []),
      );
    case candidateDashboardConstants.SET_JOB:
      return state.set('job', typeCastResponse(action, 'data', 'object', {}));
    case candidateDashboardConstants.FLUSH:
      return initialState;
    case LOCATION_CHANGE:
      return state.set('message', null).set('loadErr', null).set('loadErrInline', null);
    default:
      return state;
  }
};
