import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import '../style.scss';
import { Link } from 'react-router-dom';
import { DATE_FORMAT_MDY_WITH_TIME } from '../../../../utils/siteSpecificConstants';
import { Popup, PopupHeader, PopupBody } from '../../../../components/--primitives/pr-popup';

function NotificationDetails({
  dispatch,
  isOpen,
  toggleModal,
  notificationDetails,
  companyName,
  jobTitle,
  interviewId,
  candidateName,
  jobId,
  companyId,
  interview_Id,
  candidateId,
}) {
  const { subject = '', body = '', createdAt, receiver, sender } = notificationDetails;
  return (
    <Popup scrollContent className="Notifications-details" isOpen={isOpen}>
      <PopupHeader close toggle={toggleModal}>
        <div className="title">Notification Details</div>
      </PopupHeader>
      <PopupBody>
        <div className="row grid-6">
          {companyName && (
            <div className="row grid-6">
              <div className="grid">
                <strong>Client :</strong>
              </div>
              <div className="grid">
                <Link className="make-link" target="_blank" to={`/client-dashboard/${companyId}`}>
                  {companyName}
                </Link>
              </div>
            </div>
          )}
          {candidateName && (
            <div className="row grid-6">
              <div className="grid">
                <strong>Candidate :</strong>
              </div>
              <div className="grid">
                <Link
                  className="make-link"
                  target="_blank"
                  to={`/candidate-dashboard/${candidateId}`}
                >
                  {candidateName}
                </Link>
              </div>
            </div>
          )}
        </div>
        {(jobTitle || interviewId) && (
          <div className="row grid-6">
            {jobTitle && (
              <div className="row grid-6">
                <div className="grid">
                  <strong>Job :</strong>
                </div>
                <div className="grid">
                  <Link className="make-link" target="_blank" to={`/job/${jobId}/1`}>
                    {jobTitle}
                  </Link>
                </div>
              </div>
            )}
            {interviewId && (
              <div className="row grid-6">
                <div className="grid">
                  <strong>Interview ID :</strong>
                </div>
                <div className="grid">
                  <Link className="make-link" to={`/interview-dashboard/${interview_Id}/details`}>
                    {interviewId}
                  </Link>
                </div>
              </div>
            )}
          </div>
        )}
        <hr />
        <div className="row grid-6 info-header">
          <div className="row grid-6">
            <div className="grid">
              <strong>From :</strong>
            </div>
            <div className="grid">{sender}</div>
          </div>
          <div className="row grid-6">
            <div className="grid">
              <strong>To:</strong>
            </div>
            <div className="grid">
              <div className="reciever-text">{receiver}</div>
              <div className="dateTime">
                {moment(new Date(createdAt)).format(DATE_FORMAT_MDY_WITH_TIME)}
              </div>
            </div>
          </div>
        </div>
        <div className="notes-content row vertical-start">
          <div>
            <strong>Subject :</strong>
          </div>
          <div>
            <div dangerouslySetInnerHTML={{ __html: subject }} />
          </div>
        </div>
        <div className="notes-content row vertical-start">
          <div>
            <strong>Body :</strong>
          </div>
          <div>
            <div dangerouslySetInnerHTML={{ __html: body }} />
          </div>
        </div>
      </PopupBody>
    </Popup>
  );
}

NotificationDetails.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  notificationDetails: PropTypes.objectOf(PropTypes.shape).isRequired,
  companyName: PropTypes.string,
  jobTitle: PropTypes.string,
  interviewId: PropTypes.number,
  candidateName: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  companyId: PropTypes.string,
  jobId: PropTypes.string,
  candidateId: PropTypes.string,
  interview_Id: PropTypes.string,
};

NotificationDetails.defaultProps = {
  companyName: undefined,
  jobTitle: undefined,
  interviewId: undefined,
  candidateName: undefined,
  companyId: undefined,
  jobId: undefined,
  candidateId: undefined,
  interview_Id: undefined,
};

export default NotificationDetails;
