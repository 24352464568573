/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { readNotification, notificationDetail } from '../../redux/actions';
import {
  typeCastToKeyValueObject,
  strictValidArrayWithLength,
  strictValidObjectWithKeys,
} from '../../utils/commonUtils';
import Origin from '../Origin';
import NormalMessage from './Types/NormalMessage';
import ClientUserStatusChanged from './Types/ClientUserStatusChanged';
import { messages } from '../../language';
import './style.scss';
import Loading from '../../components/--primitives/pr-loading';
import {
  DEFAULT_TIMEZONE,
  DATE_FORMAT_MDY,
} from '../../utils/siteSpecificConstants';
import { Popup, PopupFooter } from '../../components/--primitives/pr-popup';

const defaultModal = {
  size: 'md',
  className: 'signmodel',
};
class Notifications extends Origin {
  constructor(props) {
    super(props);
    this.notificationEl = React.createRef();
    this.popupRef = React.createRef();
  }

  state = {
    dropdownOpen: false,
    profileDetails: false,
    viewNotificationModal: false,
    notification: null,
    isModalOpenFlag: false,
    toOpenComponent: null,
    isRead: false,
    modal: defaultModal,
  };

  componentDidMount() {
    window.document.addEventListener('click', this.handleDocumentClick);
  }

  componentWillUnmount() {
    window.document.removeEventListener('click', this.handleDocumentClick);
  }

  handleDocumentClick = (event) => {
    const { onAction } = this.props;
    const dataModal =
      this.notificationEl &&
      this.notificationEl.current &&
      this.notificationEl.current.getAttribute('data-modal') === 'true';

    const ignoreEl = event.target && event.target.getAttribute('data-ignore') === 'true';

    if (
      this.notificationEl.current &&
      !this.notificationEl.current.contains(event.target) &&
      !dataModal &&
      !ignoreEl
    ) {
      onAction();
    }
  };

  onChangeModal = async (modal) => {
    await this.setState({ modal });
  };

  // Toggle modal
  openNotification = async (type, _id) => {
    const {
      dispatch,
      notifications,
    } = this.props;
    const notification = notifications.find((v) => v._id === _id);
    // To Updated the unread count
    this.setState(
      {
        isRead: true,
        notification,
      },
      async () => {
        await dispatch(readNotification(_id));
        await dispatch(notificationDetail(_id));
        let component = null;
        if(type === 'client-user-status-changed'){
          component = (
            <ClientUserStatusChanged
              onClose={this.closeNotification}
              details={notification}
              onChangeModal={this.onChangeModal}
            />
          );
        }else{
          component = <NormalMessage onClose={this.closeNotification} />;
        }
        this.setState({
          viewNotificationModal: true,
          toOpenComponent: component,
        });
      },
    );
  };

  // Close modal
  closeNotification = async (_action, forceClose) => {
    const { onAction } = this.props;
    const { viewNotificationModal } = this.state;
    this.setState({
      viewNotificationModal: !forceClose ? !viewNotificationModal : false,
      modal: defaultModal,
    });
    if (onAction) {
      onAction(false);
    }
  };

  // Renders the header segment
  render() {
    const { notifications, loadingNotifications } = this.props;
    const { viewNotificationModal, toOpenComponent, notification } = this.state;
    // Render the  segment
    return (
      <>
        {strictValidObjectWithKeys(notification) && (
          <Popup
            popupRef={this.popupRef}
            scrollContent
            className="notification-content"
            // popupRef={ref}
            isOpen={viewNotificationModal}
          >
            {toOpenComponent}
            <PopupFooter>
              <p className="date">
                {moment(notification.createdAt).tz(DEFAULT_TIMEZONE).format(DATE_FORMAT_MDY)}
              </p>
              <p className="time">
                {moment(notification.createdAt).tz(DEFAULT_TIMEZONE).format('hh:mm a')}
              </p>
            </PopupFooter>
          </Popup>
        )}
        <ul
          ref={this.notificationEl}
          data-modal={viewNotificationModal ? 'true' : 'false'}
          className="notifications-list-wrapper"
        >
          <p className="title">Notifications</p>
          {loadingNotifications && (
            <li className="loading-inner">
              <Loading className="loading" type="button" color="#4a85fb" />
            </li>
          )}
          {!loadingNotifications &&
            strictValidArrayWithLength(notifications) &&
            notifications.map((item) => {
              const { _id, subject, createdAt, is_read, type, sender_name = '' } = item;
              return (
                <li
                  key={_id}
                  className={!is_read ? 'unread' : ''}
                  onClick={() => this.openNotification(type, _id)}
                >
                  <div className="title">
                    <div className={`indicator ${!is_read ? 'active' : ''}`} />
                    <p dangerouslySetInnerHTML={{ __html: sender_name }} />
                  </div>
                  <p className="description" dangerouslySetInnerHTML={{ __html: subject }} />
                  <div className="date">
                    <p className="date">
                      {moment(createdAt).tz(DEFAULT_TIMEZONE).format(DATE_FORMAT_MDY)}
                    </p>
                    <p className="time">
                      {moment(createdAt).tz(DEFAULT_TIMEZONE).format('hh:mm a')}
                    </p>
                  </div>
                </li>
              );
            })}
          {!loadingNotifications && !strictValidArrayWithLength(notifications) && (
            <li className="message">{messages.NO_NEW_NOTIFICATIONS}</li>
          )}
        </ul>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  job: typeCastToKeyValueObject(state.get('jobs').get('job')),
  notifications: state.get('notifications').get('notifications'),
});

export default connect(mapStateToProps)(Notifications);
