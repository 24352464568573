import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import DropZone from 'react-dropzone'; // eslint-disable-line
import FileUploader from './file-uploader'; // eslint-disable-line
import { strictValidArray, strictValidObjectWithKeys } from '../../utils/commonUtils';
import Alert from '../--primitives/pr-alert';
import ErrorMsg from './error-msg-popup';

const initialThanksmodalState = {
  message: '',
  show: false,
};

export default class S3FileUploader extends React.Component {
  constructor(props) {
    super(props);
    this.dropzoneRef = createRef();
  }

  static get propTypes() {
    return {
      fileName: PropTypes.string,
      signingUrl: PropTypes.string.isRequired,
      onFileUploadStarted: PropTypes.func,
      onFileUpload: PropTypes.func.isRequired,
      resetOnComplete: PropTypes.func,
      toShowContent: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      allowedMimeTypes: PropTypes.array,
    };
  }

  static defaultProps = {
    toShowContent: ' Drag & Drop or Select a file to upload',
    toShowContentStyle: {
      borderWidth: '2px',
      borderColor: '#000',
      borderStyle: 'none',
      borderRadius: '2px',
      width: '100%',
    },
  };

  static get contextTypes() {
    return {
      store: PropTypes.object,
    };
  }

  state = {
    files: [],
    error: null,
    errorModel: initialThanksmodalState,
  };

  // Handles on file drop
  onDrop = (acceptedFiles) => {
    const { files } = this.state;
    const { allowedMimeTypes, onErrorChange, onChangeInfo, onFileUploadStarted, uploadMultiple } =
      this.props;
    const rejectedFiles = [];
    // error on uploadMultiple files if uploadMultiple key value is false
    if (acceptedFiles.length > 1 && !uploadMultiple) {
      const errorMsg = 'Uploaded more then one file';
      this.setState({ error: errorMsg, errorModel: { message: errorMsg, show: true } });
      return false;
    }
    // Check if file is in allowedMimeTypes, if allowedMimeTypes is not defined or empty ignore it
    const filesToUpload = acceptedFiles.filter((file) => {
      const isValidMimeTypes =
        allowedMimeTypes && Array.isArray(allowedMimeTypes) && !!allowedMimeTypes.length;
      let isFileAllowedFlag = !isValidMimeTypes;
      if (isValidMimeTypes) {
        const type = file.type || (file.name && `.${file.name.split('.').pop()}`);
        for (const element of allowedMimeTypes) {
          if (type && type.toLowerCase().includes(element.toLowerCase())) {
            isFileAllowedFlag = true;
            break;
          }
        }
      }
      if (!isFileAllowedFlag) rejectedFiles.push(file);
      return isFileAllowedFlag;
    });
    // Show error if there are files rejected
    if (rejectedFiles.length && allowedMimeTypes.length) {
      const allowType = allowedMimeTypes.filter(
        (v) =>
          v !== 'application/msword' &&
          v !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' &&
          v !== 'application/pdf',
      );

      const lastMimeType = strictValidArray(allowType) ? allowType[allowType.length - 1] : '';
      const allowedMimeTypeText =
        `${allowType
          .filter((_v, k) => k !== allowType.length - 1)
          .map((val) => {
            let v = val;
            if (v.charAt(0) === '.') {
              v = v.substr(1);
            }
            v = v.toUpperCase();
            return v;
          })
          .join(', ')}` +
        ` ${allowType.length > 1 ? 'or' : ''}` +
        ` ${
          lastMimeType && lastMimeType.charAt(0) === '.'
            ? lastMimeType.substr(1).toUpperCase()
            : lastMimeType.toUpperCase()
        }`;
      // Handle error messages
      let errorMsg = '';
      if (onErrorChange && typeof onErrorChange === 'function') {
        const errorInfo = onChangeInfo;
        if (strictValidObjectWithKeys(errorInfo)) {
          errorInfo.error = `Please upload a valid ${allowedMimeTypeText} file`;
          errorMsg = errorInfo.error;
        }
        onErrorChange('uploadFileErr', errorInfo);
      } else {
        errorMsg = `Please upload a valid ${allowedMimeTypeText} file`;
        this.setState({
          error: errorMsg,
        });
      }
      this.setState({ errorModel: { message: errorMsg, show: true } });
    } else if (onErrorChange && typeof onErrorChange === 'function') {
      const errorInfo = onChangeInfo;
      if (strictValidObjectWithKeys(errorInfo)) {
        errorInfo.error = null;
      }
      onErrorChange('uploadFileErr', errorInfo);
    } else {
      this.setState({
        error: null,
      });
    }
    this.setState(
      {
        files: files.concat(filesToUpload),
      },
      () => {
        if (onFileUploadStarted && typeof onFileUploadStarted === 'function') {
          onFileUploadStarted();
        }
      },
    );
  };

  // Handles on upload completed
  uploadCompleted = (data) => {
    const { resetOnComplete } = this.props;
    let { files } = this.state;
    files = files.filter((file) => data.map((v) => v.name).indexOf(file.name) <= -1);
    this.setState({ files });
    if (!files.length && resetOnComplete && typeof resetOnComplete === 'function') {
      resetOnComplete(data);
    }
  };

  // Renders the S3FileUploader
  render() {
    const {
      signingUrl,
      onFileUpload,
      toShowContent,
      toShowContentStyle,
      s3Path,
      allowedMimeTypes,
      onErrorChange,
      showErrorModal,
    } = this.props;
    const { files, error, errorModel } = this.state;
    // Renders the S3FileUploader
    return (
      <div className="fullWidth">
        <DropZone ref={this.dropzoneRef} multiple onDrop={this.onDrop} style={toShowContentStyle}>
          {typeof toShowContent === 'function' ? toShowContent(this.dropzoneRef) : toShowContent}
        </DropZone>
        {!!error &&
          !(onErrorChange && typeof onErrorChange === 'function') &&
          (showErrorModal && errorModel.show ? (
            <ErrorMsg
              message={errorModel.message}
              isOpen={errorModel.show}
              toggle={() => this.setState({ errorModel: initialThanksmodalState })}
            />
          ) : (
            <Alert size="full" type="error" value={error} />
          ))}

        {!!files.length && (
          <ul divided relaxed>
            <li>
              {' '}
              <FileUploader
                files={files}
                onFileUpload={onFileUpload}
                onUploadComplete={this.uploadCompleted}
                signingUrl={signingUrl}
                s3Path={s3Path}
                allowedMimeTypes={allowedMimeTypes}
              />
            </li>
          </ul>
        )}
      </div>
    );
  }
}
