import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router/immutable';
import Helmet from 'react-helmet';
import { Route, Switch } from 'react-router';
import {
  typeCastToKeyValueObject,
  validObjectWithParameterKeys,
  strictValidArrayWithLength,
  strictValidString,
  strictValidObjectWithKeys,
} from '../utils/commonUtils';
import NotFound from '../containers/404-not-found';
import { Login, ResetPassword } from './LoadableComponents';
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';
import {
  expireSession,
  loadSettings,
  load,
  loadPermissions,
  getTenantConfig,
} from '../redux/actions';
import Header from '../components/Header';
import Footer from '../components/Footer';
import RealTimeNotificationMessages from '../components/real-time-notication-message';
import AsidebarComponent from '../containers/Common/asidebar';
import { validUser } from '../utils/siteSpecificCommonUtils';
import { blackListRoutes } from '../utils/siteSpecificConstants';
import Alert from '../components/Alert';
import { Popup, PopupBody, PopupFooter, PopupHeader } from '../components/--primitives/pr-popup';
import PrButton from '../components/--primitives/pr-button';

class MainRoute extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    sessionExpired: PropTypes.bool,
    userNotAllowed: PropTypes.bool,
    userNotAllowedMsg: PropTypes.string,
  };

  // After page loads
  async componentDidMount() {
    const { dispatch, user, history } = this.props;
    await dispatch(getTenantConfig());
    if (validObjectWithParameterKeys(user, ['_id'])) {
      await dispatch(load());
      await dispatch(loadPermissions());
      await dispatch(loadSettings());
    }
  }

  componentDidUpdate(prevProps) {
    // window.scrollTo(0, 0) doesn't work on modals
    // because it scrolls modal's window to top instead
    if (prevProps.userNotAllowed !== this.props.userNotAllowed) {
      document.querySelector('body').scrollTop = 0;
    }
  }

  // Expire user session
  expireSession = async () => {
    const { dispatch } = this.props;
    await dispatch(expireSession());
  };

  // Renders routes based on user logged in state
  render() {
    const {
      history,
      sessionExpired,
      user,
      userNotAllowed,
      userNotAllowedMsg,
      isSidebarOpen,
      tenantConfig = {},
      isLoad,
    } = this.props;

    return (
      <div id="app" className={isSidebarOpen ? 'sidebar-open' : ''}>
        {!isLoad &&
          strictValidObjectWithKeys(tenantConfig) &&
          validObjectWithParameterKeys(tenantConfig, ['logo_favicon']) && (
            <Helmet>
              {strictValidArrayWithLength(tenantConfig.logo_favicon) &&
                tenantConfig.logo_favicon.map((v, i) => (
                  <link key={i} rel="shortcut icon" sizes={v.key} href={v.url} />
                ))}
            </Helmet>
          )}
        {sessionExpired && (
          <Popup isOpen={sessionExpired} action="warning">
            <PopupHeader action="warning" />
            <PopupBody>
              <div className="content title">Session expired</div>
              <div className="content text">
                Your session has expired, please login to continue.
              </div>
            </PopupBody>
            <PopupFooter>
              <PrButton
                value="Go back to login"
                type="blue tall"
                onClick={() => this.expireSession()}
              />
            </PopupFooter>
          </Popup>
        )}

        <RealTimeNotificationMessages />

        <ConnectedRouter history={history}>
          <>
            {!strictValidArrayWithLength(
              blackListRoutes.filter((v) => history.location.pathname.indexOf(v) > -1),
            ) && <Header />}
            {validUser(user) &&
              !strictValidArrayWithLength(
                blackListRoutes.filter((v) => history.location.pathname.indexOf(v) > -1),
              ) && <AsidebarComponent />}
            <section
              className={
                strictValidArrayWithLength(
                  blackListRoutes.filter((v) => history.location.pathname.indexOf(v) > -1),
                )
                  ? ''
                  : `page-container`
              }
            >
              {userNotAllowed && strictValidString(userNotAllowedMsg) && (
                <Alert
                  type="error"
                  style={{ marginLeft: '15px', marginRight: '15px' }}
                  message={userNotAllowedMsg}
                />
              )}
              <Switch>
                <PublicRoute exact path="/login" component={Login} />
                <PublicRoute exact path="/reset-password/:key" component={ResetPassword} />
                <PublicRoute exact path="/activate-account/:key" component={ResetPassword} />
                <PrivateRoute path="/" />
                <Route path="*" exact component={NotFound} />
              </Switch>
            </section>
            {!strictValidArrayWithLength(
              blackListRoutes.filter((v) => history.location.pathname.indexOf(v) > -1),
            ) && <Footer />}
          </>
        </ConnectedRouter>
      </div>
    );
  }
}

export default connect((state) => ({
  isSidebarOpen: state.get('common').get('isSideBarOpen'),
  user: typeCastToKeyValueObject(state.get('auth').get('user')),
  currentTime: state.get('auth').get('currentTime'),
  sessionExpired: state.get('auth').get('sessionExpired'),
  userNotAllowed: state.get('auth').get('userNotAllowed'),
  userNotAllowedMsg: state.get('auth').get('userNotAllowedMsg'),
  tenantConfig: state.get('config').get('tenantConfig'),
  isLoad: state.get('config').get('isLoad'),
}))(MainRoute);
