const INTERVIEW_DETAILS_REQUEST = 'INTERVIEW_DETAILS_REQUEST';
const INTERVIEW_DETAILS_SUCCESS = 'INTERVIEW_DETAILS_SUCCESS';
const INTERVIEW_DETAILS_FAILURE = 'INTERVIEW_DETAILS_FAILURE';

const INTERVIEW_UPDATE_REQUEST = 'INTERVIEW_UPDATE_DETAILS_REQUEST';
const INTERVIEW_UPDATE_SUCCESS = 'INTERVIEW_UPDATE_DETAILS_SUCCESS';
const INTERVIEW_UPDATE_FAILURE = 'INTERVIEW_UPDATE_DETAILS_FAILURE';
const INTERVIEW_UPDATE_RESET_MESSAGE = 'INTERVIEW_UPDATE_RESET_MESSAGE';

const PREPPED_INTERVIEW = 'PREPPED_INTERVIEW';

const SET_EMAIL_BODY = 'SET_EMAIL_BODY';

const interviewsDetailsRequest = (dispatch) => {
  dispatch({ type: INTERVIEW_DETAILS_REQUEST, payload: { loading: true } });
};

const interviewsDetailsSuccess = (dispatch, response) => {
  dispatch({
    type: INTERVIEW_DETAILS_SUCCESS,
    payload: { data: response, loading: false },
  });
};

const interviewsDetailsFailure = (dispatch, response) => {
  dispatch({ type: INTERVIEW_DETAILS_FAILURE, payload: { loading: false } });
};

const interviewsUpdateDetailsRequest = (dispatch) => {
  dispatch({
    type: INTERVIEW_UPDATE_REQUEST,
    payload: { loadingModal: true, modalError: false },
  });
};

const interviewsUpdateDetailsSuccess = (dispatch) => {
  dispatch({
    type: INTERVIEW_UPDATE_SUCCESS,
    payload: { loadingModal: false, modalError: false },
  });
};

const interviewsUpdateDetailsFailure = (dispatch, response) => {
  dispatch({
    type: INTERVIEW_UPDATE_FAILURE,
    payload: { loadingModal: false, modalError: response },
  });
};

const interviewsUpdateDetailsResetMessage = (dispatch) => {
  dispatch({
    type: INTERVIEW_UPDATE_RESET_MESSAGE,
    payload: { modalError: false },
  });
};

const preppedInterview = (dispatch, status) => {
  dispatch({
    type: PREPPED_INTERVIEW,
    payload: { prepped_on_interview: status },
  });
};

const setEmailContent = (dispatch, content) => {
  dispatch({
    type: SET_EMAIL_BODY,
    payload: { email_body: content },
  });
  dispatch({ type: INTERVIEW_DETAILS_SUCCESS, payload: { loading: false } });
};

export {
  INTERVIEW_DETAILS_REQUEST,
  INTERVIEW_DETAILS_SUCCESS,
  INTERVIEW_DETAILS_FAILURE,
  INTERVIEW_UPDATE_REQUEST,
  INTERVIEW_UPDATE_SUCCESS,
  INTERVIEW_UPDATE_FAILURE,
  INTERVIEW_UPDATE_RESET_MESSAGE,
  PREPPED_INTERVIEW,
  SET_EMAIL_BODY,
  interviewsDetailsRequest,
  interviewsDetailsSuccess,
  interviewsDetailsFailure,
  interviewsUpdateDetailsRequest,
  interviewsUpdateDetailsSuccess,
  interviewsUpdateDetailsFailure,
  interviewsUpdateDetailsResetMessage,
  preppedInterview,
  setEmailContent,
};
