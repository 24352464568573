import { combineReducers } from 'redux-immutable';
import { List } from '../../containers/WorkBench/Leads/List/reducer';
import { Add } from '../../containers/WorkBench/Leads/Add/reducer';
import { Edit } from '../../containers/WorkBench/Leads/Edit/reducer';

export default combineReducers({
  Add,
  List,
  Edit,
});
