import { LOCATION_CHANGE } from 'react-router-redux';
import { JOBS_AT_RISK_REQUEST, JOBS_AT_RISK_SUCCESS, JOBS_AT_RISK_FAILURE } from './actions';

const initialState = {};

const risk = (state = initialState, action) => {
  switch (action.type) {
    case JOBS_AT_RISK_REQUEST:
    case JOBS_AT_RISK_SUCCESS:
    case JOBS_AT_RISK_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case LOCATION_CHANGE:
      return {
        ...state,
        err: null,
      };
    default:
      return state;
  }
};
export { risk };
