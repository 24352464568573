import { messages } from '../../../../../language';
import { VALID_EMAIL, VALID_NAME } from '../../../../../utils/regexs';
import {
  strictValidArrayWithLength,
  strictValidStringWithMinLength,
} from '../../../../../utils/commonUtils';
import { getTextFromHTML } from '../../../../../components/final-form/ff-HTML-editor';

const validate = (values) => {
  const {
    sender_email,
    sender_name,
    sender_role,
    to,
    body,
    subject,
    alert_type = {},
    to_emails = [],
    text_body,
    rtn_body,
    rtn_subject,
    to_field_required,
  } = values;
  const errors = {};

  if (!VALID_EMAIL.test(sender_email)) {
    errors.sender_email = messages.VALIDATIONS.INVALID_EMAIL_ADDRESS;
  }
  if (!VALID_NAME.test(sender_name)) {
    errors.sender_name = messages.VALIDATIONS.STRICT_VALID_NAME;
  }
  if (sender_email && !sender_name) {
    errors.sender_name = messages.VALIDATIONS.INVALID_NAME;
  }
  if (!sender_email && !sender_name) {
    errors.sender_email = undefined;
    errors.sender_name = undefined;
  }
  if (!sender_email && sender_name) {
    errors.sender_email = messages.VALIDATIONS.INVALID_EMAIL_ADDRESS;
  }
  if (!sender_role && !sender_name && !sender_email) {
    errors.sender_name = messages.VALIDATIONS.INVALID_NAME;
    errors.sender_email = messages.VALIDATIONS.INVALID_EMAIL_ADDRESS;
  }
  if (sender_role) {
    errors.sender_email = undefined;
    errors.sender_name = undefined;
  }
  if (
    to_field_required &&
    !strictValidArrayWithLength(to) &&
    !strictValidArrayWithLength(to_emails)
  ) {
    errors.to_emails = 'Please add email or select field ';
  }
  if (alert_type.value === 'Email') {
    if (!strictValidStringWithMinLength(getTextFromHTML(body))) {
      errors.body = messages.VALIDATIONS.REQUIRED;
    }
    if (!subject) {
      errors.subject = messages.VALIDATIONS.REQUIRED;
    }
  }
  if (alert_type.value === 'Text') {
    if (!text_body) {
      errors.text_body = messages.VALIDATIONS.REQUIRED;
    }
  }
  if (alert_type.value === 'Both') {
    if (!strictValidStringWithMinLength(getTextFromHTML(body))) {
      errors.body = messages.VALIDATIONS.REQUIRED;
    }
    if (!subject) {
      errors.subject = messages.VALIDATIONS.REQUIRED;
    }
    if (!text_body) {
      errors.text_body = messages.VALIDATIONS.REQUIRED;
    }
  }

  if (!rtn_subject) {
    errors.rtn_subject = messages.VALIDATIONS.REQUIRED;
  }

  if (!strictValidStringWithMinLength(getTextFromHTML(rtn_body))) {
    errors.rtn_body = messages.VALIDATIONS.REQUIRED;
  }

  return errors;
};

export default validate;
