const IMPACT_STATISTICS_REQUEST = 'IMPACT_STATISTICS_REQUEST';
const IMPACT_STATISTICS_SUCCESS = 'IMPACT_STATISTICS_SUCCESS';
const IMPACT_STATISTICS_FAILURE = 'IMPACT_STATISTICS_FAILURE';

const impactStatisticsRequest = (dispatch, type) => {
  dispatch({
    type: IMPACT_STATISTICS_REQUEST,
    payload: { type, loading: true },
  });
};

const impactStatisticsSuccess = (dispatch, response, type) => {
  dispatch({
    type: IMPACT_STATISTICS_SUCCESS,
    payload: { response, type, loading: false },
  });
};

const impactStatisticsFailure = (dispatch, type) => {
  dispatch({
    type: IMPACT_STATISTICS_FAILURE,
    payload: { type, loading: false },
  });
};

export {
  IMPACT_STATISTICS_REQUEST,
  IMPACT_STATISTICS_SUCCESS,
  IMPACT_STATISTICS_FAILURE,
  impactStatisticsRequest,
  impactStatisticsSuccess,
  impactStatisticsFailure,
};
