const CLIENT_FEEDBACK_REQUEST = 'CLIENT_FEEDBACK_REQUEST';
const CLIENT_FEEDBACK_SUCCESS = 'CLIENT_FEEDBACK_SUCCESS';
const CLIENT_FEEDBACK_FAILURE = 'CLIENT_FEEDBACK_FAILURE';

const clientFeedbackRequest = (dispatch) => {
  dispatch({ type: CLIENT_FEEDBACK_REQUEST, payload: { loading: true } });
};

const clientFeedbackSuccess = (dispatch, response) => {
  dispatch({ type: CLIENT_FEEDBACK_SUCCESS, payload: { data: response, loading: false } });
};

const clientFeedbackFailure = (dispatch) => {
  dispatch({ type: CLIENT_FEEDBACK_FAILURE, payload: { loading: false } });
};

export {
  CLIENT_FEEDBACK_REQUEST,
  CLIENT_FEEDBACK_SUCCESS,
  CLIENT_FEEDBACK_FAILURE,
  clientFeedbackRequest,
  clientFeedbackSuccess,
  clientFeedbackFailure,
};
