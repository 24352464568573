import { USERS_REQUEST, USERS__FAILURE, USERS__SUCCESS } from './actions';

const initialState = {
  loadErr: null,
};

const users = (state = initialState, action) => {
  switch (action.type) {
    case USERS_REQUEST:
    case USERS__FAILURE:
    case USERS__SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export { users };
