import { settingsConstants } from '../constants';
import {
  validObjectWithParameterKeys,
  strictValidArrayWithLength,
  removeInValidKeyValuePairs,
  strictValidObjectWithKeys,
} from '../../utils/commonUtils';
import { messages } from '../../language';
import { errorConstants } from '../constants/error.constants';
/**
 * To load settings object which contain jobFunctions <Array>
 * Access role allowed - any
 */
export const loadSettings = (params) => async (dispatch, getState, api) => {
  let data = params;
  const settingData = removeInValidKeyValuePairs(getState().get('settings').toJS(), true);
  if (!strictValidArrayWithLength(data)) {
    data = [
      'jobfunctions',
      'regions',
      'titles',
      'invoice_paycodes_listing',
    ];
  }

  // parseKeys is to map querystring with saved keys in redux
  const parseKeys = {
    jobfunctions: 'jobFunctions',
    companycultures: 'cultures',
    ['states-list']: 'states',
  };

  // reverseParseKeys is to map saved keys in redux with  querystring
  const reverseParseKeys = {
    jobFunctions: 'jobfunctions',
    states: 'states-list'
    // cultures: 'companycultures',
  };
  // formatKeys is to map querystring with get from api res
  const formatKeys = {
    jobfunctions: 'job_functions',
    ['states-list']: 'states',
    // companycultures: 'company_cultures',
  };
  const settingKeys = data.map((v) => {
    if (validObjectWithParameterKeys(parseKeys, [v])) {
      return parseKeys[v];
    }
    return v;
  });

  // If setting data is already present then return
  if (validObjectWithParameterKeys(settingData, settingKeys)) {
    return settingData;
  }
  // below filter will filter out the keys which already present in redux
  data = settingKeys
    .filter((v) => {
      return !(validObjectWithParameterKeys(settingData, [v]) &&
        strictValidArrayWithLength(settingData[v]));
    })
    .map((v) => {
      if (validObjectWithParameterKeys(reverseParseKeys, [v])) {
        return reverseParseKeys[v];
      }
      return v;
    });
  dispatch({ type: settingsConstants.LOAD });

  try {
    // Get job functions from api
    const res = await api.get(`/settings?select=${data.join(',')}`);
    const dataKeys = data.map((v) => {
      if (validObjectWithParameterKeys(formatKeys, [v])) {
        return formatKeys[v];
      }
      return v;
    });
    const response =
      validObjectWithParameterKeys(res, ['data']) &&
      strictValidObjectWithKeys(res.data) &&
      res.data;

    const isValidReponseFlag = validObjectWithParameterKeys(response || {}, dataKeys);

    // If not valid response
    if (!isValidReponseFlag) {
      dispatch({
        type: settingsConstants.LOAD_FAIL,
        error: '', // messages.DEFAULT_ERROR_MESSAGE
      });
      dispatch({ type: errorConstants.SET_ERROR, error: messages.DEFAULT_ERROR_MESSAGE });
      return false;
    }
    // Else if valid response continue
    const {
      job_functions,
      industries,
      educations,
      benefits,
      company_cultures,
      regions,
      titles,
      // requirements,
      invoice_paycodes_listing,
      msa_lovs,
      location_label,
      client_hear_about_us,
      candidate_hear_about_us,
      markup,
      resignation_notice,
      reason_for_leaving,
      candidate_decline_reason,
      close_reason,
      company_size,
      placement_reason_ended,
      timesheet_missed_break_reason,
      states,
    } = res.data;

    const previousValue = removeInValidKeyValuePairs(getState().get('settings').toJS(), true);
    dispatch({
      type: settingsConstants.LOAD_SETTINGS,
      jobFunctions: job_functions,
      industries,
      educations,
      benefits,
      cultures: company_cultures,
      regions,
      titles,
      // requirements,
      invoicePaycodesListing: invoice_paycodes_listing,
      location_label,
      client_hear_about_us,
      candidate_hear_about_us,
      markup,
      resignation_notice,
      reason_for_leaving,
      candidate_decline_reason,
      close_reason,
      company_size,
      placement_reason_ended,
      timesheet_missed_break_reason,
      msa_lovs,
      states,
      ...previousValue,
    });
    dispatch({ type: settingsConstants.LOAD_SUCCESS });
    return res.data;
  } catch (err) {
    // If an error occurs, set error field
    dispatch({
      type: settingsConstants.LOAD_FAIL,
      error: messages.DEFAULT_ERROR_MESSAGE,
    });
  }
};
