export const messages = {
  VALID_AUTHORIZATION: 'Please select Work Authorization value',
  DEFAULT_ERROR_MESSAGE: 'Oops!! something went wrong, please try again',
  INTERVIEW_EXPERIENCE: 'Tell us about your interview experience',
  NOT_REGISTERED: 'Account not found. Please enter a registered email address',
  LINK_EXPIRED: 'Invalid or expired link',
  SESSION_EXPIRED: 'Your session has expired',
  ACTIVATION_FAILED: 'An error occurred while activating your account',
  SUCCESS_MESSAGE: 'Successfully saved',
  NEW_JOB_CREATED: 'Successfully created new job',
  AVAILABILITY_SAVED: 'Your availability is updated successfully',
  CHECK_FIELDS: 'Please provide valid values for all required fields',
  GOOGLE_MAPS_LOCATION_ERROR: 'Location is required to load the map',
  FAILED_TO_SIGN_TAC: 'An error occurred while signing Terms & Conditions',
  KEY_SKILLS_DUPLICATE_OPTION: 'You have already added this skill',
  KEY_SKILLS_MAX_LIMIT_EXCEED: 'Maximum 10 key Skills can be added',
  KEY_SKILLS_DEFAULT_NO_OPTION: 'No results found',
  KEY_SKILLS_CANNOT_PASTE: 'You cannot paste text here.',
  NO_NEW_NOTIFICATIONS: 'No new notifications',
  NO_PROFILES_FOUND: 'No matching profiles found',
  COMPANY_DOES_NOT_EXISTS: 'Company does not exists',
  DUPLICATE_ACCOUNT: (name = 'This domain') => `${name} is already added on your account`,
  INACTIVE_CANDIDATE_ON_RECOMMENDED_LIST: 'Only active candidates can be recommended to a job',
  INVALID_CLIENT_OR_CLIENT_USER: 'Please select valid company and its user',
  INTERVIEW_FEEDBACK: 'Please enter feedback in the text box',
  MAX_TWELEVE_WEEKS: 'Please select a maximum range of 12 weeks for date',
  START_DATE_GREATER_THAN_END_DATE: 'Start date can not be greater than end date',
  COMPANY_CULTURE_AND_VALUES_DOES_NOT_EXIST:
    'Company culture and values does not exist. Do you want to copy job culture and values to company profile?',
  COMPANY_CULTURE_AND_VALUES_PARTIALY_EXIST:
    'Company culture and values does not exist. Do you want to copy job culture and values to company profile? <br/><br/> <strong>NOTE: Company culture and values partially filled</strong>',
  PLACEMENT_VERSIONING_ERROR:
    'Current date is equivalent of placement end date. Please change your placement end date.',

  FEEDBACK_LABEL_TEXT: 'This feedback is important and will be shared directly with the client.',
  COMPENSATION_I_TEXT: 'Include bonus, commission, or other compensation if relevant',

  // General label text or verbiages
  GENERAL_LABEL_TEXT: {
    JOB_SHARE_POP_UP_HEADING: "Share with users within this company's organization",
  },

  MSA_NOT_SIGNED_PLC_TEXT: 'Placement cannot be made if “MSA Signed” checkbox is unchecked',

  SHARE_WITH_ACTIVE_CANDIDATES:
    'This is to email job details to candidates who have already created a profile. If a candidate applies through the link sent in this email this job will be marked as “Liked By” the candidate.',
  SHARE_WITH_POTENTIAL_CANDIDATES:
    'This is to email job details to candidates who have not yet created a profile. If a candidate applies through the link sent in this email the candidate will be taken through the “Apply” process to create a profile and this job will be marked as “Liked By” the candidate.',

  // Places auto complete constants
  PLACES_AUTO_COMPLETE: {
    NO_RESULTS: 'No results found',
    CITY_STATE_MANDATORY: 'Please provide your preferred city and state',
    PREFERRED_JOB_LOCATION_MANDATORY: 'Please provide preferred job location',
    CITY_MANDATORY: "Please enter city name in 'City, State' format",
    CITY_IS_ALREADY_INCLUDED: 'Please select a different city, as this city is already selected.',
    CITY_ALREADY_EXISTS_IN_OTHER_REGIONS:
      'Please select a different city as this area is already included in an existing region',
    COUNTY_MANDATORY: "Please enter county name in 'County, State' format",
    COUNTY_IS_ALREADY_INCLUDED: 'Please select a different county',
    COUNTY_ALREADY_EXISTS_IN_OTHER_REGIONS:
      'Please select a different county as this county is already included in an existing region',
    STATE_MANDATORY: 'Please enter state name',
    STATE_IS_ALREADY_INCLUDED: 'Please select a different state',
    STATE_ALREADY_EXISTS_IN_OTHER_REGIONS:
      'Please select a different state as this state is already included in an existing region',
    COMPLETE_ADDRESS: 'Please provide your complete address',
    PICK_FROM_SUGGESTION_LIST: 'Please select an address from the suggestions list',
  },

  // Form constants
  DEFAULT_FORM: {
    PASSWORD_CONF_PASS_UNMATCH: 'Passwords do not match',
  },

  // Apply
  EXPERIENCES: {
    MINIMUM_ONE_REQUIREMENT_REQUIRED:
      'Please select at least one job requirement that you performed during this job',
    MINIMUM_ONE_DUTY_REQUIRED:
      'Please select at least one job duty that you performed during this job',
    APPLY_MINIMUM_EXPERIENCE: 'At least one job experience has to be provided',
    DUPLICATE_EXPERIENCE_ROW: 'Please provide unique values for your experiences',
    MINIMUM_EXPERIENCE_ROW: 'Please provide your Years of Experience for this job',
  },

  // Show talent form
  SHOW_TALENT_FORM: {
    JOB_NOT_EXIST: 'This job post no longer exists',
    INACTIVE_JOB_POST: 'This job post already exists',
    INVALID_JOB_POST:
      'This job post is incomplete. Please provide all required fields to see matched talent for this job',
    INVALID_JOB_DURATIONS: 'Select your job type',
    INVALID_IS_REMOTE_OPTION: 'Select one option',
    INVALID_EMPLOYMENT_TYPE: 'Select your employment type',
    INVALID_NO_OF_POSITIONS: 'Select at least one',
    INVALID_CLOSE_REASON: 'Select select valid close reason',
  },

  SHOW_JOBS_FORM: {
    INACTIVE_PROFILE: 'This profile already exists',
    JOB_TYPE_MANDATORY: 'Job type is a required field',
    EDUCATION_MANDATORY: 'Education is a required field',
    TIMEZONE_MISSING: 'Error tracing your timezone',
    CITY_STATE_MISSING: 'Error getting your preferred job location',
    CITY_STATE_DUPLICATE: 'Duplicate address',
    INVALID_JOB_DURATIONS: 'Select at least one option',
    INVALID_EMPLOYEMENT_TYPE: 'Select your employment type',
    INVALID_EDUCATION: 'Select your education',
    INVALID_DATE: 'Please select the date',
  },

  // Calendar constants
  CALENDAR: {
    OUT_OF_BOUNDS: 'Please provide availability for current or future time & date',
    TIME_PASSED: 'You can not remove availablity now',
    NO_EVENTS: 'Please provide at least one slot to save your availability',
    JOB_INTERVIEWS_ALREADY_SCHEDULED_IS_SELECTED:
      "You cannot add availability when 'Jobs interviews already scheduled' is selected",
    ALL_ROUNDS_IS_SELECTED:
      "You cannot add availability when 'All rounds' is selected, please go to a particular round to add availability",
    CANNOT_ADD_AVAILABILITY_IN_SCHEDULED_TAB: {
      TEXT: 'The “Scheduled Interviews” calendar is view-only.',
      SUB_TEXT:
        'Please toggle to the “Available hours” view if you’re looking to add or edit your interview availability.',
    },
  },

  // Validations
  VALIDATIONS: {
    REQUIRED: '* Required',
    AT_LEAST_ONE_INTERVIEW_TYPE_IS_REQUIRED: 'Please select the Interview Type',
    AT_LEAST_ONE_INTERVIEWER_IS_REQUIRED: 'Please add at least one Interviewer',
    AT_LEAST_ONE_CLIENT_IS_REQUIRED: 'Please select a Client',
    AT_LEAST_ONE_CLIENT_USER_IS_REQUIRED: 'Please select a Client User',
    AT_LEAST_ONE_CANDIDATE_IS_REQUIRED: 'Please select at least one Candidate',
    AT_LEAST_ONE_JOB_IS_REQUIRED: 'Please select a Job',
    FEEDBACK_IS_REQUIRED: 'Please enter feedback',
    CLIENT_JOB_TITLE_IS_REQUIRED: 'Please enter client job title',
    JOB_FUNCTION_IS_REQUIRED: 'Please select the Job function',
    JOB_TITLE_IS_REQUIRED: 'Please select the Job Title',
    AT_LEAST_ONE_JOB_DUTY: 'Please select at least one job duty',
    INVALID_EMAIL_ADDRESS: 'Please provide a valid email address',
    EMAIL_ALREADY_EXISTS: 'Email address is already taken',
    EMAIL_REQUIRED_FOR_FORGOT_PASSWORD: 'Please enter your registered email address',
    INVALID_URL: 'Please enter a valid url',
    INVALID_PHONE_NUMBER: 'Please enter a valid phone number',
    INVALID_MOBILE_NUMBER: 'Please enter a valid mobile number',
    STRICT_VALID_INTEGER: 'Must be an integer',
    INVALID_FILE_NAME: 'Please select a valid file',
    INVALID_PASSWORD: 'Please enter your password as per the password requirements',
    INVALID_ZIP_CODE: 'Please enter a valid zip code',
    INVALID_JOB_TITLE: 'Please select a job title',
    INVALID_INDUSTRY_FOR_CANDIDATE: 'Please select the industry that you worked in',
    INVALID_INDUSTRY_FOR_CLIENT: 'Please select an industry',
    STRICT_VALID_VALUE: 'Please select a valid value',
    INVALID_SOURCE: 'Please select how you heard about us',
    INVALID_TELL_US_MORE: 'Please tell us more',
    REGION_ALREADY_EXISTS: 'This region already exists',
    INVALID_REGION_BOUNDARY_TYPE: 'Please select the type of area boundary',
    RESOURCE_IS_ALREADY_INCLUDED: 'Please select a different resource',
    AT_LEAST_ONE_BOUNDARY_IS_REQUIRED: 'Please provide at least one area that defines boundary',
    AT_LEAST_ONE_RESOURCE_IS_REQUIRED: 'Please select at least one resource is required',
    INVALID_NAME: 'Please enter name',
    STRICT_VALID_NAME: 'Please enter a valid name',
    INVALID_ROLE: 'Please select role',
    REGION_DOES_NOT_EXIST: 'No such region exists',
    DECLINE_REASON_MANDATORY: 'Please provide your reason for declining this job invite',
    INVALID_FIRST_NAME: 'Please provide first name',
    INVALID_LAST_NAME: 'Please provide last name',
    INVALID_VALUE: 'Please enter valid value',
    STRICT_FIVE_CANDIDATES: 'You can not select more than 5 candidates.',
    PASSWORD_CONF_PASS_UNMATCH: 'Passwords do not match',
    INVALID_CONFIRM_PASSWORD: 'Please confirm your password',

    AT_LEAST_ONE_JOB_TYPE_IS_REQUIRED: 'Job type is a required field',
    AT_LEAST_ONE_EMPLOYMENT_TYPE_IS_REQUIRED: 'Select your employment type',
    AT_LEAST_ONE_RESOURCES_EMPLOYMENT_TYPE_IS_REQUIRED:
      'Select at least one employment type for each resources ',
    DIFFERENT_ADDRESS: 'Duplicate address',
  },

  TIMESHEET: {
    REJECT_REASON_REQUIRED: 'Please provide the reason for rejecting this timesheet',
    DRAFT_SAVE_SUCCESS:
      'Timesheet draft saved successfully. Click on submit to submit the timesheet !',
    TIMESHEET_SUCCESSFULLY_SUBMITED:
      'Timesheet copied successfully. Please review before submission!',
    TIMESHEET_SUBMITED_WITH_ZERO_VALUE:
      "Note: You are submitting a timecard with zero hours for this week. Are you sure you'd like to submit?",
    TIMESHEET_SICKLEAVE_DAILY_ERROR:
      'Sick time cannot be applied to days you have already entered 8+ hours worked. Please adjust the hours.',
    MORE_THEN40_HOURS_ERROR: "Total sick hours can't be more than 40 hours.",
    WORK_ON_HOLIDAY:
      'Only enter hours here if you worked on this holiday. Would you like to proceed?',
    SUBMIT_WORK_ON_HOLIDAY:
      "You've indicated that you worked on a holiday. Are you sure you want to proceed?",
    APPROVE_WORK_ON_HOLIDAY:
      'This candidate indicated that they worked on the holiday. Do you still want to approve this timesheet?',
  },

  EMAIL_SENT: 'Email has been sent successfully !',

  APPLY_PROCESS_HELPER_TEXT: {
    JOB_FUNCTIONS: 'Which of the following job functions interests you ?',
    EXPERIENCE: 'What kind of work experience do you have?',
    EXPERIENCE_RESPONSIBILITIES: 'Please add your previous work experience',
    JOB_LOCATION: 'What is your preferred job location?',
    PREF_JOB_LOCATION: 'Where would you like to work?',
    JOB_MATCHES: `Great! Here's a preview of your top matches!`,
    CANDIDDATE_INFO: 'To get started, tell us a bit about yourself.',
    THANK_YOU_MESSAGE:
      'An activation email has been sent your way! Please activate your account to complete the registration and see your matches!',
    ACCOUNT_ALREDAY_EXISTS: 'An account already exists with this email address.',
    JOB_SEARCH_DETAILS_SUBTEXT:
      'Your dream job awaits! Tell us a bit about your job search to get started.',
  },
  EDIT_PROFILE: {
    JOB_SEARCH_DETAILS_SUBTEXT: 'Update your job search details, then click the "Submit" button',
    GENERAL_INFO_SUBTEXT:
      'Make changes and updates to your information, then click the "Submit" button ',
    EXPERIENCE_RESPONSIBILITIES:
      'Update your previous work experience, then click the "Submit" button',
    // eslint-disable-next-line max-len
    RESUME: `Upload any relevant resumes below to detail your professional history, experience, and background, then press the “Submit” button`,
  },
};
