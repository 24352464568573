import React, { useCallback, useEffect, useRef, useState } from 'react';
import browser from 'browser-detect';
import PropTypes from 'prop-types';
import './ff-animated-input.scss';
import PrIcoCalendarComponent from '../../--primitives/pr-ico-calendar';
import PrIcoAddressComponent from '../../--primitives/pr-ico-address';
import PrIcoEyeComponent from '../../--primitives/pr-ico-eye';
import PrIcoArrowLinkComponent from '../../--primitives/pr-ico-arrow-link';
import {
  isFunction,
  strictValidNumber,
} from '../../../utils/commonUtils';

function FfAnimatedInput({
  prefix,
  prefixText,
  showError,
  input,
  input: { value = '', type = '' } = {},
  placeholder,
  meta: { touched, error },
  onChange,
  style,
  isDisable,
  tip,
  customClassName,
  tabDisabled,
  showErrorOnDisable,
  onBlur: onBlurProps,
  pattern,
  ...otherProps
}) {
  const getType = () => {
    if (type.indexOf('password') > -1) {
      return 'password';
    }
    if (type.indexOf('number') > -1) {
      return 'number';
    }
    return 'text';
  };

  const [inputType, setInputType] = useState(getType());

  useEffect(() => {
    // add input type in array if want custom
    const inputTypeCollection = ['number'];
    if (inputTypeCollection.indexOf(type) > -1) setInputType(type);
  }, [type]);

  const inputEl = useRef(null);
  const parentInputEl = useRef(null);

  const onFocus = () => !isDisable && parentInputEl.current.classList.add('focused');

  const onBlur = useCallback((e) => {
    isFunction(onBlurProps) && onBlurProps(e);
    // Update coordinates
    if (e.target.value !== '')
      parentInputEl.current && parentInputEl.current.classList.add('filled');
    else !prefixText && parentInputEl.current.classList.remove('filled');
    if (parentInputEl.current) parentInputEl.current.classList.remove('focused');
  }, []);

  useEffect(() => {
    inputEl.current.addEventListener('blur', onBlur, false);
    inputEl.current.addEventListener('focus', onFocus, false);
    return () => {
      if (inputEl && inputEl.current) {
        inputEl.current.removeEventListener('focus', onFocus);
        inputEl.current.removeEventListener('blur', onBlur);
      }
    };
  }, []);

  const isAndroid = browser().os.indexOf('Android') !== -1;
  let ico = null;
  if (type.indexOf('date') !== -1 && !isAndroid) {
    ico = (
      <div className="ico-wrapper">
        <PrIcoCalendarComponent />
      </div>
    );
  } else if (type.indexOf('address') !== -1) {
    ico = (
      <div className="ico-wrapper">
        <PrIcoAddressComponent />
      </div>
    );
  } else if (type.indexOf('YR') !== -1) {
    ico = (
      <p className={`${prefix ? 'left' : ''} ico-wrapper ${isDisable ? 'disabled' : 'text'}`}>
        /YR
      </p>
    );
  } else if (type.indexOf('yr') !== -1) {
    ico = (
      <p className={`${prefix ? 'left' : ''} ico-wrapper ${isDisable ? 'disabled' : 'text'}`}>yr</p>
    );
  } else if (type.indexOf('dollar-ico') !== -1) {
    ico = (
      <p className={`${prefix ? 'left' : ''} ico-wrapper ${isDisable ? 'disabled' : 'text'}`}>$</p>
    );
  } else if (type.indexOf('arrow-link-ico') !== -1) {
    ico = (
      <div className="ico-wrapper after-filling">
        <PrIcoArrowLinkComponent />
      </div>
    );
  } else if (type.indexOf('eye-ico') !== -1) {
    ico = (
      <div className="ico-wrapper after-filling">
        <PrIcoEyeComponent
          onClick={() => setInputType((state) => (state === 'password' ? 'text' : 'password'))}
          inputType={inputType}
        />
      </div>
    );
  } else if (prefixText && prefix) {
    ico = (
      <p className={`${prefix ? 'left' : ''} ico-wrapper ${isDisable ? 'disabled' : 'text'}`}>
        {prefixText}
      </p>
    );
  }

  const isIco = !!ico;

  // method to handle custom actions on "onChange" event
  const handleChange = (e) => {
    const { inputmode = '' } = otherProps;
    let isValid = false;
    if(inputmode === 'numeric'){
      if (/^\d*$/.test(e.target.value)) {
        isValid = true;
      }
    }else{
      isValid = true;
    }
    if (pattern && e.target.value !== '') {
      const regex = new RegExp(pattern);
      if (regex.test(e.target.value)) {
        e.target.value = e.target.value.replace(regex, '');
        isValid = false;
        return;
      }
    }
    if (isValid) {
      const { onChange: onChangeInput } = input;
      if (onChange) onChange(e);

      if (onChangeInput) onChangeInput(e);
    }
  };

  return (
    <div
      ref={parentInputEl}
      className={`ff-data-animated-input-wrapper ${
        strictValidNumber(value) ? 'filled' : ''
      } ${inputType} ${value || prefixText ? 'filled' : ''} is-ico-${isIco} ${
        prefix ? 'prefix-input' : ''
      }  ${isDisable ? 'disable' : ''} ${browser().name} ${customClassName}`}
      tip={`${tip} ${error && error.length > 45 ? 'double-line-tooltip' : ''}`}
      data-description={error}
      is-error={
        touched && error && showError && (showErrorOnDisable || !isDisable) ? 'true' : 'false'
      }
    >
      {prefix && ico}
      <input
        {...otherProps}
        {...input}
        type={inputType}
        ref={inputEl}
        onKeyDown={(e) => {
          if (tabDisabled && e.keyCode === 9) {
            e.preventDefault();
          }
        }}
        readOnly={!!isDisable}
        style={style || { width: '100%' }}
        onChange={handleChange}
      />
      {isFunction(placeholder) ? placeholder() : <span className="placeholder">{placeholder}</span>}
      {!prefix && ico}
    </div>
  );
}

FfAnimatedInput.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  tip: PropTypes.string,
  showError: PropTypes.bool,
  type: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  style: PropTypes.shape(PropTypes.object),
  onChange: PropTypes.func,
  customClassName: PropTypes.string,
  prefix: PropTypes.bool,
  showErrorOnDisable: PropTypes.bool,
  prefixText: PropTypes.string,
  pattern: PropTypes.string,
};

FfAnimatedInput.defaultProps = {
  placeholder: '',
  meta: {
    touched: false,
    error: '',
  },
  type: 'text',
  onChange: null,
  showError: true,
  tip: 'error tool-bottom-left',
  style: {},
  customClassName: '',
  prefix: false,
  showErrorOnDisable: false,
  prefixText: '',
  pattern: '',
};

export default FfAnimatedInput;
