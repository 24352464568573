import {
  ADD_ATTACHMENT_REQUEST,
  ADD_ATTACHMENT_SUCCESS,
  ADD_ATTACHMENT_FAILURE,
  ADD_ATTACHMENT_RESET_MESSAGE,
} from './actions';

const initialState = {};

const addAttachment = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ATTACHMENT_REQUEST:
    case ADD_ATTACHMENT_SUCCESS:
    case ADD_ATTACHMENT_RESET_MESSAGE:
    case ADD_ATTACHMENT_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export { addAttachment };
