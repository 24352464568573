import axios from 'axios';
import store from 'store2';
import {
  removeInValidKeyValuePairs,
  validObjectWithParameterKeys,
  strictValidArrayWithMinLength,
} from '../../../utils/commonUtils';
import { consoleOnlyInDebugMode } from '../../../utils/debugging';
import { notificationsFailure, notificationsRequest, notificationsSuccess } from './actions';
import { errorConstants } from '../../../redux/constants/error.constants';

// eslint-disable-next-line no-unused-vars
const getNotifications =
  (job, company, interview, candidate, timesheet, placement, pn, ps, sc, st, tableFilters = {}) =>
  (dispatch) => {
    notificationsRequest(dispatch);
    const searchFilters = {
      ...tableFilters,
      ...{
        job,
        company,
        interview,
        candidate,
        timesheet,
        placement,
      },
    };
    const url = `${process.env.REACT_APP_APIHOST}/notifications/?limit=${ps}&page=${pn}&sort=${sc}&order=${st}`;
    axios
      .post(url, searchFilters, {
        headers: {
          'x-access-token': store.get('user').token,
        },
      })
      .then((res) => {
        notificationsSuccess(dispatch, res.data.data);
      })
      .catch((err) => {
        notificationsFailure(dispatch, err.response);
        dispatch({ type: errorConstants.SET_ERROR, error: extractErrorMessage(err.response) });
        consoleOnlyInDebugMode('err-getNotifications', err.response);
      });
  };

// NOTE: This API is written specific to Async multi select.
const getReceivers = (
  workBench,
  clientId,
  jobId,
  candidateId,
  placementId,
  keyword = '',
  callback = () => {/* empty fun */},
) => {
  if (keyword.length < 3) {
    callback([]);
    return;
  }
  let url = `${process.env.REACT_APP_APIHOST}/lookup/users-list?keyword=${keyword}`;

  switch (workBench) {
    case 'company':
      url = `${url}&type=${workBench}&company=${clientId}`;
      break;
    case 'job':
      url = `${url}&type=${workBench}&job=${jobId}`;
      break;
    case 'candidate':
      url = `${url}&type=${workBench}&candidate=${candidateId}`;
      break;
    case 'interview':
      url = `${url}&type=${workBench}&job=${jobId}&candidate=${candidateId}`;
      break;
    case 'placement':
      url = `${url}&type=${workBench}&placement=${placementId}&candidate=${candidateId}`;
      break;
    case 'timesheet':
      url = `${url}&type=${workBench}&placement=${placementId}&candidate=${candidateId}`;
      break;
    case 'invoice':
      url = `${url}&type=${workBench}&incomplete=${'incomplete'}`;
      break;
    case 'payroll':
      url = `${url}&type=${workBench}&incomplete=${'incomplete'}`;
      break;
    default:
      break;
  }

  axios
    .get(url, {
      headers: {
        'x-access-token': store.get('user').token,
      },
    })
    .then((res) => {
      // Map list to show in multi select
      const {
        ajna_users = [],
        company_users = [],
        candidate_in_flight = [],
        job_owner = [],
        interviewers = [],
        candidates = [],
        tse_aprover = [],
        client_billing_user = [],
      } = res.data.data;
      const formatData = (data, title) => {
        const dataToReturn = {
          value: title,
          label: title,
          options: data.map(({ email, name, _id: key }) => ({
            value: key,
            // NOTE: This code will handle the scenario of interviewers as we don't have name
            // of interviewers for now.
            label: name || email,
          })),
        };
        return dataToReturn;
      };
      const receivers = [
        formatData(ajna_users, 'Ajna Users'),
        formatData(company_users, 'Clients'),
        formatData(candidate_in_flight, 'Candidates In Flight'),
        formatData(job_owner, 'Job Owner'),
        formatData(interviewers, 'Interviewers'),
        formatData(candidates, 'Candidate'),
        formatData(tse_aprover, 'TSE Approver'),
        formatData(client_billing_user, 'Client Billing User'),
      ];

      const list = [];
      receivers.forEach(
        (v) =>
          validObjectWithParameterKeys(v, ['options']) &&
          strictValidArrayWithMinLength(v.options) &&
          v.options.forEach((item) =>
            list.push({
              ...item,
              groupTitle: v.label,
              groupId: v.value,
            }),
          ),
      );
      callback(list);
    })
    .catch((err) => {
      callback([]);
      dispatch({ type: errorConstants.SET_ERROR, error: extractErrorMessage(err.response) });
      consoleOnlyInDebugMode('err-getCompanies', err.response);
    });
};

// eslint-disable-next-line no-unused-vars
const addNotification =
  (body, onSuccess = () => {/* empty fun */}) =>
  (dispatch) => {
    const url = `${process.env.REACT_APP_APIHOST}/notifications/add/`;
    notificationsRequest(dispatch);
    axios
      .post(url, removeInValidKeyValuePairs(body), {
        headers: {
          'x-access-token': store.get('user').token,
        },
      })
      .then((res) => {
        notificationsSuccess(dispatch, res.data.data);
        onSuccess();
      })
      .catch((err) => {
        notificationsFailure(dispatch, err.response);
        dispatch({ type: errorConstants.SET_ERROR, error: extractErrorMessage(err.response) });
        consoleOnlyInDebugMode('err-addNotification', err.response);
      });
  };

export { getNotifications, getReceivers, addNotification };
