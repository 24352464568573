const INTERVIEW_LIST_REQUEST = 'INTERVIEW_LIST_REQUEST';
const INTERVIEW_LIST_SUCCESS = 'INTERVIEW_LIST_SUCCESS';
const INTERVIEW_LIST_FAILURE = 'INTERVIEW_LIST_FAILURE';

const MANAGERS_LIST_REQUEST = 'MANAGERS_LIST_REQUEST';
const MANAGERS_LIST_SUCCESS = 'MANAGERS_LIST_SUCCESS';
const MANAGERS_LIST_FAILURE = 'MANAGERS_LIST_FAILURE';

const SETTINGS_REQUEST = 'SETTINGS_REQUEST';
const SETTINGS_SUCCESS = 'SETTINGS_SUCCESS';
const SETTINGS_FAILURE = 'SETTINGS_FAILURE';

const interviewsListRequest = (dispatch) => {
  dispatch({ type: INTERVIEW_LIST_REQUEST, payload: { loading: true } });
};

const interviewsListSuccess = (dispatch, response) => {
  dispatch({ type: INTERVIEW_LIST_SUCCESS, payload: { data: response, loading: false } });
};

const interviewsListFailure = (dispatch) => {
  dispatch({ type: INTERVIEW_LIST_FAILURE, payload: { loading: false } });
};

const managersListRequest = (dispatch) => {
  dispatch({ type: MANAGERS_LIST_REQUEST, payload: { loading: true } });
};

const managersListSuccess = (dispatch, response) => {
  dispatch({ type: MANAGERS_LIST_SUCCESS, payload: { data: response, loading: false } });
};

const managersListFailure = (dispatch) => {
  dispatch({ type: MANAGERS_LIST_FAILURE, payload: { loading: false } });
};

const settingsRequest = (dispatch) => {
  dispatch({ type: SETTINGS_REQUEST, payload: { loading: true } });
};

const settingsSuccess = (dispatch, response) => {
  dispatch({ type: SETTINGS_SUCCESS, payload: { data: response, loading: false } });
};

const settingsFailure = (dispatch) => {
  dispatch({ type: SETTINGS_FAILURE, payload: { loading: false } });
};

export {
  INTERVIEW_LIST_REQUEST,
  INTERVIEW_LIST_SUCCESS,
  INTERVIEW_LIST_FAILURE,
  interviewsListRequest,
  interviewsListSuccess,
  interviewsListFailure,
  MANAGERS_LIST_REQUEST,
  MANAGERS_LIST_SUCCESS,
  MANAGERS_LIST_FAILURE,
  managersListRequest,
  managersListSuccess,
  managersListFailure,
  settingsRequest,
  settingsSuccess,
  settingsFailure,
  SETTINGS_REQUEST,
  SETTINGS_SUCCESS,
  SETTINGS_FAILURE,
};
