import { combineReducers } from 'redux-immutable';
import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { reducer as formReducer } from 'redux-form/immutable';
import Immutable from 'immutable';
import { routerMiddleware, connectRouter } from 'connected-react-router/immutable';
import { createLogger } from 'redux-logger';
import history from '../utils/history';
import moduleReducers from './reducers';
import ApiClient from './ApiClient';
import clientMiddleware from './clientMiddleware';
// import routerReducer from './reactRouterReducer';
import { socketMiddleWare } from './socket';

const api = new ApiClient();

function configureStore(history, preLoadedState) {
  const middlewares = [
    thunkMiddleware.withExtraArgument(api),
    clientMiddleware(api),
    routerMiddleware(history),
    socketMiddleWare,
  ];

  if (process.env.REACT_APP_ENV !== 'prod' && typeof window !== 'undefined') {
    middlewares.push(
      createLogger({
        level: 'info',
        collapsed: true,
        stateTransformer: (state) => state.toJS(),
      }),
    );
  }

  const composeWithDevTools = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const enhancer = composeWithDevTools(applyMiddleware(...middlewares));

  const reducers = combineReducers({
    router: connectRouter(history),
    form: formReducer,
    ...moduleReducers,
  });

  const initialState = Immutable.fromJS(preLoadedState || {});
  return createStore(connectRouter(history)(reducers), initialState, enhancer);
}

const store = configureStore(history, {});

export { store, history };
