import {
  ACCOUNT_MANAGERS_REQUEST,
  ACCOUNT_MANAGERS_SUCCESS,
  ACCOUNT_MANAGERS_FAILURE,
  SAVE_ACCOUNT_MANAGERS_REQUEST,
  SAVE_ACCOUNT_MANAGERS_SUCCESS,
  SAVE_ACCOUNT_MANAGERS_FAILURE,
} from './actions';

const initialState = {};

const jobManagers = (state = initialState, action) => {
  switch (action.type) {
    case ACCOUNT_MANAGERS_REQUEST:
    case ACCOUNT_MANAGERS_SUCCESS:
    case ACCOUNT_MANAGERS_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

const saveJobManager = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_ACCOUNT_MANAGERS_REQUEST:
    case SAVE_ACCOUNT_MANAGERS_SUCCESS:
    case SAVE_ACCOUNT_MANAGERS_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export { jobManagers, saveJobManager };
