import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import './pr-alert.scss';

function Alert({ close, value, size, type, onClick: handleClick, scrollToTop }) {
  useEffect(() => {
    if (scrollToTop) window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [value]);

  return (
    <div className={`message-wrapper ${size} ${type} right-part top`}>
      <div className="content">
        <p>{value}</p>
        <span className="divider" />
        {close && (
          <span onClick={() => handleClick && handleClick()} className="x-mark-wrapper">
            <span className="x-mark white" />
          </span>
        )}
      </div>
    </div>
  );
}

Alert.propTypes = {
  close: PropTypes.bool,
  value: PropTypes.string.isRequired,
  size: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  scrollToTop: PropTypes.bool,
};

Alert.defaultProps = {
  close: false,
  size: 'half',
  type: 'error',
  onClick: undefined,
  scrollToTop: false,
};

export default Alert;
