import Immutable from 'immutable';
import store from 'store2';
import { LOCATION_CHANGE } from 'react-router-redux';
import { typeCastToKeyValueObject } from '../../utils/commonUtils';
import { typeCastResponse, validUser } from '../../utils/siteSpecificCommonUtils';
import { authConstants } from '../constants/auth.constants';

const initialState = Immutable.fromJS({
  isLoad: false,
  message: null,
  loadErr: null,
  user: (validUser(store.get('user')) && store.get('user')) || null,
  tempUser: null,
  dontFlushFlag: false,
  sessionExpired: false,
  permissions: {},
  currentTime: null,
  userNotAllowed: false,
  userNotAllowedMsg: '',
  tenantConfig: {},
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    // Loading
    case authConstants.LOAD:
      return state.set('isLoad', true).set('loadErr', null);
    case authConstants.LOAD_SUCCESS:
      return state
        .set('isLoad', false)
        .set('loadErr', null)
        .set('message', typeCastResponse(action, 'message'));
    case authConstants.LOAD_FAIL:
      return state
        .set('isLoad', false)
        .set('message', null)
        .set('loadErr', typeCastResponse(action, 'error'));
    // Update company profile
    case authConstants.UPDATE_COMPANY_PROFILE:
      const user = state.get('user');
      user.company = typeCastResponse(action, 'data', 'object', {});
      store({ user });
      return state.set('user', (validUser(user) && user) || null);
    // Assign user
    case authConstants.ASSIGN_USER:
      return state.set('user', (validUser(action.user) && action.user) || null);
    // Set Permission
    case authConstants.SET_USER_PERMISSIONS:
      return state.set('permissions', typeCastResponse(action, 'permissions', 'object', {}));
    // Temp user
    case authConstants.SAVE_TEMP_USER:
      return state.set('tempUser', action.user);
    // Reset reducer
    case authConstants.RESET_MESSAGE:
      return state.set('message', null).set('loadErr', null);
    case authConstants.FLUSH:
      return initialState
        .set('user', null)
        .set('SAAS_ADMIN_TOKEN', null)
        .set('tenantConfig', state.get('tenantConfig'));
    // Reset state on location change
    case LOCATION_CHANGE:
      return state
        .set('message', null)
        .set('loadErr', null)
        .set('currentTime', new Date())
        .set('userNotAllowed', false)
        .set('userNotAllowedMsg', '');
    case authConstants.SESSION_EXPIRED:
      return state.set('sessionExpired', action.sessionExpired).set('user', action.user);
    case authConstants.USER_NOT_ALLOWED:
      return state
        .set('userNotAllowed', action.userNotAllowed)
        .set('userNotAllowedMsg', action.message);
    case authConstants.UPADATE_USER:
      return state.set('user', action.user);
    case authConstants.TENANT_CONFIG:
      return state.set('tenantConfig', typeCastResponse(action, 'data', 'object', {}));
    // Default
    default:
      return state;
  }
}
