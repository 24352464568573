import { draftFromHtml, getTextFromHTML } from '../../../components/final-form/ff-HTML-editor';
import {
  strictValidArrayWithLength,
  validObjectWithParameterKeys,
} from '../../../utils/commonUtils';

export const updateHTMLBodyContent = (obj = {}) => {
  let htmlBodyString = obj.html_body;
  if (strictValidArrayWithLength(obj.users) && validObjectWithParameterKeys(obj.users, ['name'])) {
    obj.users.forEach((el) => {
      const htmlBody = htmlBodyString.split(el._id);
      const start = htmlBody[1].search('>@');
      const end = htmlBody[1].search('</a');
      const text = `${htmlBody[1].substring(0, start + 1)}<strong>@${
        el.name
      }</strong>${htmlBody[1].substring(end)}`;
      htmlBodyString = htmlBody[0] + el._id + text;
    });
  }
  return {
    ...obj,
    html_body: htmlBodyString,
    body: getTextFromHTML(draftFromHtml(htmlBodyString)),
  };
};

export const convertHTMLData = (arr = []) => {
  const data = arr.map((v) => updateHTMLBodyContent(v));
  return data || [];
};

export const convertEditNoteContent = (htmlText = '') => {
  const commentVal = htmlText.replaceAll('href', 'data-id');
  const splitArr = commentVal.split('data-id="');
  const users = splitArr.map((v, i) => i !== 0 && v.slice(0, 24)).filter((s) => s);
  return { html_body: commentVal, users };
};
