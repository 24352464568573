import { combineReducers } from 'redux-immutable';
import { candidate } from '../../containers/Feedback/Candidate/reducer';
import { client } from '../../containers/Feedback/Client/reducer';
import { optionsList } from '../../containers/Feedback/reducer';

export default combineReducers({
  candidate,
  client,
  optionsList,
});
