import { LOCATION_CHANGE } from 'react-router-redux';
import { RULES_LIST_FAILURE, RULES_LIST_PROCESSING, RULES_LIST_SUCCESS } from './actions';

const initialState = {};

const rules = (state = initialState, action) => {
  switch (action.type) {
    case RULES_LIST_PROCESSING:
    case RULES_LIST_SUCCESS:
    case RULES_LIST_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case LOCATION_CHANGE:
      return {
        ...state,
        err: null,
      };
    default:
      return state;
  }
};

export default rules;
