const getDataChangedString = (dataChanged, action_on) => {
  switch (dataChanged) {
    case 'job_function':
      return 'Job Function';
    case 'experiences':
      return 'Experience';
    case 'titles':
      return 'Title';
    case 'duties':
      return 'Duties';
    case 'location':
      return 'Location';
    case 'location_2':
      return 'Location';
    case 'proximity':
      return 'Proximity';
    case 'is_remote':
      return 'Remote';
    case 'is_relocation':
      return 'Relocation';
    case 'job_type_1':
      return 'Job Type';
    case 'job_type_2':
      return 'Employment Type';
    case 'contract_duration':
      return 'Contract Duration';
    case 'compensation_min':
      return 'Compensation';
    case 'compensation_max':
      return 'Compensation';
    case 'start_date':
      return 'Start Date';
    case 'no_of_hires':
      return 'Number Of Hires';
    case 'work_authorization':
      return 'Work Authorization';
    case 'description_file':
      return 'Description File';
    case 'description_url':
      return 'Description URL';
    case 'description':
      return 'Description';
    case 'Spacing In Interview':
      return 'Gap between Interviews';
    case 'Emp Num':
      return 'Payroll ID';
    case 'Dh Charge Value':
      return 'Perm Charge Value';
    case 'Po Expiration Notify':
      return 'Po Expiration Type';
    case 'Background Paid By Premier':
      return 'Background Paid By Tenant';
    case 'Intacct Customer Id':
      return 'ERP Customer ID';
    case 'Source':
      if (action_on === 'Company') return 'How did you hear about us?';
      break;
    default:
      break;
  }
  return dataChanged;
};

const getDataChangedArray = (data) => data.map((val) => getDataChangedString(val));

export { getDataChangedArray, getDataChangedString };
