import { combineReducers } from 'redux-immutable';
import { timesheetsList } from '../../containers/WorkBench/Timesheets/List/reducer';
import { timesheet } from '../../containers/WorkBench/Timesheets/View/reducer';
import { transaction } from '../../containers/WorkBench/Timesheets/Transactions/reducer';
import { transactionsLogs } from '../../containers/WorkBench/Timesheets/Transactions/logs/reducer';

export default combineReducers({
  timesheetsList,
  timesheet,
  transaction,
  transactionsLogs,
});
