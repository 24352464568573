import React, { useRef, useEffect, useState, useCallback } from 'react';
import './ff-data-textarea.scss';
import { isFunction } from '../../../utils/commonUtils';

function FfDataTextareaComponent({
  input: { value: fieldValue = '', type = '', onChange } = {},
  placeholder,
  meta: { touched, error },
  isDisable,
}) {
  const textareaEl = useRef(null);
  const perentTextareaEl = useRef(null);
  const [value, setValue] = useState(null);

  useEffect(() => {
    textareaEl.current.innerText = fieldValue;
  }, []);

  useEffect(() => {
    setValue(fieldValue);
  }, [fieldValue]);

  const onTextChange = () => {
    onChange(textareaEl.current.innerText);
  };

  const onBlur = useCallback(() => {
    // Update coordinates
    if (textareaEl.current.innerHTML.replace('<br>', ''))
      perentTextareaEl.current.classList.add('filled');
    else {
      perentTextareaEl.current.classList.remove('filled');
      perentTextareaEl.current.classList.remove('focused');
    }
  }, []);

  const onFocus = () => perentTextareaEl.current.classList.add('focused');

  useEffect(() => {
    textareaEl.current.addEventListener('input', onTextChange, false);
    textareaEl.current.addEventListener('blur', onBlur, false);
    textareaEl.current.addEventListener('focus', onFocus, false);
    return () => {
      if (textareaEl && textareaEl.current) {
        textareaEl.current.removeEventListener('input', onTextChange);
        textareaEl.current.removeEventListener('focus', onFocus);
        textareaEl.current.removeEventListener('blur', onBlur);
      }
    };
  }, []);

  return (
    <div
      className={`pr-data-textarea-wrapper ${type || ''} ${isDisable ? 'disable' : ''} ${
        value ? 'filled' : ''
      }`}
      tip="error top-left"
      ref={perentTextareaEl}
      data-description={error}
      is-error={touched && !!error ? 'true' : 'false'}
    >
      <div className="input-wrapper">
        <div id="textarea" ref={textareaEl} contentEditable={!isDisable} spellCheck="false" />
        {isFunction(placeholder) ? (
          placeholder()
        ) : (
          <span className="placeholder">{placeholder}</span>
        )}
      </div>
    </div>
  );
}

export default FfDataTextareaComponent;
