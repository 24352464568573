import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { findDOMNode } from 'react-dom';
import {
  importImagesFromImageDirectory,
  loadRoute,
  strictValidString,
} from '../../utils/commonUtils';
import './style.scss';
import PrTooltipComponent from '../../components/--primitives/pr-tooltip';

const images = importImagesFromImageDirectory(
  require.context('../../assets/sidebar', false, /\.(png|jpe?g|svg)$/),
);

const subMenus = [
  {
    name: 'Payroll',
    route: '/reports/payroll',
    financial: true,
  },
  {
    name: 'Benefits',
    route: '/reports/benefits',
    financial: true,
  },
  {
    name: 'People OPS',
    route: '/reports/people-ops',
    financial: false,
  },
  {
    name: 'Invoice',
    route: '/reports/invoice',
    financial: true,
  },
  {
    name: 'Weekly Metrics',
    route: '/reports/weekly-metrics',
    financial: false,
  },
  {
    name: 'Company Reports',
    route: '/reports/company-reports',
    financial: true,
  },
  {
    name: 'KPI Reports',
    route: '/reports/kpi-reports',
    financial: false,
  },
];

function SidebarComponent({
  location,
  dispatch,
  tenantConfig,
}) {
  const [isOpenMenu, setMenuOpen] = useState(false);
  const { pathname } = location;
  const leftSidebarEl = useRef(null);
  const CURRENT_WB_NAME = pathname.split('/')[1];
  const [isOpenSubMenu, setSubMenuOpen] = useState('');

  const handleDocumentClick = (event) => {
    const isSubMenuOpen =
      leftSidebarEl.current && leftSidebarEl.current.getAttribute('data-submenu');
    const domNode = findDOMNode(leftSidebarEl.current);
    if (!domNode || !domNode.contains(event.target) || isSubMenuOpen === 'opened') {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    window.document.addEventListener('click', handleDocumentClick);
    return () => {
      window.document.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  const setSubMenuPosition = () => {
    if (strictValidString(isOpenSubMenu)) {
      const leftSidebar = document.querySelector('.left_sidebar').getBoundingClientRect();
      const elmpos = document.querySelector('.activeSubmenu').getBoundingClientRect();
      const tooltip = document.querySelector('.left-bar-popup');
      const tooltipContentPos = tooltip.querySelector('.left-bar-popup ul').getBoundingClientRect();
      tooltip.style.left = `${elmpos.left + elmpos.width + 10}px`;
      tooltip.style.width = '100%';
      tooltip.style.bottom = 'unset';
      const noOfItem = subMenus.filter(
        ({ financial = false }) => enableFinancial || !financial,
      ).length;
      if (leftSidebar.height < elmpos.bottom + tooltipContentPos.height) {
        tooltip.style.top = `${elmpos.top + elmpos.height / 2 - noOfItem * 27.5}px`; //
      } else {
        tooltip.style.top = `${elmpos.top + elmpos.height / 2}px`;
        tooltip.style.bottom = 'unset';
      }
    }
  };

  useEffect(() => {
    if (strictValidString(isOpenSubMenu)) {
      setSubMenuPosition();
    }
  }, [isOpenSubMenu]);

  const onClickEl = (e) => {
    e && e.stopPropagation();
    setMenuOpen(!isOpenMenu);
  };

  const renderMenu = (title, routeToLoad, img, hoverimg, subMenu = false, routeName = '') => {
    const openSubMenuClass = isOpenSubMenu === title ? 'activeSubmenu' : '';
    const activeSubMenuClass =
      strictValidString(CURRENT_WB_NAME) &&
      strictValidString(routeName) &&
      CURRENT_WB_NAME.includes(routeName)
        ? 'active item'
        : 'item submen';
    const linkClass =
      strictValidString(CURRENT_WB_NAME) &&
      strictValidString(routeName) &&
      CURRENT_WB_NAME.includes(routeName)
        ? 'active item'
        : 'item';
    return subMenu ? (
      <a
        className={`${openSubMenuClass} 
          ${activeSubMenuClass}
          `}
        onClick={(e) => {
          isOpenSubMenu === title ? setSubMenuOpen('') : setSubMenuOpen(title);
          onClickEl(e);
        }}
      >
        <img src={images[img]} className="inline" />
        <img src={images[hoverimg]} className="hover-img" />
        <p className="item-info">{title}</p>
      </a>
    ) : (
      <Link
        className={linkClass}
        to={routeToLoad}
      >
        <img src={images[img]} className="inline" />
        <img src={images[hoverimg]} className="hover-img" />
        <p className="item-info">{title}</p>
      </Link>
    );
  };
  const enableFinancial = tenantConfig.finance;
  return (
    <>
      <div
        className="left_sidebar"
        data-submenu={isOpenMenu ? 'opened' : ''}
        ref={leftSidebarEl}
        onScroll={() => (isOpenMenu ? setSubMenuPosition() : null)}
      >
        <div className="left_sidebar_inner">
          {renderMenu(
            'Clients',
            '/clients-list-view',
            'clients-icon.svg',
            'clients-icon-white.svg',
            null,
            'client',
          )}
          {renderMenu(
            'Jobs',
            '/job-list-view',
            'jobs-icon.svg',
            'jobs-icon-white.svg',
            null,
            'job',
          )}
          {renderMenu(
            'Candidates',
            '/candidates-list-view',
            'candidates-icon.svg',
            'candidates-icon-white.svg',
            null,
            'candidate',
          )}
          {renderMenu(
            'Interviews',
            '/interviews-list-view',
            'interviews-icon.svg',
            'interviews-icon-white.svg',
            null,
            'interview',
          )}
          {renderMenu(
            'Placements',
            '/placement-list-view',
            'placements-icon.svg',
            'placements-icon-white.svg',
            null,
            'placement',
          )}
          {enableFinancial &&
            renderMenu(
              'Timesheets',
              '/timesheet-list-view',
              'timesheets-icon.svg',
              'timesheets-icon-white.svg',
              null,
              'timesheet',
            )}
          {enableFinancial &&
            renderMenu(
              'Invoices',
              '/invoices-list-view',
              'invoices-icon.svg',
              'invoices-icon-white.svg',
              null,
              'invoice',
            )}
          {renderMenu(
            'Leads',
            '/leads/list',
            'leads-icon.svg',
            'leads-icon-white.svg',
            null,
            'lead',
          )}
          {renderMenu(
            'Reports',
            false,
            'reports-icon.svg',
            'reports-icon-white.svg',
            true,
            'reports',
          )}
        </div>
      </div>
      <PrTooltipComponent
        type={`bottom left-bar-popup with-triangle shift with-extra-cta ${
          isOpenMenu ? 'in-view' : ''
        }`}
        CTAsList={subMenus
          .filter(({ financial = false }) => enableFinancial || !financial)
          .map((menu) => ({
            name: `<b>${menu.name}</b>`,
            type: pathname === menu.route ? 'active' : '',
            onClick: () => {
              loadRoute(dispatch, menu.route);
              setMenuOpen(false);
            },
          }))}
      />
    </>
  );
}

const mapStateProps = (state) => {
  return {
    tenantConfig: state.get('config').get('tenantConfig'),
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default withRouter(connect(mapStateProps, mapDispatchToProps)(SidebarComponent));
