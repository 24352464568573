import PropTypes from 'prop-types';
import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CustomReactTable from '../../../../components/table/CustomReactTable';
import { notesSuccess } from './actions';
import { getNotes, updateNotes } from './api';
import Details from './detail-modal';
import { showDateTimeInListingWithoutTimezone } from '../../../../utils/stringsHelpers';
import { clipText, importImagesFromImageDirectory } from '../../../../utils/commonUtils';
import PrButton from '../../../../components/--primitives/pr-button';
import PrAnimatedInput from '../../../../components/--primitives/pr-data-input-2';
import { getUsers } from '../../../Dashboard/Modules/AjnaUsers/api';
import '../style.scss';

const images = importImagesFromImageDirectory(
  require.context('../../../../assets/icons', false, /\.(png|jpe?g|svg)$/),
);

const initialTableSearch = {
  page: 1,
  limit: 10,
  sort: 'id',
  order: 'desc',
  filter: {},
};
class Notes extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showDetailsModal: false,
      selectedNote: {},
      userList: [],
      ...initialTableSearch,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    notesSuccess(dispatch, {});
    this.GetUserData();
  }

  GetUserData = async () => {
    const { dispatch } = this.props;
    const val = [1, 1000, 'name', 'asc', { status: 1 }];
    const data = await dispatch(getUsers(...val));
    if (data.rows.length > 0) {
      const list = data.rows.map((v) => ({
        text: v.name,
        value: v.name,
        url: v._id, // `${window.origin}/ajna-user/${v._id}`,
      }));
      this.setState({ userList: list });
    }
  };

  toggleModal = (res = false) => {
    if (res) {
      const { page, limit, sort, order, filter } = this.state;
      this.callAPI([page, limit, sort, order, filter]);
    }
    this.setState({ showDetailsModal: false });
  };

  callAPI = (params) => {
    const {
      callGetNotesAPI,
      jobId,
      interviewId,
      clientId,
      candidateId,
      timesheetId,
      placementId,
      leadId,
    } = this.props;
    callGetNotesAPI(
      jobId,
      clientId,
      interviewId,
      candidateId,
      timesheetId,
      placementId,
      leadId,
      ...params,
    );
  };

  // eslint-disable-next-line class-methods-use-this
  renderInput = (filter, onChange) => (
    // eslint-disable-next-line react/jsx-filename-extension
    <PrAnimatedInput
      type="text"
      onChange={(value) => onChange(value)}
      value={filter ? filter.value : ''}
    />
  );

  renderBodyContent = (props) => (
    <a
      className="make-link"
      onClick={() => {
        this.setState({ selectedNote: props.original }, this.setState({ showDetailsModal: true }));
      }}
    >
      {clipText(props.value, 100)}
    </a>
  );

  ChangePin = async (id = '', values = false) => {
    const { leadId, callUpdateNotesAPI } = this.props;
    const { page, limit, sort, order, filter } = this.state;
    const res = await callUpdateNotesAPI(id, { pin_status: !values }, leadId);
    if (res) this.callAPI([page, limit, sort, order, filter]);
  };

  renderPinImage = ({ value, original }) => (
    <img
      style={{ cursor: 'pointer' }}
      src={images[value ? 'pin-angle-fill.svg' : 'pin-angle.svg']}
      alt="pin"
      onClick={() => {
        this.ChangePin(original._id, value);
      }}
    />
  );

  getColumns = () => [
    {
      Header: 'Note',
      accessor: 'body',
      Filter: ({ filter, onChange }) => this.renderInput(filter, onChange),
      Cell: (props) => this.renderBodyContent(props),
    },
    {
      Header: 'Created By',
      accessor: 'creator',
      minWidth: 40,
      Filter: ({ filter, onChange }) => this.renderInput(filter, onChange),
    },
    {
      Header: 'Created Date',
      accessor: 'date',
      minWidth: 30,
      Cell: ({ value }) => showDateTimeInListingWithoutTimezone(value),
      filterable: false,
    },
    {
      Header: 'Last Activity Date',
      accessor: 'updatedAt',
      minWidth: 40,
      Cell: ({ value, original }) =>
        original.date !== value ? showDateTimeInListingWithoutTimezone(value) : 'NA',
      filterable: false,
    },
    {
      Header: '# of Comments',
      accessor: 'comments',
      minWidth: 30,
      Cell: ({ value }) => value || 0,
      filterable: false,
    },
    {
      Header: 'PIN',
      accessor: 'pin_status',
      minWidth: 20,
      filterable: false,
      Cell: (params) => this.renderPinImage(params),
    },
  ];

  render() {
    const {
      dispatch,
      notes,
      switchAddAndList,
      jobId,
      interviewId,
      clientId,
      companyName,
      jobTitle,
      candidateName,
      interviewIdToShow,
      candidateId,
      timesheetId,
      shouldShowAddOption,
      leadId,
      leadName,
      placementId,
      location,
      activeLinks,
    } = this.props;

    const { search } = location;
    const urlParams = new URLSearchParams(search);
    const activeNoteId = urlParams.get('active') || undefined;
    const { showDetailsModal, selectedNote, limit, userList } = this.state;
    const columns = this.getColumns();
    const { count = 0 } = notes;
    return (
      <>
        <div className="card">
          <div className="row space-between vertical-center">
            <div className="col">
              <h2 className="card-title">Notes</h2>
              <div className="card-subtitle">
                {`${count}`} {count > 1 ? 'results found' : 'result found'}
              </div>
            </div>
            <div className="col">
              {shouldShowAddOption && (
                <PrButton
                  value="Add Note"
                  type="light-blue medium tall"
                  onClick={switchAddAndList}
                />
              )}
            </div>
          </div>
          <p>&nbsp;</p>
          <CustomReactTable
            defaultData={notes}
            columns={columns}
            callDataAPI={(...params) => {
              this.setState(
                {
                  page: params[0],
                  sort: params[2],
                  order: params[3],
                  filter: params[4],
                },
                () => this.callAPI(params),
              );
            }}
            defaultPageSize={limit}
          />
        </div>
        {(showDetailsModal || !!activeNoteId) && (
          <Details
            dispatch={dispatch}
            isOpen={showDetailsModal || !!activeNoteId}
            toggleModal={this.toggleModal}
            note={selectedNote}
            companyId={clientId}
            companyName={companyName}
            jobId={jobId}
            jobTitle={jobTitle}
            candidateId={candidateId}
            candidateName={candidateName}
            timesheetId={timesheetId}
            interviewId={interviewIdToShow}
            interview_Id={interviewId}
            leadId={leadId}
            leadName={leadName}
            placementId={placementId}
            activeNoteId={activeNoteId}
            userList={userList}
            activeLinks={activeLinks}
          />
        )}
      </>
    );
  }
}

Notes.propTypes = {
  callGetNotesAPI: PropTypes.func.isRequired,
  callUpdateNotesAPI: PropTypes.func.isRequired,
  notes: PropTypes.objectOf(PropTypes.shape).isRequired,
  location: PropTypes.objectOf(PropTypes.shape),
  dispatch: PropTypes.func.isRequired,
  switchAddAndList: PropTypes.func.isRequired,
  jobId: PropTypes.string.isRequired,
  companyName: PropTypes.string,
  jobTitle: PropTypes.string,
  candidateName: PropTypes.string,
  clientId: PropTypes.string.isRequired,
  interviewId: PropTypes.string,
  interviewIdToShow: PropTypes.number,
  timesheetId: PropTypes.string,
  candidateId: PropTypes.string,
  shouldShowAddOption: PropTypes.bool,
  placementId: PropTypes.string,
  leadId: PropTypes.string,
  leadName: PropTypes.string,
  activeLinks: PropTypes.objectOf(PropTypes.shape),
};

Notes.defaultProps = {
  companyName: undefined,
  jobTitle: undefined,
  candidateName: undefined,
  interviewId: undefined,
  interviewIdToShow: undefined,
  candidateId: undefined,
  timesheetId: undefined,
  shouldShowAddOption: true,
  placementId: undefined,
  leadId: undefined,
  leadName: undefined,
  location: undefined,
  activeLinks: {},
};

const mapStateProps = (state) => {
  const { data: { rows = [], count = 0 } = {}, loading: notesLoading } = state
    .get('note')
    .get('notes');
  const { data: company, loading: companyLoading } = state
    .get('clientWorkBench')
    .get('companyDetails');
  return {
    notes: {
      data: rows,
      count,
      loading: notesLoading || companyLoading,
    },
    company,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  callGetNotesAPI: (...params) => dispatch(getNotes(...params)),
  callUpdateNotesAPI: (...params) => dispatch(updateNotes(...params)),
});

export default withRouter(connect(mapStateProps, mapDispatchToProps)(Notes));
