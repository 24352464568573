import store from 'store2';
import { push } from 'react-router-redux';
import {
  validObjectWithParameterKeys,
  typeCastToKeyValueObject,
  typeCastToString,
} from '../../utils/commonUtils';
import {
  validUser,
  validLoggedInUser,
  validInvitedCompanyUser,
} from '../../utils/siteSpecificCommonUtils';
import {
  authConstants,
  jobConstants,
  notificationsConstants,
  commonConstants,
  errorConstants,
} from '../constants';
import { socketConstants } from '../../utils/socketConstants';
import { DEFAULT_MILLISECONDS_TO_SHOW_MESSAGES } from '../../utils/constants';
import { messages } from '../../language';

/**
 * To load users account details
 * Access role allowed - any
 * @param forced: boolean
 */
export const load = (forced) => async (dispatch, getState, api) => {
  dispatch({ type: authConstants.LOAD });
  try {
    // Call api to fetch user data
    const res = await api.get('/users/authenticate');
    // If not valid response object
    if (!(validObjectWithParameterKeys(res, ['data']) && validLoggedInUser(res.data))) {
      return false;
    }
    // Else if valid response continue
    dispatch({
      type: authConstants.ASSIGN_USER,
      user: res.data,
    });
    dispatch({ type: socketConstants.JOIN, _id: res.data._id });
    dispatch({ type: authConstants.LOAD_SUCCESS });
  } catch (err) {
    // If an error occurs, set error field
    dispatch({ type: commonConstants.SET_SIDEBAR_STATUS, status: false });
    dispatch({ type: authConstants.LOAD_FAIL });
    store({
      SAAS_ADMIN_TOKEN: null,
      user: null,
    });
  }
};

/**
 * To load users permissions
 */
export const loadPermissions = () => async (dispatch, getState, api) => {
  dispatch({ type: authConstants.LOAD });
  try {
    // Call api to fetch user permissions data
    const res = await api.get('/users/permissions');
    // Remove it in the last commit of AJNA-500
    dispatch({
      type: authConstants.SET_USER_PERMISSIONS,
      permissions: res.data,
    });
    dispatch({ type: authConstants.LOAD_SUCCESS });
  } catch (err) {
    // If an error occurs, set error field
    dispatch({ type: authConstants.LOAD_FAIL });
  }
};

/**
 * Authenticates user for logging in
 * Access role allowed - any
 * @param data: object containing email: string & password: string
 */
export const login = (data) => async (dispatch, getState, api) => {
  // Start loading
  dispatch({ type: authConstants.LOAD });
  try {
    // Call api to authenticate user
    const res = await api.post('/users/login', { data });
    // If not valid response object
    if (!(validObjectWithParameterKeys(res, ['data']) && validLoggedInUser(res.data))) {
      await dispatch({
        type: authConstants.LOAD_FAIL,
        error: messages.DEFAULT_ERROR_MESSAGE,
      });
      dispatch({
        type: errorConstants.SET_ERROR,
        error: messages.DEFAULT_ERROR_MESSAGE,
      });
      return false;
    }
    if (validObjectWithParameterKeys(res.data, ['token'])) {
      store({
        SAAS_ADMIN_TOKEN: typeCastToString(res.data.token),
        user: res.data,
      });
    }
    // Else if valid response continue
    await dispatch({
      type: authConstants.ASSIGN_USER,
      user: res.data,
    });
    dispatch({ type: socketConstants.JOIN, _id: res.data._id });
    await dispatch({ type: authConstants.LOAD_SUCCESS });
    // It will call twice if user is logging in for the first time.
    await dispatch(loadPermissions());
    return true;
  } catch (error) {
    // If an error occurs, set error field
    dispatch({
      type: authConstants.LOAD_FAIL,
      error,
    });
    dispatch({
      type: errorConstants.SET_ERROR,
      error: error || messages.DEFAULT_ERROR_MESSAGE,
    });
    return false;
  }
};

/**
 * Sends reset password email to submitted email address
 * Access role allowed - any
 * @param data: object containing email: string
 */
export const forgotPassword = (data) => async (dispatch, getState, api) => {
  // Start loading
  dispatch({ type: authConstants.LOAD });
  try {
    // Call api to send reset password email
    const res = await api.post('/users/forgot-password', { data });
    dispatch({
      type: authConstants.LOAD_SUCCESS,
      message: typeCastToString(res.message),
    });
  } catch (error) {
    // If an error occurs, set error field
    dispatch({
      type: authConstants.LOAD_FAIL,
      error,
    });
    dispatch({
      type: errorConstants.SET_ERROR,
      error: error || messages.DEFAULT_ERROR_MESSAGE,
    });
  }
};

/**
 * To validate user
 * Access role allowed - any
 * @param data: object containing key: string
 * @param type: string type of user
 */
export const validateUser = (data, type) => async (dispatch, getState, api) => {
  // Start loading
  dispatch({ type: authConstants.LOAD });
  try {
    // Call post session api to verify
    const res = await api.post('/users/validate', { data });
    // Switch validation on type of user
    let validate = () => {/*empty fun */};
    switch (type) {
      case 'invitedCompanyUser':
        validate = validInvitedCompanyUser;
        break;
      default:
        validate = validUser;
        break;
    }
    // If not valid response object
    if (!(validObjectWithParameterKeys(res, ['data']) && validate(res.data))) {
      dispatch({
        type: authConstants.LOAD_FAIL,
        error: messages.DEFAULT_ERROR_MESSAGE,
      });
      dispatch({
        type: errorConstants.SET_ERROR,
        error: messages.DEFAULT_ERROR_MESSAGE,
      });
      return false;
    }
    // Else if valid response continue
    await dispatch({
      type: authConstants.SAVE_TEMP_USER,
      user: res.data,
    });
    dispatch({ type: authConstants.LOAD_SUCCESS });
    return res.data;
  } catch (error) {
    // If an error occurs, set error field
    dispatch({
      type: authConstants.LOAD_FAIL,
      error,
    });
    dispatch({
      type: errorConstants.SET_ERROR,
      error: error || messages.DEFAULT_ERROR_MESSAGE,
    });
  }
};

/**
 * To activate user
 * Access role allowed - any
 * @param data: object containing key: string
 */
export const activateUser = (data) => async (dispatch, getState, api) => {
  // Start loading
  dispatch({ type: authConstants.LOAD });
  try {
    // Call post session api to set password
    const res = await api.post('/users/activate', { data });
    const validResponseFlag =
      validObjectWithParameterKeys(res, ['data']) && validLoggedInUser(res.data);
    // If not valid response object
    if (!validResponseFlag) {
      dispatch({
        type: authConstants.LOAD_FAIL,
        error: messages.DEFAULT_ERROR_MESSAGE,
      });
      return false;
    }
    // Else if valid response continue
    dispatch({
      type: authConstants.ASSIGN_USER,
      user: res.data,
    });
    if (validObjectWithParameterKeys(res.data, ['token'])) {
      store({
        SAAS_ADMIN_TOKEN: typeCastToString(res.data.token),
        user: res.data,
      });
    }
    dispatch({ type: socketConstants.JOIN, _id: res.data._id });
    dispatch({ type: authConstants.LOAD_SUCCESS });
    await dispatch(loadPermissions());
    return true;
  } catch (error) {
    // If an error occurs, set error field
    dispatch({
      type: authConstants.LOAD_FAIL,
      error,
    });
    dispatch({
      type: errorConstants.SET_ERROR,
      error: error || messages.DEFAULT_ERROR_MESSAGE,
    });
    return false;
  }
};

/**
 * To reset password of user
 * Access role allowed - any
 * @param data: object containing key: string
 */
export const resetPassword = (data) => async (dispatch, getState, api) => {
  // Start loading
  dispatch({ type: authConstants.LOAD });
  try {
    // Call post session api to reset password
    const res = await api.post('/users/reset-password', { data });
    // If not valid response object
    if (!validObjectWithParameterKeys(res, ['message'])) {
      dispatch({
        type: authConstants.LOAD_FAIL,
        error: messages.DEFAULT_ERROR_MESSAGE,
      });
      dispatch({
        type: errorConstants.SET_ERROR,
        error: messages.DEFAULT_ERROR_MESSAGE,
      });
      return false;
    }
    // Else if valid response continue
    dispatch({
      type: authConstants.LOAD_SUCCESS,
      message: typeCastToString(res.message),
    });
    return true;
  } catch (error) {
    // If an error occurs, set error field
    dispatch({
      type: authConstants.LOAD_FAIL,
      error,
    });
    dispatch({
      type: errorConstants.SET_ERROR,
      error: error || messages.DEFAULT_ERROR_MESSAGE,
    });
    return false;
  }
};

/**
 * To change password of user
 * Access role allowed - any
 * @param data: object containing key: string
 */
export const changePassword = (data) => async (dispatch, getState, api) => {
  // Start loading
  dispatch({ type: authConstants.LOAD });
  try {
    // Call post session api to reset password
    const res = await api.post('/users/change-password', { data });
    // If not valid response object
    if (!validObjectWithParameterKeys(res, ['message'])) {
      dispatch({
        type: authConstants.LOAD_FAIL,
        error: messages.DEFAULT_ERROR_MESSAGE,
      });
      return false;
    }
    // Else if valid response continue
    dispatch({
      type: authConstants.LOAD_SUCCESS,
      message: typeCastToString(res.message),
    });
  } catch (error) {
    // If an error occurs, set error field
    dispatch({
      type: authConstants.LOAD_FAIL,
      error,
    });
  }
};

/**
 * Checks email availability
 * Access role allowed - any
 * @param data: object containing email: string
 */
export const checkEmailAvailability = (data) => async (dispatch, getState, api) => {
  try {
    // Call post session api to verify
    const res = await api.post('/users/check-email-availability', { data });
    // If not valid response
    if (
      !(
        validObjectWithParameterKeys(res, ['data']) &&
        validObjectWithParameterKeys(res.data, ['exists'])
      )
    ) {
      dispatch({
        type: errorConstants.SET_ERROR,
        error: messages.DEFAULT_ERROR_MESSAGE,
      });
      return false;
    }
    // Else if valid response continue
    return res.data.exists;
  } catch (err) {
    // If an error occurs, set error field
    dispatch({
      type: errorConstants.SET_ERROR,
      error: err || messages.DEFAULT_ERROR_MESSAGE,
    });
    return false;
  }
};

/**
 * To save company profile upon form submission
 * Access role allowed - admin/user
 * @param data: object containing client profile table values
 */
export const updateCompanyProfile = (data) => async (dispatch, getState, api) => {
  // Start loading
  dispatch({ type: authConstants.LOAD });
  try {
    // Get jobs tree from api
    await api.post('/companies/update', { data });
    dispatch({
      type: authConstants.LOAD_SUCCESS,
      message: '', // messages.SUCCESS_MESSAGE
    });
    return true;
  } catch (error) {
    // If an error occurs, set error field
    await dispatch({
      type: authConstants.LOAD_FAIL,
      error,
    });
    dispatch({
      type: errorConstants.SET_ERROR,
      error: error || messages.DEFAULT_ERROR_MESSAGE,
    });
    return false;
  }
};

/**
 * To reset message fields in reducer
 */
export const authResetMessage = (defaultTimeout = DEFAULT_MILLISECONDS_TO_SHOW_MESSAGES) => {
  return (dispatch) =>
    setTimeout(() => {
      dispatch({ type: authConstants.RESET_MESSAGE });
    }, defaultTimeout || DEFAULT_MILLISECONDS_TO_SHOW_MESSAGES);
};

/**
 * To set sessionExpired as true
 * Access role allowed - any
 * @param error: string
 */
export const validateSession = (status) => async (dispatch, getState) => {
  // Get user from reducer
  const user = typeCastToKeyValueObject(getState().get('auth').get('user'));
  if (validLoggedInUser(user) && status === 403) {
    await dispatch({
      type: authConstants.SESSION_EXPIRED,
      user,
      sessionExpired: true,
    });
  }
};

/**
 * To logged out user on session timed out
 * only for logged in users - postlogin
 */
export const expireSession = () => async (dispatch, getState) => {
  await dispatch({
    type: authConstants.SESSION_EXPIRED,
    user: null,
    sessionExpired: false,
  });
  await dispatch(logout());
};

/**
 * To logout user, this flushes or empties all browser states
 * Access role allowed - logged in user of any role
 */
export const logout = () => async (dispatch, getState) => {
  // Flush this reducer on condition based
  await dispatch({ type: authConstants.FLUSH });
  await dispatch({ type: jobConstants.FLUSH });
  await dispatch({ type: notificationsConstants.FLUSH });
  await dispatch({ type: socketConstants.DISCONNECT });
  // Empty store
  if (store.get('rememberMe')) {
    const data = {
      email: store.get('email'),
      password: store.get('password'),
      rememberMe: store.get('rememberMe'),
    };
    store(false);
    store(data);
  } else {
    store(false);
  }

  // Redirect user
  dispatch(push('/'));
};

/**
 * To set error message when a user is not
 * allowed to do that action (role based security)
 * @param error: string
 */
export const isUserAllowedToPerformAction = (status, message) => async (dispatch, getState) => {
  // Get user from reducer
  const user = typeCastToKeyValueObject(getState().get('auth').get('user'));
  if (validLoggedInUser(user) && status === 401) {
    await dispatch({
      type: authConstants.USER_NOT_ALLOWED,
      userNotAllowed: true,
      message,
    });
  }
};
