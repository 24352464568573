import {
  INTERVIEW_LIST_REQUEST,
  INTERVIEW_LIST_SUCCESS,
  INTERVIEW_LIST_FAILURE,
  MANAGERS_LIST_REQUEST,
  MANAGERS_LIST_FAILURE,
  MANAGERS_LIST_SUCCESS,
  SETTINGS_REQUEST,
  SETTINGS_SUCCESS,
  SETTINGS_FAILURE,
} from './actions';

const initialState = {};

const interviews = (state = initialState, action) => {
  switch (action.type) {
    case INTERVIEW_LIST_REQUEST:
    case INTERVIEW_LIST_SUCCESS:
    case INTERVIEW_LIST_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

const managers = (state = initialState, action) => {
  switch (action.type) {
    case MANAGERS_LIST_REQUEST:
    case MANAGERS_LIST_SUCCESS:
    case MANAGERS_LIST_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

const settings = (state = initialState, action) => {
  switch (action.type) {
    case SETTINGS_REQUEST:
    case SETTINGS_SUCCESS:
    case SETTINGS_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export { interviews, managers, settings };
