import React, { useState, useRef, createRef } from 'react';
import { Link } from 'react-router-dom';
import './pr-tooltip.scss';

const PrTooltipComponent = ({ CTAsList, type, restProps, children }) => {
  const [copied, setCopied] = useState(false);

  const dynamicRefs = useRef(children ? null : CTAsList.map(() => createRef()));
  const reloadRoute = (e) => {
    const currentTarget = e.target.getAttribute('href');
    if (currentTarget === window.location.pathname) {
      window.location.reload();
    }
  };

  const handleCopyToClipBoard = (index) => {
    const tempInput = document.createElement('input');
    tempInput.value = dynamicRefs.current[index].current.innerHTML;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');

    setCopied(true);
    document.body.removeChild(tempInput);

    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <div {...restProps} className={`pr-tooltip-wrapper ${type}`}>
      {children ? (
        <div className="tooltip-child">{children}</div>
      ) : (
        <ul>
          {CTAsList.map((value, index) => {
            const keyName = 'key-'+index;
            const valueOnClick = value.onClick ? value.onClick : null;
            return (
            <li
              key={keyName}
              className={`${value.type} ${value.disabled ? 'disabled' : ''} ${
                value.cursor ? 'pointer' : ''
              }`}
              onClick={
                value.copyToClipboard
                  ? () => handleCopyToClipBoard(index)
                  : valueOnClick
              }
            >
              {value.ico ? <div className="ico-wrapper">{value.ico}</div> : null}
              {value.url ? (
                <Link onClick={reloadRoute} to={value.url}>
                  {value.title}
                </Link>
              ) : null}
              {value.title && !value.url ? <p className="title">{value.title}</p> : null}
              {value.list ? (
                <ul>
                  {value.list.map((val, i) => {
                    const key = 'key-'+i;
                    return (
                    <li key={key}>{val}</li>
                  )})}
                </ul>
              ) : null}
              <p
                ref={dynamicRefs.current[index]}
                tip={value.copyToClipboard && copied && 'bottom'}
                data-description={value.copyToClipboard && copied && 'Copied !'}
                dangerouslySetInnerHTML={{ __html: value.name }}
              />
            </li>
          )})}
        </ul>
      )}
      <span className="triangle"></span>
    </div>
  );
};

export default PrTooltipComponent;
