import React from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  InterviewsAtRisk,
  ClientsAtRisk,
  JobsAtRisk,
  CandidatesAtRisk,
  AssignmentsAtRisk,
} from '../../../routes/LoadableComponents';

function Risk() {
  return (
    <Switch>
      <Route exact path="/risk/interviews" component={InterviewsAtRisk} />
      <Route exact path="/risk/clients" component={ClientsAtRisk} />
      <Route exact path="/risk/jobs" component={JobsAtRisk} />
      <Route exact path="/risk/candidates" component={CandidatesAtRisk} />
      <Route exact path="/risk/assignments" component={AssignmentsAtRisk} />
    </Switch>
  );
}

export default Risk;
