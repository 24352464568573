import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MainRoute from '../../routes';

export default class App extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
  };

  // Renders app
  render() {
    const { history } = this.props;
    // Renders app segment
    return <MainRoute history={history} />;
  }
}
