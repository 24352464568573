import Immutable from 'immutable';
import { LOCATION_CHANGE } from 'react-router-redux';
import { reportsConstants } from './constants';
import { typeCastResponse } from '../../../../utils/siteSpecificCommonUtils';

const initialState = Immutable.fromJS({
  isLoad: false,
  isLoadInner: false,
  loadErr: null,
  message: null,
  list: {
    data: [],
    count: 0,
    loading: false,
  },
});

export const Reports = (state = initialState, action) => {
  switch (action.type) {
    case reportsConstants.LOAD:
      return state.set('isLoad', true).set('loadErr', null);
    case reportsConstants.LOAD_INNER:
      return state.set('isLoadInner', true).set('loadErr', null);
    case reportsConstants.LOAD_SUCCESS:
      return state
        .set('isLoad', false)
        .set('isLoadInner', false)
        .set('loadErr', null)
        .set('message', typeCastResponse(action, 'message'));
    case reportsConstants.LOAD_FAIL:
      return state
        .set('isLoad', false)
        .set('isLoadInner', false)
        .set('message', null)
        .set('loadErr', typeCastResponse(action, 'error'));
    case reportsConstants.GET_LIST:
      return state.set('list', typeCastResponse(action, 'data', 'object', []));
    // Reset state on location change
    case LOCATION_CHANGE:
      return initialState;
    default:
      return state;
  }
};
