export const timesheetConstants = {
  DETAIL_PROCESSING: ' timesheet/DETAIL_PROCESSING',
  DETAIL_SUCCESS: 'timesheet/DETAIL_SUCCESS',
  DETAIL_FAILURE: 'timesheet/DETAIL_FAILURE',
  ADD_DETAIL_PROCESSING: ' timesheet/ADD_DETAIL_PROCESSING',
  ADD_DETAIL_SUCCESS: 'timesheet/ADD_DETAIL_SUCCESS',
  ADD_DETAIL_FAILURE: 'timesheet/ADD_DETAIL_FAILURE',
  LAST_SUBMITTED_PROCESSING: 'timesheet/LAST_SUBMITTED_PROCESSING',
  LAST_SUBMITTED_SUCCESS: 'timesheet/LAST_SUBMITTED_SUCCESS',
  LAST_SUBMITTED_FAILURE: 'timesheet/LAST_SUBMITTED_FAILURE'
};
