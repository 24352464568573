import React from 'react';
import PropTypes from 'prop-types';
import S3Upload from './s3-upload';
import Alert from '../--primitives/pr-alert';
import PrProgressLineComponent from '../--primitives/pr-progress-line';
import PrProgressComponent from '../--primitives/pr-progress';
import Loading from '../--primitives/pr-loading';

export default class FileUploader extends React.Component {
  static get propTypes() {
    return {
      onFileUpload: PropTypes.func.isRequired,
      onUploadComplete: PropTypes.func.isRequired,
      signingUrl: PropTypes.string,
      saveFileError: PropTypes.string,
      files: PropTypes.array,
      isDisabled: PropTypes.bool,
    };
  }

  state = {
    progress: 0,
    status: false,
    error: null,
    onUploadFinishResponse: [],
  };

  componentDidMount() {
    const { files, signingUrl, s3Path, isDisabled } = this.props;
    try {
      new S3Upload({
        // eslint-disable-line
        files,
        signingUrl,
        s3Path,
        onProgress: this.onUploadProgress,
        onError: this.onUploadError,
        onFinishS3Put: this.onUploadFinish,
        uploadRequestHeaders: {},
        isDisabled,
      });
    } catch (e) {
      console.log('-')
    }
  }

  onUploadProgress = (progress, status) => this.setState({ progress, status });

  onUploadError = (error) => this.setState({ error });

  onUploadFinish = (signResult) => {
    const { onUploadFinishResponse } = this.state;
    const { files, onFileUpload, onUploadComplete } = this.props;
    if (signResult && signResult.data) {
      onUploadFinishResponse.push(signResult.data);
    }
    if (onUploadFinishResponse.length === files.length) {
      const data = files.map((file, i) => ({
        name: file.name,
        type: file.type,
        path: onUploadFinishResponse[i].split('?')[0],
      }));
      onFileUpload(data)
        .then(() => onUploadComplete(data))
        .catch((error) => this.setState({ error: error.message || error }));
    }
  };

  render() {
    const { saveFileError } = this.props;
    const { progress, error } = this.state;
    const err = error || saveFileError;
    return (
      <div className="text-center">
        <ul>
          <li>
            {!err && <PrProgressComponent progress={progress} action="dynamic-progress" />}
            {err && <Alert size="full" type="error" value={err} />}
          </li>
        </ul>
      </div>
    );
  }
}
