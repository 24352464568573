import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import Moment from 'moment';
import App from './containers/App';
import './index.scss';
import { store, history } from './redux/createStore';
import ErrorPopup from './components/pop-up/error-popup';
import SuccessPopup from './components/pop-up/success-popup2';
import 'moment/locale/en-gb';
import { unregister } from './registerServiceWorker';

Moment.locale('en-gb');

ReactDOM.render(
  <Provider store={store}>
    <ErrorPopup />
    <SuccessPopup />
    <App history={history} />
  </Provider>,
  document.getElementById('root'),
);

unregister();
