import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './styles.scss';

function Option({ children, className, activeClassName, active, disabled, title, onClick, value }) {
  Option.propTypes = {
    onClick: PropTypes.func.isRequired,
    children: PropTypes.any,
    value: PropTypes.string,
    className: PropTypes.string,
    activeClassName: PropTypes.string,
    active: PropTypes.bool,
    disabled: PropTypes.bool,
    title: PropTypes.string,
  };

  Option.defaultProps = {
    activeClassName: '',
  };

  const onClickOption = () => {
    if (!disabled) {
      onClick(value);
    }
  };

  return (
    <div
      className={classNames('rdw-option-wrapper', className, {
        [`rdw-option-active ${activeClassName}`]: active,
        'rdw-option-disabled': disabled,
      })}
      onClick={onClickOption}
      aria-selected={active}
      title={title}
    >
      {children}
    </div>
  );
}

export default Option;
