import {
  RAC_LIST_REQUEST,
  RAC_LIST_SUCCESS,
  RAC_LIST_FAILURE,
  SC_LIST_REQUEST,
  SC_LIST_SUCCESS,
  SC_LIST_FAILURE,
  RAC_LIST_TAB_DATA,
} from './actions';

const initialState = {};

const racList = (state = initialState, action) => {
  switch (action.type) {
    case RAC_LIST_REQUEST:
    case RAC_LIST_SUCCESS:
    case RAC_LIST_FAILURE:
    case RAC_LIST_TAB_DATA:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

const scList = (state = initialState, action) => {
  switch (action.type) {
    case SC_LIST_REQUEST:
    case SC_LIST_SUCCESS:
    case SC_LIST_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export { racList, scList };
