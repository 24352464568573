import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  strictValidArrayWithLength,
  strictValidNumber,
} from '../../utils/commonUtils';
import {
  notificationDetail,
  clearDetails,
  updateRTN,
  readNotification,
} from '../../redux/actions/notifications.actions';
import { DEFAULT_TIMEZONE, DATE_FORMAT_MDY } from '../../utils/siteSpecificConstants';
import './real-time-notification-message.scss';

const RealTimeNotificationMessage = ({
  rtnList,
  callUpdateRTN,
}) => {
  const listRef = useRef([]);
  const [list, setList] = useState([]);
  const [timeoutID, setTimeoutID] = useState(null);
  const [cardHeight, setCardHeight] = useState(0);



  const removeReadNotification = (data, manuallyRemove = false) => {
    const timeOutID = setTimeout(() => {
      const updatedList = strictValidArrayWithLength(data)
        ? data.filter(({ is_read }) => !is_read)
        : [];

      if (manuallyRemove) callUpdateRTN(updatedList);

      setList(updatedList);
      setTimeoutID(null);
    }, 500);
    if (timeoutID) clearTimeout(timeoutID);
    setTimeoutID(timeOutID);
  };

  const notificationReadTimer = (notificationList) => {
    if (strictValidArrayWithLength(notificationList)) {
      const timeOutID = setTimeout(() => {
        const updatedList = notificationList.map((element, indexRTN) => {
          const newMapping = { ...element };
          if (indexRTN === 0) newMapping.is_read = true;
          return newMapping;
        });
        setList(updatedList);
        setTimeoutID(null);
        removeReadNotification(updatedList, true);
      }, 3500);
      if (timeoutID) clearTimeout(timeoutID);

      setTimeoutID(timeOutID);
    }
  };

  useEffect(() => {
    const unreadNotifications = strictValidArrayWithLength(rtnList)
      ? rtnList.filter(({ is_read }) => !is_read)
      : [];
    setList(unreadNotifications);
    notificationReadTimer(unreadNotifications);
  }, [rtnList]);

  useEffect(() => {
    if (strictValidArrayWithLength(list)) {
      setCardHeight(
        listRef.current.querySelector('li > .card-content').getBoundingClientRect().height,
      );
      if (
        !strictValidArrayWithLength(list.filter(({ is_read }) => is_read)) &&
        !strictValidNumber(timeoutID)
      ) {
        notificationReadTimer(list);
      }
    }
  }, [list, timeoutID]);

  const closeNotification = (e, _id) => {
    if (strictValidNumber(timeoutID)) {
      clearTimeout(timeoutID);
      setTimeoutID(null);
    }
    const updatedList = list.map((v) => {
      const newMapping = { ...v };
      if (v._id === _id) newMapping.is_read = true;
      return newMapping;
    });

    removeReadNotification(updatedList, true);
    setList(updatedList);
    e.stopPropagation();
  };

  return (
    <div
      ref={listRef}
      className="real-time-notification-message"
      style={{ '--card-height': `${cardHeight}px` }}
    >
      {strictValidArrayWithLength(list) &&
        list.map((v) => (
          <li
            key={v._id}
            className={v.is_read ? 'removed' : ''}
          >
            <div className="card-content">
              <div className="title-wrapper">
                <p className="title">{v.sender_name}</p>
                <p className="date">
                  {moment(v.createdAt).tz(DEFAULT_TIMEZONE).format(DATE_FORMAT_MDY)}
                </p>
              </div>
              <p className="description">{v.subject}</p>
              <div className="x-mark" onClick={(e) => closeNotification(e, v._id)}>
                <svg viewBox="0 0 20 20" fill="none">
                  <path
                    d="M0.500001 0.5L19.5 19.5M19.5 0.500001L0.5 19.5"
                    strokeWidth="1"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
          </li>
        ))}
    </div>
  );
};

RealTimeNotificationMessage.propTypes = {
  callReadNotificationAPI: PropTypes.func.isRequired,
  callUpdateRTN: PropTypes.func.isRequired,
  callClearDetail: PropTypes.func.isRequired,
  callNotificationDetailAPI: PropTypes.func.isRequired,
  rtnList: PropTypes.shape(PropTypes.arrayOf),
};

RealTimeNotificationMessage.defaultProps = {
  rtnList: [],
};

const mapStateProps = (state) => {
  return {
    rtnList: state.get('notifications').get('rtnList'),
    loadErr: state.get('notifications').get('loadErr'),
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  callNotificationDetailAPI: (...params) => dispatch(notificationDetail(...params)),
  callClearDetail: (...params) => dispatch(clearDetails(...params)),
  callUpdateRTN: (...params) => dispatch(updateRTN(...params)),
  callReadNotificationAPI: (...params) => dispatch(readNotification(...params)),
});

export default connect(mapStateProps, mapDispatchToProps)(RealTimeNotificationMessage);
