import Immutable from 'immutable';
import { LOCATION_CHANGE } from 'react-router-redux';
import { addCandidateConstants } from './constants';
import { typeCastResponse } from '../../../../utils/siteSpecificCommonUtils';

const initialState = Immutable.fromJS({
  isLoad: false,
  loadErr: null,
  message: null,
  isEmailExists: false,
});

export const AddCandidate = (state = initialState, action) => {
  switch (action.type) {
    case addCandidateConstants.LOAD:
      return state.set('isLoad', true).set('loadErr', null);
    case addCandidateConstants.LOAD_SUCCESS:
      return state
        .set('isLoad', false)
        .set('loadErr', null)
        .set('message', typeCastResponse(action, 'message'));
    case addCandidateConstants.LOAD_FAIL:
      return state
        .set('isLoad', false)
        .set('message', null)
        .set('loadErr', typeCastResponse(action, 'error'));
    // Reset state on location change
    case LOCATION_CHANGE:
      return state.set('message', null).set('loadErr', null);
    case addCandidateConstants.EMAIL_EXISTS:
      return state.set('isEmailExists', action.value);
    default:
      return state;
  }
};
