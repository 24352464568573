export const DEFAULT_LONG_TIME_TO_REDIRECT = 10000;

export const DEFAULT_MILLISECONDS_TO_SHOW_MESSAGES = 3000;

export const DEFAULT_MILLISECONDS_TO_HIDE_POPUP = 2000;

export const DEFAULT_MILLISECONDS_TO_FIRE_QUERY = 500;

export const VALID_ACCESSIBLE_IMAGE_FILE_FORMATS = ['jpg', 'jpeg', 'png', 'gif'];

export const DEFAULT_VALID_BEGIN_FILE_NAME_REG_EXP = '^[_|0-9|a-z|A-Z]+';

export const DEFAULT_IMAGE_FILE_NAME_BEGIN_REG_EXP = '^[_|0-9|a-z|A-Z|//|-]+';

export const DEFAULT_OPTION = 'SELECT';

export const DEFAULT_SELECTED__CANDIDATES_LIMIT = 150;
