import moment from 'moment';
import QueryString from 'query-string';
import {
  validObjectWithParameterKeys,
  strictValidString,
  strictValidArray,
  strictValidNumber,
  strictValidArrayWithMinLength,
  typeCastToString,
  strictValidObject,
  strictValidSplittableStringWithMinLength,
  strictValidArrayWithLength,
  integerToDoubleDigit,
  formatNumber,
  strictValidStringWithMinLength,
} from './commonUtils';
import { USER_ROLES, DEFAULT_TIMEZONE } from './siteSpecificConstants';
import { messages } from '../language';

/**
 * Checks if a valid category
 * @param category: object
 */
export const validCategory = (category) =>
  validObjectWithParameterKeys(category, ['_id', 'title', 'icon', 'titles']) &&
  strictValidString(category.title) &&
  strictValidString(category.icon) &&
  strictValidArray(category.titles);

/**
 * Checks if a valid experience
 * @param role: object
 */
export const validExperience = (experience) =>
  validObjectWithParameterKeys(experience, ['_id', 'title', 'icon', 'min_exp', 'max_exp']) &&
  strictValidString(experience.title) &&
  strictValidString(experience.icon) &&
  strictValidNumber(experience.min_exp) &&
  strictValidNumber(experience.max_exp);

/**
 * Checks if a valid role
 * @param role: object
 */
export const validRole = (role) =>
  validObjectWithParameterKeys(role, ['_id', 'title', 'duties']) &&
  strictValidString(role.title) &&
  strictValidArray(role.duties);

/**
 * Checks if a valid education
 * @param education: object
 */
export const validEducation = (education) =>
  validObjectWithParameterKeys(education, ['_id', 'title']) && strictValidString(education.title);

/**
 * Checks if a valid duty
 * @param duty: object
 */
export const validDuty = (duty) =>
  validObjectWithParameterKeys(duty, ['_id', 'title']) && strictValidString(duty.title);

export const validCandidateRequirement = (requirement) =>
  validObjectWithParameterKeys(requirement, ['_id', 'candidate_label']) &&
  strictValidStringWithMinLength(requirement.candidate_label);

/**
 * Checks if a valid job requirement
 * @param requirement: object
 */
export const validJobRequirement = (requirement) =>
  validObjectWithParameterKeys(requirement, ['_id', 'job_label']) &&
  strictValidStringWithMinLength(requirement.job_label);

/**
 * Checks if a valid count object
 * @param count: object
 */
export const validCount = (count) =>
  validObjectWithParameterKeys(count, ['posted', 'paired', 'searching']);

/**
 * Get minimum & maximum string
 * @param min: number
 * @param max: number
 * @param assistedText: string
 */
export const getMinMaxString = (min, max, assistedText, preAssistedText, formatting = false) =>
  (strictValidNumber(min) &&
    strictValidNumber(max) &&
    strictValidString(assistedText) &&
    ((strictValidString(preAssistedText) &&
      ((max &&
        min < max &&
        `${preAssistedText}${formatting ? formatNumber(min) : min} - ${preAssistedText}${
          formatting ? formatNumber(max) : max
        } ${assistedText}`) ||
        (min && `> ${preAssistedText}${formatting ? formatNumber(min) : min} ${assistedText}`))) ||
      (!strictValidString(preAssistedText) &&
        ((max &&
          min < max &&
          `${formatting ? formatNumber(min) : min} - ${
            formatting ? formatNumber(max) : max
          } ${assistedText}`) ||
          (min && `> ${formatting ? formatNumber(min) : min} ${assistedText}`))))) ||
  '';

const countBillPay = (val, billRate) =>
  strictValidNumber(val) && Number((val / (1 + billRate / 100)).toFixed(2));

export const getBillPayMinMaxString = (
  min,
  max,
  billPay,
  assistedText,
  preAssistedText,
  formatting = false,
) =>
  (strictValidNumber(min) &&
    strictValidNumber(max) &&
    strictValidString(assistedText) &&
    ((strictValidString(preAssistedText) &&
      ((max &&
        min < max &&
        `${preAssistedText}${
          formatting ? formatNumber(countBillPay(min, billPay)) : countBillPay(min, billPay)
        } - ${preAssistedText}${
          formatting ? formatNumber(countBillPay(max, billPay)) : countBillPay(max, billPay)
        } ${assistedText}`) ||
        (min &&
          `> ${preAssistedText}${
            formatting ? formatNumber(countBillPay(min, billPay)) : countBillPay(min, billPay)
          } ${assistedText}`))) ||
      (!strictValidString(preAssistedText) &&
        ((max &&
          min < max &&
          `${
            formatting ? formatNumber(countBillPay(min, billPay)) : countBillPay(min, billPay)
          } - ${
            formatting ? formatNumber(countBillPay(max, billPay)) : countBillPay(max, billPay)
          } ${assistedText}`) ||
          (min &&
            `> ${
              formatting ? formatNumber(countBillPay(min, billPay)) : countBillPay(min, billPay)
            } ${assistedText}`))))) ||
  '';

/**
 * If valid logged in user
 * @param user: object
 */
export const validUser = (user) =>
  validObjectWithParameterKeys(user, ['_id', 'status', 'name', 'first_name', 'email', 'role']) &&
  strictValidString(user.name) &&
  strictValidString(user.first_name) &&
  strictValidString(user.email) &&
  strictValidNumber(user.status) &&
  strictValidString(user.role);

/**
 * If valid logged in user
 * @param user: object
 */
export const validLoggedInUser = (user) =>
  validUser(user) && !!user.status && strictValidString(user.role);

/**
 * If valid invited company user
 * @param user: object
 */
export const validInvitedCompanyUser = (user) =>
  validObjectWithParameterKeys(user, ['_id', 'email', 'status', 'role', 'activation_key']) &&
  strictValidString(user.email) &&
  strictValidNumber(user.status) &&
  strictValidString(user.role) &&
  [USER_ROLES.CLIENT_ADMIN, USER_ROLES.CLIENT_USER].indexOf(user.role) > -1 &&
  strictValidString(user.activation_key);

/**
 * Checks if a valid company user
 * @param user: object
 */
export const validCompany = (user) =>
  validLoggedInUser(user) &&
  [
    USER_ROLES.SUPER_ADMIN,
    USER_ROLES.ADMIN,
    USER_ROLES.CLIENT_ADMIN,
    USER_ROLES.CLIENT_USER,
    USER_ROLES.ACCOUNT_MANAGER,
  ].indexOf(user.role) > -1;

/**
 * Checks if a valid candidate user
 * @param user: object
 */
export const validCandidate = (user) =>
  validLoggedInUser(user) && user.role === USER_ROLES.CANDIDATE;

/**
 * Checks if a valid company admin
 * @param user: object
 */
export const validCompanyAdmin = (user) =>
  validCompany(user) && user.role === USER_ROLES.CLIENT_ADMIN;

/**
 * Checks if a valid job
 * @param job: object
 */
export const validJob = (job) => validObjectWithParameterKeys(job, ['_id', 'status']);

/**
 * Checks if a valid active job
 * @param job: object
 */
export const validActiveJob = (job) => validJob(job) && !job.is_deleted;

/**
 * Checks if a valid pending job
 * @param job: object
 */
export const validPendingJob = (job) =>
  validActiveJob(job) &&
  validObjectWithParameterKeys(job, ['is_first']) &&
  job.is_first &&
  job.status === 2;

/**
 * Checks if a valid profile
 * @param job: object
 */
export const validProfile = (profile) =>
  validObjectWithParameterKeys(profile, [
    'filters',
    'proximity',
    'is_remote',
    'is_relocation',
    'location_coordinates',
    'is_pending',
  ]) &&
  strictValidArrayWithLength(profile.filters) &&
  strictValidNumber(profile.proximity) &&
  validObjectWithParameterKeys(profile, ['location_coordinates']) &&
  validObjectWithParameterKeys(profile.location_coordinates, ['coordinates']) &&
  strictValidArrayWithMinLength(profile.location_coordinates.coordinates, 2);

/**
 * Checks if a valid pending profile
 * @param job: object
 */
export const validPendingProfile = (profile) => validProfile(profile) && profile.is_pending;

/**
 * Checks if a valid industry
 * @param industry: object
 */
export const validIndustry = (industry) =>
  validObjectWithParameterKeys(industry, ['_id', 'title']) && strictValidString(industry.title);

/**
 * Typecast response from api to specified type, default string
 * @param object: string or object containing key: string
 * @param key: string in object
 * @param type: string
 * @param defaultValue: any
 */
export const typeCastResponse = (object, key, type = 'string', defaultValue = null) => {
  let response = null;
  switch (type) {
    case 'number':
      response =
        (validObjectWithParameterKeys(object, [key]) && Number(object[key])) || defaultValue || 0;
      break;
    case 'string':
      response =
        (validObjectWithParameterKeys(object, [key]) && typeCastToString(object[key])) ||
        defaultValue ||
        null;
      break;
    case 'object':
      response =
        (validObjectWithParameterKeys(object, [key]) &&
          strictValidObject(object[key]) &&
          object[key]) ||
        defaultValue ||
        {};
      break;
    case 'array':
      response =
        (validObjectWithParameterKeys(object, [key]) &&
          strictValidArray(object[key]) &&
          object[key]) ||
        defaultValue ||
        [];
      break;
    default:
      break;
  }
  return response;
};

/**
 * Formats a location suggestion
 * @param locationSuggestion: string
 * @param minDepth: number
 */
export const extractInfoFromPlaceResult = (locationSuggestion, minDepth = 0) =>
  (strictValidNumber(minDepth) &&
    strictValidSplittableStringWithMinLength(locationSuggestion, ',', minDepth) &&
    locationSuggestion
      .split(', ')
      .reverse()
      .map((v, k) => (k === 1 && v.replace(/\d+/g, '').trim()) || v)) ||
  [];

/**
 * Checks if a valid user role
 * @param userRole: string
 */
export const validExperienceForm = (filters, formData) =>
  validObjectWithParameterKeys(filters, ['categoriesIds']) &&
  strictValidArrayWithLength(filters.categoriesIds) &&
  validObjectWithParameterKeys(formData, ['values']) &&
  validObjectWithParameterKeys(formData.values, ['industry']) &&
  strictValidArrayWithLength(formData.values.industry) &&
  formData.values.industry.length === filters.categoriesIds.length;

/**
 * Checks if a valid user role
 * @param placeAddress: string
 * @param placeCoordinates: object
 * @param minDepth: number
 */
export const validPlaceSelected = (placeAddress, placeCoordinates, minDepth) =>
  strictValidString(placeAddress) &&
  validObjectWithParameterKeys(placeCoordinates, ['lat', 'lng']) &&
  strictValidNumber(placeCoordinates.lat) &&
  strictValidNumber(placeCoordinates.lng) &&
  strictValidArrayWithMinLength(extractInfoFromPlaceResult(placeAddress), minDepth);

/**
 * Blurrs a given string
 * @param str: string
 */
export const blurrString = (str) => {
  let res = '';
  if (strictValidString(str)) {
    res = str
      .split(' ')
      .map((v) =>
        v.length
          ? `${v.charAt(0)}${v
              .substr(1)
              .split('')
              .map((i) => '*')
              .join('')}`
          : '',
      )
      .join(' ');
  }
  return res;
};

/**
 * Get job status
 * @param {*} status: number
 */
export const getJobStatus = (status) => {
  switch (status) {
    case 1:
      return 'Open';
    case 2:
      return 'Draft';
    case 3:
      return 'On Hold';
    case 4:
      return 'Closed';
    default:
      return '';
  }
};

/**
 * Get time string in hh:mm:ss format
 * @param h: integer
 * @param m: integer
 * @param s: integer
 */
export const getTimeString = (h, m, s) =>
  `${integerToDoubleDigit(h)}:${integerToDoubleDigit(m)}:${integerToDoubleDigit(s)}`;

/**
 * Validate keyword
 * @param keyWord
 */
export const validKeyWord = (keyWord) => validObjectWithParameterKeys(keyWord, ['_id', 'title']);

/**
 * Get zoom level based on no. of markers
 * @param miles: integer
 */
export const getZoomLevel = (miles) => {
  if (miles <= 50) return 9;
  return 8;
};

/**
 * Get job status for candidate side
 * @param {*} status: number
 */
export const getCandidateJobStatus = (status, subStatus, rejectedBy) => {
  let statusText = '';
  switch (status) {
    case 1:
      statusText = 'Invited';
      break;
    case 2:
      switch (rejectedBy) {
        case 'Company':
          if (subStatus === 5) statusText = 'Offer Declined';
          else statusText = 'Passed On';
          break;
        case 'Candidate':
          statusText = 'Declined';
          break;
        case 'System':
          statusText = 'Passed On';
          break;
        default:
          statusText = '';
          break;
      }
      break;
    case 3:
      switch (subStatus) {
        case 0:
          statusText = 'Scheduling';
          break;
        case 1:
          statusText = 'Scheduled';
          break;
        case 2:
          statusText = 'Conflict';
          break;
        case 3:
          statusText = 'Interviewed';
          break;
        default:
          statusText = '';
          break;
      }
      break;
    case 4:
      statusText = 'Offered';
      break;
    case 5:
      statusText = 'On Hold';
      break;
    case 6:
      statusText = 'Interviewed';
      break;
    case 7:
      statusText = 'Offer Accepted';
      break;
    default:
      statusText = '';
      break;
  }

  return statusText || '';
};

export const getTimezone = (timezone) =>
  (strictValidString(timezone) && timezone) || DEFAULT_TIMEZONE;

export const getTimezoneStr = (timezone) => moment.tz(getTimezone(timezone)).format('z');

export const formatTimezoneStr = (timezone) => getTimezoneStr(timezone).replace('DT', 'ST');

export const arrayOnlyContainsContract = (arr) =>
  strictValidArrayWithLength(arr) &&
  !arr.find(
    (v) =>
      [messages.JOB_TYPE_OPTIONS.CONTRACT, messages.JOB_TYPE_OPTIONS.TEMPTOHIRE].indexOf(v) <= -1,
  );

export const getUTCDate = (date) => (date === null ? null : moment(date).format('YYYY-MM-DD'));

export const formatToDateObj = (date, format = 'YYYY-MM-DD') =>
  date ? moment(date, format).toDate() : null;

export const getQueryString = (location) => {
  if (!strictValidObject(location)) return;
  return QueryString.parse(location.search, {
    parseBooleans: true,
    parseNumbers: true,
  });
};

export const getRemoteLOVs = (id) => {
  switch (id) {
    case 1:
      return 'Yes, I only want to work remotely';
    case 2:
      return "Yes, I'm open to remote work";
    case 0:
      return "No, I'm not open to remote work";
    default:
      return '';
  }
};

export const getJobRemoteLOVs = (id) => {
  switch (id) {
    case 0:
      return 'No';
    case 1:
      return '100% remote';
    case 2:
      return 'Remote optional';
    case 3:
      return 'Temporarily remote due to special circumstance';
    default:
      return '';
  }
};

export const contractDurationOptions = [
  { value: '1 Day', label: '1 Day' },
  { value: '1 Week', label: '1 Week' },
  { value: '1 Month', label: '1 Month' },
  { value: '3 Months', label: '3 Months' },
  { value: '6 Months', label: '6 Months' },
  { value: 'Ongoing', label: 'Ongoing' },
  { value: 'Last Minute Hire', label: 'Last Minute Hire' },
];

export const getLocationOptions = (locations, type = 'long') =>
  locations
    .filter((location) => location.is_billing_location)
    .map((loc) => {
      const address = loc.address ? `${loc.address}, ` : '';
      const address_2 = loc.address_2 ? `${loc.address_2}, ` : '';
      const city = loc.city ? `${loc.city}, ` : '';
      const state = loc.state ? `${loc.state} ` : '';
      const zipcode = loc.zipcode || '';
      let label = `${city}${state}`;
      if (type === 'long') label = `${address}${address_2}${city}${state}${zipcode}`;

      return {
        value: loc._id,
        label,
      };
    });
