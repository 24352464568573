import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { typeCastToKeyValueObject } from '../../../utils/commonUtils';
import { consoleOnlyInDebugMode } from '../../../utils/debugging';
import { PopupBody, PopupHeader } from '../../../components/--primitives/pr-popup';

function NormalMessage({ details, onClose }) {
  consoleOnlyInDebugMode(details, 'details');
  const { body = '', sender_name = '' } = details;
  return (
    <>
      <PopupHeader toggle={onClose} close>
        <div className="title">{sender_name}</div>
      </PopupHeader>
      <PopupBody>
        <div className="content text custom-content">
          <p dangerouslySetInnerHTML={{ __html: body }} />
        </div>
      </PopupBody>
    </>
  );
}

NormalMessage.propTypes = {
  details: PropTypes.objectOf(PropTypes.shape),
};

NormalMessage.defaultProps = {
  details: {},
};

const mapStateToProps = (state) => ({
  details: state.get('notifications').get('detail'),
});

export default connect(mapStateToProps)(NormalMessage);
