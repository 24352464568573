import Immutable from 'immutable';
import { LOCATION_CHANGE } from 'react-router-redux';
import { invoiceListConstants } from './constants';
import { typeCastResponse } from '../../../../utils/siteSpecificCommonUtils';

const initialState = Immutable.fromJS({
  isLoad: true,
  loadErr: null,
  message: null,
  invoiceList: {},
  searchString: null,
});

export const invoicesData = (state = initialState, action) => {
  switch (action.type) {
    case invoiceListConstants.LOAD:
      return state.set('isLoad', true).set('loadErr', null);
    case invoiceListConstants.LOAD_SUCCESS:
      return state
        .set('isLoad', false)
        .set('loadErr', null)
        .set(
          'sentInvoicesResponse',
          typeCastResponse(action, 'sentInvoicesResponse', 'object', {}),
        );
    case invoiceListConstants.LOAD_FAIL:
      return state
        .set('isLoad', false)
        .set('loadErr', typeCastResponse(action, 'error'));
    case invoiceListConstants.GET_INVOICES_LIST:
      return state.set(
        'invoiceList',
        typeCastResponse(action, 'data', 'object', {}),
      );
    case LOCATION_CHANGE:
      return state.set('message', null).set('loadErr', null);
    default:
      return state;
  }
};
