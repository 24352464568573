/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, ContentState, convertToRaw, SelectionState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import './html-editor.scss';
import { getSelectionCustomInlineStyle } from 'draftjs-utils';
import { extractContentFromHtml } from '../../../utils/commonUtils';
import linkDropdown from './sub-modules/link-dropdown';

export const getTextFromHTML = (body = EditorState.createEmpty()) => {
  const { blocks } = convertToRaw(body.getCurrentContent());
  return blocks.map((block) => block.text && block.text.trim()).join('\n');
};

export const htmlFromDraft = (draft = EditorState.createEmpty()) => {
  return draftToHtml(convertToRaw(draft.getCurrentContent()));
};

export const draftFromHtml = (html) => {
  let editorState;
  if (html) {
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      editorState = EditorState.createWithContent(contentState);
    } else {
      editorState = EditorState.createEmpty();
    }
  } else {
    editorState = EditorState.createEmpty();
  }
  editorState = EditorState.moveSelectionToEnd(editorState);

  return editorState;
};

const moveSelectionToEnd = (editorState) => {
  const content = editorState.getCurrentContent();
  const blockMap = content.getBlockMap();

  const key = blockMap.last().getKey();
  const length = blockMap.last().getLength();

  const selection = new SelectionState({
    anchorKey: key,
    anchorOffset: length,
    focusKey: key,
    focusOffset: length,
  });
  return EditorState.forceSelection(editorState, selection);
};

export function HtmlEditor({
  input = {},
  meta = {},
  readOnly = false,
  placeholder = '',
  defaultFontFamily,
  defaultFontSize,
  ...props
}) {
  const [isMobView, setIsMobview] = useState(window.innerWidth < 700);
  const { onChange, value } = input;

  const setMobView = () => {
    if (window.innerWidth < 700) setIsMobview(true);
    else setIsMobview(false);
  };

  useEffect(() => {
    window.addEventListener('resize', setMobView);
    return () => {
      window.removeEventListener('resize', setMobView);
    };
  });
  const mobOption = {
    options: ['inline', 'fontFamily', 'fontSize', 'list', 'textAlign'],
    inline: {
      inDropdown: isMobView,
      options: ['bold', 'italic', 'underline', 'strikethrough'],
    },
    list: {
      inDropdown: isMobView,
    },
    textAlign: {
      inDropdown: isMobView,
    },
    history: {
      inDropdown: isMobView,
    },
    link: {
      showOpenOptionOnHover: false,
      component: linkDropdown,
    },
  };
  const options = {
    options: [
      'inline',
      'blockType',
      'fontFamily',
      'fontSize',
      'list',
      'textAlign',
      'link',
      'colorPicker',
      'history',
    ],
    inline: {
      options: ['bold', 'italic', 'underline', 'strikethrough'],
    },
    link: {
      showOpenOptionOnHover: false,
      component: linkDropdown,
    },
  };

  const getEditorState = (state) => {
    if (!defaultFontFamily && !defaultFontSize) return state;
    const currentFontFamily = state
      ? getSelectionCustomInlineStyle(state, ['FONTFAMILY']).FONTFAMILY
      : undefined;
    const currentFontSize = state
      ? getSelectionCustomInlineStyle(state, ['FONTSIZE']).FONTSIZE
      : undefined;

    let newState = state;

    if (defaultFontFamily && defaultFontSize && !currentFontFamily && !currentFontSize) {
      const data = htmlFromDraft(newState);
      const text = extractContentFromHtml(data);
      if (text.length === 1) {
        const html = `<p><span style=\"font-size: ${defaultFontSize}px;font-family: ${defaultFontFamily};\">${text}</span></p>\n`;
        newState = moveSelectionToEnd(draftFromHtml(html));
      }
    }
    return newState;
  };

  return (
    <div
      className="html-editor-wrapper"
      tip="error tool-bottom-left"
      data-description={meta.error}
      is-error={meta.touched && meta.error ? 'true' : 'false'}
    >
      <div className="uploadDetailsDiv html-editor-wysiwyg">
        <div className="details">
          <div className="detailsBorder padd-0">
            <Editor
              placeholder={placeholder}
              editorState={getEditorState(value)}
              toolbarClassName="draft-toolbar"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={onChange}
              toolbar={isMobView ? mobOption : options}
              readOnly={readOnly}
              {...props}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
