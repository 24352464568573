import {
  ACTIVITIES_LIST_REQUEST,
  ACTIVITIES_LIST_SUCCESS,
  ACTIVITIES_LIST_FAILURE,
} from './actions';

const initialState = {};

const activityLogs = (state = initialState, action) => {
  switch (action.type) {
    case ACTIVITIES_LIST_REQUEST:
    case ACTIVITIES_LIST_SUCCESS:
    case ACTIVITIES_LIST_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export { activityLogs };
