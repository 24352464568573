import axios from 'axios';
import store from 'store2';
import {
  extractErrorMessage,
  removeInValidKeyValuePairs,
  strictValidStringWithMinLength,
  validValue,
} from '../../../../utils/commonUtils';
import { consoleOnlyInDebugMode } from '../../../../utils/debugging';
import { notesFailure, notesRequest, notesSuccess, commentRequest, commentSuccess, commentFailure, successfullyCallAPI, successfullyCallCommentAPI } from './actions';
import { errorConstants } from '../../../../redux/constants/error.constants';

const getNotes =
  (
    job = null,
    company = null,
    interview = null,
    candidate = null,
    timesheet = null,
    placement = null,
    lead = null,
    pn,
    ps,
    sc,
    st,
    tableFilters = {},
  ) =>
    (dispatch) => {
      const searchFilters = {
        ...tableFilters,
        job,
        company,
        interview,
        candidate,
        timesheet,
        placement,
        lead,
      };
      const APIUrl =
        validValue(lead) && strictValidStringWithMinLength(lead)
          ? `${process.env.REACT_APP_APIHOST}/leads/notes/`
          : `${process.env.REACT_APP_APIHOST}/notes/`;
      notesRequest(dispatch);
      const url = `${APIUrl}?page=${pn}&limit=${ps}&sort=${sc}&order=${st}`;
      axios
        .post(url, removeInValidKeyValuePairs(searchFilters), {
          headers: {
            'x-access-token': store.get('user').token,
          },
        })
        .then((res) => {
          notesSuccess(dispatch, res.data.data);
        })
        .catch((err) => {
          notesFailure(dispatch, err.response);
          dispatch({ type: errorConstants.SET_ERROR, error: extractErrorMessage(err.response) });
          consoleOnlyInDebugMode('err-getNotes', err.response);
        });
    };

const getNoteDetail =
  (
    id, lead
  ) =>
    async (dispatch, getState, api) => {
      try {
        const APIUrl =
          validValue(lead) && strictValidStringWithMinLength(lead)
            ? `${process.env.REACT_APP_APIHOST}/leads/notes/${id}`
            : `${process.env.REACT_APP_APIHOST}/notes/${id} `;
        const url = `${APIUrl}`;
        notesRequest(dispatch);
        const res = await api.get(url);
        successfullyCallAPI(dispatch);
        return res.data;
      } catch (err) {
        notesFailure(dispatch, err.response);
        dispatch({ type: errorConstants.SET_ERROR, error: extractErrorMessage(err.response) });
        return false;
      }
    };

const updateNotes =
  (
    id, values, lead
  ) =>
    async (dispatch, getState, api) => {
      try {
        const APIUrl =
          validValue(lead) && strictValidStringWithMinLength(lead)
            ? `${process.env.REACT_APP_APIHOST}/leads/notes/${id}`
            : `${process.env.REACT_APP_APIHOST}/notes/${id} `;
        const url = `${APIUrl}`;
        notesRequest(dispatch);
        const res = await api.put(url, {
          data: values,
        });
        successfullyCallAPI(dispatch);
        return res.data;
      } catch (err) {
        notesFailure(dispatch, err.response);
        dispatch({ type: errorConstants.SET_ERROR, error: extractErrorMessage(err.response) });
        return false;
      }
    };

/*
Get comments list
@params noteId
*/
const getComments =
  (
    id, lead
  ) =>
    async (dispatch, getState, api) => {
      try {        
        const APIUrl =
          validValue(lead) && strictValidStringWithMinLength(lead)
            ? `${process.env.REACT_APP_APIHOST}/leads/notes/comment/${id}`
            : `${process.env.REACT_APP_APIHOST}/notes/comment/${id} `;
        commentRequest(dispatch);
        const url = `${APIUrl}`;
        const res = await api.get(url);
        commentSuccess(dispatch, res.data);
        return res.data;
      } catch (err) {
        commentFailure(dispatch, err.response);
        dispatch({ type: errorConstants.SET_ERROR, error: extractErrorMessage(err.response) });
        return false;
      }
    };

const addComment =
  (
    values, lead
  ) =>
    async (dispatch, getState, api) => {
      try {
        const APIUrl =
          validValue(lead) && strictValidStringWithMinLength(lead)
            ? `${process.env.REACT_APP_APIHOST}/leads/notes/comment`
            : `${process.env.REACT_APP_APIHOST}/notes/comment `;
        const url = `${APIUrl}`;
        commentRequest(dispatch);
        const res = await api.post(url, {
          data: values,
        });
        successfullyCallCommentAPI(dispatch);
        return res.data;
      } catch (err) {
        commentFailure(dispatch, err.response);
        dispatch({ type: errorConstants.SET_ERROR, error: extractErrorMessage(err.response) });
        return false;
      }
    };

export { getNotes, getNoteDetail, updateNotes, getComments, addComment };
