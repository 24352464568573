import Immutable from 'immutable';
import { LOCATION_CHANGE } from 'react-router-redux';
import { typeCastResponse } from '../../utils/siteSpecificCommonUtils';
import { reduxFormsConstants } from '../constants';
import { validObjectWithParameterKeys, typeCastToKeyValueObject } from '../../utils/commonUtils';

const initialState = Immutable.fromJS({
  isLoad: true,
  message: null,
  loadErr: null,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    // Loading
    case reduxFormsConstants.LOAD:
      return state.set('isLoad', true).set('loadErr', null);
    case reduxFormsConstants.LOAD_SUCCESS:
      return state
        .set('isLoad', false)
        .set('loadErr', null)
        .set('message', typeCastResponse(action, 'message'));
    case reduxFormsConstants.LOAD_FAIL:
      return state.set('isLoad', false).set('loadErr', typeCastResponse(action, 'error'));
    // Update Scheduled Availability
    case reduxFormsConstants.UPDATE_REDUX_FORM:
      return state.set(`form[${action.reduxForm}]['values'][${action.key}]`, action.value);
    // Reset reducer
    case reduxFormsConstants.RESET_MESSAGE:
      return state.set('message', null).set('loadErr', null);
    // Flush reducer
    case reduxFormsConstants.FLUSH: {
      return initialState;
    }
    // Reset state on location change
    case LOCATION_CHANGE:
      return state.set('message', null).set('loadErr', null);
    // Default case
    default:
      return state;
  }
}
