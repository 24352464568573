import Immutable from 'immutable';
import { typeCastResponse } from '../../../../utils/siteSpecificCommonUtils';
import { timesheetConstants } from './constants';

const initialState = Immutable.fromJS({
  detail: {},
  lastSubmittedTimesheet: {}
});

export const timesheet = (state = initialState, action) => {
  switch (action.type) {
    case timesheetConstants.DETAIL_PROCESSING:
    case timesheetConstants.DETAIL_SUCCESS:
    case timesheetConstants.DETAIL_FAILURE:
      return state.set(
        'detail',
        typeCastResponse(action, 'detail', 'object', {})
      );
    case timesheetConstants.ADD_DETAIL_PROCESSING:
    case timesheetConstants.ADD_DETAIL_SUCCESS:
    case timesheetConstants.ADD_DETAIL_FAILURE:
    case timesheetConstants.LAST_SUBMITTED_PROCESSING:
    case timesheetConstants.LAST_SUBMITTED_SUCCESS:
    case timesheetConstants.LAST_SUBMITTED_FAILURE:
      return state.set(
        'lastSubmittedTimesheet',
        typeCastResponse(action, 'lastSubmittedTimesheet', 'object', {})
      );
    default:
      return state;
  }
};
