import React, { useEffect, useRef, useState, useCallback } from 'react';
import './ff-textarea-comments.scss';

const initialState = {
  opened: false,
  inEdit: false,
  minHeight: 0,
  focus: false,
};

const FFTextareaComments = ({
  // hasOverflow = false,
  input,
  disabled,
  meta: { touched, error } = {},
  rows = 1,
  labelText = 'Type your comment',
  onChange,
  hideLabel = false,
  placeholder = 'Type your comment',
  ...otherProps
}) => {
  const [{ opened, minHeight }, setState] = useState(initialState);
  const paragraph = useRef();

  useEffect(() => {
    setHeight();
    paragraph.current.addEventListener('focus', onFocus, false);
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside);
      if (paragraph && paragraph.current) paragraph.current.removeEventListener('focus', onFocus);
    };
  }, []);

  const onFocus = () => {
    setState((prev) => ({ ...prev, focus: true }));
  };

  const handleClickOutside = useCallback((e) => {
    const { target } = e;
    if (!paragraph.current.contains(target)) {
      setState((prev) => ({ ...prev, focus: false }));
    }
  }, []);

  const enableEdit = (e) => {
    setState((prev) => ({ ...prev, inEdit: true }));
    setHeight(e);
  };

  const disableEdit = () => {
    setState((prev) => ({ ...prev, inEdit: false }));
    if (!opened) {
      setHeight();
    }
  };

  const setHeight = (e = null) => {
    if (e) {
      const height = e.target.scrollHeight;
      setState((prev) => ({ ...prev, minHeight: height }));
    } else setState((prev) => ({ ...prev, minHeight: paragraph.current.scrollHeight || 0 }));
  };

  const handleChange = (e) => {
    const { onChange: onChangeInput } = input;
    if (onChange) onChange(e.target.value);
    if (onChangeInput) onChangeInput(e.target.value);
  };
  return (
    <div
      className={`ff-textarea-comments`}
      tip="error tool-bottom-left"
      data-description={error}
      is-error={touched && error ? 'true' : 'false'}
    >
      {!hideLabel && <label>{labelText}</label>}
      <textarea
        {...otherProps}
        {...input}
        className={`text ${!hideLabel ? 'marging-top' : ''}`}
        ref={paragraph}
        spellCheck="false"
        placeholder={placeholder}
        rows={Number(rows) || 2}
        style={{ '--min-height': minHeight > 250 ? 250 + 'px' : minHeight + 'px' }}
        onFocus={enableEdit}
        onBlur={disableEdit}
        onInput={setHeight}
        disabled={disabled}
        onChange={handleChange}
      ></textarea>
    </div>
  );
};

FFTextareaComments.defaultProps = {
  placeholder: '',
  meta: {
    touched: false,
    error: '',
  },
};

export default FFTextareaComments;
