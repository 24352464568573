const EDIT_LOGS_REQUEST = 'EDIT_LOGS_REQUEST';
const EDIT_LOGS__SUCCESS = 'EDIT_LOGS__SUCCESS';
const EDIT_LOGS__FAILURE = 'EDIT_LOGS__FAILURE';

const editLogsRequest = (dispatch) => {
  dispatch({ type: EDIT_LOGS_REQUEST, payload: { loading: true } });
};

const editLogsSuccess = (dispatch, response) => {
  dispatch({ type: EDIT_LOGS__SUCCESS, payload: { data: response, loading: false } });
};

const editLogsFailure = (dispatch) => {
  dispatch({ type: EDIT_LOGS__FAILURE, payload: { loading: false } });
};

export {
  EDIT_LOGS_REQUEST,
  EDIT_LOGS__SUCCESS,
  EDIT_LOGS__FAILURE,
  editLogsRequest,
  editLogsSuccess,
  editLogsFailure,
};
