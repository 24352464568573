import { ADD_NOTE_REQUEST, ADD_NOTE_SUCCESS, ADD_NOTE_FAILURE } from './actions';

const initialState = {};

const addNote = (state = initialState, action) => {
  switch (action.type) {
    case ADD_NOTE_REQUEST:
    case ADD_NOTE_SUCCESS:
    case ADD_NOTE_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export { addNote };
