import React from 'react';
import { Link } from 'react-router-dom';
import { loadRoute } from './commonUtils';

const createLinkToClientDashboard = (dispatch, id, name, anchorId = 'company_name') => (
  <Link id={anchorId} className="make-link" to={`/client-dashboard/${id}`} target="_blank">
    {name}
  </Link>
);

const createLinkToJobDashboard = (dispatch, id, name, anchorId = 'job_title') => (
  <Link id={anchorId} className="make-link" to={`/job/${id}/1`} target="_blank">
    {name}
  </Link>
);

const createLinkToCandidateDashboard = (dispatch, id, name) => (
  <Link className="make-link" to={`/candidate-dashboard/${id}`} target="_blank">
    {name}
  </Link>
);

const createLinkToInterviewDashboard = (dispatch, id, name) => (
  <Link style={{ color: '#5DA6FB' }} to={`/interview-dashboard/${id}/details`} target="_blank">
    {name}
  </Link>
);

const createLinkToAlertNotification = (dispatch, id, name) => (
  <Link style={{ color: '#5DA6FB' }} to={`/notification-contents/${id}`} target="_blank">
    {name}
  </Link>
);

const createLinkToPlacementDashboard = (dispatch, id, name) => (
  <Link style={{ color: '#5DA6FB' }} to={`/placement/${id}/details`} target="_blank">
    {name}
  </Link>
);

const createLinkToInvoiceList = (dispatch, name = 'test', addOnUrl = '') => (
  <Link style={{ color: '#5DA6FB' }} to={`/invoices-list-view${addOnUrl}`} target="_blank">
    {name}
  </Link>
);

export {
  createLinkToClientDashboard,
  createLinkToJobDashboard,
  createLinkToCandidateDashboard,
  createLinkToInterviewDashboard,
  createLinkToAlertNotification,
  createLinkToPlacementDashboard,
  createLinkToInvoiceList,
};
