const MANAGERS_LIST_REQUEST = 'MANAGERS_LIST_REQUEST';
const MANAGERS_LIST_SUCCESS = 'MANAGERS_LIST_SUCCESS';
const MANAGERS_LIST_FAILURE = 'MANAGERS_LIST_FAILURE';

const managersListRequest = (dispatch) => {
  dispatch({ type: MANAGERS_LIST_REQUEST, payload: { loading: true } });
};

const managersListSuccess = (dispatch, response) => {
  dispatch({
    type: MANAGERS_LIST_SUCCESS,
    payload: { data: response, loading: false },
  });
};

const managersListFailure = (dispatch) => {
  dispatch({ type: MANAGERS_LIST_FAILURE, payload: { loading: false } });
};

const STATES_REQUEST = 'STATES_REQUEST';
const STATES_SUCCESS = 'STATES_SUCCESS';
const STATES_FAILURE = 'STATES_FAILURE';

const statesListRequest = (dispatch) => {
  dispatch({ type: STATES_REQUEST, payload: { loading: true } });
};

const statesListSuccess = (dispatch, response) => {
  dispatch({
    type: STATES_SUCCESS,
    payload: { data: response, loading: false },
  });
};

const statesListFailure = (dispatch) => {
  dispatch({ type: STATES_FAILURE, payload: { loading: false } });
};

export {
  MANAGERS_LIST_REQUEST,
  MANAGERS_LIST_SUCCESS,
  MANAGERS_LIST_FAILURE,
  managersListRequest,
  managersListSuccess,
  managersListFailure,
  statesListRequest,
  statesListSuccess,
  statesListFailure,
  STATES_REQUEST,
  STATES_SUCCESS,
  STATES_FAILURE,
};
