/* eslint-disable no-console */

const consoleOnlyInDebugMode = (...props) => {
  if (process.env.NODE_ENV === 'development') {
    console.log(...props);
  }
};

/* eslint-disable no-debugger */

const debuggerOnlyInDebugMode = () => {
  if (process.env.NODE_ENV === 'development') {
    debugger;
  }
};
export {
  // eslint-disable-next-line import/prefer-default-export
  consoleOnlyInDebugMode,
  debuggerOnlyInDebugMode,
};
