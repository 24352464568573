import axios from 'axios';
import store from 'store2';
import { removeInValidKeyValuePairs, extractErrorMessage } from '../../../../utils/commonUtils';
import { consoleOnlyInDebugMode } from '../../../../utils/debugging';
import { attachmentsFailure, attachmentsRequest, attachmentsSuccess } from './actions';
import {
  addAttachmentRequest,
  addAttachmentSuccess,
  addAttachmentFailure,
  attachmentsResetMessage,
} from '../add/actions';
import { errorConstants, successConstants } from '../../../../redux/constants';

const APIUrl = `${process.env.REACT_APP_APIHOST}/attachments/list`;

const getAttachments =
  (Id = null, type, pn = 1, ps = 10, sc = 'id', st = 'desc', tableFilters = {}) =>
  (dispatch) => {
    const searchFilters = {
      ...tableFilters,
      [type]: Id,
    };
    attachmentsRequest(dispatch);
    const url = `${APIUrl}?page=${pn}&limit=${ps}&sort=${sc}&order=${st}`;
    axios
      .post(url, removeInValidKeyValuePairs(searchFilters), {
        headers: {
          'x-access-token': store.get('user').token,
        },
      })
      .then((res) => {
        attachmentsResetMessage(dispatch, 1);
        attachmentsSuccess(dispatch, res.data.data);
      })
      .catch((err) => {
        attachmentsFailure(dispatch, err);
        dispatch({ type: errorConstants.SET_ERROR, error: extractErrorMessage(err) });
        consoleOnlyInDebugMode('err-getAttachments', err.Error);
      });
  };

const updateAttachment =
  (id, company, placement, is_deleted, page, limit, sort, order, filter={}) => (dispatch) => {
    const searchFilters = {
      is_deleted,
    };

    addAttachmentRequest(dispatch);
    const url = `${process.env.REACT_APP_APIHOST}/attachments/${id}`;
    axios
      .post(url, removeInValidKeyValuePairs(searchFilters), {
        headers: {
          'x-access-token': store.get('user').token,
        },
      })
      .then((res) => {
        addAttachmentSuccess(dispatch, res.data.message);
        dispatch({ type: successConstants.SET_SUCCESS_MESSAGE, message: res.data.message });
        dispatch(getAttachments(company, placement, page, limit, sort, order, filter));
      })
      .catch((err) => {
        // console.log("update api error", err)
        addAttachmentFailure(dispatch, err);
        dispatch({ type: errorConstants.SET_ERROR, error: extractErrorMessage(err) });
        consoleOnlyInDebugMode('err-getAttachments', err.response);
      });
  };

export { getAttachments, updateAttachment };
