const ATTACHMENTS_REQUEST = 'ATTACHMENTS_REQUEST';
const ATTACHMENTS_SUCCESS = 'ATTACHMENTS_SUCCESS';
const ATTACHMENTS_FAILURE = 'ATTACHMENTS_FAILURE';

const attachmentsRequest = (dispatch) => {
  dispatch({ type: ATTACHMENTS_REQUEST, payload: { loading: true } });
};

const attachmentsSuccess = (dispatch, response) => {
  dispatch({ type: ATTACHMENTS_SUCCESS, payload: { data: response, error: '', loading: false } });
};

const attachmentsFailure = (dispatch, error) => {
  dispatch({ type: ATTACHMENTS_FAILURE, payload: { error, loading: false } });
};

export {
  ATTACHMENTS_REQUEST,
  ATTACHMENTS_SUCCESS,
  ATTACHMENTS_FAILURE,
  attachmentsRequest,
  attachmentsSuccess,
  attachmentsFailure,
};
