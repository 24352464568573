import {
  COMPANIES_LIST_REQUEST,
  COMPANIES_LIST_SUCCESS,
  COMPANIES_LIST_FAILURE,
  STATES_REQUEST,
  STATES_SUCCESS,
  STATES_FAILURE,
} from './actions';

const initialState = {};

const companies = (state = initialState, action) => {
  switch (action.type) {
    case COMPANIES_LIST_REQUEST:
    case COMPANIES_LIST_SUCCESS:
    case COMPANIES_LIST_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

const states = (state = initialState, action) => {
  switch (action.type) {
    case STATES_REQUEST:
    case STATES_SUCCESS:
    case STATES_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export { companies, states };
