import {
  COMPANY_DETAILS_REQUEST,
  COMPANY_DETAILS_SUCCESS,
  COMPANY_DETAILS_FAILURE,
  COMPANY_FIELD_PERMISSION,
} from './actions';

const initialState = {};

const companyDetails = (state = initialState, action) => {
  switch (action.type) {
    case COMPANY_DETAILS_REQUEST:
    case COMPANY_DETAILS_SUCCESS:
    case COMPANY_DETAILS_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case COMPANY_FIELD_PERMISSION:
      return {
        ...state,
        field_permission: { ...action.payload.data },
      };
    default:
      return state;
  }
};

export { companyDetails };
