export const NOTES_REQUEST = 'NOTES_REQUEST';
export const NOTES_SUCCESS = 'NOTES_SUCCESS';
export const NOTES_FAILURE = 'NOTES_FAILURE';

export const notesRequest = (dispatch) => {
  dispatch({ type: NOTES_REQUEST, payload: { loading: true } });
};

export const notesSuccess = (dispatch, response) => {
  dispatch({ type: NOTES_SUCCESS, payload: { data: response, loading: false } });
};

export const successfullyCallAPI = (dispatch) => {
  dispatch({ type: NOTES_REQUEST, payload: { loading: false } });
};

export const notesFailure = (dispatch) => {
  dispatch({ type: NOTES_FAILURE, payload: { loading: false } });
};

export const COMMENT_REQUEST = 'COMMENT_REQUEST';
export const COMMENT_SUCCESS = 'COMMENT_SUCCESS';
export const COMMENT_FAILURE = 'COMMENT_FAILURE';

export const commentRequest = (dispatch) => {
  dispatch({ type: COMMENT_REQUEST, payload: { loading: true } });
};

export const commentSuccess = (dispatch, response) => {
  dispatch({ type: COMMENT_SUCCESS, payload: { data: response, loading: false } });
};

export const successfullyCallCommentAPI = (dispatch) => {
  dispatch({ type: COMMENT_REQUEST, payload: { loading: false } });
};

export const commentFailure = (dispatch) => {
  dispatch({ type: COMMENT_FAILURE, payload: { loading: false } });
};
