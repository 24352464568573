import { LOCATION_CHANGE } from 'react-router-redux';
import { strictValidObjectWithKeys } from '../../utils/commonUtils';

export default function routerLocations(state = {}, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      const { currentLocation } = state;
      return currentLocation
        ? {
            prevLocation: currentLocation,
            currentLocation: action.payload.location.pathname,
          }
        : {
            ...state,
            currentLocation: action.payload.location.pathname,
          };
    default:
      return state;
  }
}
