export const authConstants = {
  // Action creators
  // Loading
  LOAD: 'auth/LOAD',
  LOAD_SUCCESS: 'auth/LOAD_SUCCESS',
  LOAD_FAIL: 'auth/LOAD_FAIL',
  // Assign user
  ASSIGN_USER: 'auth/ASSIGN_USER',
  // Temp user
  SAVE_TEMP_USER: 'auth/SAVE_TEMP_USER',
  REASSIGN_TEMP_USER: 'auth/REASSIGN_TEMP_USER',
  // Reset reducer
  RESET_MESSAGE: 'auth/RESET_MESSAGE',
  SESSION_EXPIRED: 'auth/SESSION_EXPIRED',
  FLUSH: 'auth/FLUSH',
  REMOVE_SCHEDULE_AVAILABILITY_TOKEN: 'auth/REMOVE_SCHEDULE_AVAILABILITY_TOKEN',
  // Company Update Profile
  UPDATE_COMPANY_PROFILE: 'auth/UPDATE_COMPANY_PROFILE',
  SET_USER_PERMISSIONS: 'auth/SET_USER_PERMISSIONS',
  USER_NOT_ALLOWED: 'auth/USER_NOT_ALLOWED',
  TENANT_CONFIG: 'TENANT_CONFIG',
};
