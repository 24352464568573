import React, { createRef } from 'react';
import './pr-drop-area-1.scss';
import PrImgIconComponent from '../pr-img-icon';
import uploadIco from '../../../assets/icons/upload.svg';

export default class PrDropArea1Component extends React.Component {
  counter = 1;

  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
    };
    this.dropRef = createRef();
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (e) => {
    if (!this.dropRef.current) return;
    if (!this.dropRef.current.contains(e.target) && !e.target.classList.contains('dot')) {
      this.counter += 1;
      document.querySelector('.pr-drop-area-1-wrapper').classList.remove('in-view');
      this.setState({
        isActive: false,
      });
    }
  };

  forPresentation1(e) {
    e && e.stopPropagation();
    let flag = false;
    this.counter += 1;
    if (this.counter % 2 === 0) {
      flag = true;
      document.querySelector('.pr-drop-area-1-wrapper').classList.add('in-view');
    } else {
      document.querySelector('.pr-drop-area-1-wrapper').classList.remove('in-view');
    }
    this.setState({
      isActive: flag,
    });

    if (typeof this.props.setToolTipState === 'function') this.props.setToolTipState();
  }

  render() {
    const { onRemove, onClick = null } = this.props;
    return (
      <div
        ref={this.dropRef}
        className={`pr-drop-area-1-wrapper ${this.props.type ? this.props.type : ''} ${
          this.state.isActive ? 'active' : ''
        }`}
        onClick={() => onClick && onClick()}
      >
        <div className="aligner">
          <div onClick={onRemove}>{this.props.icon}</div>
          <p>{this.props.title}</p>
          <span>{this.props.subtitle}</span>
        </div>
        <div className="mask">
          <p>{this.props.title}</p>
          <span>{this.props.subtitle}</span>
        </div>
        <div className="magic-wrapper">
          <p className="placeholder">
            Drag and Drop file or &nbsp;
            <span>Upload from Computer</span>
          </p>
          <div className="cta-wrapper">
            <div className="upload-cta">
              <PrImgIconComponent
                type="wrapped-to-circle clickable type-2 double"
                url={uploadIco}
              ></PrImgIconComponent>
            </div>
            <div
              className="dots"
              onClick={(e) => {
                this.forPresentation1(e);
              }}
            >
              <span></span>
              <span></span>
              <span></span>
              {typeof this.props.tooltip === 'function'
                ? this.props.tooltip(this.state.isActive)
                : this.props.tooltip}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
