import { getTextFromHTML } from '../../../../components/final-form/ff-HTML-editor';
import { strictValidStringWithMinLength } from '../../../../utils/commonUtils';

const validate = (values) => {
  const errors = {};
  if (!values.type) {
    errors.type = 'Please select notification type';
  }
  if (!values.receivers) {
    errors.receivers = 'Please Type or Select Receivers ';
  }
  if (!values.subject) {
    errors.subject = 'Please enter subject';
  }
  if (!strictValidStringWithMinLength(getTextFromHTML(values.body))) {
    errors.body = 'Please enter body text';
  }
  return errors;
};

export default validate;
