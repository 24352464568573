import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { typeCastToKeyValueObject } from '../../../utils/commonUtils';
import { consoleOnlyInDebugMode } from '../../../utils/debugging';
import { PopupHeader } from '../../../components/--primitives/pr-popup';

function ClientUserStatusChanged({ details, onClose }) {
  consoleOnlyInDebugMode(details, 'details');
  const { body = '' } = details;
  return (
    <>
      <PopupHeader toggle={onClose} close customClassName="client-user-status-changed" />
      <div className="content normaltext">
        {
          <span>
            <p dangerouslySetInnerHTML={{ __html: body }} />
          </span>
        }
      </div>
    </>
  );
}

ClientUserStatusChanged.propTypes = {
  details: PropTypes.objectOf(PropTypes.shape),
};

ClientUserStatusChanged.defaultProps = {
  details: {},
};

const mapStateToProps = (state) => ({
  details: state.get('notifications').get('detail'),
});

export default connect(mapStateToProps)(ClientUserStatusChanged);
