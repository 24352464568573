import { strictValidStringWithMinLength } from '../../../../utils/commonUtils';
import { messages } from '../../../../language';
import { getTextFromHTML } from '../../../../components/final-form/ff-HTML-editor';

const validate = (values) => {
  const errors = {};
  if (!strictValidStringWithMinLength(getTextFromHTML(values.body))) {
    errors.body = messages.VALIDATIONS.REQUIRED;
  }
  return errors;
};

export default validate;
