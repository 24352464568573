import axios from 'axios';
import { omitBy, isUndefined, isNull } from 'lodash';
import store from 'store2';
import moment from 'moment-timezone';
import { store as appStore } from './createStore';
import { validateSession, isUserAllowedToPerformAction } from './actions/auth.actions';
import { STARTING_WITH_HTTPS_OR_HTTPS } from '../utils/regexs';
import {
  validObjectWithParameterKeys,
  strictValidString,
  extractErrorMessage,
} from '../utils/commonUtils';
import { messages } from '../language';

const methods = ['get', 'post', 'put', 'patch', 'delete'];

function formatUrl(path) {
  if (!path) {
    return path;
  }
  if (STARTING_WITH_HTTPS_OR_HTTPS.test(path)) {
    return path;
  }
  const adjustedPath = path[0] !== '/' ? `/${path}` : path;
  // Prepend `/api` to relative URL, to proxy to API server.
  return `${process.env.REACT_APP_APIHOST}${adjustedPath}`;
}

axios.interceptors.request.use(
  (config) => {
    if (process.env.REACT_APP_ENV === 'dev')
      config.headers['x-tenant'] = 'http://badmin.ajnainside.com';
    return config;
  },
  (error) => reject(error),
);

/*
  This will run for every axios request
  This interceptor will intercept each response of axios requests
  and will run this code.
*/
axios.interceptors.response.use(
  (response) => {
    const newToken =
      validObjectWithParameterKeys(response, ['headers']) &&
      validObjectWithParameterKeys(response.headers, ['x-access-token']) &&
      response.headers['x-access-token'];
    if (newToken) {
      const user = { ...store.get('user'), token: newToken };
      store({
        user,
        SAAS_ADMIN_TOKEN: newToken,
      });
    }
    return Promise.resolve(response);
  },
  function (error) {
    const isValidErrorObject =
      validObjectWithParameterKeys(error, ['response']) &&
      validObjectWithParameterKeys(error.response, ['status', 'data']);
    if (isValidErrorObject) {
      const { status, data = {} } = error.response;
      const { message } = data;
      // Check if user's session is expired
      appStore.dispatch(validateSession(status));

      // Check if user is allowed to perform that action
      appStore.dispatch(isUserAllowedToPerformAction(status, extractErrorMessage(error)));
      if (strictValidString(message)) {
        return Promise.reject(message);
      }
      return Promise.reject(messages.DEFAULT_ERROR_MESSAGE);
    }
    if (strictValidString(error)) {
      return Promise.reject(error);
    }
    return Promise.reject(messages.DEFAULT_ERROR_MESSAGE);
  },
);

export default class ApiClient {
  constructor(req) {
    methods.forEach((method) => {
      this[method] = this._req(method);
    });
  }

  _req =
    (method) =>
    (path, { params, data } = {}, crossBrowser = false) =>
      new Promise((resolve, reject) => {
        const apiObj = {
          method,
          url: formatUrl(path),
        };
        if (params) {
          const emptySting = (val) => val === '';
          const cleanFromUndefined = omitBy(params, isUndefined);
          const cleanFromNull = omitBy(cleanFromUndefined, isNull);
          const localParam = omitBy(cleanFromNull, emptySting);
          apiObj.params = localParam || {};
        }
        if (data) {
          apiObj.data = data;
        }

        const http = axios.create({
          headers: {
            'Content-Type': 'application/json',
          },
        });
        http.interceptors.request.use(
          (config) => {
            if (!crossBrowser) {
              const token = store('SAAS_ADMIN_TOKEN');
              const refreshToken = store('refreshToken');
              config.headers['x-timezone'] = moment.tz.guess();
              if (token) config.headers['x-access-token'] = token;
              if (refreshToken) config.headers.RefreshToken = refreshToken;
            }
            if (process.env.REACT_APP_ENV === 'dev' && path.indexOf('googleapis') < 0)
              config.headers['x-tenant'] = 'http://badmin.ajnainside.com';
            return config;
          },
          (error) => reject(error),
        );
        http(apiObj)
          .then((response) => {
            const newToken =
              validObjectWithParameterKeys(response, ['headers']) &&
              validObjectWithParameterKeys(response.headers, ['x-access-token']) &&
              response.headers['x-access-token'];
            if (newToken) {
              const user = {
                ...store.get('user'),
                token: newToken,
              };
              store({
                user,
                SAAS_ADMIN_TOKEN: newToken,
              });
            }
            resolve(response.data);
          })
          .catch((error) => {
            const isValidErrorObject =
              validObjectWithParameterKeys(error, ['response']) &&
              validObjectWithParameterKeys(error.response, ['status', 'data']);
            if (isValidErrorObject) {
              const { status, data = {} } = error.response;
              const { message } = data;
              // Check if user's session is expired
              appStore.dispatch(validateSession(status));

              // Check if user is allowed to perform that action
              appStore.dispatch(isUserAllowedToPerformAction(status, extractErrorMessage(error)));
              if (strictValidString(message)) {
                reject(message);
              } else {
                reject(messages.DEFAULT_ERROR_MESSAGE);
              }
            } else if (strictValidString(error)) {
              reject(error);
            } else {
              reject(messages.DEFAULT_ERROR_MESSAGE);
            }
          });
      });
}
