const CANDIDATE_FEEDBACK_REQUEST = 'CANDIDATE_FEEDBACK_REQUEST';
const CANDIDATE_FEEDBACK_SUCCESS = 'CANDIDATE_FEEDBACK_SUCCESS';
const CANDIDATE_FEEDBACK_FAILURE = 'CANDIDATE_FEEDBACK_FAILURE';

const candidateFeedbackRequest = (dispatch) => {
  dispatch({ type: CANDIDATE_FEEDBACK_REQUEST, payload: { loading: true } });
};

const candidateFeedbackSuccess = (dispatch, response) => {
  dispatch({ type: CANDIDATE_FEEDBACK_SUCCESS, payload: { data: response, loading: false } });
};

const candidateFeedbackFailure = (dispatch) => {
  dispatch({ type: CANDIDATE_FEEDBACK_FAILURE, payload: { loading: false } });
};

export {
  CANDIDATE_FEEDBACK_REQUEST,
  CANDIDATE_FEEDBACK_SUCCESS,
  CANDIDATE_FEEDBACK_FAILURE,
  candidateFeedbackRequest,
  candidateFeedbackSuccess,
  candidateFeedbackFailure,
};
