import React from 'react';
import { findDOMNode } from 'react-dom';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  logout,
  loadNotifications,
  ResetNotificationsCount,
  setSidebarStatus,
} from '../../redux/actions';
import {
  typeCastToKeyValueObject,
  importImagesFromImageDirectory,
  strictValidStringWithMinLength,
  strictValidArrayWithLength,
} from '../../utils/commonUtils';
import { validLoggedInUser } from '../../utils/siteSpecificCommonUtils';
import './header.scss';
import Origin from '../../containers/Origin';
import { AjnaUsersRoles } from '../../utils/siteSpecificConstants';

import PrTooltipComponent from '../--primitives/pr-tooltip';
import PrImgIconComponent from '../--primitives/pr-img-icon';
import Notifications from '../../containers/Notifications';

const images = importImagesFromImageDirectory(
  require.context('../../assets/icons', false, /\.(png|jpe?g|svg)$/),
);
class Header extends Origin {
  constructor(props) {
    super(props);
    this.headerEl = React.createRef();
  }

  state = {
    ...this.state,
    isLoading: false,
    dropdownOpen: false,
    notificationDropDownOpen: false,
  };

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  componentDidMount() {
    window.document.addEventListener('click', this.handleDocumentClick);
  }

  componentWillUnmount() {
    window.document.removeEventListener('click', this.handleDocumentClick);
  }

  handleDocumentClick = (event) => {
    const domNode = findDOMNode(this.headerEl.current);
    if (!domNode || !domNode.contains(event.target)) {
      this.setState({
        dropdownOpen: false,
      });
    }
  };

  // Logs out user
  logOut = async () => {
    const { dispatch } = this.props;
    this.setState(
      {
        dropdownOpen: false,
        notificationDropDownOpen: false,
      },
      () => dispatch(logout()),
    );
  };

  toggle = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
      notificationDropDownOpen: false,
    });
  };

  // Toggle notifications
  toggleNotifications = async (action) => {
    const { notificationDropDownOpen } = this.state;
    const { dispatch, resetNewUnreadCount } = this.props;
    this.setState({
      notificationDropDownOpen: action ? !notificationDropDownOpen : false,
      dropdownOpen: false,
    });
    if (!notificationDropDownOpen && action) {
      const data = await this.dispatchActions(dispatch, loadNotifications());
      if (data) {
        resetNewUnreadCount();
      }
    }
  };

  // Profile details
  profileDetails = () => {
    this.setState({
      profileDetails: !this.state.profileDetails,
    });
  };

  goToRoute = () => {
    this.loadRoute('/');
  };

  renderNotificationCount = () => {
    const { dispatch } = this.props;
    this.dispatchActions(dispatch, loadNotifications(true));
  };

  toggleSidebar = () => {
    const { isSidebarOpen, callSetSidebarStatus } = this.props;
    callSetSidebarStatus(!isSidebarOpen);
  };

  // Renders the header segment
  render() {
    const { notificationDropDownOpen, isLoading, dropdownOpen } = this.state;
    const {
      user,
      new_unread,
      isSidebarOpen,
      tenantConfig = {},
      isLoad,
    } = this.props;
    const { roles, name, image } = user || {};
    const { label = '' } =
      AjnaUsersRoles.find((v) => strictValidArrayWithLength(roles) && v.value === roles[0]) || {};
    // Render the header segment
    return (
      <div id="header" className="header-main row" ref={this.headerEl}>
        <div className="logo pointer">
          <div className="left_sidebar_close" onClick={() => this.toggleSidebar()}>
            <img
              src={isSidebarOpen ? images['fi_menu.svg'] : images['fi_menu_with_arrow.svg']}
              alt="close"
            />
          </div>
          <Link className="black" to="/">
            {!isLoad &&
              (tenantConfig.logo_black ? (
                <img src={tenantConfig.logo_black} alt="logo" className="headerLogo" />
              ) : (
                <svg
                  width="110"
                  height="52.8"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 326.67 184.62"
                >
                  <defs />
                  <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                      <path
                        fill="#010101"
                        className="cls-1"
                        d="M199.54,37.84c-9.71,0-21.31,4.38-26.45,13.32V40.32h-28v94.52h28.91V80.64c0-9.51,6.08-16.55,15.6-16.55,10.46,0,15.4,7,15.4,16.36v54.39H234V75.5C234,54.77,223.3,37.84,199.54,37.84Zm126,82.36V74c0-19.41-11.23-36.52-41.85-36.52-27.76,0-40.31,17.87-41.46,31.38L267.33,74c.57-7,6.09-13.89,16.17-13.89,9.13,0,13.88,4.76,13.88,10.27,0,3.23-1.71,5.7-6.85,6.47l-22.25,3.42c-15.59,2.28-28,11.8-28,28.53,0,15,12,28.72,32.72,28.72,12.55,0,21.68-5.33,26.63-13.32a46.48,46.48,0,0,0,.94,10.65h26.06A92.84,92.84,0,0,1,325.53,120.2ZM297.38,97.76c0,14.64-8.75,18.83-17.3,18.83-8,0-11.23-4.76-11.23-9.7,0-6.66,4.57-9.52,10.66-10.46l17.87-2.86Zm-192.26-57v3.19h.14V147.66a10,10,0,1,1-19.91,0V125.28c-.09-1.59-.15-3.3-.15-5.08V74C85.2,54.58,74,37.47,43.36,37.47,15.59,37.47,3,55.34,1.9,68.85L27,74c.57-7,6.09-13.89,16.17-13.89,9.12,0,13.88,4.76,13.88,10.27,0,3.23-1.71,5.7-6.85,6.47L28,80.26c-15.6,2.28-28,11.8-28,28.53,0,15,12,28.72,32.7,28.72,11.08,0,19.5-4.16,24.74-10.64h-.15v18.55a39.21,39.21,0,0,0,78.42,0v-8.83a41.52,41.52,0,0,0,.23-4.35V40.75ZM39.74,116.59c-8,0-11.21-4.76-11.21-9.7,0-6.66,4.56-9.52,10.64-10.46l17.88-2.86v4.19C57.05,112.4,48.3,116.59,39.74,116.59Zm65.52-72.65v86.88h-.14V43.94ZM120.34,0A16.93,16.93,0,1,1,103.6,16.93,16.89,16.89,0,0,1,120.34,0Z"
                      />
                    </g>
                  </g>
                </svg>
              ))}
          </Link>
        </div>
        <div className="menu">
          {validLoggedInUser(user) && (
            <div className="menu-item">
              <Link className="black" to="/login">
                <img src={images['fi_home.svg']} alt="Home Icon" />
              </Link>
            </div>
          )}
          {!validLoggedInUser(user) && (
            <div className="menu-item">
              <Link className="black" to="/login">
                Login
              </Link>
            </div>
          )}

          {validLoggedInUser(user) && (
            <div className="menu-item  notification-bell">
              <div
                className="icon"
                onClick={() => this.toggleNotifications(true)}
                data-ignore="true"
              >
                <svg className="bell" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M18.5 10C18.5 5.23531 15.6 2 12 2C8.4 2 5 5 5 10C5 16 3 17 3 17H21C21 17 18.5 15.5 18.5 10Z"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M14 21C13.5 21.5 13.1771 22 12 22C10.8229 22 10 21 10 21"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                {new_unread > 0 && <span className="rtn-count">{new_unread}</span>}
              </div>
              <div
                className={
                  notificationDropDownOpen
                    ? 'notifications-wrapper opened'
                    : 'notifications-wrapper'
                }
              >
                {notificationDropDownOpen && (
                  <Notifications
                    isModalOpen={notificationDropDownOpen}
                    onAction={() => this.toggleNotifications()}
                    loadingNotifications={isLoading}
                  />
                )}
              </div>
            </div>
          )}

          {validLoggedInUser(user) && (
            <div className="menu-item header-icon">
              <span className="wrap-text user-avatar" onClick={this.toggle}>
                {strictValidStringWithMinLength(image) && (
                  <img src={image} alt="User Avatar Icon" />
                )}{' '}
              </span>
              <div onClick={this.toggle}>
                <p className="login-user">{name}</p>
              </div>
              <PrTooltipComponent
                type={`bottom-right with-triangle shift with-extra-cta ${
                  dropdownOpen ? 'in-view' : ''
                }`}
                CTAsList={[
                  {
                    name: `<b>${label}</b>`,
                  },
                  {
                    ico: <PrImgIconComponent type="default" url={images['logout_small_red.svg']} />,
                    name: 'Log Out',
                    onClick: () => {
                      this.logOut();
                    },
                  },
                ]}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: typeCastToKeyValueObject(state.get('auth').get('user')),
    new_unread: state.get('notifications').get('new_unread'),
    isSidebarOpen: state.get('common').get('isSideBarOpen'),
    tenantConfig: state.get('config').get('tenantConfig'),
    isLoad: state.get('config').get('isLoad'),
  };
}

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  callSetSidebarStatus: (...params) => dispatch(setSidebarStatus(...params)),
  loadNotifications: (...params) => dispatch(loadNotifications(...params)),
  resetNewUnreadCount: () => dispatch(ResetNotificationsCount()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
