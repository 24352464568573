import { strictValidObjectWithKeys } from '../../../../../utils/commonUtils';

const COMPANY_DETAILS_REQUEST = 'COMPANY_DETAILS_REQUEST';
const COMPANY_DETAILS_SUCCESS = 'COMPANY_DETAILS_SUCCESS';
const COMPANY_DETAILS_FAILURE = 'COMPANY_DETAILS_FAILURE';
const COMPANY_FIELD_PERMISSION = 'COMPANY_FIELD_PERMISSION';

const companyDetailsRequest = (dispatch) => {
  dispatch({ type: COMPANY_DETAILS_REQUEST, payload: { loading: true } });
};

const companyDetailsSuccess = (dispatch, response, field_permissions = {}) => {
  dispatch({
    type: COMPANY_DETAILS_SUCCESS,
    payload: { data: response, loading: false },
  });

  if (strictValidObjectWithKeys(field_permissions)) {
    dispatch({
      type: COMPANY_FIELD_PERMISSION,
      payload: { data: { ...field_permissions }, loading: false },
    });
  }
};

const companyDetailsSuccessCopy = (dispatch, response, field_permissions = {}) => {
  dispatch({
    type: COMPANY_DETAILS_SUCCESS,
    payload: { data: response, loading: false },
  });
  if (strictValidObjectWithKeys(field_permissions)) {
    dispatch({
      type: COMPANY_FIELD_PERMISSION,
      payload: { data: { ...field_permissions }, loading: false },
    });
  }
};

const companyDetailsFailure = (dispatch) => {
  dispatch({ type: COMPANY_DETAILS_FAILURE, payload: { loading: false } });
};

export {
  COMPANY_DETAILS_REQUEST,
  COMPANY_DETAILS_SUCCESS,
  COMPANY_DETAILS_FAILURE,
  COMPANY_FIELD_PERMISSION,
  companyDetailsRequest,
  companyDetailsSuccess,
  companyDetailsFailure,
  companyDetailsSuccessCopy,
};
