import { LOCATION_CHANGE } from 'react-router-redux';
import { REQUEST, SUCCESS, FAILURE } from './actions';

const initialState = {};

const ajnaUsers = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST:
    case SUCCESS:
    case FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case LOCATION_CHANGE:
      return {
        ...state,
        err: null,
      };
    default:
      return state;
  }
};

export { ajnaUsers };
