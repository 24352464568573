/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-useless-constructor */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Attachments from './list';
import Alert from '../../../components/Alert';

class Attachment extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const { Id, type, pathname = '', listError } = this.props;
    return (
      <div>
        {listError && <Alert type="error" message={listError} />}
        <div className="card">
          <Attachments Id={Id} type={type} pathname={pathname} />
        </div>
      </div>
    );
  }
}

Attachment.propTypes = {
  Id: PropTypes.string,
  type: PropTypes.string,
  pathname: PropTypes.string,
  listError: PropTypes.string,
};

Attachment.defaultProps = {
  Id: undefined,
  type: undefined,
  pathname: '',
  listError: '',
};

const mapStateToProps = (state) => {
  const { loading, error, message } = state.get('attachment').get('addAttachment');
  const { error: listError } = state.get('attachment').get('attachments');
  return {
    loading,
    error,
    message,
    listError,
  };
};

export default withRouter(connect(mapStateToProps)(Attachment));
