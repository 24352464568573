import Immutable from 'immutable';
import store from 'store2';
import { typeCastResponse } from '../../utils/siteSpecificCommonUtils';
import { commonConstants } from '../constants';

const initialState = Immutable.fromJS({
  isLoad: false,
  message: null,
  loadErr: null,
  recruiters: [],
  accountManagers: [],
  isSideBarOpen: store.get('isSideBarOpen') || false,
  exportListLoad: false,
  exportListError: null,
  exportListMessage: null,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case commonConstants.SET_COMMON_DATA:
      return state
        .set('recruiters', typeCastResponse(action, 'recruiters', 'array', []))
        .set('accountManagers', typeCastResponse(action, 'accountManagers', 'array', []));
    case commonConstants.SET_SIDEBAR_STATUS:
      const status = action.status || false;
      store({
        isSideBarOpen: status,
      });
      return state.set('isSideBarOpen', status);
    case commonConstants.EXPORT_LIST_LOAD:
      return state.set('exportListLoad', true);
    case commonConstants.EXPORT_LIST_LOAD_SUCCESS:
      return state
        .set('exportListLoad', false)
        .set('exportListMessage', typeCastResponse(action, 'message'));
    case commonConstants.EXPORT_LIST_FAIL:
      return state
        .set('exportListError', typeCastResponse(action, 'error'))
        .set('exportListLoad', false);
    case commonConstants.EXPORT_LIST_RESET_MESSAGE:
      return state
        .set('exportListMessage', typeCastResponse(action, 'message'))
        .set('exportListError', typeCastResponse(action, 'error'));
    default:
      return state;
  }
}
