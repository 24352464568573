export const candidateDashboardConstants = {
  LOAD: 'candidates/dashboard/LOAD',
  LOAD_INLINE: 'candidates/dashboard/LOAD_INLINE',
  LOAD_SUCCESS: 'candidates/dashboard/LOAD_SUCCESS',
  LOAD_FAIL: 'candidates/dashboard/LOAD_FAIL',
  LOAD_FAIL_INNER: 'candidates/dashboard/LOAD_FAIL_INNER',
  LOAD_KEY_SKILL: 'candidates/dashboard/FETCH_SKILLS',

  SET_CANDIDATE: 'candidates/dashboard/SET_CANDIDATE',
  SET_JOB: 'candidates/dashboard/SET_JOB',
  SET_VERIFIERS: 'candidates/dashboard/SET_VERIFIERS',
  SET_CANDIDATE_JOBS: 'candidates/dashboard/SET_CANDIDATE_JOBS',
  SET_CANDIDATE_JOBS_LIKED: 'candidates/dashboard/SET_CANDIDATE_JOBS_LIKED',
  SET_CANDIDATE_INTERVIEWS: 'candidates/dashboard/SET_CANDIDATE_INTERVIEWS',
  SET_CANDIDATE_RECOMMENDED_JOBS: 'candidates/dashboard/SET_CANDIDATE_RECOMMENDED_JOBS',
  SET_CANDIDATE_SUGGESTED_JOBS: 'candidates/dashboard/SET_CANDIDATE_SUGGESTED_JOBS',
  SET_CANDIDATE_AVAILABILITY: 'candidates/dashboard/SET_CANDIDATE_AVAILABILITY',
  SET_MATCHED_JOBS: 'candidates/dashboard/SET_MATCHED_JOBS',
  SET_LIKED_JOBS: 'candidates/dashboard/SET_LIKED_JOBS',
  SET_EXPERIENCES_DATA: 'candidates/dashboard/SET_EXPERIENCES_DATA',
  CLIENT_SLOTS_FOR_JOB_PER_CANDIDATE: 'candidates/dashboard/CLIENT_SLOTS_FOR_JOB_PER_CANDIDATE',
  FLUSH: 'candidates/dashboard/FLUSH',
  GET_QUESTIONS: 'candidates/caltureAndValues/GET_QUESTIONS',
  SAVE_SELECTED_VALUES: 'candidates/caltureAndValues/SAVE_SELECTED_VALUES',
  RESET_MESSAGE: 'candidates/dashboard/RESET_MESSAGE',
  GET_JOB_FUNCTION_QUESTIONS: 'candidates/GET_JOB_FUNCTION_QUESTIONS',
  GET_JOB_VERTICALIZATION_DATA: 'candidates/GET_JOB_VERTICALIZATION_DATA',

  CANDIDATE_FIELD_PERMISSIONS: 'CANDIDATE_FIELD_PERMISSIONS',
};
