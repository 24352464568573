import React from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  ReportsListView,
} from '../../../routes/LoadableComponents';

function Reports() {
  return (
    <Switch>
      <Route exact path="/reports/:type" component={ReportsListView} />
    </Switch>
  );
}

export default Reports;
