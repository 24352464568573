import { LOCATION_CHANGE } from 'react-router-redux';
import { TEMPLATE_PROCESSING, TEMPLATE_SUCCESS, TEMPLATE_FAILURE } from './constants';

const initialState = {
  isLoading: false,
  error: null,
  message: null,
};

export default function templateListing(state = initialState, action) {
  switch (action.type) {
    case TEMPLATE_PROCESSING: {
      return { ...state, isLoading: true };
    }
    case TEMPLATE_SUCCESS: {
      return { ...state, isLoading: false, message: action.payload.message };
    }
    case TEMPLATE_FAILURE: {
      return { ...state, isLoading: false, error: action.payload.error };
    }
    // Reset state on location change
    case LOCATION_CHANGE:
      return { ...state, isLoading: false, error: null, message: null };
    default:
      return state;
  }
}
