import React from 'react';
import { withRouter } from 'react-router-dom';

// NOTE: For reference https://reacttraining.com/react-router/web/guides/scroll-restoration
class ScrollToTop extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      // document.body.scrollTo(0, 0); is not supported by Safari
      // but supported scrollTop and scrollLeft.
      const { body } = document;
      body.scrollTop = 0;
      body.scrollLeft = 0;
      window.scrollTo(0, 0);
    }
  }

  render() {
    return null;
  }
}

export default withRouter(ScrollToTop);
