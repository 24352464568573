import Immutable from 'immutable';
import { LOCATION_CHANGE } from 'react-router-redux';
import { typeCastResponse } from '../../utils/siteSpecificCommonUtils';
import { jobConstants } from '../constants';

const initialState = Immutable.fromJS({
  isLoad: false,
  skillLoad: false,
  message: null,
  loadErr: null,
  bucketLoading: false,
  list: {},
  count: {
    posted: 0,
    paired: 0,
    searching: 0,
  },
  matchedProfiles: [],
  likedJobs: [],
  savedProfiles: [],
  passedProfiles: [],
  selectedProfiles: [],
  recommendedProfiles: [],
  candidates: {},
  candidate: {},
  filters: {},
  selected_job_funtion_answers: {},
  verticalizationData: [],
  availability: [],
  appointments_count: 0,
  totalScheduledRounds: 1,
  job: {},
  candidateProfileCount: {
    matchedProfiles: 0,
    likedJobs: 0,
    savedProfiles: 0,
    passedProfiles: 0,
    selectedProfiles: 0,
    recommendedProfiles: 0,
  },
  questions: [],
  selectedValues: [],
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    // Loading
    case jobConstants.LOAD:
      return state.set('isLoad', true).set('loadErr', null);
    case jobConstants.BUCKET_LOADING:
      return state.set('bucketLoading', true).set('loadErr', null);
    case jobConstants.LOAD_KEY_SKILL:
      return state.set('skillLoad', true).set('loadErr', null);
    case jobConstants.LOAD_SUCCESS:
      return state
        .set('isLoad', false)
        .set('skillLoad', false)
        .set('loadErr', null)
        .set('message', typeCastResponse(action, 'message'));
    case jobConstants.LOAD_FAIL:
      return state
        .set('isLoad', false)
        .set('skillLoad', false)
        .set('loadErr', typeCastResponse(action, 'error'));
    case jobConstants.LOAD_ALL_JOBS:
      return state.set('list', typeCastResponse(action, 'list', 'object', {}));
    case jobConstants.LOAD_ALL_TEMPLATES:
      return state.set('interviewTemplates', typeCastResponse(action, 'data', 'object', {}));

    case jobConstants.CANDIDATE_SLOTS:
      return state.set('candidateSlots', typeCastResponse(action, 'data', 'array', []));
    case jobConstants.GET_JOB_VERTICALIZATION_DATA:
      return state.set('verticalizationData', typeCastResponse(action, 'data', 'array', []));
    case jobConstants.GET_JOB_FUNCTION_QUESTIONS:
      return state.set('jobFunctionQuestions', typeCastResponse(action, 'data', 'array', []));
    // Pending Job
    case jobConstants.LOAD_PENDING_JOB:
      return state.set('job', typeCastResponse(action, 'job', 'object', {}));
    case jobConstants.GET_CANDIDATE_BY_ID:
      return state.set('candidate', typeCastResponse(action, 'candidate', 'object', {}));
    case jobConstants.GET_JOB_BY_ID:
      return state.set('job', typeCastResponse(action, 'job', 'object', {}));
    case jobConstants.GET_JOB_CANDIDATES_BY_ID:
      return state
        .set('bucketLoading', false)
        .set('candidates', typeCastResponse(action, 'candidates', 'object', {}))
        .set(
          'selectedProfiles',
          typeCastResponse(
            typeCastResponse(action, 'candidates', 'object', {}),
            'selectedProfiles',
            'array',
            [],
          ),
        );
    case jobConstants.SELECTED_INTERVIEW_CANDIDATES:
      return state.set(
        'selectedCandidates',
        typeCastResponse(action, 'selectedCandidates', 'array', []),
      );
    case jobConstants.GET_JOB_AVAILABILITY_BY_ID:
      return state.set('availability', typeCastResponse(action, 'availability', 'array', []));
    case jobConstants.GET_TOTAL_APPOINTMENTS:
      return state.set(
        'appointments_count',
        typeCastResponse(action, 'appointments_count', 'number', 0),
      );
    case jobConstants.GET_QUESTIONS:
      return state.set('questions', typeCastResponse(action, 'data', 'array', []));
    case jobConstants.SAVE_SELECTED_VALUES:
      return state.set('selectedValues', action.selectedValues);
    // .set('canSave', action.canSave);
    case jobConstants.GET_JOB_MAX_SCHEDULED_ROUNDS_BY_ID:
      return state.set(
        'totalScheduledRounds',
        typeCastResponse(action, 'totalScheduledRounds', 'number', 1),
      );
    case jobConstants.GET_APPOINTMENTS_BY_ID:
      return state
        .set('availability', typeCastResponse(action, 'availability', 'array', []))
        .set('invitations', typeCastResponse(action, 'invitations', 'array', []));
    // Count
    case jobConstants.LOAD_COUNT:
      return state.set('filters', typeCastResponse(action, 'filters', 'object', {}));
    case jobConstants.SET_JOB_FUNTION_ANSWERS:
      return state.set(
        'selected_job_funtion_answers',
        typeCastResponse(action, 'data', 'object', {}),
      );
    // Matched Profiles
    case jobConstants.LOAD_MATCHED_PROFILES:
      return state
        .set('matchedProfiles', typeCastResponse(action, 'matchedProfiles', 'array', []))
        .set('candidatesCount', typeCastResponse(action, 'candidatesCount', 'number', 0))
        .set('companiesCount', typeCastResponse(action, 'companiesCount', 'number', 0))
        .set('filters', typeCastResponse(action, 'filters', 'object', {}));
    case jobConstants.UPDATE_FILTERED_PROFILES:
      return state
        .set('matchedProfiles', typeCastResponse(action, 'matchedProfiles', 'array', []))
        .set('likedJobs', typeCastResponse(action, 'likedJobs', 'array', []))
        .set('passedProfiles', typeCastResponse(action, 'passedProfiles', 'array', []))
        .set('selectedProfiles', typeCastResponse(action, 'selectedProfiles', 'array', []))
        .set('savedProfiles', typeCastResponse(action, 'savedProfiles', 'array', []))
        .set('recommendedProfiles', typeCastResponse(action, 'recommendedProfiles', 'array', []));
    case jobConstants.LOAD_MATCHED_FILTERED_PROFILES:
      return state
        .set('matchedProfiles', typeCastResponse(action, 'matchedProfiles', 'array', []))
        .set('likedJobs', typeCastResponse(action, 'likedJobs', 'array', []))
        .set('passedProfiles', typeCastResponse(action, 'passedProfiles', 'array', []))
        .set('selectedProfiles', typeCastResponse(action, 'selectedProfiles', 'array', []))
        .set('savedProfiles', typeCastResponse(action, 'savedProfiles', 'array', []))
        .set('recommendedProfiles', typeCastResponse(action, 'recommendedProfiles', 'array', []))
        .set(
          'candidateProfileCount',
          typeCastResponse(action, 'candidateProfileCount', 'object', {}),
        )
        .set('filters', typeCastResponse(action, 'filters', 'object', {}));
    // Load keyskills
    case jobConstants.LOAD_KEY_SKILLS:
      return state.set('keySkills', typeCastResponse(action, 'keySkills', 'array', []));
    // Create Scheduled Availability
    case jobConstants.SAVE_SCHEDULED_AVAILABILITY:
      return state;
    case jobConstants.UPDATE_JOB_STATUS:
      return state;
    // Clone Job
    case jobConstants.CLONE_JOB:
      return state;
    // Update Scheduled Availability
    case jobConstants.UPDATE_SCHEDULED_AVAILABILITY:
      return state;
    case jobConstants.SEND_RESUMES_EMAIL:
      return state.set('message', typeCastResponse(action, 'message', 'object'));
    // Reset reducer
    case jobConstants.RESET_MESSAGE:
      return state.set('message', null).set('loadErr', null);
    // Flush reducer
    case jobConstants.FLUSH: {
      return initialState;
    }
    // Reset state on location change
    case LOCATION_CHANGE:
      return (
        state
          .set('message', null)
          .set('loadErr', null)
          .set('filters', {})
          .set('candidateProfileCount', initialState.get('candidateProfileCount'))
          .set('matchedProfiles', [])
          .set('savedProfiles', [])
          .set('passedProfiles', [])
          .set('recommendedProfiles', [])
      );
    // Default case
    default:
      return state;
  }
}
