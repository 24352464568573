import Immutable from 'immutable';
import { typeCastResponse } from '../../utils/siteSpecificCommonUtils';
import { notificationsConstants } from '../constants/notifications.constants';

const initialState = Immutable.fromJS({
  isLoad: false,
  loadErr: null,
  message: null,
  notifications: [],
  notification: null,
  rtnList: [],
  count: 0,
  unread: 0,
  new_unread: 0,
  ringing: false,
  detail: {},
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    // Loading
    case notificationsConstants.LOAD_FAIL:
      return state.set('loadErr', typeCastResponse(action, 'error'));
    // Loading notifications into list
    case notificationsConstants.LOAD_NOTIFICATIONS:
      return state
        .set('notifications', typeCastResponse(action, 'notifications', 'array', []))
        .set('count', typeCastResponse(action, 'count', 'number', 0))
        .set('unread', typeCastResponse(action, 'unread', 'number', 0))
        .set('ringing', false);
    // Loading notifications into list
    case notificationsConstants.UPDATE_NOTIFICATIONS_COUNT:
      return state
        .set('unread', typeCastResponse(action, 'unread', 'number', 0))
        .set('new_unread', typeCastResponse(action, 'new_unread', 'number', 0))
        .set('ringing', false);
    case notificationsConstants.LOAD_RTN:
      return state.set('rtnList', [
        typeCastResponse(action, 'data', 'object', {}),
        ...state.get('rtnList'),
      ]);
    case notificationsConstants.RESET_NEW_UNREAD_COUNT:
      return state.set('new_unread', typeCastResponse(action, 'new_unread', 'number', 0));
    case notificationsConstants.UPDATE_RTN:
      return state.set('rtnList', typeCastResponse(action, 'list', 'array', []));
    // .set('detail', {});
    case notificationsConstants.RING_NOTIFICATIONS_COUNT:
      return state
        .set('unread', typeCastResponse(action, 'unread', 'number', 0))
        .set('ringing', true);
    case notificationsConstants.FLUSH:
      return initialState
        .set('message', null)
        .set('loadErr', null)
        .set('notifications', [])
        .set('notification', null)
        .set('count', 0)
        .set('unread', 0)
        .set('new_unread', 0);
    case notificationsConstants.NOTIFICATION_DETAIL:
      return state
        .set('message', null)
        .set('loadErr', null)
        .set('detail', typeCastResponse(action, 'detail', 'object'));
    // Default
    default:
      return state;
  }
}
