import React from 'react';
import './pr-ico-arrow-link.scss';

export default class PrIcoArrowLinkComponent extends React.Component {
  render() {
    return (
      <div className="pr-ico-arrow-link-wrapper">
        <svg
          width="12"
          height="14"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 11L11 1"
            stroke="#4A85FB"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1 1H11V11"
            stroke="#4A85FB"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    );
  }
}
