import axios from 'axios';
import store from 'store2';
import { removeInValidKeyValuePairs, loadRoute } from '../../../../../utils/commonUtils';
import { consoleOnlyInDebugMode } from '../../../../../utils/debugging';
import {
  editNotificationRequest,
  editNotificationSuccess,
  editNotificationFailure,
} from './actions';

const APIUrl = `${process.env.REACT_APP_APIHOST}/alerts`;

const editNotificationContent = (data, id) => async (dispatch, getState, api) => {
  editNotificationRequest(dispatch);
  try {
    const url = `${APIUrl}/${id}`;
    await api.put(url, { data: data });
    loadRoute(dispatch, '/notification-contents');
  } catch (error) {
    editNotificationFailure(dispatch, error);
    return false;
  }
};

const getNotificationContent = (id) => async (dispatch) => {
  editNotificationRequest(dispatch);
  try {
    const url = `${APIUrl}/${id}`;
    const res = await axios.get(url, {
      headers: {
        'x-access-token': store.get('user').token,
      },
    });
    editNotificationSuccess(dispatch, res.data.data);
  } catch (error) {
    editNotificationFailure(dispatch, error);
    consoleOnlyInDebugMode('err-alert-Notification', error.response);
  }
};

export { editNotificationContent, getNotificationContent };
