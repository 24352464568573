import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { findLastEntry } from 'draft-js/lib/DefaultDraftBlockRenderMap';
import CustomReactTable from '../../../../components/table/CustomReactTable';
import { attachmentsSuccess } from './actions';
import { getAttachments, updateAttachment } from './api';
import '../style.scss';
import AddAttachment from '../add';
import { showDateTimeInListingWithoutTimezone } from '../../../../utils/stringsHelpers';
import PrButton from '../../../../components/--primitives/pr-button';
import PrAnimatedInput from '../../../../components/--primitives/pr-data-input-2';
import {
  strictValidArrayWithLength,
  strictValidObject,
  strictValidObjectWithKeys,
} from '../../../../utils/commonUtils';
import { AttachmentFilterOptions } from '../../../../utils/siteSpecificConstants';
import FfDropdownInput from '../../../../components/final-form/ff-dropdown-input/ff-dropdown-input';

const initialTableSearch = {
  page: 1,
  limit: 10,
  sort: 'id',
  order: 'desc',
  filter: { is_deleted: false },
};
class Attachments extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...initialTableSearch,
      isModalOpen: false,
    };
  }

  componentDidMount() {
    this.fetchListAttachment();
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    attachmentsSuccess(dispatch, {});
  }

  toggle = () => {
    this.setState((prevState) => ({
      isModalOpen: !prevState.isModalOpen,
    }));
  };

  onSaveModal = () => {
    const { filter } = this.state;
    this.setState(
      {
        ...initialTableSearch,
        filter,
        isModalOpen: false,
      },
      () => this.fetchListAttachment(),
    );
  };

  fetchListAttachment = () => {
    const { Id, type, callGetAttachmentsAPI } = this.props;
    const { page, limit, sort, order, filter } = this.state;
    callGetAttachmentsAPI(Id, type, page, limit, sort, order, filter);
  };

  onClickAction = (id, action) => {
    const { page, sort, limit, order, filter } = this.state;
    const { callUpdateAttachment, Id, type } = this.props;
    callUpdateAttachment(id, Id, type, !action, page, limit, sort, order, filter);
  };

  renderInput = (filter, onChange) => (
    <PrAnimatedInput type="text" onChange={onChange} value={filter ? filter.value : ''} />
  );

  renderTableValues = (props, type) => {
    const { path, org_file_name, uploaded_on = null, _id, is_deleted = false } = props.original;
    switch (type) {
      case 'Attachment':
        return (
          <a target="_blank" href={path} className="overflow-ellipsis make-link" rel="noreferrer">
            {org_file_name}
          </a>
        );
      case 'uploaded_on':
        return <p>{showDateTimeInListingWithoutTimezone(uploaded_on)}</p>;
      case 'is_deleted': {
        const color = is_deleted ? 'blue' : 'red';
        const buttonName = is_deleted ? 'Unarchive ' : 'Archive';
        return (
          <PrButton
            value={buttonName}
            type={color}
            onClick={() => this.onClickAction(_id, is_deleted)}
          />
        );
      }
      default:
        return '';
    }
  };

  // eslint-disable-next-line class-methods-use-this
  ActionFilter = ({ filter, onChange }) => {
    const option =
      strictValidObject(filter) && strictValidObjectWithKeys(filter, ['value'])
        ? AttachmentFilterOptions.find((v) => v.value === filter.value)
        : AttachmentFilterOptions[0];
    return (
      <FfDropdownInput
        placeholder=""
        options={AttachmentFilterOptions}
        input={{
          value: option,
          onChange: (val) => {
            onChange(val.value);
          },
        }}
      />
    );
  };

  getColumns = () => [
    {
      Header: '#',
      accessor: 'id',
      minWidth: 3,
      Filter: ({ filter, onChange }) => this.renderInput(filter, onChange),
    },
    {
      Header: 'Name',
      accessor: 'name',
      minWidth: 5,
      Filter: ({ filter, onChange }) => this.renderInput(filter, onChange),
    },
    {
      Header: 'Attachment',
      accessor: 'path',
      sortable: false,
      filterable: false,
      minWidth: 20,
      Cell: (props) => this.renderTableValues(props, 'Attachment'),
    },
    {
      Header: 'Description',
      accessor: 'description',
      sortable: false,
      minWidth: 10,
      Filter: ({ filter, onChange }) => this.renderInput(filter, onChange),
    },
    {
      Header: 'Uploaded By',
      accessor: 'uploaded_by',
      minWidth: 7,
      Filter: ({ filter, onChange }) => this.renderInput(filter, onChange),
    },
    {
      Header: 'Uploaded On',
      accessor: 'uploaded_on',
      minWidth: 10,
      filterable: false,
      Cell: (props) => this.renderTableValues(props, 'uploaded_on'),
    },
    {
      Header: 'Action',
      accessor: 'is_deleted',
      minWidth: 8,
      Filter: (props) => this.ActionFilter(props),
      Cell: (props) => this.renderTableValues(props, 'is_deleted'),
    },
  ];

  render() {
    const { attachment, callGetAttachmentsAPI, Id, type, pathname } = this.props;
    const { sort, order, page, isModalOpen, limit, filter } = this.state;
    const columns = this.getColumns();
    const { count = 0 } = attachment;
    return (
      <div>
        {isModalOpen && (
          <AddAttachment
            Id={Id}
            type={type}
            toggle={this.toggle}
            onSaveModal={this.onSaveModal}
            pathname={pathname}
            isOpen={isModalOpen}
          />
        )}
        <div className="row space-between vertical-center">
          <div className="col">
            <h2 className="card-title">Attachments</h2>
            <div className="card-subtitle">
              {`${count}`} {count > 1 ? 'results found' : 'result found'}
            </div>
          </div>
          <div className="col">
            <PrButton
              value="Upload Attachments"
              type="light-blue medium tall"
              onClick={this.toggle}
            />
          </div>
        </div>
        <p>&nbsp;</p>
        <CustomReactTable
          className="overflow-filter"
          defaultData={attachment}
          columns={columns}
          callDataAPI={(...params) => {
            if (JSON.stringify(params[4]) !== JSON.stringify(filter)) {
              callGetAttachmentsAPI(Id, type, ...params);
              this.setState({ filter: params[4] });
            }
          }}
          getSortingDetails={(newSorted) => {
            const { id = 'id', desc = true } =
              (strictValidArrayWithLength(newSorted) && newSorted[0]) || {};
            const orderVal = desc ? 'desc' : 'asc';
            this.setState(
              {
                sort: id,
                order: orderVal,
              },
              () => callGetAttachmentsAPI(Id, type, page, limit, id, orderVal, filter),
            );
          }}
          page={page - 1}
          defaultPageSize={10}
          defaultSorted={[{ id: sort, desc: order === 'desc' }]}
          defaultFiltered={[{ id: 'is_deleted', value: false }]}
        />
      </div>
    );
  }
}

Attachments.propTypes = {
  callGetAttachmentsAPI: PropTypes.func.isRequired,
  callUpdateAttachment: PropTypes.func.isRequired,
  attachment: PropTypes.objectOf(PropTypes.shape).isRequired,
  dispatch: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  Id: PropTypes.string.isRequired,
  pathname: PropTypes.string.isRequired,
};

// Attachments.defaultProps = {
//   type: undefined,
//   Id: undefined,
//   pathname: '',
// };

const mapStateToProps = (state) => {
  const { data: { rows = [], count = 0 } = {}, loading = false } = state
    .get('attachment')
    .get('attachments');
  return {
    attachment: {
      data: rows,
      count,
      loading,
    },
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  callGetAttachmentsAPI: (...params) => dispatch(getAttachments(...params)),
  callUpdateAttachment: (...params) => dispatch(updateAttachment(...params)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Attachments));
