const TRANSACTIONS_LOGS_REQUEST = 'TRANSACTIONS_LOGS_REQUEST';
const TRANSACTIONS_LOGS__SUCCESS = 'TRANSACTIONS_LOGS__SUCCESS';
const TRANSACTIONS_LOGS__FAILURE = 'TRANSACTIONS_LOGS__FAILURE';

const transactionLogsRequest = dispatch => {
  dispatch({ type: TRANSACTIONS_LOGS_REQUEST, payload: { loading: true } });
};

const transactionLogsSuccess = (dispatch, transactionId, response) => {
  dispatch({
    type: TRANSACTIONS_LOGS__SUCCESS,
    payload: { data: response, loading: false, transactionId },
  });
};

const transactionLogsFailure = dispatch => {
  dispatch({ type: TRANSACTIONS_LOGS__FAILURE, payload: { loading: false } });
};

export {
  TRANSACTIONS_LOGS_REQUEST,
  TRANSACTIONS_LOGS__SUCCESS,
  TRANSACTIONS_LOGS__FAILURE,
  transactionLogsRequest,
  transactionLogsSuccess,
  transactionLogsFailure,
};
