const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = 'Please enter File name';
  }
  if (!values.description) {
    errors.description = 'Please enter Description';
  }
  if (!values.uploadFile) {
    errors.uploadFile = 'Please upload valid file';
  }
  return errors;
};

export default validate;
