import { DEFAULT_MILLISECONDS_TO_SHOW_MESSAGES } from '../../../../utils/constants';

const ADD_ATTACHMENT_REQUEST = 'ADD_ATTACHMENT_REQUEST';
const ADD_ATTACHMENT_SUCCESS = 'ADD_ATTACHMENT_SUCCESS';
const ADD_ATTACHMENT_FAILURE = 'ADD_ATTACHMENT_FAILURE';
const ADD_ATTACHMENT_RESET_MESSAGE = 'ADD_ATTACHMENT_RESET_MESSAGE';

const addAttachmentRequest = (dispatch) => {
  dispatch({ type: ADD_ATTACHMENT_REQUEST, payload: { loading: true } });
};

const addAttachmentSuccess = (dispatch, response) => {
  dispatch({
    type: ADD_ATTACHMENT_SUCCESS,
    payload: { message: response, error: '', loading: false },
  });
};

const addAttachmentFailure = (dispatch, error) => {
  dispatch({ type: ADD_ATTACHMENT_FAILURE, payload: { error, loading: false } });
};

/**
 * To reset message fields in reducer
 */
const attachmentsResetMessage = (
  dispatch,
  defaultTimeout = DEFAULT_MILLISECONDS_TO_SHOW_MESSAGES,
) => {
  setTimeout(() => {
    dispatch({
      type: ADD_ATTACHMENT_RESET_MESSAGE,
      payload: { message: '', error: '', loading: false },
    });
  }, DEFAULT_MILLISECONDS_TO_SHOW_MESSAGES);
};

export {
  ADD_ATTACHMENT_REQUEST,
  ADD_ATTACHMENT_SUCCESS,
  ADD_ATTACHMENT_FAILURE,
  ADD_ATTACHMENT_RESET_MESSAGE,
  addAttachmentRequest,
  addAttachmentSuccess,
  addAttachmentFailure,
  attachmentsResetMessage,
};
