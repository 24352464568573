export const jobConstants = {
  LOAD: 'jobs/LOAD',
  LOAD_SUCCESS: 'jobs/LOAD_SUCCESS',
  LOAD_FAIL: 'jobs/LOAD_FAIL',
  LOAD_SETTINGS: 'jobs/LOAD_SETTINGS',
  LOAD_PENDING_JOB: 'jobs/LOAD_PENDING_JOB',
  LOAD_PENDING_PROFILE: 'jobs/LOAD_PENDING_PROFILE',
  LOAD_ALL_TEMPLATES: 'jobs/LOAD_ALL_TEMPLATES',
  LOAD_KEY_SKILL: 'jobs/LOAD_KEY_SKILL',
  CANDIDATE_SLOTS: 'jobs/CANDIDATE_SLOTS',
  REASSIGN_JOB: 'jobs/REASSIGN_JOB',
  LOAD_COUNT: 'jobs/LOAD_COUNT',
  SET_JOB_FUNTION_ANSWERS: 'jobs/SET_JOB_FUNTION_ANSWERS',
  LOAD_MATCHED_PROFILES: 'jobs/LOAD_MATCHED_PROFILES',
  SAVE_SCHEDULED_AVAILABILITY: 'jobs/SAVE_SCHEDULED_AVAILABILITY',
  UPDATE_JOB_STATUS: 'jobs/UPDATE_JOB_STATUS',
  UPDATE_SCHEDULED_AVAILABILITY: 'jobs/UPDATE_SCHEDULED_AVAILABILITY',
  LOAD_ALL_JOBS: 'jobs/LOAD_ALL_JOBS',
  LOAD_ALL_SCHEDULED_JOBS: 'jobs/LOAD_ALL_SCHEDULED_JOBS',
  CLONE_JOB: 'jobs/CLONE_JOB',
  GET_QUESTIONS: 'jobs/GET_QUESTIONS',
  SAVE_SELECTED_VALUES: 'jobs/SAVE_SELECTED_VALUES',
  GET_CANDIDATE_BY_ID: 'jobs/GET_CANDIDATE_BY_ID',
  GET_JOB_CANDIDATES_BY_ID: 'jobs/GET_JOB_CANDIDATES_BY_ID',
  GET_JOB_AVAILABILITY_BY_ID: 'jobs/GET_JOB_AVAILABILITY_BY_ID',
  GET_TOTAL_APPOINTMENTS: 'jobs/GET_TOTAL_APPOINTMENTS',
  GET_JOB_MAX_SCHEDULED_ROUNDS_BY_ID: 'jobs/GET_JOB_MAX_SCHEDULED_ROUNDS_BY_ID',
  GET_APPOINTMENTS_BY_ID: 'jobs/GET_APPOINTMENTS_BY_ID',
  SAVE_FORM: 'jobs/SAVE_FORM',
  SET_JOB_FILTERS: 'jobs/SET_JOB_FILTERS',
  GET_JOB_BY_ID: 'jobs/GET_JOB_BY_ID',
  SET_NEXT_STEP: 'jobs/SET_NEXT_STEP',
  SELECTED_INTERVIEW_CANDIDATES: 'jobs/SELECTED_INTERVIEW_CANDIDATES',
  LOAD_MATCHED_FILTERED_PROFILES: 'jobs/LOAD_MATCHED_FILTERED_PROFILES',
  UPDATE_FILTERED_PROFILES: 'jobs/UPDATE_FILTERED_PROFILES',
  SEND_RESUMES_EMAIL: 'jobs/SEND_RESUMES_EMAIL',
  RESET_MESSAGE: 'jobs/RESET_MESSAGE',
  FLUSH: 'jobs/FLUSH',
  BUCKET_LOADING: 'BUCKET_LOADING',
  BUCKET_FINISH_LOADING: 'BUCKET_FINISH_LOADING',
  GET_JOB_VERTICALIZATION_DATA: 'jobs/GET_JOB_VERTICALIZATION_DATA',
  GET_JOB_FUNCTION_QUESTIONS: 'jobs/GET_JOB_FUNCTION_QUESTIONS',
};
