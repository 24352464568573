const ASSIGNMENTS_AT_RISK_REQUEST = 'ASSIGNMENTS_AT_RISK_REQUEST';
const ASSIGNMENTS_AT_RISK_SUCCESS = 'ASSIGNMENTS_AT_RISK_SUCCESS';
const ASSIGNMENTS_AT_RISK_FAILURE = 'ASSIGNMENTS_AT_RISK_FAILURE';

const assignmentsAtRiskRequest = (dispatch) => {
  dispatch({ type: ASSIGNMENTS_AT_RISK_REQUEST, payload: { loading: true } });
};

const assignmentsAtRiskSuccess = (dispatch, response) => {
  dispatch({
    type: ASSIGNMENTS_AT_RISK_SUCCESS,
    payload: { data: response, loading: false },
  });
};

const assignementsAtRiskFailure = (dispatch) => {
  dispatch({ type: ASSIGNMENTS_AT_RISK_FAILURE, payload: { loading: false } });
};

export {
  ASSIGNMENTS_AT_RISK_REQUEST,
  ASSIGNMENTS_AT_RISK_SUCCESS,
  ASSIGNMENTS_AT_RISK_FAILURE,
  assignmentsAtRiskRequest,
  assignmentsAtRiskSuccess,
  assignementsAtRiskFailure,
};
