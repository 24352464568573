export const clientConstants = {
  LOAD: 'client/LOAD',
  LOAD_SUCCESS: 'client/LOAD_SUCCESS',
  LOAD_FAIL: 'client/LOAD_FAIL',
  GET_ALL_CLIENTS: 'client/GET_ALL_CLIENTS',
  SET_CLIENT: 'client/SET_CLIENT',
  GET_ALL_LOCATIONS: 'client/GET_ALL_LOCATIONS',
  SET_CLIENT_LOCATION: 'client/SET_CLIENT_LOCATION',
  // Flush
  FLUSH: 'client/FLUSH',
  RESET_MESSAGE: 'client/RESET_MESSAGE',
};
