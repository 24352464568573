import React, { useState, forwardRef, useEffect } from 'react';

import PropTypes from 'prop-types';
import './pr-expand-collapse.scss';

const PrExpandCollapse = forwardRef(
  ({ title, subtitle, children, className, defaultCollapsed, customTitle }, ref) => {
    const [isClosed, setIsClosed] = useState(false);
    useEffect(() => {
      setIsClosed(defaultCollapsed);
    }, [defaultCollapsed]);
    return (
      <div
        ref={ref}
        className={`gr-job-group pr-expand-collapse ${className || ''} ${isClosed ? 'closed' : ''}`}
      >
        <div className="header">
          <div className="indicator" onClick={() => setIsClosed((prevState) => !prevState)} />
          {customTitle ? (
            <p onClick={() => setIsClosed((prevState) => !prevState)}>{customTitle}</p>
          ) : (
            <p onClick={() => setIsClosed((prevState) => !prevState)}>{title}</p>
          )}
          {!!subtitle && subtitle}
        </div>
        <div className="content">{children}</div>
      </div>
    );
  },
);

PrExpandCollapse.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  defaultCollapsed: PropTypes.bool,
  customTitle: PropTypes.node,
};

PrExpandCollapse.defaultProps = {
  className: '',
  subtitle: '',
  defaultCollapsed: false,
  customTitle: '',
};
export default PrExpandCollapse;
