import {
  ADD_COMPANY_REQUEST,
  ADD_COMPANY_SUCCESS,
  ADD_COMPANY_FAILURE,
  HOLIDAY_CALENDAR_PROCESSING,
  HOLIDAY_CALENDAR_SUCCESS,
  HOLIDAY_CALENDAR_FAILURE,
} from './actions';

const initialState = {};

const addCompany = (state = initialState, action) => {
  switch (action.type) {
    case ADD_COMPANY_REQUEST:
    case ADD_COMPANY_SUCCESS:
    case ADD_COMPANY_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

const holidayCalendar = (state = initialState, action) => {
  switch (action.type) {
    case HOLIDAY_CALENDAR_PROCESSING:
    case HOLIDAY_CALENDAR_SUCCESS:
    case HOLIDAY_CALENDAR_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export { addCompany, holidayCalendar };
