const REQUEST = 'REQUEST';
const SUCCESS = 'SUCCESS';
const FAILURE = 'FAILURE';
const ALLOT_REGION = 'ALLOT_REGION';

const request = (dispatch, key = 'data') => {
  dispatch({ type: REQUEST, payload: { loading: true, [key]: {} } });
};

const success = (dispatch, response, key = 'data') => {
  dispatch({ type: SUCCESS, payload: { [key]: response, loading: false } });
};

const failure = (dispatch, err) => {
  dispatch({ type: FAILURE, payload: { err, loading: false } });
};

const allotRegion = (dispatch, response) => {
  dispatch({
    type: ALLOT_REGION,
    payload: { region: response, loading: false },
  });
};

export { request, success, failure, allotRegion, REQUEST, SUCCESS, FAILURE, ALLOT_REGION };
