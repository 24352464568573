/* eslint-disable react/no-unstable-nested-components */
import createDecorator from 'final-form-focus';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Field, Form } from 'react-final-form';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { validObjectWithParameterKeys } from '../../../../utils/commonUtils';
import S3FileUploader from '../../../../components/s3-file-uploader/s3-file-uploader';
import { addAttachmentFailure } from './actions';
import { addAttachment } from './api';
import validate from './validate';
import PrButton from '../../../../components/--primitives/pr-button';
import FfAnimatedInput from '../../../../components/final-form/ff-animated-input';
import FFTextareaComments from '../../../../components/final-form/ff-textarea-comments';
import PrDropArea1Component from '../../../../components/--primitives/pr-drop-area-1';
import PrTooltipComponent from '../../../../components/--primitives/pr-tooltip';
import PrImgIconComponent from '../../../../components/--primitives/pr-img-icon';
import icoTrash from '../../../../assets/icons/trash_small_red.svg';
import { Popup, PopupHeader, PopupBody } from '../../../../components/--primitives/pr-popup';

const focusOnErrors = createDecorator();
class AddAttachment extends PureComponent {
  handleFileFinishedUpload = async (data, onChange) => {
    onChange(data[0]);
  };

  onSubmit = async (values) => {
    const { callUploadAttachmentAPI, Id, type, onSaveModal } = this.props;
    const { uploadFile = {}, ...restValue } = values;
    const { name, ...restFileValue } = uploadFile;
    const valuesToSend = {
      ...restValue,
      ...restFileValue,
      org_file_name: name,
    };
    valuesToSend[type] = Id;
    // valuesToSend.company = clientId;
    // valuesToSend.placement = placementId;
    await callUploadAttachmentAPI(valuesToSend);
    onSaveModal();
  };

  onCancel = () => {
    const { toggle, dispatch } = this.props;
    toggle();
    addAttachmentFailure(dispatch, '');
  };

  dropDownAre = (value, form) => {
    const tooltipContent = (active) => (
      <PrTooltipComponent
        type={`bottom-right shift with-triangle with-extra-cta ${active ? 'in-view' : ''}`}
        CTAsList={[
          {
            ico: <PrImgIconComponent type="default" url={icoTrash} />,
            name: 'Remove',
            onClick: () => form.change('uploadFile', {}),
          },
        ]}
      />
    );
    return (
      <PrDropArea1Component
        title="Upload Attachment"
        subtitle={validObjectWithParameterKeys(value, ['name']) && value.name}
        type={`primary ${validObjectWithParameterKeys(value, ['path']) ? 'filled' : ''}`}
        tooltip={(active) => tooltipContent(active)}
      />
    );
  };

  render() {
    const { loading, toggle, pathname, isOpen } = this.props;
    return (
      <Popup className="add-attachement" isOpen={isOpen}>
        <PopupHeader customClassName="form-group" close toggle={toggle}>
          Add Attachment
        </PopupHeader>
        <PopupBody>
          <Form
            decorators={[focusOnErrors]}
            validate={validate}
            onSubmit={this.onSubmit}
            render={({ handleSubmit, form, hasValidationErrors }) => (
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="row grid grid-12">
                  <div className="col">
                    <Field
                      name="uploadFile"
                      component={({ meta, input: { onChange, value } }) => {
                        return [
                          <S3FileUploader
                            key="s3uploader"
                            signingUrl={`${process.env.REACT_APP_APIHOST}/settings/get-signed-url`}
                            s3Path={pathname}
                            showErrorModal
                            onFileUpload={async (data) =>
                              this.handleFileFinishedUpload(data, onChange)
                            }
                            toShowContent={() => this.dropDownAre(value, form)}
                            parallelUploads={1}
                            uploadMultiple={false}
                            allowedMimeTypes={[
                              '.pdf',
                              '.doc',
                              '.docx',
                              'png',
                              'jpg',
                              'jpeg',
                              'application/pdf',
                              'application/msword',
                              'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                            ]}
                          />,
                          meta.touched && meta.error && <span className="error">{meta.error}</span>,
                        ];
                      }}
                    />
                  </div>

                  <div className="col">
                    <Field name="name" component={FfAnimatedInput} placeholder="File Name" />
                  </div>
                  <div className="col">
                    <Field
                      name="description"
                      placeholder="Add Description"
                      labelText="Add Description"
                      component={FFTextareaComments}
                      rows={3}
                      hideLabel
                      meta={{}}
                    />
                  </div>
                </div>

                <p>&nbsp;</p>
                <div className="submit-button-group">
                  <PrButton
                    value="Cancel"
                    type="light-blue"
                    onClick={() => {
                      form.reset();
                      this.onCancel();
                    }}
                  />
                  <PrButton
                    value="Save"
                    type="light-blue"
                    isDisabled={loading || hasValidationErrors}
                    onClick={handleSubmit}
                  />
                </div>
              </form>
            )}
          />
        </PopupBody>
      </Popup>
    );
  }
}

AddAttachment.propTypes = {
  callUploadAttachmentAPI: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  Id: PropTypes.string,
  error: PropTypes.string,
  type: PropTypes.string,
  pathname: PropTypes.string,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
};

AddAttachment.defaultProps = {
  Id: undefined,
  loading: false,
  error: undefined,
  type: undefined,
  pathname: '',
  isOpen: false,
  toggle: null,
};

const mapStateToProps = (state) => {
  const { loading, error } = state.get('attachment').get('addAttachment');
  return {
    loading,
    error,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  callUploadAttachmentAPI: (...params) => dispatch(addAttachment(...params)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddAttachment));
