import { LOCATION_CHANGE } from 'react-router-redux';
import { FAILURE, PROCESSING, SUCCESS } from './actions';

// Initial state of the app.
const dataState = {};

/* Reducer code for every action, which helps in adding/deleting/updating data in redux store. */
const placementsList = (state = dataState, action) => {
  switch (action.type) {
    case PROCESSING:
    case SUCCESS:
    case FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    // Reset state on location change
    case LOCATION_CHANGE:
      return { ...state, loadError: null };
    default:
      return state;
  }
};

export { placementsList };
