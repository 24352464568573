import axios from 'axios';
import store from 'store2';
import {
  companyDetailsRequest,
  companyDetailsSuccess,
  companyDetailsSuccessCopy,
  companyDetailsFailure,
} from './actions';
import { consoleOnlyInDebugMode } from '../../../../../utils/debugging';
import { extractErrorMessage } from '../../../../../utils/commonUtils';

const getCompanyData = (id) => (dispatch) => {
  companyDetailsRequest(dispatch);
  const url = `${process.env.REACT_APP_APIHOST}/companies/${id}`;
  axios
    .get(url, {
      headers: {
        'x-access-token': store.get('user').token,
      },
    })
    .then((res) => {
      companyDetailsSuccess(dispatch, res.data.data, res.data.field_permissions);
    })
    .catch((err) => {
      companyDetailsFailure(dispatch, err.response);
      consoleOnlyInDebugMode('err-getCompanyData', err.response);
    });
};
// eslint-disable-next-line consistent-return
const getAsyncCompanyData = (id) => async (dispatch) => {
  companyDetailsRequest(dispatch);
  try {
    const url = `${process.env.REACT_APP_APIHOST}/companies/${id}`;
    const res = await axios.get(url, {
      headers: {
        'x-access-token': store.get('user').token,
      },
    });
    consoleOnlyInDebugMode(res, 'res-getCompanyData details');
    companyDetailsSuccessCopy(dispatch, res.data.data, res.data.field_permissions);
    return res.data.data;
  } catch (error) {
    companyDetailsFailure(dispatch, extractErrorMessage(error));
    consoleOnlyInDebugMode('err-getCompanyData', extractErrorMessage(error));
  }
};

export { getCompanyData, getAsyncCompanyData };
