import { ATTACHMENTS_REQUEST, ATTACHMENTS_SUCCESS, ATTACHMENTS_FAILURE } from './actions';

const initialState = {};

const attachments = (state = initialState, action) => {
  switch (action.type) {
    case ATTACHMENTS_REQUEST:
    case ATTACHMENTS_SUCCESS:
    case ATTACHMENTS_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export { attachments };
