const LOCATIONS_REQUEST = 'LOCATIONS_REQUEST';
const LOCATIONS__SUCCESS = 'LOCATIONS__SUCCESS';
const LOCATIONS__FAILURE = 'LOCATIONS__FAILURE';

const locationsRequest = (dispatch) => {
  dispatch({ type: LOCATIONS_REQUEST, payload: { loading: true } });
};

const locationsSuccess = (dispatch, response) => {
  dispatch({
    type: LOCATIONS__SUCCESS,
    payload: { data: response, loading: false },
  });
};

const locationsFailure = (dispatch) => {
  dispatch({ type: LOCATIONS__FAILURE, payload: { loading: false } });
};

export {
  LOCATIONS_REQUEST,
  LOCATIONS__SUCCESS,
  LOCATIONS__FAILURE,
  locationsRequest,
  locationsSuccess,
  locationsFailure,
};
