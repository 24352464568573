/* eslint-disable no-console */
import Immutable from 'immutable';
import { LOCATION_CHANGE } from 'react-router-redux';
import { editLeadConstants } from './constants';
import { typeCastResponse } from '../../../../utils/siteSpecificCommonUtils';

const initialState = Immutable.fromJS({
  isLoad: false,
  isLoadInline: false,
  isLoadCandidates: false,
  loadErr: null,
  message: null,
  lead: {},
  users: [],
  opportunities: [],
  candidates: {},
});

export const Edit = (state = initialState, action) => {
  switch (action.type) {
    case editLeadConstants.LOAD:
      return state.set('isLoad', true).set('loadErr', null);
    case editLeadConstants.LOAD_INLINE:
      return state.set('isLoadInline', true).set('loadErr', null);
    case editLeadConstants.LOAD_CANDIDATES:
      return state.set('isLoadCandidates', true).set('loadErr', null);
    case editLeadConstants.LOAD_SUCCESS:
      return state
        .set('isLoad', false)
        .set('isLoadInline', false)
        .set('isLoadCandidates', false)
        .set('loadErr', null)
        .set('message', typeCastResponse(action, 'message'));
    case editLeadConstants.LOAD_FAIL:
      return state
        .set('isLoad', false)
        .set('isLoadInline', false)
        .set('isLoadCandidates', false)
        .set('message', null)
        .set('loadErr', typeCastResponse(action, 'error'));
    case editLeadConstants.GET_LEAD:
      return state.set('lead', typeCastResponse(action, 'data', 'object', {}));
    case editLeadConstants.GET_OPPORTUNITY:
      return state.set('opportunities', typeCastResponse(action, 'data', 'object', {}));
    case editLeadConstants.GET_USERS:
      return state.set('users', typeCastResponse(action, 'users', 'array', []));
    case editLeadConstants.GET_CANDIDATES:
      return state.set('candidates', typeCastResponse(action, 'data', 'object', {}));
    // Reset state on location change
    case LOCATION_CHANGE:
      return state.set('message', null).set('loadErr', null);
    default:
      return state;
  }
};
