import React from 'react';
import './pr-checkbox.scss';

const PrCheckbox = ({ type, active, disable, onClick = () => {/* empty fun */} }) => {
  return (
    <div
      className={`pr-checkbox-wrapper ${disable ? 'disable' : ''}${type ? type : ''}${
        active ? ' active' : ''
      }`}
      onClick={() => !disable && onClick()}
    >
      <svg className="checkmark" viewBox="0 0 10 8" fill="none">
        <path
          d="M8.91652 1.5L3.11535 6.5L1 4.50204"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default PrCheckbox;
