import axios from 'axios';
import store from 'store2';
import { consoleOnlyInDebugMode } from '../../../utils/debugging';
import { editLogsFailure, editLogsRequest, editLogsSuccess } from './actions';
import { errorConstants } from '../../../redux/constants/error.constants';

let APIUrl = `${process.env.REACT_APP_APIHOST}/logs/edits`;

const getEditLogs =
  (
    job,
    company,
    interview,
    candidate,
    timesheet,
    placement,
    lead,
    pn,
    ps,
    sc,
    st,
    tableFilters = {},
  ) =>
  (dispatch) => {
    const searchFilters = {
      ...tableFilters,
      job,
      company,
      interview,
      candidate,
      ...timesheet,
      placement,
      lead,
    };
    if (lead) {
      APIUrl = `${process.env.REACT_APP_APIHOST}/leads/edits`;
    } else APIUrl = `${process.env.REACT_APP_APIHOST}/logs/edits`;
    editLogsRequest(dispatch);
    let url = `${APIUrl}?page=${pn}&limit=${ps}&sort=${sc}&order=${st}`;
    axios
      .post(url, searchFilters, {
        headers: {
          'x-access-token': store.get('user').token,
        },
      })
      .then((res) => {
        editLogsSuccess(dispatch, res.data.data);
      })
      .catch((err) => {
        editLogsFailure(dispatch, err.response);
        dispatch({ type: errorConstants.SET_ERROR, error: extractErrorMessage(err.response) });
        consoleOnlyInDebugMode('err-getEditLogs', err.response);
      });
  };

export { getEditLogs };
