import Immutable from 'immutable';
import { feedbackListConstants } from './constants';
import { typeCastResponse } from '../../utils/siteSpecificCommonUtils';

const initialState = Immutable.fromJS({
  feedback: {
    clientList: [],
    jobsList: [],
  },
  isLoad: true,
  loadErr: null,
  message: null,
});

export const optionsList = (state = initialState, action) => {
  switch (action.type) {
    case feedbackListConstants.LOAD:
      return state.set('isLoad', true).set('loadErr', null);
    case feedbackListConstants.LOAD_SUCCESS:
      return state
        .set('isLoad', false)
        .set('loadErr', null)
        .set('message', typeCastResponse(action, 'message'));
    case feedbackListConstants.LOAD_FAIL:
      return state.set('isLoad', false).set('loadErr', typeCastResponse(action, 'error'));
    case feedbackListConstants.GET_CLIENTS_LIST:
      return state.set('clientList', typeCastResponse(action, 'data', 'array', []));
    default:
      return state;
  }
};
