const RULES_LIST_PROCESSING = 'RULES_LIST_PROCESSING';
const RULES_LIST_SUCCESS = 'RULES_LIST_SUCCESS';
const RULES_LIST_FAILURE = 'RULES_LIST_FAILURE';

const rulesListProcessing = (dispatch) => {
  dispatch({ type: RULES_LIST_PROCESSING, payload: { loading: true } });
};

const rulesListSuccess = (dispatch, response) => {
  dispatch({
    type: RULES_LIST_SUCCESS,
    payload: { data: response, loading: false },
  });
};

const rulesListFailure = (dispatch, err) => {
  dispatch({ type: RULES_LIST_FAILURE, payload: { loading: false, err } });
};

export {
  RULES_LIST_PROCESSING,
  RULES_LIST_SUCCESS,
  RULES_LIST_FAILURE,
  rulesListProcessing,
  rulesListSuccess,
  rulesListFailure,
};
