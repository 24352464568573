import React from 'react';
import './pr-progress-line.scss';

export default class PrProgressLineComponent extends React.Component {
  componentDidMount() {
    //
  }
  render() {
    return (
      <div
        className="pr-progress-line-wrapper"
        progress-size={this.props.questionsLength}
        progress-state={this.props.activeState}
      >
        <span className="pr-progress-line"></span>
      </div>
    );
  }
}
