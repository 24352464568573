import Immutable from 'immutable';
import { typeCastResponse } from '../../utils/commonUtils';
import { successConstants } from '../constants';

const initialState = Immutable.fromJS({
  message: null,
  status: null,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case successConstants.SET_SUCCESS_MESSAGE:
      return state
        .set('message', typeCastResponse(action, 'message'))
        .set('status', action.status ? typeCastResponse(action, 'status') : null);
    case successConstants.FLUSH_SUCCESS_MESSAGE:
      return state.set('message', null).set('status', null);
    default:
      return state;
  }
}
