import io from 'socket.io-client';
import {
  validObjectWithParameterKeys,
  strictValidObjectWithKeys,
  typeCastToKeyValueObject,
} from '../utils/commonUtils';
import { notificationsConstants } from './constants/notifications.constants';
import { socketConstants } from '../utils/socketConstants';

let socket = {};

export const socketMiddleWare =
  ({ getState, dispatch }) =>
  (next) =>
  async (action) => {
    switch (action.type) {
      case socketConstants.DISCONNECT:
        if (strictValidObjectWithKeys(socket)) socket.disconnect();
        break;
      case socketConstants.JOIN:
        socket = io(`${process.env.REACT_APP_SOCKETHOST}`);
        socket.emit('join', action._id);
        // Ping on server and Update notification count
        socket.on('noti_count', (data) => {
          dispatch({
            type: notificationsConstants.UPDATE_NOTIFICATIONS_COUNT,
            unread: data.unread,
            new_unread: data.new_unread,
          });
        });
        // Pings when recieved a new notification
        socket.on('noti_received', (data) => {
          // NOTE: We are checking the environment here to match the current hostname.
          // if (!data.env || data.env !== config.env) {
          //   return;
          // }
          if (
            !validObjectWithParameterKeys(data, [
              // 'env',
              'subject',
              'unread',
              'receiver',
            ])
          )
            return;

          const { receiver, unread, sender_name, createdAt, _id, subject, new_unread = 0 } = data;
          const user = typeCastToKeyValueObject(getState().get('auth').get('user'));

          if (validObjectWithParameterKeys(user, ['_id']) && user._id === receiver) {
            dispatch({
              type: notificationsConstants.LOAD_RTN,
              data: { subject, sender_name, createdAt, _id },
            });

            dispatch({
              type: notificationsConstants.RING_NOTIFICATIONS_COUNT,
              unread,
            });
            dispatch({
              type: notificationsConstants.RESET_NEW_UNREAD_COUNT,
              new_unread,
            });
          }
        });
        break;
      default:
        break;
    }
    next(action);
  };
