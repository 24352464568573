import React from 'react';
import PropTypes from 'prop-types';
import './pr-progress.scss';
import { Portal } from 'react-portal';

const dynamicTopProgresBar = (progress) => {
  return (
    <Portal>
      <div className="progress-wrapper dynamic-progress">
        <div className="progress-bar">
          <span className="progress-bar-fill" style={{ width: `${progress}%` }} />
        </div>
      </div>
    </Portal>
  );
};
const staticProgresBar = (progress) => {
  return (
    <div className="progress-wrapper">
      <div className="progress-bar">
        <span className="progress-bar-fill" style={{ width: `${progress}%` }} />
      </div>
    </div>
  );
};

const PrProgressComponent = ({ progress, action }) => {
  switch (action) {
    case 'dynamic-progress':
      return dynamicTopProgresBar(progress);
    case 'static-progress':
      return staticProgresBar(progress);
    default:
      return staticProgresBar(progress);
  }
};

PrProgressComponent.propTypes = {
  progress: PropTypes.number.isRequired,
  action: PropTypes.string,
};

PrProgressComponent.defaultProps = {
  action: 'dynamic-progress',
};
export default PrProgressComponent;
