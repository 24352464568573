export const commonConstants = {
  LOAD: 'common/LOAD',
  LOAD_SUCCESS: 'common/LOAD_SUCCESS',
  LOAD_FAIL: 'common/LOAD_FAIL',
  SET_COMMON_DATA: 'common/SET_COMMON_DATA',
  SET_SIDEBAR_STATUS: 'common/SET_SIDEBAR_STATUS',
  // Flush
  FLUSH: 'common/FLUSH',
  RESET_MESSAGE: 'common/RESET_MESSAGE',
  EXPORT_LIST_LOAD: 'common/EXPORT_LIST_LOAD',
  EXPORT_LIST_LOAD_SUCCESS: 'common/EXPORT_LIST_LOAD_SUCCESS',
  EXPORT_LIST_FAIL: 'common/EXPORT_LIST_LOAD_FAIL',
  EXPORT_LIST_RESET_MESSAGE: 'common/EXPORT_LIST_RESET_MESSAGE',
};
