import { LOCATION_CHANGE } from 'react-router-redux';
import {
  ADD_RULES_CALCULATION_FAILURE,
  ADD_RULES_CALCULATION_PROCESSING,
  ADD_RULES_CALCULATION_SUCCESS,
  RULES_DETAILS_PROCESSING,
  RULES_DETAILS_SUCCESS,
  RULES_DETAILS_FAILURE,
  PAYCODES_FAILURE,
  PAYCODES_PROCESSING,
  PAYCODES_SUCCESS,
  STATES_FAILURE,
  STATES_PROCESSING,
  STATES_SUCCESS,
  UPDATE_RULES_CALCULATION_FAILURE,
  UPDATE_RULES_CALCULATION_PROCESSING,
  UPDATE_RULES_CALCULATION_SUCCESS,
} from './actions';

const initialState = {};

const states = (state = initialState, action = {}) => {
  switch (action.type) {
    case STATES_PROCESSING:
    case STATES_SUCCESS:
    case STATES_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case LOCATION_CHANGE:
      return {
        ...state,
        err: null,
      };
    default:
      return state;
  }
};

const paycodes = (state = initialState, action = {}) => {
  switch (action.type) {
    case PAYCODES_PROCESSING:
    case PAYCODES_SUCCESS:
    case PAYCODES_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case LOCATION_CHANGE:
      return {
        ...state,
        err: null,
      };
    default:
      return state;
  }
};

const addRulesCalculation = (state = initialState, action = {}) => {
  switch (action.type) {
    case ADD_RULES_CALCULATION_PROCESSING:
    case ADD_RULES_CALCULATION_SUCCESS:
    case ADD_RULES_CALCULATION_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case LOCATION_CHANGE:
      return {
        ...state,
        err: null,
      };
    default:
      return state;
  }
};

const updateRulesCalculation = (state = initialState, action = {}) => {
  switch (action.type) {
    case UPDATE_RULES_CALCULATION_PROCESSING:
    case UPDATE_RULES_CALCULATION_SUCCESS:
    case UPDATE_RULES_CALCULATION_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case LOCATION_CHANGE:
      return {
        ...state,
        err: null,
      };
    default:
      return state;
  }
};

const ruleDetails = (state = initialState, action = {}) => {
  switch (action.type) {
    case RULES_DETAILS_PROCESSING:
    case RULES_DETAILS_SUCCESS:
    case RULES_DETAILS_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case LOCATION_CHANGE:
      return {
        ...state,
        err: null,
      };
    default:
      return state;
  }
};

export { states, paycodes, addRulesCalculation, updateRulesCalculation, ruleDetails };
