import axios from 'axios';
import store from 'store2';
import { jobConstants } from '../constants';
import {
  getValidElementValueInChildArray,
  validObjectWithParameterKeys,
  typeCastToKeyValueObject,
  removeInValidKeyValuePairs,
  strictValidArrayWithLength,
  strictValidString,
  strictValidObjectWithKeys,
  strictValidArray,
  extractErrorMessage,
} from '../../utils/commonUtils';
import {
  validCategory,
  validJob,
  validKeyWord,
} from '../../utils/siteSpecificCommonUtils';
import { DEFAULT_MILLISECONDS_TO_SHOW_MESSAGES } from '../../utils/constants';
import { JOB_TYPE_OPTIONS } from '../../utils/siteSpecificConstants';
import { messages } from '../../language';
import { TRIM_WHITE_SPACE_AT_END } from '../../utils/regexs';
import { consoleOnlyInDebugMode } from '../../utils/debugging';
import { errorConstants } from '../constants/error.constants';

const internals = {};

/**
 * To load count object which contain posted <Number>, paired <Number> & searching <Number>
 * Access role allowed - any
 * @param filters: object
 * @param role: accessible role company or candidate
 */
export const loadCount = (filters, role) => async (dispatch, getState, api) => {
  try {
    // Format filters to api side filters
    let data = await dispatch(getCompanyFilters(filters));
    // Remove unwanted null or undefined key-value pairs from data,
    // if true is passed empty arrays are also excluded
    data = removeInValidKeyValuePairs(data, true);
    if (strictValidObjectWithKeys(data)) {
      dispatch({
        type: jobConstants.LOAD_COUNT,
        filters,
      });
    }
    // Finally return data
    return data;
  } catch (error) {
    // If an error occurs, set error field
    dispatch({
      type: jobConstants.LOAD_FAIL,
      // error,
    });
    dispatch({ type: errorConstants.SET_ERROR, error: extractErrorMessage(error) });
  }
};

/**
 * Get jobs listing
 * @param {*} object
 */
export const getJobsList =
  ({ page = 0, limit = 25, sort = 'id', order = 'desc', params }) =>
  async (dispatch, getState, api) => {
    dispatch({ type: jobConstants.LOAD });

    const postUrl = `/jobs/list?page=${
      parseInt(page) + 1
    }&limit=${limit}&sort=${sort}&order=${order}`;
    try {
      const res = await api.post(postUrl, {
        data: removeInValidKeyValuePairs(params),
      });
      dispatch({
        type: jobConstants.LOAD_ALL_JOBS,
        list: res.data,
      });
      dispatch({ type: jobConstants.LOAD_SUCCESS });
    } catch (error) {
      // If an error occurs, set error field
      await dispatch({
        type: jobConstants.LOAD_FAIL,
        // error,
      });
      dispatch({ type: errorConstants.SET_ERROR, error: extractErrorMessage(error) });
    }
  };

/**
 * To save filters associated with a job
 * Access role allowed - client/candidate
 * @param data: object
 * @param userRole: string
 */
export const saveJob =
  (data, saveJobInReducer = true) =>
  async (dispatch, getState, api) => {
    dispatch({ type: jobConstants.LOAD });
    try {
      // Put saved job with api
      const url = '/jobs';
      let method = 'post';
      if (data.id) method = 'put';
      const res = await api[method](url, { data });
      saveJobInReducer &&
        dispatch({
          type: jobConstants.LOAD_PENDING_JOB,
          job: res.data,
        });
      let culture_values = {};
      if (validObjectWithParameterKeys(res.data, ['culture_values'])) {
        culture_values = res.data.culture_values;
      }
      dispatch({
        type: jobConstants.SAVE_SELECTED_VALUES,
        selectedValues: {
          ...culture_values,
        },
      });
      let job_function_questions = {};
      if (validObjectWithParameterKeys(res.data, ['job_function_questions'])) {
        job_function_questions = res.data.job_function_questions;
      }
      dispatch({
        type: jobConstants.SET_JOB_FUNTION_ANSWERS,
        data: { job_function_questions },
      });
      dispatch({ type: jobConstants.LOAD_SUCCESS });
      return res.data;
    } catch (error) {
      // If an error occurs, set error field
      await dispatch({
        type: jobConstants.LOAD_FAIL,
        // error,
      });
      dispatch({ type: errorConstants.SET_ERROR, error: extractErrorMessage(error) });
      return false;
    }
  };

/**
 * Get job by requesting a particular id
 * Access role allowed - client/candidate
 * @param id: string
 */
export const getJob = (id) => async (dispatch, getState, api) => {
  dispatch({ type: jobConstants.LOAD });
  try {
    // Put saved job with api
    const url = `/jobs/${id}`;
    const res = await api.get(url);
    dispatch({
      type: jobConstants.GET_JOB_BY_ID,
      job: res.data,
    });
    let culture_values = {};
    if (validObjectWithParameterKeys(res.data, ['culture_values'])) {
      culture_values = res.data.culture_values;
    }
    dispatch({
      type: jobConstants.SAVE_SELECTED_VALUES,
      selectedValues: {
        ...culture_values,
      },
    });
    dispatch({ type: jobConstants.LOAD_SUCCESS });
  } catch (error) {
    // If an error occurs, set error field
    await dispatch({
      type: jobConstants.LOAD_FAIL,
      // error,
    });
    dispatch({ type: errorConstants.SET_ERROR, error: extractErrorMessage(error) });
  }
};

/**
 * Get job scheduled candidates
 * Access role allowed - client/candidate
 * @param id: string
 */
export const getJobCandidates = (id) => async (dispatch, getState, api) => {
  try {
    dispatch({ type: jobConstants.BUCKET_LOADING });
    // Put saved job with api
    const url = `/jobs/candidates/${id}`;
    const res = await api.get(url);
    dispatch({
      type: jobConstants.GET_JOB_CANDIDATES_BY_ID,
      candidates: res.data,
    });
    dispatch({ type: jobConstants.LOAD_SUCCESS });
  } catch (error) {
    // If an error occurs, set error field
    await dispatch({
      type: jobConstants.LOAD_FAIL,
      // error,
    });
    dispatch({ type: errorConstants.SET_ERROR, error: extractErrorMessage(error) });
  }
};

/**
 * Get interview availability schedule
 * Access role allowed - candidate
 * @param id: string
 * @param options: object
 */
export const getInterviewAvailabilitySchedule =
  (id, options) => async (dispatch, getState, api) => {
    dispatch({ type: jobConstants.LOAD });
    try {
      // Put saved job with api
      const url = '/candidates/availability-appointment/';
      const res = await api.get(url);
      dispatch({
        type: jobConstants.GET_APPOINTMENTS_BY_ID,
        availability: res.data.availability,
        invitations: res.data.invitations,
      });
      dispatch({ type: jobConstants.LOAD_SUCCESS });
    } catch (error) {
      // If an error occurs, set error field
      await dispatch({
        type: jobConstants.LOAD_FAIL,
        // error,
      });
      dispatch({ type: errorConstants.SET_ERROR, error: extractErrorMessage(error) });
    }
  };

/**
 * Clone job
 * Access role allowed - client
 * @param id: string
 */
export const cloneJob = (id) => async (dispatch, getState, api) => {
  dispatch({ type: jobConstants.LOAD });
  try {
    // Put saved job with api
    const url = '/jobs/clone';
    const newJob = await api.post(url, { data: { job: id } });
    dispatch({
      type: jobConstants.CLONE_JOB,
    });
    dispatch({
      type: jobConstants.LOAD_SUCCESS,
      message: null,
    });
    return newJob.data;
  } catch (error) {
    // If an error occurs, set error field
    await dispatch({
      type: jobConstants.LOAD_FAIL,
      // error,
    });
    dispatch({ type: errorConstants.SET_ERROR, error: extractErrorMessage(error) });
    return false;
  }
};

/**
 * Changed in profiles stack by removing one or more
 * @param type: string
 * @param profiles: object
 */
export const changedFilteredProfile =
  (profiles, increasedBucketName, decreasedBucketName) => (dispatch, getState, api) => {
    const candidates = typeCastToKeyValueObject(getState().get('jobs').get('candidates'));
    const currentBucketCounts = typeCastToKeyValueObject(
      getState().get('jobs').get('candidates').candidateProfileCount,
    );

    let newUpdatedBucketCounts = { ...currentBucketCounts };
    if (strictValidObjectWithKeys(currentBucketCounts)) {
      if (strictValidString(increasedBucketName)) {
        newUpdatedBucketCounts = {
          ...newUpdatedBucketCounts,
          [increasedBucketName]: currentBucketCounts[increasedBucketName] + 1,
        };
      }
      if (strictValidString(decreasedBucketName)) {
        newUpdatedBucketCounts = {
          ...newUpdatedBucketCounts,
          [decreasedBucketName]: currentBucketCounts[decreasedBucketName] - 1,
        };
      }
    }

    dispatch({
      type: jobConstants.GET_JOB_CANDIDATES_BY_ID,
      candidates: {
        ...candidates,
        ...profiles,
        candidateProfileCount: strictValidObjectWithKeys(newUpdatedBucketCounts)
          ? newUpdatedBucketCounts
          : currentBucketCounts,
      },
    });
    return true;
  };
/**
 * To load matched profiles associated with specific filters and userRole
 * Access role allowed - (company aka client)/candidate
 * @param filters: object
 * @param role: string
 */
export const loadFilteredProfiles =
  (filters, newJob, resetField) => async (dispatch, getState, api) => {
    // Do no use loading here as it globally dimms the whole page,
    // instead use component's internal loading
    dispatch({ type: jobConstants.BUCKET_LOADING });
    try {
      let data = {};
      const jobDetails = typeCastToKeyValueObject(getState().get('jobs').get('job'));
      const {
        page,
        limit,
        offset,
        keyword,
        compensation_min,
        compensation_max,
        exp_min,
        exp_max,
        filterType,
        compensation_min_yearly,
        compensation_max_yearly,
        sort = 'matching',
      } = filters;
      // If valid job
      if (validJob(jobDetails)) {
        const {
          _id,
          job_type_1,
          job_type_2,
          job_function,
          experiences,
          titles,
          duties,
          location,
          proximity,
          location_coordinates,
          is_relocation,
          is_remote,
          work_authorization,
          no_of_hires,
        } = jobDetails;
        // Do object.assign on data & values from pendingJob
        data = {
          job: _id,
          page,
          limit,
          sort,
          offset,
          job_type_1,
          job_type_2,
          keyword,
          location,
          proximity,
          is_relocation,
          is_remote,
          work_authorization,
          no_of_hires,
          job_function:
            (strictValidString(job_function) && job_function) ||
            (validObjectWithParameterKeys(job_function, ['_id']) && job_function._id),
          experiences,
          exp_min: exp_min || 0,
          exp_max: exp_max || 20,
          titles:
            (strictValidString(titles) && titles) ||
            (validObjectWithParameterKeys(titles, ['_id']) && titles._id),
          duties: duties || [],
          location_coordinates:
            (validObjectWithParameterKeys(location_coordinates, ['coordinates']) &&
              strictValidArrayWithLength(location_coordinates.coordinates) &&
              location_coordinates.coordinates) ||
            [],
          compensation_min:
            job_type_2 === JOB_TYPE_OPTIONS.PERMANENT ? undefined : compensation_min,
          compensation_max:
            job_type_2 === JOB_TYPE_OPTIONS.PERMANENT ? undefined : compensation_max,
          compensation_min_yearly:
            job_type_2 === JOB_TYPE_OPTIONS.PERMANENT ? compensation_min_yearly * 1000 : undefined,
          compensation_max_yearly:
            job_type_2 === JOB_TYPE_OPTIONS.PERMANENT ? compensation_max_yearly * 1000 : undefined,
        };
      } else {
        data = filters;
      }
      // If valid job

      // Remove unwanted undefined or null key value pairs
      data = removeInValidKeyValuePairs(data);
      if (filterType !== 'liked') {
        const { sort, ...rest } = data;
        data = rest;
      }
      let url;
      switch (filterType) {
        case 'matched':
          url = 'matching/job-to-candidate';
          break;
        case 'liked':
          url = 'matching/candidate-liked-job';
          break;
        default:
          url = `/candidates/filter${strictValidString(filterType) ? '/'+filterType : ''}`;
          break;
      }
      // Load matched profiles api
      const res = await api.post(url, { data: removeInValidKeyValuePairs(data) });
      const recommendedProfiles = newJob
        ? []
        : typeCastToKeyValueObject(getState().get('jobs').get('candidates').recommendedProfiles) ||
          [];
      const matchedProfiles =
        newJob || resetField === 'matched'
          ? []
          : typeCastToKeyValueObject(getState().get('jobs').get('candidates').matchedProfiles) ||
            [];
      const likedProfiles =
        newJob || resetField === 'liked'
          ? []
          : typeCastToKeyValueObject(getState().get('jobs').get('candidates').likedProfiles) || [];
      const savedProfiles = newJob
        ? []
        : typeCastToKeyValueObject(getState().get('jobs').get('candidates').savedProfiles) || [];
      const selectedProfiles = newJob
        ? []
        : typeCastToKeyValueObject(getState().get('jobs').get('candidates').selectedProfiles) || [];
      const passedProfiles = newJob
        ? []
        : typeCastToKeyValueObject(getState().get('jobs').get('candidates').passedProfiles) || [];
      const candidateProfileCount = typeCastToKeyValueObject(
        getState().get('jobs').get('candidates').candidateProfileCount,
      );

      const isMatchedExists =
        validObjectWithParameterKeys(res.data, ['matched']) &&
        !!res.data.matched.rows.filter((v) => {
          const profile = matchedProfiles.find((k) => k._id === v._id);
          if (strictValidObjectWithKeys(profile)) return v;
        }).length;
      const isLikedExists =
        validObjectWithParameterKeys(res.data, ['liked']) &&
        !!res.data.liked.rows.filter((v) => {
          const profile = likedProfiles.find((k) => k._id === v._id);
          if (strictValidObjectWithKeys(profile)) return v;
        }).length;

      const isSavedExists =
        validObjectWithParameterKeys(res.data, ['saved']) &&
        !!res.data.saved.rows.filter((v) => {
          const profile = savedProfiles.find((k) => k._id === v._id);
          if (strictValidObjectWithKeys(profile)) return v;
        }).length;

      const isPassedExists =
        validObjectWithParameterKeys(res.data, ['passed']) &&
        !!res.data.passed.rows.filter((v) => {
          const profile = passedProfiles.find((k) => k._id === v._id);
          if (strictValidObjectWithKeys(profile)) return v;
        }).length;

      const isSelectedExists =
        validObjectWithParameterKeys(res.data, ['selected']) &&
        !!res.data.selected.rows.filter((v) => {
          const profile = selectedProfiles.find((k) => k._id === v._id);
          if (strictValidObjectWithKeys(profile)) return v;
        }).length;

      if (
        (isMatchedExists || isLikedExists || isSavedExists || isPassedExists || isSelectedExists) &&
        limit < 20
      ) {
        dispatch(loadFilteredProfiles({ ...filters, offset: offset + 1 }, false, resetField));
        return;
      }

      // After getting result
      dispatch({
        type: jobConstants.GET_JOB_CANDIDATES_BY_ID,
        candidates: {
          recommendedProfiles:
            validObjectWithParameterKeys(res.data, ['recommended']) &&
            validObjectWithParameterKeys(res.data.recommended, ['rows'])
              ? recommendedProfiles.concat(res.data.recommended.rows)
              : recommendedProfiles,
          matchedProfiles:
            validObjectWithParameterKeys(res.data, ['matched']) &&
            validObjectWithParameterKeys(res.data.matched, ['rows'])
              ? matchedProfiles.concat(res.data.matched.rows)
              : matchedProfiles,
          likedProfiles:
            validObjectWithParameterKeys(res.data, ['liked']) &&
            validObjectWithParameterKeys(res.data.liked, ['rows'])
              ? likedProfiles.concat(res.data.liked.rows)
              : likedProfiles,
          savedProfiles:
            validObjectWithParameterKeys(res.data, ['saved']) &&
            validObjectWithParameterKeys(res.data.saved, ['rows'])
              ? savedProfiles.concat(res.data.saved.rows)
              : savedProfiles,
          selectedProfiles:
            validObjectWithParameterKeys(res.data, ['selected']) &&
            validObjectWithParameterKeys(res.data.selected, ['rows'])
              ? selectedProfiles.concat(res.data.selected.rows)
              : selectedProfiles,
          passedProfiles:
            validObjectWithParameterKeys(res.data, ['passed']) &&
            validObjectWithParameterKeys(res.data.passed, ['rows'])
              ? passedProfiles.concat(res.data.passed.rows)
              : passedProfiles,
          candidateProfileCount: {
            recommendedProfiles: validObjectWithParameterKeys(res.data, ['recommended'])
              ? res.data.recommended.count
              : candidateProfileCount.recommendedProfiles || 0,
            matchedProfiles: validObjectWithParameterKeys(res.data, ['matched'])
              ? res.data.matched.count
              : candidateProfileCount.matchedProfiles || 0,
            likedProfiles: validObjectWithParameterKeys(res.data, ['liked'])
              ? res.data.liked.count
              : candidateProfileCount.likedProfiles || 0,
            savedProfiles: validObjectWithParameterKeys(res.data, ['saved'])
              ? res.data.saved.count
              : candidateProfileCount.savedProfiles || 0,
            selectedProfiles: validObjectWithParameterKeys(res.data, ['selected'])
              ? res.data.selected.count
              : candidateProfileCount.selectedProfiles || 0,
            passedProfiles: validObjectWithParameterKeys(res.data, ['passed'])
              ? res.data.passed.count
              : candidateProfileCount.passedProfiles || 0,
          },
          filters,
        },
      });
      return true;
    } catch (error) {
      // If an error occurs, set error field
      await dispatch({
        type: jobConstants.LOAD_FAIL,
      });
      dispatch({
        type: errorConstants.SET_ERROR,
        error: extractErrorMessage(error),
      });
      return false;
    }
  };
/**
 * To send feedback
 * Access role allowed - Client/Candidate
 * @param data: object
 */
export const sendFeedback = (data) => async (dispatch, getState, api) => {
  try {
    await api.post('/feedbacks', { data });
    return true;
  } catch (error) {
    // If an error occurs, set error field
    await dispatch({
      type: jobConstants.LOAD_FAIL,
      // error,
    });
    dispatch({ type: errorConstants.SET_ERROR, error: extractErrorMessage(error) });
  }
};

/**
 * To resend invitation to the candidate by id
 * Access role allowed - client
 * @param data: object
 */
export const reSendInvitation = (data, successCallback) => async (dispatch, getState, api) => {
  try {
    const res = await api.post('/jobs/invite', { data });
    if (successCallback && typeof successCallback === 'function') {
      await successCallback(res);
    }
    return true;
  } catch (error) {
    // If an error occurs, set error field
    await dispatch({
      type: jobConstants.LOAD_FAIL,
      // error,
    });
    dispatch({ type: errorConstants.SET_ERROR, error: extractErrorMessage(error) });
  }
};

/**
 * To reject the candidate by id
 * Access role allowed - client
 * @param data: object
 */
export const rejectCandidate = (data) => async (dispatch, getState, api) => {
  try {
    await api.post('/jobs/candidate-passed', { data });
    return true;
  } catch (error) {
    // If an error occurs, set error field
    await dispatch({
      type: jobConstants.LOAD_FAIL,
      // error,
    });
    dispatch({ type: errorConstants.SET_ERROR, error: extractErrorMessage(error) });
    return false;
  }
};
/**
 * To pass the candidate by id
 * Access role allowed - client
 * @param data: object
 */
export const passedCandidate = (data) => async (dispatch, getState, api) => {
  try {
    await api.post('/jobs/candidate-may-be-later', { data });
    return true;
  } catch (error) {
    // If an error occurs, set error field
    await dispatch({
      type: jobConstants.LOAD_FAIL,
      // error,
    });
    dispatch({ type: errorConstants.SET_ERROR, error: extractErrorMessage(error) });
    return false;
  }
};
/**
 * To select the candidate by id
 * @param data: object
 */
export const selectCandidate = (data) => async (dispatch, getState, api) => {
  try {
    await api.post('/jobs/candidate-selected', { data });
    return true;
  } catch (error) {
    // If an error occurs, set error field
    await dispatch({
      type: jobConstants.LOAD_FAIL,
      // error,
    });
    dispatch({ type: errorConstants.SET_ERROR, error: extractErrorMessage(error) });
    return false;
  }
};
export const updateJobAppointment = (data) => async (dispatch, getState, api) => {
  dispatch({ type: jobConstants.LOAD });
  try {
    const res = await api.post('jobs/scheduled-interview-appointment-update', { data });
    dispatch({ type: jobConstants.LOAD_SUCCESS });
    return res;
  } catch (err) {
    const { message } = err;
    // If an error occurs, set error field
    await dispatch({
      type: jobConstants.LOAD_FAIL,
      // error: message || messages.DEFAULT_ERROR_MESSAGE,
    });
    dispatch({
      type: errorConstants.SET_ERROR,
      error: extractErrorMessage(message || messages.DEFAULT_ERROR_MESSAGE),
    });
    return false;
  }
};
/**
 * To set candidate
 * Access role allowed - client
 * @param candidate: object
 */
export const setCandidate = (candidate) => async (dispatch, getState, api) => {
  dispatch({
    type: jobConstants.GET_CANDIDATE_BY_ID,
    candidate,
  });
};
/**
 * To pass the candidate by id
 * Access role allowed - client
 * @param id: object
 */
export const getCandidate = (id, job) => async (dispatch, getState, api) => {
  try {
    const res = await api.get(`/candidates/${id}`);
    await dispatch(setCandidate(res.data));
    return true;
  } catch (error) {
    // If an error occurs, set error field
    await dispatch({
      type: jobConstants.LOAD_FAIL,
      // error,
    });
    dispatch({ type: errorConstants.SET_ERROR, error: extractErrorMessage(error) });
    return false;
  }
};
/**
 * To schedule selected profiles
 * Access role allowed - client/candidate
 * @param data: object
 * @param userRole: string
 */
export const scheduleSelectedProfiles = (data, userRole) => async (dispatch, getState, api) => {
  dispatch({ type: jobConstants.LOAD });
  try {
    // Put saved job with api
    // const user = typeCastToKeyValueObject(getState().get('auth').get('user'));
    const postUrl = '/jobs/candidates-schedule';
    const res = await api.post(postUrl, { data });
    if (!validObjectWithParameterKeys(res, ['message'])) {
      return false;
    }
    dispatch({
      type: jobConstants.SELECTED_INTERVIEW_CANDIDATES,
      selectedCandidates: data.candidates,
    });
    dispatch({ type: jobConstants.LOAD_SUCCESS });
    return true;
  } catch (error) {
    // If an error occurs, set error field
    await dispatch({
      type: jobConstants.LOAD_FAIL,
      // error,
    });
    dispatch({ type: errorConstants.SET_ERROR, error: extractErrorMessage(error) });
    return false;
  }
};

/**
 * Get Questions for Jobs
 * @param {*} type
 */
export const getQuestions = () => async (dispatch, getState, api) => {
  dispatch({ type: jobConstants.LOAD });
  try {
    const res = await api.get('/culturevalues/questions');
    await dispatch({
      type: jobConstants.GET_QUESTIONS,
      data: res.data,
    });
    // eslint-disable-next-line no-use-before-define
    dispatch(getQuestionsAnswers());
    dispatch({
      type: jobConstants.LOAD_SUCCESS,
      message: res.data.message,
    });
  } catch (error) {
    dispatch({
      type: jobConstants.LOAD_FAIL,
      // error,
    });
    dispatch({ type: errorConstants.SET_ERROR, error: extractErrorMessage(error) });
  }
};

/**
 * Get Job Questions Answers
 * @param {*} type
 */
export const getQuestionsAnswers = () => async (dispatch, getState, api) => {
  dispatch({ type: jobConstants.LOAD });
  try {
    const res = await api.get('/culturevalues');
    const questions = getState().get('jobs').get('questions');
    // eslint-disable-next-line no-console
    dispatch({
      type: jobConstants.GET_QUESTIONS,
      data: questions.map((v) => {
        return {
          ...v,
          answers: validObjectWithParameterKeys(res.data, [v.culture_type])
            ? res.data[v.culture_type]
            : [],
        };
      }),
    });
    dispatch({
      type: jobConstants.LOAD_SUCCESS,
      message: res.data.message,
    });
  } catch (error) {
    dispatch({
      type: jobConstants.LOAD_FAIL,
      // error,
    });
    dispatch({ type: errorConstants.SET_ERROR, error: extractErrorMessage(error) });
  }
};

export const setJobQuestionAnswers =
  (state, id, question, isRadio, canSave = false) =>
  async (dispatch, getState) => {
    const questions = getState().get('jobs').get('questions');

    const selectedValues = typeCastToKeyValueObject(getState().get('jobs').get('selectedValues'));
    // eslint-disable-next-line no-restricted-syntax
    for (const key of Object.keys(questions)) {
      if (
        validObjectWithParameterKeys(questions[key], ['answers', '_id', 'culture_type']) &&
        questions[key]._id === question
      ) {
        if (!strictValidArray(selectedValues[questions[key].culture_type])) {
          selectedValues[questions[key].culture_type] = [];
          selectedValues[questions[key].culture_type].push(id);
        } else if (selectedValues[questions[key].culture_type].indexOf(id) > -1 || !state) {
          selectedValues[questions[key].culture_type] = selectedValues[
            questions[key].culture_type
          ].filter((v) => v !== id);
        } else {
          if (isRadio) {
            const filteredValues = selectedValues[questions[key].culture_type].filter(
              (v) => v !== isRadio,
            );
            selectedValues[questions[key].culture_type] = filteredValues;
          }
          selectedValues[questions[key].culture_type].push(id);
        }
      }
    }

    dispatch({
      type: jobConstants.SAVE_SELECTED_VALUES,
      selectedValues: {
        ...selectedValues,
      },
      // canSave,
    });
  };

/**
 * Set filters for company when categoriesIds etc are passed
 * Access role allowed - company
 */
export const getCompanyFilters = (filters) => async (dispatch, getState) => {
  const jobFunctions = getState().get('settings').get('jobFunctions') || [];
  // Calculate category & associated experience
  const category =
    jobFunctions.find(
      (v) => v._id === getValidElementValueInChildArray(filters, 'categoriesIds', 0),
    ) || {};
  let data = {};
  // If valid category is selected
  if (validCategory(category)) {

    const filterJobContractTypeMin =
      filters.jobContractType !== JOB_TYPE_OPTIONS.CONTRACT
        ? filters.jobCompensation.min * 1000
        : filters.jobCompensation.min;
    const filterJobContractTypeMax =
      filters.jobContractType !== JOB_TYPE_OPTIONS.CONTRACT
        ? filters.jobCompensation.max * 1000
        : filters.jobCompensation.max;
    // Initialize data
    data = {
      job_function: getValidElementValueInChildArray(filters, 'categoriesIds', 0),
      titles: getValidElementValueInChildArray(filters, 'rolesIds', 0),
      duties: getValidElementValueInChildArray(filters, 'dutiesIds'),
      job_type_1: validObjectWithParameterKeys(filters, ['jobDuration']) && filters.jobDuration,
      job_type_2:
        validObjectWithParameterKeys(filters, ['jobContractType']) && filters.jobContractType,
      exp_min: validObjectWithParameterKeys(filters, ['exp_min']) && filters.exp_min,
      exp_max: validObjectWithParameterKeys(filters, ['exp_max']) && filters.exp_max,
      no_of_hires: validObjectWithParameterKeys(filters, ['no_of_hires']) && filters.no_of_hires,
      is_remote: validObjectWithParameterKeys(filters, ['isRemote']) && filters.isRemote,
      is_relocation:
        validObjectWithParameterKeys(filters, ['isRelocation']) && filters.isRelocation,
      work_authorization:
        validObjectWithParameterKeys(filters, ['workAuthorization']) && filters.workAuthorization,
      location: validObjectWithParameterKeys(filters, ['location']) && filters.location,
      location_coordinates: validObjectWithParameterKeys(filters, ['location_coordinates'])
        ? validObjectWithParameterKeys(filters.location_coordinates, ['lat', 'lng']) && [
            filters.location_coordinates.lng,
            filters.location_coordinates.lat,
          ]
        : null,
      city: validObjectWithParameterKeys(filters, ['city']) && filters.city,
      state: validObjectWithParameterKeys(filters, ['state']) && filters.state,
      zipcode: validObjectWithParameterKeys(filters, ['zipcode']) && filters.zipcode,
      county: validObjectWithParameterKeys(filters, ['county']) && filters.county,
      timezone: validObjectWithParameterKeys(filters, ['timezone']) && filters.timezone,
      country: validObjectWithParameterKeys(filters, ['country']) && filters.country,
      proximity:
        (validObjectWithParameterKeys(filters, ['jobProximity']) && filters.jobProximity) || null,
      compensation_min:
        validObjectWithParameterKeys(filters, ['jobCompensation', 'jobContractType']) &&
        validObjectWithParameterKeys(filters.jobCompensation, ['min', 'max']) &&
        strictValidString(filters.jobContractType)
          ? filterJobContractTypeMin
          : null,
      compensation_max:
        validObjectWithParameterKeys(filters, ['jobCompensation', 'jobContractType']) &&
        validObjectWithParameterKeys(filters.jobCompensation, ['min', 'max']) &&
        strictValidString(filters.jobContractType)
          ? filterJobContractTypeMax
          : null,
    };
  }
  // Return filters
  return removeInValidKeyValuePairs(data);
};

/**
 * To flush reducer state
 * Access role allowed - admin
 */
export const flush = () => async (dispatch) => {
  await dispatch({ type: jobConstants.FLUSH });
};

/**
 * To reset message fields in reducer
 */
internals.resetMessage = (defaultTimeout = DEFAULT_MILLISECONDS_TO_SHOW_MESSAGES) => {
  return (dispatch) =>
    setTimeout(() => {
      dispatch({ type: jobConstants.RESET_MESSAGE });
    }, defaultTimeout || DEFAULT_MILLISECONDS_TO_SHOW_MESSAGES);
};
/**
 * Get all interview templates for company
 * @param filters: object
 * @param userRole: string
 */
export const getInterviewTemplatesListing =
  (clientId = null, page = 1, limit = 10, sort = 'id', order = 'desc', tableFilters = {}) =>
  async (dispatch, getState, api) => {
    const searchFilters = {
      ...tableFilters,
    };
    dispatch({ type: jobConstants.LOAD });
    try {
      const postUrl = `interviewscheduletemplate/list?page=${page}&sort=${sort}&order=${order}&limit=${limit}&company_id=${clientId}`;
      const res = await api.post(postUrl, searchFilters);
      await dispatch({
        type: jobConstants.LOAD_ALL_TEMPLATES,
        data: {
          rows: res.data.rows,
          count: res.data.count,
        },
      });
      await dispatch({ type: jobConstants.LOAD_SUCCESS });
    } catch (error) {
      // If an error occurs, set error field
      await dispatch({
        type: jobConstants.LOAD_FAIL,
        // error,
      });
      dispatch({ type: errorConstants.SET_ERROR, error: extractErrorMessage(error) });
    }
  };

export const getCandidateSlots = (candidateId, duration) => async (dispatch, getState, api) => {
  try {
    const postUrl = `candidates/candidate-availability/${candidateId}`;
    const res = await api.post(postUrl, { data: { duration } });
    await dispatch({
      type: jobConstants.CANDIDATE_SLOTS,
      data: res.data,
    });
    await dispatch({ type: jobConstants.LOAD_SUCCESS });
    return res.data;
  } catch (error) {
    // If an error occurs, set error field
    await dispatch({
      type: jobConstants.LOAD_FAIL,
      // error,
    });
    dispatch({ type: errorConstants.SET_ERROR, error: extractErrorMessage(error) });
  }
};

/**
 * Fetch Job Fucntion ralt4ed additional  questions to display on Job Post
 * Access role allowed - company
 */
export const getJobFunctionQuestions = (data) => async (dispatch, getState, api) => {
  dispatch({ type: jobConstants.LOAD });
  try {
    const res = await api.get(`/jobfunctionquestions?job_function=${data}`);
    dispatch({ type: jobConstants.LOAD_SUCCESS });
    if (res) {
      await dispatch({
        type: jobConstants.GET_JOB_FUNCTION_QUESTIONS,
        data: res.data,
      });
    }
    return true;
  } catch (error) {
    await dispatch({
      type: jobConstants.LOAD_FAIL,
    });
    dispatch({ type: errorConstants.SET_ERROR, error: extractErrorMessage(error) });
    return false;
  }
};

export const getVerticalizationData = (data) => async (dispatch, getState, api) => {
  dispatch({ type: jobConstants.LOAD });
  try {
    const res = await api.get(`/jobs/${data}/job-function-questions`);
    dispatch({ type: jobConstants.LOAD_SUCCESS });
    if (res && res.data) {
      await dispatch({
        type: jobConstants.GET_JOB_VERTICALIZATION_DATA,
        data: res.data.job_function_questions,
      });
    }
    return true;
  } catch (error) {
    await dispatch({
      type: jobConstants.LOAD_FAIL,
    });
    dispatch({ type: errorConstants.SET_ERROR, error: extractErrorMessage(error) });
    return false;
  }
};

export const setJobFuntionAnswers = (data, action) => async (dispatch, getState, api) => {
  try {
    if (strictValidObjectWithKeys(data)) {
      dispatch({
        type: jobConstants.SET_JOB_FUNTION_ANSWERS,
        data,
      });
    }
    // Finally return data
    return data;
  } catch (error) {
    // If an error occurs, set error field
    dispatch({
      type: jobConstants.LOAD_FAIL,
      // error,
    });
    dispatch({ type: errorConstants.SET_ERROR, error: extractErrorMessage(error) });
  }
};

export const getJobDetailPdf = (id) => async (dispatch, getState, api) => {
  dispatch({ type: jobConstants.LOAD });
  try {
    const res = await api.get(`/jobs/download-pdf/${id}`);
    await dispatch({ type: jobConstants.LOAD_SUCCESS });
    return res;
  } catch (error) {
    dispatch({
      type: jobConstants.LOAD_FAIL,
      // error,
    });
    dispatch({ type: errorConstants.SET_ERROR, error: extractErrorMessage(error) });
    return false;
  }
};

export const getKeySkillLookUp =
  ({ keyword, job = '' }) =>
  async (dispatch) => {
    if (!strictValidString(keyword)) return [];
    const inputValue = keyword.replace(TRIM_WHITE_SPACE_AT_END, '');
    dispatch({ type: jobConstants.LOAD_KEY_SKILL });
    try {
      // Put saved job with api
      let url = `${process.env.REACT_APP_APIHOST}/settings/get-skill?keyword=${inputValue}`;
      if (strictValidString(job)) url += `&job=${job}`;
      const res = await axios.get(url, {
        headers: {
          'x-access-token': store.get('user').token,
        },
      });
      dispatch({ type: jobConstants.LOAD_SUCCESS });
      if (!validObjectWithParameterKeys(res, ['data'])) {
        return false;
      }
      return res.data.data
        .filter(validKeyWord)
        .map(({ _id, title }) => ({ value: _id, label: title }));
    } catch (error) {
      // If an error occurs, set error field
      consoleOnlyInDebugMode('err-getCompanies', error.response);

      dispatch({
        type: jobConstants.LOAD_FAIL,
        // error: extractErrorMessage(error),
      });
      dispatch({ type: errorConstants.SET_ERROR, error: extractErrorMessage(error) });
      return [];
    }
  };

export const getDutiesAndRequirements =
  (duties, requirements) => async (dispatch, getState, api) => {
    const url = '/jobs/get-duties-requirements';
    try {
      const res = await api.post(url, { data: { duties, requirements } });
      return res.data;
    } catch (error) {
      const { message } = error;
      // If an error occurs, set error field
      dispatch({
        type: errorConstants.SET_ERROR,
        error: message || messages.DEFAULT_ERROR_MESSAGE,
      });
      return { duties: [], requirements: [] };
    }
  };

export const updateInflightCandidates =
  (candidate, bucketType) => async (dispatch, getState, api) => {
    let notfound = 0;
    const { rows = [], ...rest } = typeCastToKeyValueObject(
      getState().get('jobs').get('candidates'),
    );
    for (const row of rows) {
      row.candidates = row.candidates.filter((v) => v._id !== candidate._id);
      if (row.type === bucketType) {
        row.candidates = [candidate, ...row.candidates];
      } else {
        notfound++;
      }
    }

    if (notfound === rows.length) {
      rows.push({
        type: bucketType,
        candidates: [candidate],
      });
    }
    dispatch({
      type: jobConstants.GET_JOB_CANDIDATES_BY_ID,
      candidates: { ...rest, rows },
    });
  };

/**
 * To update post to external site flag in job
 * Access role allowed - all
 * @param data: object
 */
export const postToPortal = (data) => async (dispatch, getState, api) => {
  dispatch({ type: jobConstants.LOAD });

  try {
    const url = '/jobs/post-to-portal';
    const res = await api.post(url, { data });
    // dispatch({
    //   type: jobConstants.LOAD_PENDING_JOB,
    //   job: res.data,
    // });
    dispatch({ type: jobConstants.LOAD_SUCCESS });

    return res.data;
  } catch (error) {
    // If an error occurs, set error field
    await dispatch({
      type: jobConstants.LOAD_FAIL,
    });
    dispatch({ type: errorConstants.SET_ERROR, error: extractErrorMessage(error) });

    return false;
  }
};
