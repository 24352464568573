import createDecorator from 'final-form-focus';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw } from 'draft-js';
import Loading from '../../../../components/--primitives/pr-loading';
import { addNote } from './api';
import validate from './validate';
import { HtmlEditor, getTextFromHTML } from '../../../../components/final-form/ff-HTML-editor';
import PrButton from '../../../../components/--primitives/pr-button';
import { convertEditNoteContent } from '../helpers';
import { getUsers } from '../../../Dashboard/Modules/AjnaUsers/api';
import '../style.scss';

const initialState = {
  html_body: '',
  body: EditorState.createEmpty(),
};

const focusOnErrors = createDecorator();

function AddNote({
  dispatch,
  loading,
  callAddNoteAPI,
  switchAddAndList,
  jobId,
  companyId,
  interviewId,
  candidateId,
  timesheetId,
  placementId,
  leadId,
  leadName,
  companyName,
  jobTitle,
  candidateName,
}) {
  const [useList, setUseList] = useState([]);

  useEffect(async () => {
    const val = [1, 1000, 'name', 'asc', { status: 1 }];
    const data = await dispatch(getUsers(...val));
    if (data.rows.length > 0) {
      const list = data.rows.map((v) => ({
        text: v.name,
        value: v.name,
        url: v._id, // `${window.origin}/ajna-user/${v._id}`,
      }));
      setUseList(list);
    }
  }, []);

  const onSubmit = (values) => {
    const valuesToSend = {
      ...values,
      body: getTextFromHTML(values.body),
      ...convertEditNoteContent(draftToHtml(convertToRaw(values.body.getCurrentContent()))),
      companyName,
      jobTitle,
      candidateName,
    };
    valuesToSend.job = jobId;
    valuesToSend.company = companyId;
    valuesToSend.interview = interviewId;
    valuesToSend.candidate = candidateId;
    valuesToSend.timesheet = timesheetId;
    valuesToSend.placement = placementId;
    valuesToSend.lead = leadId;
    valuesToSend.leadName = leadName;
    callAddNoteAPI(valuesToSend, switchAddAndList);
  };

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <>
      {loading && <Loading type="overlay" />}
      <Form
        initialValues={initialState}
        decorators={[focusOnErrors]}
        validate={validate}
        onSubmit={onSubmit}
        render={(res) => (
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="card">
              <h3 className="card-title">Add a Note</h3>
              <p>&nbsp;</p>
              <div className="MentionUI">
                <Field
                  name="body"
                  component={HtmlEditor}
                  mention={{
                    separator: ' ',
                    trigger: '@',
                    suggestions: useList,
                  }}
                />
              </div>
              <p>&nbsp;</p>
              <div className="submit-button-group">
                <PrButton value="Cancel" type="light-blue" onClick={switchAddAndList} />
                <PrButton value="Save" type="light-blue" onClick={res.handleSubmit} />
              </div>
            </div>
          </form>
        )}
      />
    </>
  );
}

AddNote.propTypes = {
  dispatch: PropTypes.func.isRequired,
  switchAddAndList: PropTypes.func,
  callAddNoteAPI: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  jobId: PropTypes.string,
  companyId: PropTypes.string,
  interviewId: PropTypes.string,
  candidateId: PropTypes.string,
  timesheetId: PropTypes.string,
  placementId: PropTypes.string,
  leadId: PropTypes.string,
  leadName: PropTypes.string,
  companyName: PropTypes.string,
  jobTitle: PropTypes.string,
  candidateName: PropTypes.string,
};

AddNote.defaultProps = {
  jobId: undefined,
  companyId: undefined,
  interviewId: undefined,
  loading: false,
  candidateId: undefined,
  timesheetId: undefined,
  companyName: undefined,
  jobTitle: undefined,
  candidateName: undefined,
  switchAddAndList: () => {
    /* empty fun */
  },
  placementId: undefined,
  leadId: undefined,
  leadName: undefined,
};

const mapStateProps = (state) => {
  const { loading } = state.get('note').get('addNote');
  return {
    loading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  callAddNoteAPI: (...params) => dispatch(addNote(...params)),
});

export default withRouter(connect(mapStateProps, mapDispatchToProps)(AddNote));
