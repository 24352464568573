import React from 'react';
import moment from 'moment';
import { getTimezone, formatTimezoneStr } from './siteSpecificCommonUtils';
import {
  DATE_FORMAT_MDY,
  DATE_FORMAT_HOUR,
  DATE_FORMAT_MDY_WITH_TIME,
  DATE_FORMAT_HOUR_WITHOUT_AM_PM,
} from './siteSpecificConstants';

const appendCommasWithValues = (strings = []) => {
  if (strings.length === 0) {
    return '-';
  }
  return strings.reduce((val, newVal) => {
    if (newVal) {
      return `${val}, ${newVal}`;
    }
    return val;
  });
};

// NOTE: This method is specifically written to show date time fields in listing.
// This will print data and time in separate lines. It Also support printing - if
// date time is not available
const showDateTimeInListing = (value) => {
  const { original = {} } = value;
  const { date_formated, start, end, timezone: originalTimezone } = original;
  const timezone = getTimezone(originalTimezone);
  return date_formated && start && end ? (
    <div>
      <div className="mb-0">{moment.unix(start).tz(timezone).format(DATE_FORMAT_MDY)}</div>
      <div>
        {`${moment.unix(start).tz(timezone).format(DATE_FORMAT_HOUR_WITHOUT_AM_PM)}
        - ${moment.unix(end).tz(timezone).format(DATE_FORMAT_HOUR)}`}
        {` (${formatTimezoneStr(timezone)})`}
      </div>
    </div>
  ) : (
    '-'
  );
};

const getFormattedDate = (date) =>
  date
    ? moment(new Date(date)).format(DATE_FORMAT_MDY)
    : moment(new Date()).format(DATE_FORMAT_MDY);

const getFormattedDateTime = (dateTime) =>
  moment(new Date(dateTime)).format(DATE_FORMAT_MDY_WITH_TIME);

const showDateTimeInListingWithoutTimezone = (dateTime) =>
  dateTime ? (
    <div className="time">
      <div className="m-0">{moment(new Date(dateTime)).format(DATE_FORMAT_MDY)}</div>
      <div className="ml-0">{moment(new Date(dateTime)).format(DATE_FORMAT_HOUR)}</div>
    </div>
  ) : (
    <span className="text-center">-</span>
  );
const formatDateToUTC = (date, dateFormat = 'MM/DD/YYYY') => moment.utc(date).format(dateFormat);

const isValidDateString = (date) => moment(date, moment.ISO_8601, true).isValid();

export {
  appendCommasWithValues,
  showDateTimeInListing,
  showDateTimeInListingWithoutTimezone,
  getFormattedDate,
  getFormattedDateTime,
  formatDateToUTC,
  isValidDateString,
};
