import Immutable from 'immutable';
import { typeCastResponse } from '../../../../utils/siteSpecificCommonUtils';
import { transactionConstants } from './constants';
import { LOCATION_CHANGE } from 'react-router-redux';

const initialState = Immutable.fromJS({
  transactionDetails: {},
  transactionsList: {},
  isLoad: false,
	message: null,
  loadErr: null,
  paycodes: []
});
const transaction = (state = initialState, action) => {
  switch (action.type) {
    // Loading
    case transactionConstants.LOAD:
      return state.set('isLoad', true).set('loadErr', null);
    case transactionConstants.LOAD_SUCCESS:
      return state
        .set('isLoad', false)
        .set('loadErr', null)
        .set('message', typeCastResponse(action, 'message'));
    case transactionConstants.LOAD_FAIL:
      return state
        .set('isLoad', false)
        .set('loadErr', typeCastResponse(action, 'error'));
    case transactionConstants.DETAIL_SUCCESS:
      return state.set(
        'transactionDetails',
        typeCastResponse(action, 'transactionDetails', 'object', {}),
      );
    case transactionConstants.PAY_CODES_SUCCESS:
      return state.set(
        'paycodes',
        typeCastResponse(action, 'paycodes', 'array', []),
      );
    case LOCATION_CHANGE:
      return state.set('loadErr', null);
    default:
      return state;
  }
};

export {
  transaction,
};
