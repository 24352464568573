import React from 'react';
import { Link } from 'react-router-dom';
import { push } from 'connected-react-router';
import { DEFAULT_VALID_BEGIN_FILE_NAME_REG_EXP, DEFAULT_OPTION } from './constants';
import {
  VALID_URL,
  VALID_US_ZIP,
  VALID_LARGE_NUMBER,
  FIRST_CHARACTER_IN_WORDS,
  NUMBER_UPTO_ONE_DECIMAL,
} from './regexs';
import { messages } from '../language';

/**
 * Checks if a valid string;
 * @param val: number/string/object/array != (undefined or null)
 */
export const validValue = (val) =>
  typeof val !== 'undefined' && val !== undefined && val !== null && val !== 'undefined';

/**
 * Get window width and height
 */
export const getWindowDimensions = () => {
  const w = window;
  const d = document;
  const e = d.documentElement;
  const g = d.getElementsByTagName('body')[0];
  const x = w.innerWidth || e.clientWidth || g.clientWidth;
  const y = w.innerHeight || e.clientHeight || g.clientHeight;
  return {
    width: x,
    height: y,
  };
};

/**
 * get element offset (width & height)
 * @param el: object
 */
export const getElementOffset = (el) => {
  let _x = 0;
  let _y = 0;
  while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
    _x += el.offsetLeft - el.scrollLeft;
    _y += el.offsetTop - el.scrollTop;
    el = el.offsetParent;
  }
  return { top: _y, left: _x };
};

/**
 * Serailize json to query string
 * @param {*} object
 */
export const jsonToQueryString = (obj) =>
  strictValidObject(obj) &&
  Object.keys(obj)
    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`)
    .join('&');

/**
 * Checks if a valid string
 * @param str: string
 */
export const strictValidString = (str) => !!str && typeof str === 'string';

/**
 * print address
 * @param {*} str
 * @param {*} length
 */
export const fullAddress = (address = {}) => {
  let { location = '', address_2 = '', city = '', state = '', zipcode = '' } = address;
  location = (strictValidStringWithMinLength(location, 1) && `${location}` + ', ') || '';
  address_2 = (strictValidStringWithMinLength(address_2, 1) && `${address_2}` + ', ') || '';
  city = (strictValidStringWithMinLength(city, 1) && `${city}` + ', ') || '';
  state = (strictValidStringWithMinLength(state, 1) && `${state}`) || '';
  zipcode = (strictValidStringWithMinLength(zipcode, 1) && ', ' + `${zipcode}`) || '';
  return `${location}${address_2}${city}${state}${zipcode}`;
};

/**
 * Checks if a valid string and validate with min length.
 * @param str: string
 */
export const strictValidStringWithMinLength = (str, length = 1) =>
  !!str && typeof str === 'string' && str.length >= length;

/**
 * Checks if a valid string which when split with a delimeter will give an array with specified minimum length
 * @param str: string
 * @param delimeter: string
 * @param minLength: integer
 */
export const strictValidSplittableStringWithMinLength = (str, delimeter, minLength) =>
  strictValidString(str) && strictValidArrayWithMinLength(str.split(delimeter), minLength);

/**
 * Typecast or converts forcefully a string, an object or an array to string else returns null
 * @param str: string
 */
export const typeCastToString = (str) =>
  (!!str && ((strictValidString(str) && str) || str.toString() || JSON.stringify(str))) || '';

/**
 * Capitalizes the first letter of every word in string
 * @param str: string
 */
export const capitalizeFirstLetter = (str) =>
  (strictValidString(str) && str.replace(FIRST_CHARACTER_IN_WORDS, (l) => l.toUpperCase())) || null;

/**
 * Capitalizes the first letter of every word in string but not word after apostrophe
 * @param str: string
 */
export const titleCase = (str = '') => {
  if (!strictValidString(str)) return null;
  const strArr = str.toLowerCase().split(' ');
  for (let i = 0; i < strArr.length; i++) {
    strArr[i] = strArr[i].charAt(0).toUpperCase() + strArr[i].slice(1);
  }
  return strArr.join(' ');
};

/**
 * Get name & extension from fileName
 * @param fileName: string
 */
export const getFileNameAndExtension = (fileName) =>
  (strictValidString(fileName) &&
    strictValidSplittableStringWithMinLength(fileName, '.', 2) && {
      name: fileName.split('.')[0],
      ext: fileName.split('.')[1],
    }) ||
  {};

export const validAlert = (alert) =>
  validObjectWithParameterKeys(alert, ['message', 'type']) &&
  validValue(alert.message) &&
  validValue(alert.type);

/**
 * Checks if given value is strictly a number
 * @param num: number
 */
export const strictValidNumber = (num) => validValue(num) && typeof num === 'number' && !isNaN(num);

/**
 * Checks if a valid array
 * @param arr: array
 */
export const strictValidArray = (arr) => arr && Array.isArray(arr);

/**
 * Checks if a valid array with minimum specified length
 * @param arr: array
 * @param minLength: integer
 */
export const strictValidArrayWithMinLength = (arr, minLength = 1) =>
  strictValidArray(arr) && arr.length >= minLength;

/**
 * Checks if a valid array with length
 * @param arr: array
 */
export const strictValidArrayWithLength = (arr) => strictValidArray(arr) && !!arr.length;

/**
 * Checks if a valid object
 * @param obj: object
 */
export const strictValidObject = (obj) =>
  obj && obj === Object(obj) && Object.prototype.toString.call(obj) !== '[object Array]';

/**
 * Checks if a valid object with keys
 * @param obj: object
 */
export const strictValidObjectWithKeys = (obj) =>
  strictValidObject(obj) && !!Object.keys(obj).length;

/**
 * Checks if a valid object with specified keys
 * @param obj: object
 * @param parameterKeys: array
 */
export const validObjectWithParameterKeys = (obj, parameterKeys = []) =>
  strictValidObjectWithKeys(obj) &&
  strictValidArrayWithLength(parameterKeys) &&
  Object.keys(obj).filter((k) => parameterKeys.indexOf(k) > -1).length === parameterKeys.length;

/**
 * Generates a regular expression from a given list of regular expressions
 * @param regExpList: array of regular expression strings
 */
export const concatenateRegularExpressions = (regExpList = []) => {
  let regExp = new RegExp();
  if (strictValidArrayWithLength(regExpList)) {
    try {
      regExp = new RegExp(regExpList.join(''));
    } catch (error) {
      // Do nothing
    }
  }
  return regExp;
};

/**
 * Checks if a valid string fileName with extension in specified list of extensions and starts with a
 * specifield or default regular expression
 * @param fileName: string
 * @param validExtensionsList: array of valid regular expression strings
 * @param startingRegExp: string
 */
export const validFileName = (fileName, validExtensionsList = [], startingRegExp = '') =>
  strictValidString(fileName) &&
  strictValidArray(validExtensionsList) &&
  !!validExtensionsList.length &&
  concatenateRegularExpressions([
    (strictValidString(startingRegExp) && startingRegExp) || DEFAULT_VALID_BEGIN_FILE_NAME_REG_EXP,
    validExtensionsList.map((v) => (strictValidString(v) && `.${v.toLowerCase()}`) || '').join('|'),
    '$',
  ]).test(fileName.toLowerCase());

/**
 * Gets file extension
 * @param fileName: string
 */
export const getFileExtension = (fileName) =>
  (strictValidString(fileName) && fileName.substring(fileName.lastIndexOf('.'), fileName.length)) ||
  '';

/**
 * Typecasts a key value pair (k, v) to string and appends it to or appends a specified string value to it
 * based on appendAfter boolean variable
 * @param k: string
 * @param v: string
 * @param appendString: string
 * @param appendAfter: boolean
 */
export const addKeyValuePairAsString = (k, v, appendString = '', appendAfter = true) => {
  let str = '';
  // i f (!appendAfter) {
  //   str += typeCastToString(appendString);
  // }
  if (validValue(v)) {
    if (['string', 'number', 'boolean'].indexOf(typeof v) > -1) {
      str = `${k}: ${typeCastToString(v)}`;
    } else if (strictValidArrayWithLength(v)) {
      str = `${k}: [${v.join(', ')}]`;
    } else {
      str = `${k}: [${JSON.stringify(v)}]`;
    }
  } else {
    str = `${k}: `;
  }
  if (appendAfter) {
    str += typeCastToString(appendString);
  }
  return str;
};

/**
 * Typecasts an array to an pobject with 'text' & 'value' parameters needed for dropdown
 * User can also add a default option if specified
 * @param keyList: array
 * @param valueList: array
 * @param useDefaultOptionFlag: boolean
 * @param defaultOption: string
 * @param otherProps: object
 */
export const getOptionsListFromArray = (
  keyList,
  valueList,
  useDefaultOptionFlag = true,
  defaultOption = DEFAULT_OPTION,
  otherProps = [],
) =>
  (useDefaultOptionFlag &&
    strictValidString(defaultOption) &&
    strictValidArrayWithLength(keyList) &&
    strictValidArrayWithLength(valueList) &&
    keyList.length === valueList.length &&
    [{ text: defaultOption, value: '' }].concat(
      keyList.map((v, k) => {
        if (
          strictValidArrayWithLength(otherProps) &&
          k in otherProps &&
          strictValidObjectWithKeys(otherProps[k])
        ) {
          return { ...otherProps[k] };
        }
        return { key: k + v, text: v, value: valueList[k] };
      }),
    )) ||
  (!useDefaultOptionFlag &&
    strictValidArrayWithLength(keyList) &&
    strictValidArrayWithLength(valueList) &&
    keyList.length === valueList.length &&
    keyList.map((v, k) => {
      if (
        strictValidArrayWithLength(otherProps) &&
        k in otherProps &&
        strictValidObjectWithKeys(otherProps[k])
      ) {
        return { ...otherProps[k] };
      }
      return { key: k + v, text: v, value: valueList[k] };
    })) ||
  (useDefaultOptionFlag &&
    strictValidString(defaultOption) &&
    (!strictValidArrayWithLength(keyList) || !strictValidArrayWithLength(valueList)) && [
      { text: defaultOption, value: '' },
    ]) ||
  [];

/**
 * Generates an integer array from minValue to maxValue
 * @param minValue: integer
 * @param maxValue: integer
 */
export const numericArrayGenerator = (minValue, maxValue) => {
  const numericArray = [];
  for (let i = minValue; i <= maxValue; i++) {
    numericArray.push(i);
  }
  return numericArray;
};

/**
 * Typecasts an immutable reducer object to its actual values
 * @param immutableObject: object
 */
export const typeCastToKeyValueObject = (immutableObject) =>
  (strictValidObject(immutableObject) &&
    immutableObject instanceof Map &&
    immutableObject.toJSON()) ||
  (strictValidObject(immutableObject) &&
    validObjectWithParameterKeys(immutableObject, ['size', '_root']) &&
    immutableObject.toJSON()) ||
  (strictValidObject(immutableObject) && immutableObject) ||
  (!strictValidObject(immutableObject) && validValue(immutableObject) && immutableObject);

/**
 * Imports all files in a directory
 * @param importedObj: object
 */
export const importImagesFromImageDirectory = (importedObj) => {
  const filesObj = {};
  importedObj.keys().forEach((file) => {
    filesObj[file.replace('./', '')] = importedObj(file);
  });
  return filesObj;
};

/**
 * Get the specified element in the specified child array of the parent object
 * or get the value of child if index is invalid index number
 * @param parentObj: object
 * @param childArrayIdentifier: string
 * @param index: number
 */
export const getValidElementValueInChildArray = (parentObj, childArrayIdentifier, index) => {
  let res = null;
  if (
    validObjectWithParameterKeys(parentObj, [childArrayIdentifier]) &&
    strictValidNumber(index) &&
    strictValidArrayWithMinLength(parentObj[childArrayIdentifier], index + 1) &&
    validValue(parentObj[childArrayIdentifier][index])
  ) {
    res = parentObj[childArrayIdentifier][index];
  } else if (
    validObjectWithParameterKeys(parentObj, [childArrayIdentifier]) &&
    !strictValidNumber(index)
  ) {
    res = parentObj[childArrayIdentifier];
  }
  return res;
};

/**
 * Loads a route
 * @param dispatch: function
 * @param route: string
 */
export const loadRoute = (dispatch, route) => {
  if (route) {
    dispatch(push(route));
    return;
  }
  dispatch(push('/'));
};

/**
 * If valid form without errors
 * @param formData: string
 */
export const inValidFormSubmission = (formData) =>
  validObjectWithParameterKeys(formData, ['syncErrors']) &&
  strictValidObjectWithKeys(formData.syncErrors);

/**
 * If valid form with values
 * @param formData: string
 */
export const validFormData = (formData) =>
  !inValidFormSubmission(formData) &&
  validObjectWithParameterKeys(formData, ['values']) &&
  strictValidObjectWithKeys(formData.values);

/**
 * Given an array of items list distribute them in array of rows
 * based on max items that can be fit in a row
 * @param itemsList: array
 * @param maxItemsToFitInRow: number
 */
export const getRowsList = (itemsList, maxItemsToFitInRow) => {
  const rowsList = [];
  if (strictValidArray(itemsList) && strictValidNumber(maxItemsToFitInRow)) {
    for (let i = 0; i < itemsList.length; i++) {
      if (!(i % maxItemsToFitInRow)) {
        rowsList[rowsList.length] = [itemsList[i]];
      } else {
        rowsList[parseInt(i / maxItemsToFitInRow)].push(itemsList[i]);
      }
    }
  }
  return rowsList;
};

/**
 * Remove null or undefined key value pairs from an object
 * @param obj: object
 * @param removeEmptyArray: bool
 */
export const removeInValidKeyValuePairs = (obj, removeEmptyArray) => {
  const res = {};
  if (strictValidObjectWithKeys(obj)) {
    Object.values(obj).forEach((v, k) => {
      if (
        (validValue(v) && !strictValidArray(v) && !strictValidObject(v)) ||
        strictValidArrayWithLength(v) ||
        (removeEmptyArray && (strictValidArrayWithLength(v) || strictValidObjectWithKeys(v)))
      ) {
        res[Object.keys(obj)[k]] = v;
      }
    });
  }
  return res;
};

/**
 * Is valid url
 * @param url: string
 */
export const isURLValid = (url) => strictValidString(url) && VALID_URL.test(url);

/**
 * Is valid zip
 * @param zip: string
 */
export const isZipValid = (zip) => strictValidString(zip) && VALID_US_ZIP.test(zip);

/**
 * Get random color
 * @param opacity: string
 */
// we may need to change the below process of getting random colors so thats why i have commented the code for future use
export const randomBGColor = (opacity) => {
  const colors = [
    'RED',
    'GREEN',
    'BLUE',
    'DARKSLATEGRAY',
    'MAROON',
    'GRAY',
    'TEAL',
    'PURPLE',
    'MEDIUMVIOLETRED',
    'INDIGO',
    'DARKSLATEBLUE',
    'BROWN',
    'PURPLE',
  ];
  const randomNumber = Math.random();
  return colors[Math.floor(randomNumber * colors.length)];
};

/**
 * Formatting number for thousand seperator
 */
export const formatNumber = (num) =>
  strictValidNumber(num) ? num.toString().replace(VALID_LARGE_NUMBER, '$1,') : 0;

/**
 * Formatting number for thousand seperator
 *
 */
export const formatNumberWithCurrency = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
});

/**
 * Converts integer to double digit example 9 -> 09, 12 -> 12, 992 -> 992
 * @param integer: string
 */
export const integerToDoubleDigit = (integer) =>
  (strictValidNumber(integer) && `0${integer}`.slice(-2)) || '00';

/**
 * Add http to the url;
 * @param integer: string
 */
export const addhttp = (url) => {
  if (strictValidString(url) && !/^(?:f|ht)tps?\:\/\//.test(url)) {
    url = `http://${url}`;
  }
  return url;
};

/**
 * Extract file name from aws s3 path
 * @param url: string
 */
export const extractFileName = (url) => {
  const delimeter = '_';
  let response = (strictValidString(url) && url.split('/').pop()) || '';
  let fileNameArr = [];
  if (strictValidSplittableStringWithMinLength(response, delimeter, 1)) {
    fileNameArr = response.split(delimeter);
    fileNameArr.shift();
    response = fileNameArr.join(delimeter);
  }
  return response;
};
/**
 * check type  of Compensation
 *  * @param url: string array
 */
export const validCompensation = (jobContractArray) => {
  if (jobContractArray.indexOf(messages.JOB_TYPE_OPTIONS.PERMANENT) > -1) {
    return true;
  }
  return jobContractArray.indexOf(messages.JOB_TYPE_OPTIONS.CONTRACT) <= -1;
};

/**
 * Show redux form errors
 * @param meta: object
 * @param custom: object
 */
export const showReduxFormErrors = (meta, custom) => {
  const { error, dirty, submitFailed } = meta;
  const { forceShowError = false, ignoreForceShowErrors = [] } = custom;
  return (
    strictValidString(error) &&
    (submitFailed || (dirty && forceShowError && ignoreForceShowErrors.indexOf(error) <= -1))
  );
};

/**
 * Extract error message
 * @param error: object
 */
export const extractErrorMessage = (error) => {
  let err = null;
  const isValidErrorObject =
    validObjectWithParameterKeys(error, ['response']) &&
    validObjectWithParameterKeys(error.response, ['status', 'data']);
  if (isValidErrorObject) {
    const { data = {} } = error.response;
    const { message } = data;
    // Check if user's session is expired
    if (strictValidString(message)) {
      err = message;
    } else {
      err = messages.DEFAULT_ERROR_MESSAGE;
    }
  } else if (strictValidString(error)) {
    err = error;
  } else {
    err = messages.DEFAULT_ERROR_MESSAGE;
  }
  return err;
};

export const getHours = (hours) => {
  return hours > 1 ? `${hours} Hrs` : `${hours} Hr`;
};

export const getIntegerValue = (val) => Math.round(val);

/**
 * Round off an Integer to 2 decimal places
 * @param value: Integer
 */
export const roundOffTo2Decimals = (value) => {
  return Math.round((value + Number.EPSILON) * 100) / 100;
};

/**
 * Normalize a phone number
 * @param value: String
 */
export const normalizePhone = (value) => {
  if (!value) return value;
  const onlyNums = value.replace(/[^\d]/g, '');
  if (onlyNums.length <= 3) return onlyNums;
  if (onlyNums.length <= 7) return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 7)}`;
  return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`;
};

export const deNormalizePhone = (value) =>
  value ? value.replace(/[^\d]/g, '').slice(0, 10) : value;

/**
 * Typecasts an array to an object with 'label' & 'value' parameters needed for dropdown
 * User can also add a default option if specified
 * NOTE: This dropdown options method is to be used
 * only for React Select having duplicate options
 * @param keyList: array
 * @param valueList: array
 * @param useDefaultOptionFlag: boolean
 * @param defaultOption: string
 * @param otherProps: array
 */
export const getOptionsListFromArrayWithDuplicates = (keyList, valueList, otherProps) => {
  const deducedArr = [];
  let i = -1;
  if (
    strictValidArrayWithLength(keyList) &&
    strictValidArrayWithLength(valueList) &&
    keyList.length === valueList.length
  ) {
    keyList.forEach((v, k) => {
      if (
        strictValidArrayWithLength(otherProps) &&
        k in otherProps &&
        strictValidString(otherProps[k])
      ) {
        i += 1;
        deducedArr.push({ label: otherProps[k], options: [] });
      } else {
        deducedArr[i].options.push({ key: v, label: v, value: valueList[k] });
      }
    });
    return deducedArr;
  }
  return [];
};

export const createNavigationLink = (link, value, willOpenInBlankPage = false) => {
  return willOpenInBlankPage ? (
    <Link className="make-link" target="_blank" to={link}>
      {value}
    </Link>
  ) : (
    <Link className="make-link" to={link}>
      {value}
    </Link>
  );
};

/**
 * Note - This will return number with upto one decimal without round Off
 *
 * function('34.567') = '34.5'
 *
 * @param {value}: Number or(string) eg 8 or '8'
 */

export const uptoOneDecimal = (value = '') => {
  if (!NUMBER_UPTO_ONE_DECIMAL.test(value) && strictValidString(value)) {
    const index = value.indexOf('.');
    return value.substring(0, index + 2);
  }
  return value ? parseFloat(value) : value;
};

/**
 * Scroll modal to top
 */
export const scrollModalToTop = () => {
  const modal = document.getElementsByClassName('modal');
  if (modal.length) {
    modal[0].scrollTop = 0;
  }
};

/**
 * Convert minutes to hours and minutes
 */
export function toHoursAndMinutes(mins) {
  if (isNaN(mins) || mins === 0) return '-';

  const hours = Math.floor(mins / 60);
  const minutes = Math.floor(mins % 60);

  let hoursText;
  let minutesText;

  if (hours === 0) {
    hoursText = '';
  } else if (hours === 1) {
    hoursText = `${hours} hour`;
  } else {
    hoursText = `${hours} hours`;
  }

  if (minutes === 0) {
    minutesText = '';
  } else if (minutes === 1) {
    minutesText = `${minutes} minute`;
  } else {
    minutesText = `${minutes} minutes`;
  }

  if (hours === 0 && minutes > 0) {
    return minutesText;
  }

  if (hours > 0 && minutes === 0) {
    return hoursText;
  }

  return `${hoursText} and ${minutesText}`;
}

export const strictValidMinMaxValue = (min, max, type) => {
  if (min && max) {
    const minvalue = Math.abs(min);
    const maxvalue = Math.abs(max);
    switch (type) {
      case 'min':
        return minvalue <= maxvalue ? minvalue : '';
      case 'max':
        return maxvalue >= minvalue ? maxvalue : '';
      default:
        return '';
    }
  }
  if (type === 'max' && !max) {
    return '';
  }
  return type === 'min' ? min : max;
};

/**
 * convert Experience into months and years i.e 1.5 converted to 1 year 6 months
 * @param exp: Number
 */

export const convertExperinceToMonthYear = (exp) => {
  let years = 0;
  let months = 0;
  const experience = parseFloat(exp);
  if (strictValidNumber(experience) && !isNaN(experience)) {
    const totalMonths = Math.trunc(Math.round(experience * 12));
    years = Math.trunc(experience);
    months = totalMonths - 12 * years;
  }
  return {
    years,
    months,
  };
};

/**
 * Get Text from html string
 * @param value: String
 */

export const extractContentFromHtml = (value) => {
  const div = document.createElement('div');
  div.innerHTML = value;
  const text = div.textContent;
  return text && text.trim();
};

export const downloadURI = (uri, name) => {
  const link = document.createElement('a');
  // If you don't know the name or want to use
  // the webserver default set name = ''
  link.setAttribute('download', name);
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  link.remove();
};

// deformat currency string to proper number
export const formatStrictNumber = (number) => {
  return Number(number.replace(/[^0-9.-]+/g, ''));
};

/**
 * Checks if value boolean
 * @param val: boolean
 */
export const isBoolean = (val) => typeof val === 'boolean';

/**
 * Get conditional name based on paramters
 * @param {*} firstName
 * @param {*} middleName
 * @param {*} lastName
 * @param {*} name
 * @returns
 */

export const getFirstName = (name) => (strictValidString(name) ? name.trim().split(' ')[0] : '');

export const getConditionalName = (
  firstName,
  middleName,
  lastName,
  name,
  firstNameGating = false,
) => {
  const FirstName =
    firstNameGating && firstName ? `${firstName.trim().charAt(0).toUpperCase()}.` : firstName;
  if (
    strictValidString(firstName) &&
    strictValidString(middleName) &&
    strictValidString(lastName)
  ) {
    return `${FirstName} ${middleName.trim().charAt(0).toUpperCase()}. ${lastName
      .trim()
      .charAt(0)
      .toUpperCase()}.`;
  }
  if (strictValidString(firstName) && strictValidString(lastName))
    return `${FirstName} ${lastName.trim().charAt(0).toUpperCase()}.`;
  return getFirstName(name);
};

export const getConditionalNameNoDotSign = (firstName, middleName, lastName, name) => {
  if (strictValidString(firstName) && strictValidString(middleName) && strictValidString(lastName))
    return `${firstName.trim().charAt(0).toUpperCase()}${middleName
      .trim()
      .charAt(0)
      .toUpperCase()}${lastName.trim().charAt(0).toUpperCase()}`;
  if (strictValidString(firstName) && strictValidString(lastName))
    return `${firstName.trim().charAt(0).toUpperCase()}${lastName.trim().charAt(0).toUpperCase()}`;
  return getFirstName(name);
};

/**
 * check for empty value
 * @param value: String
 */

export const isEmpty = (value) => value === undefined || value === null || value === '';

/**
 * Check user image if it is dummy or not
 * @param {*} url
 * @returns
 */
export const checkUserImage = (url) => {
  if (strictValidString(url)) {
    return url.search('users/user.png') > -1 ? '' : url;
  }
  return false;
};

/**
 * Checks if given value is function
 * @param fn: function
 */
export const isFunction = (fn) => validValue(fn) && typeof fn === 'function';

/**
 * Typecast response from api to specified type, default string
 * @param object: string or object containing key: string
 * @param key: string in object
 * @param type: string
 * @param defaultValue: any
 */
export const typeCastResponse = (object, key, type = 'string', defaultValue = null) => {
  let response = null;
  switch (type) {
    case 'number':
      response =
        (validObjectWithParameterKeys(object, [key]) && Number(object[key])) || defaultValue || 0;
      break;
    case 'string':
      response =
        (validObjectWithParameterKeys(object, [key]) && typeCastToString(object[key])) ||
        defaultValue ||
        null;
      break;
    case 'object':
      response =
        (validObjectWithParameterKeys(object, [key]) &&
          strictValidObject(object[key]) &&
          object[key]) ||
        defaultValue ||
        {};
      break;
    case 'array':
      response =
        (validObjectWithParameterKeys(object, [key]) &&
          strictValidArray(object[key]) &&
          object[key]) ||
        defaultValue ||
        [];
      break;
    default:
      break;
  }
  return response;
};

/**
 * Typecast response from api to specified type, default string
 * @param arr: Array
 * @param value: string in object
 * @param label: string
 */
export const createDropdownOption = ({
  arr = [],
  value = '_id',
  label = 'title',
  status = false,
}) => {
  let arrVal = [];
  if (status) {
    arrVal = arr.filter((v) => (validObjectWithParameterKeys(v, ['status']) ? v.status : v));
  } else arrVal = arr;
  return strictValidArrayWithLength(arrVal)
    ? arrVal.map((v) => ({
        value: v[value],
        label: v[label] !== undefined ? v[label].toString() : '',
        ...v,
      }))
    : [];
};

export const clipText = (text, reqLength) => {
  let length = reqLength;
  if (!strictValidNumber(length)) {
    length = text && text.length;
  }
  if (strictValidString(text) && text.length > length) {
    const cliped = text.slice(0, length);
    const dot = '...';
    return cliped.concat('', dot);
  }
  return text;
};
