import { LOCATIONS_REQUEST, LOCATIONS__SUCCESS, LOCATIONS__FAILURE } from './actions';

const initialState = {};

const adminLocations = (state = initialState, action) => {
  switch (action.type) {
    case LOCATIONS_REQUEST:
    case LOCATIONS__SUCCESS:
    case LOCATIONS__FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export { adminLocations };
