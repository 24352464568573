import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CustomReactTable from '../../../components/table/CustomReactTable';
import PrAnimatedInput from '../../../components/--primitives/pr-data-input-2';
import { Dropdown } from '../../../components/final-form/ff-dropdown-input/ff-dropdown-input';
import { activityLogsSuccess } from './actions';
import { getActivityLogs } from './api';
import { showDateTimeInListingWithoutTimezone } from '../../../utils/stringsHelpers';
import { RoleFilterOptions } from '../../../utils/siteSpecificConstants';

/**
 * Please note id is jobId here
 */
class ActivityLogs extends PureComponent {
  componentDidMount() {
    const { dispatch } = this.props;
    activityLogsSuccess(dispatch, {});
  }

  renderInput = (filter, onChange) => (
    <PrAnimatedInput
      type="text"
      onChange={(value) => onChange(value)}
      value={filter ? filter.value : ''}
    />
  );

  renderDropdoenField =({ filter, onChange }) => (
    <Dropdown options={RoleFilterOptions} onChange={(val) => onChange(val)} />
  )
  getColumns = () => [
    {
      Header: '#',
      accessor: 'id',
      minWidth: 15,
      Filter: ({ filter, onChange }) => this.renderInput(filter, onChange),
    },
    {
      Header: 'Event Creator',
      accessor: 'creator',
      minWidth: 16,
      Filter: ({ filter, onChange }) => this.renderInput(filter, onChange),
    },
    {
      Header: 'Role',
      accessor: 'role',
      minWidth: 25,
      Filter: (props) => this.renderDropdoenField(props),
      Cell: (props) => {
        let res = null;
        switch (props.value) {
          case 'super-admin':
            res = 'Super Admin';
            break;
          case 'admin':
            res = 'Admin';
            break;
          case 'account-manager':
            res = 'Account Manager';
            break;
          case 'recruiter':
            res = 'Recruiter';
            break;
          case 'verifier':
            res = 'Candidate Success Manager';
            break;
          case 'client-admin':
            res = 'Client Admin';
            break;
          case 'client-user':
            res = 'Client User';
            break;
          case 'candidate':
            res = 'Candidate';
            break;
          default:
            break;
        }
        return res;
      },
    },
    {
      Header: 'System Activity Log',
      accessor: 'activity',
      minWidth: 50,
      className: 'normal-white-space',
      Filter: ({ filter, onChange }) => this.renderInput(filter, onChange),
    },
    {
      Header: 'Timestamp',
      accessor: 'date',
      minWidth: 18,
      Cell: ({ value }) => showDateTimeInListingWithoutTimezone(value),
      filterable: false,
    },
  ];

  render() {
    const {
      logs,
      callActivityLogListAPI,
      id: jobId,
      clientId,
      interviewId,
      candidateId,
      timesheetId,
      placementId,
      leadId,
    } = this.props;
    const columns = this.getColumns();
    const { count = 0 } = logs;
    return (
      <div className="card">
        <div className="row space-between vertical-center">
          <div className="col">
            <h2 className="card-title">System Activity Logs</h2>
            <div className="card-subtitle">
              {`${count}`} {count > 1 ? 'results found' : 'result found'}
            </div>
          </div>
          <div className="col" />
        </div>
        <p>&nbsp;</p>
        <CustomReactTable
          className="overflow-filter"
          defaultData={logs}
          columns={columns}
          callDataAPI={(...params) =>
            callActivityLogListAPI(
              jobId,
              clientId,
              interviewId,
              candidateId,
              timesheetId,
              placementId,
              leadId,
              ...params,
            )
          }
          defaultPageSize={25}
        />
      </div>
    );
  }
}

ActivityLogs.propTypes = {
  callActivityLogListAPI: PropTypes.func.isRequired,
  logs: PropTypes.objectOf(PropTypes.shape).isRequired,
  dispatch: PropTypes.func.isRequired,
  id: PropTypes.string,
  clientId: PropTypes.string,
  interviewId: PropTypes.string,
  timesheetId: PropTypes.string,
  candidateId: PropTypes.string,
  placementId: PropTypes.string,
  leadId: PropTypes.string,
};

ActivityLogs.defaultProps = {
  id: undefined,
  clientId: undefined,
  interviewId: undefined,
  timesheetId: undefined,
  candidateId: undefined,
  placementId: undefined,
  leadId: undefined,
};

const mapStateProps = (state) => {
  const { data: { rows = [], count = 0 } = {}, loading } = state.get('activityLogs');
  return {
    logs: {
      data: rows,
      count,
      loading,
    },
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  callActivityLogListAPI: (...params) => dispatch(getActivityLogs(...params)),
});

export default withRouter(connect(mapStateProps, mapDispatchToProps)(ActivityLogs));
