const ADD_NOTE_REQUEST = 'ADD_NOTE_REQUEST';
const ADD_NOTE_SUCCESS = 'ADD_NOTE_SUCCESS';
const ADD_NOTE_FAILURE = 'ADD_NOTE_FAILURE';

const addNoteRequest = (dispatch) => {
  dispatch({ type: ADD_NOTE_REQUEST, payload: { loading: true } });
};

const addNoteSuccess = (dispatch, response) => {
  dispatch({ type: ADD_NOTE_SUCCESS, payload: { data: response, loading: false } });
};

const addNoteFailure = (dispatch, error) => {
  dispatch({ type: ADD_NOTE_FAILURE, payload: { error, loading: false } });
};

export {
  ADD_NOTE_REQUEST,
  ADD_NOTE_SUCCESS,
  ADD_NOTE_FAILURE,
  addNoteRequest,
  addNoteSuccess,
  addNoteFailure,
};
