import { errorConstants } from '../constants/error.constants';

export const setError = (error) => (dispatch) => {
  dispatch({
    type: errorConstants.SET_ERROR,
    error,
  });
};

export const flushError = () => (dispatch) => {
  dispatch({
    type: errorConstants.FLUSH,
  });
};
