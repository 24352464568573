import axios from 'axios';
import store from 'store2';
import { request, success, failure } from './actions';
import { consoleOnlyInDebugMode } from '../../../../utils/debugging';
import { extractErrorMessage } from '../../../../utils/commonUtils';
import { errorConstants } from '../../../../redux/constants';

const APIUrl = `${process.env.REACT_APP_APIHOST}/users/`;

const addUser = (values) => async (dispatch) => {
  request(dispatch);
  try {
    const res = await axios.post(APIUrl, values, {
      headers: {
        'x-access-token': store.get('user').token,
      },
    });
    success(dispatch, res.data.data, 'userDetails');
    return true;
  } catch (error) {
    failure(dispatch, extractErrorMessage(error));
    consoleOnlyInDebugMode('err-getUserDetails', extractErrorMessage(error));
    dispatch({
      type: errorConstants.SET_ERROR,
      error,
    });
    return false;
  }
};

const updateUser = (values) => async (dispatch) => {
  request(dispatch);
  try {
    const res = await axios.put(`${APIUrl}/${values._id}`, values, {
      headers: {
        'x-access-token': store.get('user').token,
      },
    });
    success(dispatch, res.data.data, 'userDetails');
    return true;
  } catch (error) {
    failure(dispatch, extractErrorMessage(error));
    consoleOnlyInDebugMode('err-updateUser', extractErrorMessage(error));
    return false;
  }
};

const getUserDetails = (userId) => async (dispatch) => {
  request(dispatch);
  try {
    const res = await axios.get(`${APIUrl}${userId}`, {
      headers: {
        'x-access-token': store.get('user').token,
      },
    });
    success(dispatch, res.data.data, 'userDetails');
    return res.data.data;
  } catch (error) {
    failure(dispatch, extractErrorMessage(error));
    consoleOnlyInDebugMode('err-getUserDetails', extractErrorMessage(error));
    return {};
  }
};

const getUsers =
  (pn = 1, ps = 0, sc = 'id', st = 'desc', sr = {}) =>
  async (dispatch) => {
    request(dispatch);
    try {
      const url = `${APIUrl}?page=${pn}&limit=${ps}&sort=${sc}&order=${st}&search=${JSON.stringify(
        sr,
      )}`;
      const res = await axios.get(url, {
        headers: {
          'x-access-token': store.get('user').token,
        },
      });
      success(dispatch, res.data.data);
      return res.data.data;
    } catch (error) {
      failure(dispatch, extractErrorMessage(error));
      consoleOnlyInDebugMode('err-getUsers', extractErrorMessage(error));
    }
  };

export { addUser, updateUser, getUserDetails, getUsers };
