import React from 'react';
import PropTypes from 'prop-types';
import { Popup, PopupHeader, PopupBody } from '../--primitives/pr-popup';

const ErrorMsg = ({ isOpen, toggle, message }) => {
  return (
    <Popup isOpen={isOpen} action="error">
      <PopupHeader action="error" close toggle={toggle} />
      <PopupBody>
        <div
          className="text content"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: message }}
        />
      </PopupBody>
    </Popup>
  );
};

ErrorMsg.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  message: PropTypes.string,
};

ErrorMsg.defaultProps = {
  isOpen: false,
  toggle: undefined,
  message: null,
};

export default ErrorMsg;
