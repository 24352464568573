const NOTIFICATIONS_REQUEST = 'NOTIFICATIONS_REQUEST';
const NOTIFICATIONS__SUCCESS = 'NOTIFICATIONS__SUCCESS';
const NOTIFICATIONS__FAILURE = 'NOTIFICATIONS__FAILURE';

const notificationsRequest = (dispatch) => {
  dispatch({ type: NOTIFICATIONS_REQUEST, payload: { loading: true } });
};

const notificationsSuccess = (dispatch, response) => {
  dispatch({ type: NOTIFICATIONS__SUCCESS, payload: { data: response, loading: false } });
};

const notificationsFailure = (dispatch) => {
  dispatch({ type: NOTIFICATIONS__FAILURE, payload: { loading: false } });
};

export {
  NOTIFICATIONS_REQUEST,
  NOTIFICATIONS__SUCCESS,
  NOTIFICATIONS__FAILURE,
  notificationsRequest,
  notificationsSuccess,
  notificationsFailure,
};
