import { combineReducers } from 'redux-immutable';
import { statistics } from '../../containers/Dashboard/Main/reducer';
import { risk as jobsAtRisk } from '../../containers/Dashboard/Risk/Jobs/reducer';
import { risk as clientsAtRisk } from '../../containers/Dashboard/Risk/Clients/reducer';
import { risk as candidatesAtRisk } from '../../containers/Dashboard/Risk/Candidates/reducer';
import { risk as interviewsAtRisk } from '../../containers/Dashboard/Risk/Interviews/reducer';
import { risk as assignmentsAtRisk } from '../../containers/Dashboard/Risk/Assignments/reducer';

export default combineReducers({
  statistics,
  jobsAtRisk,
  clientsAtRisk,
  candidatesAtRisk,
  interviewsAtRisk,
  assignmentsAtRisk,
});
